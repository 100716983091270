.coaches-main {
  height: 100vh;
  // height: calc(100vh - 62px);

  .coach-content {
    padding: 110px 106px 0px 106px;
    margin-bottom: 200px;

    .coach-header {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 23px;

      span {
        color: var(--primary-dark, #0d4d80);
        font-family: Arial;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    // .coach-table {
    //     width: 100%;

    //     .coachtable-main {
    //         width: 100%;

    //         .tableheader {
    //             width: 100%;
    //             border: 1px solid red;

    //             .table-head {
    //                 // width: 50%;
    //                 border: 1px solid green;
    //                 width: 25%;
    //             }

    //             .td {
    //                 width: 50%;
    //             }
    //         }
    //     }
    // }
    // .coachtable-main::-webkit-scrollbar {
    //   display: none;
    // }
    .coach-table {
      .coachtable-main {
        overflow-y: auto;
        height: 800px;

        table {
          width: 100%;
          // border: 0.5px solid #000;

          .firstRow {
            // border: 1px solid red;
            // border-right: 0.5px solid #000;
            // border-bottom: 0.5px solid #000;
            // border-left: 0.5px solid #000;
            // background: #FFF;
            // padding: 10px;
            background: #fff;
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);

            .firstTh {
              // border: 1px solid green;
              width: 25%;
              height: 58px;
              // height: 58px;
              // padding-bottom: 3px;
              // border-radius: 10px 10px 0px 0px;
              background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
              // text-align: center;

              span {
                color: #fff;
                font-family: Arial;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
              }
            }

            .secondTh {
              // border: 1px solid green;
              width: 25%;
              // width: 50%;
              // height: 58px;
              // height: 35px;

              // display: flex;
              // justify-content: center;
              // align-items: center;
              // border-bottom: 0.5px solid #000;
              // border-right: 0.5px solid #000;
              padding: 26px 27px;
              color: var(--primary-dark, #0d4d80);
              font-family: Arial;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            .thirdTh {
              // border: 1px solid green;
              width: 25%;
              padding: 26px 27px;
              color: var(--primary-dark, #0d4d80);
              font-family: Arial;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            .fourthTh {
              // border: 1px solid green;
              width: 25%;
              padding: 26px 27px;
              color: var(--primary-dark, #0d4d80);
              font-family: Arial;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            .fifthTh {
              // border: 1px solid green;
              // text-align: center;
              height: 58px;
              width: 25%;
              padding: 26px 27px;
              color: var(--primary-dark, #0d4d80);
              font-family: Arial;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }

          .secondRowP {
            // border: 1px solid red;
            // border-right: 0.5px solid #000;
            // border-bottom: 0.5px solid #000;
            // border-left: 0.5px solid #000;
            // background: #FFF;
            // padding: 10px;
            // background: hotpink;
            // box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
            border-bottom: 1.112px solid var(--Neutral-Gray-Gray-50, #f0f1f3);

            // .firstTh {
            //     border: 1px solid green;
            //     width: 25%;
            //     height: 58px;
            //     // height: 58px;
            //     // padding-bottom: 3px;
            //     // border-radius: 10px 10px 0px 0px;
            //     background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
            //     // text-align: center;

            //     span {
            //         color: #FFF;
            //         font-family: Arial;
            //         font-size: 22px;
            //         font-style: normal;
            //         font-weight: 700;
            //         line-height: normal;
            //         text-transform: capitalize;
            //     }
            // }

            .secondTh {
              // border: 1px solid green;
              width: 25%;
              // width: 50%;
              // height: 58px;
              // height: 35px;

              // display: flex;
              // justify-content: center;
              // align-items: center;
              // border-bottom: 0.5px solid #000;
              // border-right: 0.5px solid #000;
              padding: 26px 27px;
              color: var(--Neutral-Black-Black-700, #333843);
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.238px;
              /* 138.986% */
              letter-spacing: 0.08px;

              // .select {
              //     width: 100%;
              //     height: 30px;
              //     color: var(--Neutral-Black-Black-700, #333843);
              //     font-family: Arial;
              //     font-size: 16px;
              //     font-style: normal;
              //     font-weight: 400;
              //     line-height: 22.238px;
              //     letter-spacing: 0.08px;
              //     border: none;
              //     outline: none;
              //     /* Remove outline when active */

              //     .option {
              //         color: var(--Neutral-Black-Black-700, #333843);
              //         font-family: Arial;
              //         font-size: 16px;
              //         font-style: normal;
              //         font-weight: 400;
              //         line-height: 22.238px;
              //         letter-spacing: 0.08px;
              //         padding: 20px;
              //     }
              // }
              .dropdowntoggle {
                width: 100%;
                background: white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: var(--Neutral-Black-Black-700, #333843);
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22.238px;
                /* 138.986% */
                letter-spacing: 0.08px;
                border: none;
              }

              .dropdownMenu {
                width: 100%;
                color: var(--Neutral-Black-Black-700, #333843);
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22.238px;
                /* 138.986% */
                letter-spacing: 0.08px;

                .dropdownItem {
                  width: 100%;
                  color: var(--Neutral-Black-Black-700, #333843);
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22.238px;
                  /* 138.986% */
                  letter-spacing: 0.08px;
                }

                &.show {
                  width: 100%;
                }
              }

              .dropdown-menu.show {
                width: 100%;
              }
            }

            .thirdTh {
              border: 1px solid green;
              width: 25%;
              padding: 26px 27px;

              color: var(--Neutral-Black-Black-700, #333843);
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.238px;
              /* 138.986% */
              letter-spacing: 0.08px;
            }

            .fourthTh {
              border: 1px solid green;
              width: 25%;
              padding: 26px 27px;
              color: var(--Neutral-Black-Black-700, #333843);
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.238px;
              /* 138.986% */
              letter-spacing: 0.08px;
            }

            .fifthTh {
              border: 1px solid green;
              // text-align: center;
              height: 58px;
              width: 25%;
              padding: 26px 27px;
              color: var(--primary-dark, #0d4d80);
              font-family: Arial;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .coaches-main {
    height: 100%;
    // height: 100vh;
    // height: calc(100vh - 62px);

    .coach-content {
      padding: 110px 70px 70px 70px;




    }
  }
}

@media only screen and (max-width: 1200px) {
  .coaches-main {
    height: 100%;
    // height: calc(100vh - 62px);

    .coach-content {
      padding: 110px 40px 70px 40px;

    }
  }
}

@media only screen and (max-width: 600px) {
  .coaches-main {
    height: 100%;
    // height: calc(100vh - 62px);

    .coach-content {
      padding: 110px 10px 50px 10px;

      .coach-header {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 23px;

        span {
          color: var(--primary-dark, #0D4D80);
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      // .coach-table {
      //     width: 100%;

      //     .coachtable-main {
      //         width: 100%;

      //         .tableheader {
      //             width: 100%;
      //             border: 1px solid red;

      //             .table-head {
      //                 // width: 50%;
      //                 border: 1px solid green;
      //                 width: 25%;
      //             }

      //             .td {
      //                 width: 50%;
      //             }
      //         }
      //     }
      // }
      .coach-table {
        .coachtable-main {
          overflow-x: auto;
          width: 100%;

          table {
            width: 100%;
            // border: 0.5px solid #000;

            .firstRow {
              // border: 1px solid red;
              // border-right: 0.5px solid #000;
              // border-bottom: 0.5px solid #000;
              // border-left: 0.5px solid #000;
              // background: #FFF;
              // padding: 10px;
              background: #FFF;
              box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);

              .firstTh {
                // border: 1px solid green;
                width: 25%;
                height: 58px;
                // height: 58px;
                // padding-bottom: 3px;
                // border-radius: 10px 10px 0px 0px;
                background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                // text-align: center;

                span {
                  color: #FFF;
                  font-family: Arial;
                  font-size: 22px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  text-transform: capitalize;
                }
              }

              .secondTh {
                // border: 1px solid green;
                width: 25%;
                // width: 50%;
                // height: 58px;
                // height: 35px;

                // display: flex;
                // justify-content: center;
                // align-items: center;
                // border-bottom: 0.5px solid #000;
                // border-right: 0.5px solid #000;
                padding: 26px 27px;
                color: var(--primary-dark, #0D4D80);
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              .thirdTh {
                // border: 1px solid green;
                width: 25%;
                padding: 26px 27px;
                color: var(--primary-dark, #0D4D80);
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              .fourthTh {
                // border: 1px solid green;
                width: 25%;
                padding: 26px 27px;
                color: var(--primary-dark, #0D4D80);
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

              }

              .fifthTh {
                // border: 1px solid green;
                // text-align: center;
                height: 58px;
                width: 25%;
                padding: 26px 27px;
                color: var(--primary-dark, #0D4D80);
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

              }
            }

            .secondRowP {
              // border: 1px solid red;
              // border-right: 0.5px solid #000;
              // border-bottom: 0.5px solid #000;
              // border-left: 0.5px solid #000;
              // background: #FFF;
              // padding: 10px;
              // background: hotpink;
              // box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
              border-bottom: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);

              // .firstTh {
              //     border: 1px solid green;
              //     width: 25%;
              //     height: 58px;
              //     // height: 58px;
              //     // padding-bottom: 3px;
              //     // border-radius: 10px 10px 0px 0px;
              //     background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
              //     // text-align: center;

              //     span {
              //         color: #FFF;
              //         font-family: Arial;
              //         font-size: 22px;
              //         font-style: normal;
              //         font-weight: 700;
              //         line-height: normal;
              //         text-transform: capitalize;
              //     }
              // }

              .secondTh {
                // border: 1px solid green;
                width: 25%;
                // width: 50%;
                // height: 58px;
                // height: 35px;

                // display: flex;
                // justify-content: center;
                // align-items: center;
                // border-bottom: 0.5px solid #000;
                // border-right: 0.5px solid #000;
                padding: 26px 27px;
                color: var(--Neutral-Black-Black-700, #333843);
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22.238px;
                /* 138.986% */
                letter-spacing: 0.08px;

                // .select {
                //     width: 100%;
                //     height: 30px;
                //     color: var(--Neutral-Black-Black-700, #333843);
                //     font-family: Arial;
                //     font-size: 16px;
                //     font-style: normal;
                //     font-weight: 400;
                //     line-height: 22.238px;
                //     letter-spacing: 0.08px;
                //     border: none;
                //     outline: none;
                //     /* Remove outline when active */

                //     .option {
                //         color: var(--Neutral-Black-Black-700, #333843);
                //         font-family: Arial;
                //         font-size: 16px;
                //         font-style: normal;
                //         font-weight: 400;
                //         line-height: 22.238px;
                //         letter-spacing: 0.08px;
                //         padding: 20px;
                //     }
                // }
                .dropdowntoggle {
                  width: 100%;
                  background: white;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  color: var(--Neutral-Black-Black-700, #333843);
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22.238px;
                  /* 138.986% */
                  letter-spacing: 0.08px;
                  border: none;

                }

                .dropdownMenu {
                  width: 100%;
                  color: var(--Neutral-Black-Black-700, #333843);
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22.238px;
                  /* 138.986% */
                  letter-spacing: 0.08px;

                  .dropdownItem {
                    width: 100%;
                    color: var(--Neutral-Black-Black-700, #333843);
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22.238px;
                    /* 138.986% */
                    letter-spacing: 0.08px;
                  }

                  &.show {
                    width: 100%;
                  }
                }

                .dropdown-menu.show {
                  width: 100%;
                }
              }

              .thirdTh {
                border: 1px solid green;
                width: 25%;
                padding: 26px 27px;

                color: var(--Neutral-Black-Black-700, #333843);
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22.238px;
                /* 138.986% */
                letter-spacing: 0.08px;
              }

              .fourthTh {
                border: 1px solid green;
                width: 25%;
                padding: 26px 27px;
                color: var(--Neutral-Black-Black-700, #333843);
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22.238px;
                /* 138.986% */
                letter-spacing: 0.08px;

              }

              .fifthTh {
                border: 1px solid green;
                // text-align: center;
                height: 58px;
                width: 25%;
                padding: 26px 27px;
                color: var(--primary-dark, #0D4D80);
                font-family: Arial;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

              }
            }
          }
        }
      }
    }
  }
}


.dropdown-item:active {
  color: #1e2125;
  background-color: #e9ecef;
  opacity: 1 !important;
}

.dropdown-item {
  opacity: 1 !important;
}