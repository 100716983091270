.Genericswot {
  width: 100%;

  .longview-content {
    width: 100%;

    padding: 22px 250px 100px 250px;

    .longview-title {
      .longview-heading {
        text-align: center;
        // filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.1));

        span {
          color: var(--primary-dark, #0d4d80);
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.1));
        }
      }

      // .longview-accordian {
      //     width: 100%;
      //     padding-top: 39px;

      //     .accordian-content {
      //         .accordian-main {

      //             .accordian-item {
      //                 margin-bottom: 16px;
      //                 border-radius: 10px;

      //                 .accordian-header {
      //                     // border-radius: 10px;

      //                     button {
      //                         color: #000;
      //                         font-family: "Poppins";
      //                         font-size: 18px;
      //                         font-style: normal;
      //                         font-weight: 500;
      //                         line-height: normal;
      //                         // background-color: red;
      //                         background: #F8F8F8;
      //                         outline: none;
      //                         box-shadow: none;
      //                         // margin-bottom: 16px;
      //                         border-radius: 10px;

      //                         &:hover {
      //                             background: var(--side-bar, linear-gradient(90deg, #00A2D6 0%, #0D4D80 100%));
      //                             color: white;
      //                         }

      //                         &:active {
      //                             color: white;

      //                             // background-color: #EF5451;

      //                         }

      //                         &::after {
      //                             // filter: invert(-1);
      //                             // background-image: url("../assets/icons/cheveron-down.svg");
      //                             // background-size: 100%;
      //                             // width: 100%;
      //                             // height: 16px;
      //                             stroke-width: 3px;
      //                             stroke: #3D3D3D;
      //                         }

      //                     }

      //                 }

      //                 &:active {
      //                     // background-color: red;
      //                     /* Set the desired background color */

      //                 }
      //             }

      //             .accordianBody {
      //                 width: 100%;
      //                 padding: 24px 32px 50px 32px;
      //                 background: #F8F8F8;

      //                 .Accordian-body {
      //                     .accordianContent {
      //                         .accordiancard {
      //                             display: grid;
      //                             grid-template-columns: repeat(4, 1fr);
      //                             gap: 14px;

      //                             // .strength {
      //                             //     width: 100%;
      //                             //     height: 50px;
      //                             //     padding: 10px 61px;
      //                             //     border-radius: 10px;
      //                             //     background: #9CCC65;
      //                             //     display: flex;
      //                             //     justify-content: center;
      //                             //     align-items: center;

      //                             //     span {
      //                             //         color: var(--white);
      //                             //         font-family: Arial;
      //                             //         font-size: 20px;
      //                             //         font-style: normal;
      //                             //         font-weight: 400;
      //                             //         line-height: normal;
      //                             //     }
      //                             // }

      //                             // .Weaknesses {
      //                             //     width: 100%;
      //                             //     height: 50px;
      //                             //     padding: 10px 61px;
      //                             //     border-radius: 10px;
      //                             //     background: linear-gradient(90deg, #EF5451 0%, #F17C79 100%);

      //                             //     display: flex;
      //                             //     justify-content: center;
      //                             //     align-items: center;

      //                             //     span {
      //                             //         color: var(--white);
      //                             //         font-family: Arial;
      //                             //         font-size: 20px;
      //                             //         font-style: normal;
      //                             //         font-weight: 400;
      //                             //         line-height: normal;
      //                             //     }
      //                             // }

      //                             // .Opportunities {
      //                             //     width: 100%;
      //                             //     height: 50px;
      //                             //     padding: 10px 61px;
      //                             //     border-radius: 10px;
      //                             //     background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);

      //                             //     display: flex;
      //                             //     justify-content: center;
      //                             //     align-items: center;

      //                             //     span {
      //                             //         color: var(--white);
      //                             //         font-family: Arial;
      //                             //         font-size: 20px;
      //                             //         font-style: normal;
      //                             //         font-weight: 400;
      //                             //         line-height: normal;
      //                             //     }
      //                             // }

      //                             // .Threats {
      //                             //     width: 100%;
      //                             //     height: 50px;
      //                             //     padding: 10px 61px;
      //                             //     border-radius: 10px;
      //                             //     background: linear-gradient(90deg, #FFC515 0%, #FDD55D 100%);
      //                             //     display: flex;
      //                             //     justify-content: center;
      //                             //     align-items: center;

      //                             //     span {
      //                             //         color: var(--white);
      //                             //         font-family: Arial;
      //                             //         font-size: 20px;
      //                             //         font-style: normal;
      //                             //         font-weight: 400;
      //                             //         line-height: normal;
      //                             //     }
      //                             // }
      //                             .purposeCard {

      //                                 .firstCard {
      //                                     padding: 15px 10px 6px 6px;
      //                                     border-radius: 12px;
      //                                     background: #E1EDD3;
      //                                     text-align: center;

      //                                     // text-align: center;

      //                                     h6 {
      //                                         color: #000;
      //                                         font-family: Arial;
      //                                         font-size: 14px;
      //                                         font-style: normal;
      //                                         font-weight: 400;
      //                                         line-height: normal;
      //                                     }

      //                                     .important-tag {
      //                                         display: flex;
      //                                         justify-content: end;

      //                                         .badgetag {
      //                                             width: 73.9px;
      //                                             // height: 17.186px;
      //                                             flex-shrink: 0;
      //                                             border-radius: 8.593px;
      //                                             background: #F00;
      //                                             display: flex;
      //                                             gap: 6px;
      //                                             // justify-content: space-between;
      //                                             align-items: center;

      //                                             // justify-content: end;
      //                                             span {
      //                                                 color: #FFF;
      //                                                 font-family: "Poppins";
      //                                                 font-size: 8.593px;
      //                                                 font-style: normal;
      //                                                 font-weight: 500;
      //                                                 line-height: normal;
      //                                                 text-transform: capitalize;
      //                                                 // padding-left: 6px;
      //                                             }
      //                                         }
      //                                     }
      //                                 }
      //                             }

      //                             .purposeCard1 {

      //                                 .firstCard {
      //                                     padding: 15px 10px 6px 6px;
      //                                     border-radius: 12px;
      //                                     // background: #E1EDD3;
      //                                     background: #F6D6D5;
      //                                     height: 53px;

      //                                     // text-align: center;

      //                                     h6 {
      //                                         color: #000;
      //                                         font-family: Arial;
      //                                         font-size: 14px;
      //                                         font-style: normal;
      //                                         font-weight: 400;
      //                                         line-height: normal;
      //                                         text-align: center;
      //                                     }

      //                                 }
      //                             }

      //                             .purposeCard2 {

      //                                 .firstCard {
      //                                     padding: 15px 10px 6px 6px;
      //                                     border-radius: 12px;
      //                                     // background: #E1EDD3;
      //                                     // background: #F6D6D5;
      //                                     background: #BFD6E8;
      //                                     height: 53px;

      //                                     // text-align: center;

      //                                     h6 {
      //                                         color: #000;
      //                                         font-family: Arial;
      //                                         font-size: 14px;
      //                                         font-style: normal;
      //                                         font-weight: 400;
      //                                         line-height: normal;
      //                                         text-align: center;
      //                                     }

      //                                 }
      //                             }

      //                             .purposeCard3 {

      //                                 .firstCard {
      //                                     padding: 15px 10px 6px 6px;
      //                                     border-radius: 12px;
      //                                     height: 53px;
      //                                     // background: #E1EDD3;
      //                                     // background: #F6D6D5;
      //                                     // background: #BFD6E8;
      //                                     background: #FAEEC9;

      //                                     // text-align: center;

      //                                     h6 {
      //                                         color: #000;
      //                                         font-family: Arial;
      //                                         font-size: 14px;
      //                                         font-style: normal;
      //                                         font-weight: 400;
      //                                         line-height: normal;
      //                                         text-align: center;
      //                                     }

      //                                 }
      //                             }
      //                         }
      //                     }
      //                 }
      //             }
      //         }
      //     }
      // }
    }

    // .accordianSection {
    //     width: 100%;
    //     padding-top: 28px;

    //     .accordianmain {

    //         .accordianItem {
    //             // background: #F8F8F8;
    //             margin-top: 16px;

    //             .accordionHeader {

    //                 border-radius: 10px 10px 0px 0px;
    //                 background: var(--side-bar, linear-gradient(90deg, #00A2D6 0%, #0D4D80 100%));

    //                 // padding-left: 23px;
    //                 filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
    //                 // padding-right: 23px;
    //                 color: white;
    //                 font-family: "Poppins";
    //                 font-size: 18px;
    //                 font-style: normal;
    //                 font-weight: 500;
    //                 line-height: normal;
    //                 height: 53px;

    //                 .accordion-button {
    //                     // display: flex;
    //                     // justify-content: space-between !important;
    //                     // align-items: center;

    //                     height: 100%;
    //                     width: 100%;
    //                     padding: 0px 23px;

    //                     svg path {
    //                         stroke: white;
    //                     }
    //                 }

    //                 .collapsed {
    //                     background: #F8F8F8;
    //                     color: black !important;
    //                     width: 100%;
    //                     height: 100%;

    //                     svg path {
    //                         stroke: black;
    //                     }
    //                 }

    //             }

    //             .accordianBody {
    //                 background: #F8F8F8;

    //                 box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    //                 border-bottom-left-radius: 10px;
    //                 border-bottom-right-radius: 10px;

    //                 .accordianContent {
    //                     width: 100%;
    //                     padding: 24px 32px 32px 32px;

    //                     .accordiancard {
    //                         display: flex;
    //                         gap: 6px;

    //                         .firstCard {
    //                             width: 25%;
    //                             // height: 400px;
    //                             // background: gainsboro;

    //                             .strengthFcard {
    //                                 width: 100%;
    //                                 height: auto;
    //                                 margin-top: 6px;
    //                                 border-radius: 12px;
    //                                 background: #E1EDD3;
    //                                 padding: 15px 10px 6px 6px;
    //                                 display: flex;
    //                                 flex-direction: column;
    //                                 justify-content: space-between;

    //                                 .Cardtext {

    //                                     span {
    //                                         color: #000;
    //                                         font-family: Arial;
    //                                         font-size: 14px;
    //                                         font-style: normal;
    //                                         font-weight: 400;
    //                                         line-height: normal;
    //                                     }
    //                                 }

    //                                 .cardBadge {
    //                                     display: flex;
    //                                     justify-content: end;
    //                                     padding-top: 6px;

    //                                     .redBadge {
    //                                         width: 73.9px;
    //                                         height: auto;
    //                                         // height: 17.186px;
    //                                         flex-shrink: 0;
    //                                         border-radius: 8.593px;
    //                                         background: #F00;
    //                                         display: flex;
    //                                         align-items: center;

    //                                         span {
    //                                             color: #FFF;
    //                                             font-family: "Poppins";
    //                                             font-size: 8.593px;
    //                                             font-style: normal;
    //                                             font-weight: 500;
    //                                             line-height: normal;
    //                                             text-transform: capitalize;
    //                                         }
    //                                     }
    //                                 }
    //                             }

    //                             .strengthFcard2 {
    //                                 width: 100%;
    //                                 height: auto;
    //                                 margin-top: 6px;
    //                                 border-radius: 12px;
    //                                 background: #F6D6D5;
    //                                 padding: 15px 10px 6px 6px;
    //                                 display: flex;
    //                                 flex-direction: column;
    //                                 justify-content: space-between;

    //                                 .Cardtext {

    //                                     span {
    //                                         color: #000;
    //                                         font-family: Arial;
    //                                         font-size: 14px;
    //                                         font-style: normal;
    //                                         font-weight: 400;
    //                                         line-height: normal;
    //                                     }
    //                                 }

    //                                 .cardBadge {
    //                                     display: flex;
    //                                     justify-content: end;
    //                                     padding-top: 6px;

    //                                     .redBadge {
    //                                         width: 73.9px;
    //                                         height: auto;
    //                                         // height: 17.186px;
    //                                         flex-shrink: 0;
    //                                         border-radius: 8.593px;
    //                                         background: #F00;
    //                                         display: flex;
    //                                         align-items: center;

    //                                         span {
    //                                             color: #FFF;
    //                                             font-family: "Poppins";
    //                                             font-size: 8.593px;
    //                                             font-style: normal;
    //                                             font-weight: 500;
    //                                             line-height: normal;
    //                                             text-transform: capitalize;
    //                                         }
    //                                     }

    //                                     .clearBadge {
    //                                         width: 50px;
    //                                         height: auto;
    //                                         // height: 17.186px;
    //                                         flex-shrink: 0;
    //                                         border-radius: 8.593px;
    //                                         background: #9CCC65;
    //                                         display: flex;
    //                                         align-items: center;

    //                                         span {
    //                                             color: #FFF;
    //                                             font-family: "Poppins";
    //                                             font-size: 8.593px;
    //                                             font-style: normal;
    //                                             font-weight: 500;
    //                                             line-height: normal;
    //                                             text-transform: capitalize;
    //                                         }
    //                                     }
    //                                 }
    //                             }

    //                             .strengthFcard3 {
    //                                 width: 100%;
    //                                 height: auto;
    //                                 margin-top: 6px;
    //                                 border-radius: 12px;
    //                                 background: #BFD6E8;
    //                                 padding: 15px 10px 6px 6px;
    //                                 display: flex;
    //                                 flex-direction: column;
    //                                 justify-content: space-between;

    //                                 .Cardtext {

    //                                     span {
    //                                         color: #000;
    //                                         font-family: Arial;
    //                                         font-size: 14px;
    //                                         font-style: normal;
    //                                         font-weight: 400;
    //                                         line-height: normal;
    //                                     }
    //                                 }

    //                                 .cardBadge {
    //                                     display: flex;
    //                                     justify-content: end;
    //                                     padding-top: 6px;

    //                                     .redBadge {
    //                                         width: 73.9px;
    //                                         height: auto;
    //                                         // height: 17.186px;
    //                                         flex-shrink: 0;
    //                                         border-radius: 8.593px;
    //                                         background: #F00;
    //                                         display: flex;
    //                                         align-items: center;

    //                                         span {
    //                                             color: #FFF;
    //                                             font-family: "Poppins";
    //                                             font-size: 8.593px;
    //                                             font-style: normal;
    //                                             font-weight: 500;
    //                                             line-height: normal;
    //                                             text-transform: capitalize;
    //                                         }
    //                                     }
    //                                 }
    //                             }

    //                             .strengthFcard4 {
    //                                 width: 100%;
    //                                 height: auto;
    //                                 margin-top: 6px;
    //                                 border-radius: 12px;
    //                                 background: #FAEEC9;
    //                                 padding: 15px 10px 6px 6px;
    //                                 display: flex;
    //                                 flex-direction: column;
    //                                 justify-content: space-between;

    //                                 .Cardtext {

    //                                     span {
    //                                         color: #000;
    //                                         font-family: Arial;
    //                                         font-size: 14px;
    //                                         font-style: normal;
    //                                         font-weight: 400;
    //                                         line-height: normal;
    //                                     }
    //                                 }

    //                                 .cardBadge {
    //                                     display: flex;
    //                                     justify-content: end;
    //                                     padding-top: 6px;

    //                                     .redBadge {
    //                                         width: 73.9px;
    //                                         height: auto;
    //                                         // height: 17.186px;
    //                                         flex-shrink: 0;
    //                                         border-radius: 8.593px;
    //                                         background: #F00;
    //                                         display: flex;
    //                                         align-items: center;

    //                                         span {
    //                                             color: #FFF;
    //                                             font-family: "Poppins";
    //                                             font-size: 8.593px;
    //                                             font-style: normal;
    //                                             font-weight: 500;
    //                                             line-height: normal;
    //                                             text-transform: capitalize;
    //                                         }
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }

    //             }

    //         }
    //     }
    // }
    .accordan {
      // padding-left: 104px;
      // padding-right: 104px;

      .accordianParent {
        border: none;

        .accordianItem1 {
          border: none;
          margin-top: 20px;
          border-radius: 10px;
          background: #f8f8f8;

          .accordianHeader {
            border: none;
            display: flex;
            justify-content: space-between;

            .accordion-button {
              background: var(
                --side-bar,
                linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
              );

              display: flex;
              justify-content: space-between;
              color: white;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
              filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

              &::after {
                display: none;
              }

              &:focus {
                box-shadow: none;
              }
            }

            .collapsed {
              background: #f8f8f8;
              color: black;
            }
          }

          .accordion-content {
            background: #f8f8f8;
            filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
            border-radius: 0px 0px 10px 10px;
            padding: 24px 32px 32px 32px;

            .accordianContent {
              width: 100%;
              // padding: 24px 32px 32px 32px;

              .accordiancard {
                display: flex;
                gap: 6px;

                .firstCard {
                  width: 25%;
                  // height: 400px;
                  // background: gainsboro;

                  .strengthFcard {
                    width: 100%;
                    height: auto;
                    margin-top: 6px;
                    border-radius: 12px;
                    background: #e1edd3;
                    padding: 15px 10px 6px 6px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .Cardtext {
                      span {
                        color: #000;
                        font-family: Arial;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                      }
                      ul {
                        list-style: DISC;
                        padding-left: 24px;
                      }
                    }

                    .cardBadge {
                      display: flex;
                      justify-content: end;
                      padding-top: 6px;

                      .redBadge {
                        width: 73.9px;
                        height: auto;
                        // height: 17.186px;
                        flex-shrink: 0;
                        border-radius: 8.593px;
                        background: #f00;
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        cursor: pointer;

                        span {
                          color: #fff;
                          font-family: "Poppins";
                          font-size: 8.593px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }
                    }
                  }
                  .consistentFcard {
                    width: 100%;
                    height: auto;
                    margin-top: 6px;
                    border-radius: 12px;
                    background: #eff0ee;
                    padding: 15px 10px 6px 6px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    -webkit-box-shadow: 0px 0px 15px -3px rgba(153, 142, 153, 1);
                    -moz-box-shadow: 0px 0px 15px -3px rgba(153, 142, 153, 1);
                    box-shadow: 0px 0px 15px -3px rgba(153, 142, 153, 1);
                    .Cardtext {
                      span {
                        color: #000;
                        font-family: Arial;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                      }
                      ul {
                        list-style: DISC;
                        padding-left: 24px;
                      }
                    }

                    .cardBadge {
                      display: flex;
                      justify-content: end;
                      padding-top: 6px;

                      .redBadge {
                        width: 73.9px;
                        height: auto;
                        // height: 17.186px;
                        flex-shrink: 0;
                        border-radius: 8.593px;
                        background: #f00;
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        cursor: pointer;

                        span {
                          color: #fff;
                          font-family: "Poppins";
                          font-size: 8.593px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }
                    }
                  }

                  .strengthFcard2 {
                    width: 100%;
                    height: auto;
                    margin-top: 6px;
                    border-radius: 12px;
                    background: #f6d6d5;
                    padding: 15px 10px 6px 6px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .Cardtext {
                      span {
                        color: #000;
                        font-family: Arial;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                      }
                      ul {
                        list-style: DISC;
                        padding-left: 24px;
                      }
                    }

                    .cardBadge {
                      display: flex;
                      justify-content: end;
                      padding-top: 6px;

                      .redBadge {
                        width: 73.9px;
                        height: auto;
                        // height: 17.186px;
                        flex-shrink: 0;
                        border-radius: 8.593px;
                        background: #f00;
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        cursor: pointer;

                        span {
                          color: #fff;
                          font-family: "Poppins";
                          font-size: 8.593px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }

                      .clearBadge {
                        width: 50px;
                        height: auto;
                        // height: 17.186px;
                        flex-shrink: 0;
                        border-radius: 8.593px;
                        background: #9ccc65;
                        display: flex;
                        align-items: center;

                        span {
                          color: #fff;
                          font-family: "Poppins";
                          font-size: 8.593px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }
                    }
                  }

                  .strengthFcard3 {
                    width: 100%;
                    height: auto;
                    margin-top: 6px;
                    border-radius: 12px;
                    background: #bfd6e8;
                    padding: 15px 10px 6px 6px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .Cardtext {
                      span {
                        color: #000;
                        font-family: Arial;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                      }
                      ul {
                        list-style: DISC;
                        padding-left: 24px;
                      }
                    }

                    .cardBadge {
                      display: flex;
                      justify-content: end;
                      padding-top: 6px;

                      .redBadge {
                        width: 73.9px;
                        height: auto;
                        // height: 17.186px;
                        flex-shrink: 0;
                        border-radius: 8.593px;
                        background: #f00;
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        cursor: pointer;

                        span {
                          color: #fff;
                          font-family: "Poppins";
                          font-size: 8.593px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }
                    }
                  }

                  .strengthFcard4 {
                    width: 100%;
                    height: auto;
                    margin-top: 6px;
                    border-radius: 12px;
                    background: #faeec9;
                    padding: 15px 10px 6px 6px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .Cardtext {
                      span {
                        color: #000;
                        font-family: Arial;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                      }
                      ul {
                        list-style: DISC;
                        padding-left: 24px;
                      }
                    }

                    .cardBadge {
                      display: flex;
                      justify-content: end;
                      padding-top: 6px;

                      .redBadge {
                        width: 73.9px;
                        height: auto;
                        // height: 17.186px;
                        flex-shrink: 0;
                        border-radius: 8.593px;
                        background: #f00;
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        cursor: pointer;

                        span {
                          color: #fff;
                          font-family: "Poppins";
                          font-size: 8.593px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &:active {
          }
        }
      }
    }

    .accordianSubmission {
      padding-block: 30px;
      width: 100%;
      display: flex;
      justify-content: end;

      .Previous {
        border-radius: 4px;
        background: #8d8d8d;
        width: 84px;
        height: 25.714px;
        flex-shrink: 0;
        color: white;
        margin-right: 10px;
      }

      .save {
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // width: 84px;
        // width: 150px;
        height: 25.714px;
        border-radius: 4px;
        background: linear-gradient(
          90deg,
          #00a2d6 0%,
          rgba(0, 162, 214, 0.58) 100%
        );
        color: white;
        padding: 2px 10px 0px 10px;
        width: fit-content !important;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .Genericswot {
    width: 100%;

    .longview-content {
      width: 100%;

      padding: 22px 50px 100px 50px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .Genericswot {
    width: 100%;

    .longview-content {
      width: 100%;

      padding: 22px 10px 100px 10px;

      .longview-title {
        .longview-heading {
          text-align: center;
          // filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.1));

          span {
            color: var(--primary-dark, #0d4d80);
            font-family: Arial;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.1));
          }
        }

        // .longview-accordian {
        //     width: 100%;
        //     padding-top: 39px;

        //     .accordian-content {
        //         .accordian-main {

        //             .accordian-item {
        //                 margin-bottom: 16px;
        //                 border-radius: 10px;

        //                 .accordian-header {
        //                     // border-radius: 10px;

        //                     button {
        //                         color: #000;
        //                         font-family: "Poppins";
        //                         font-size: 18px;
        //                         font-style: normal;
        //                         font-weight: 500;
        //                         line-height: normal;
        //                         // background-color: red;
        //                         background: #F8F8F8;
        //                         outline: none;
        //                         box-shadow: none;
        //                         // margin-bottom: 16px;
        //                         border-radius: 10px;

        //                         &:hover {
        //                             background: var(--side-bar, linear-gradient(90deg, #00A2D6 0%, #0D4D80 100%));
        //                             color: white;
        //                         }

        //                         &:active {
        //                             color: white;

        //                             // background-color: #EF5451;

        //                         }

        //                         &::after {
        //                             // filter: invert(-1);
        //                             // background-image: url("../assets/icons/cheveron-down.svg");
        //                             // background-size: 100%;
        //                             // width: 100%;
        //                             // height: 16px;
        //                             stroke-width: 3px;
        //                             stroke: #3D3D3D;
        //                         }

        //                     }

        //                 }

        //                 &:active {
        //                     // background-color: red;
        //                     /* Set the desired background color */

        //                 }
        //             }

        //             .accordianBody {
        //                 width: 100%;
        //                 padding: 24px 32px 50px 32px;
        //                 background: #F8F8F8;

        //                 .Accordian-body {
        //                     .accordianContent {
        //                         .accordiancard {
        //                             display: grid;
        //                             grid-template-columns: repeat(4, 1fr);
        //                             gap: 14px;

        //                             // .strength {
        //                             //     width: 100%;
        //                             //     height: 50px;
        //                             //     padding: 10px 61px;
        //                             //     border-radius: 10px;
        //                             //     background: #9CCC65;
        //                             //     display: flex;
        //                             //     justify-content: center;
        //                             //     align-items: center;

        //                             //     span {
        //                             //         color: var(--white);
        //                             //         font-family: Arial;
        //                             //         font-size: 20px;
        //                             //         font-style: normal;
        //                             //         font-weight: 400;
        //                             //         line-height: normal;
        //                             //     }
        //                             // }

        //                             // .Weaknesses {
        //                             //     width: 100%;
        //                             //     height: 50px;
        //                             //     padding: 10px 61px;
        //                             //     border-radius: 10px;
        //                             //     background: linear-gradient(90deg, #EF5451 0%, #F17C79 100%);

        //                             //     display: flex;
        //                             //     justify-content: center;
        //                             //     align-items: center;

        //                             //     span {
        //                             //         color: var(--white);
        //                             //         font-family: Arial;
        //                             //         font-size: 20px;
        //                             //         font-style: normal;
        //                             //         font-weight: 400;
        //                             //         line-height: normal;
        //                             //     }
        //                             // }

        //                             // .Opportunities {
        //                             //     width: 100%;
        //                             //     height: 50px;
        //                             //     padding: 10px 61px;
        //                             //     border-radius: 10px;
        //                             //     background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);

        //                             //     display: flex;
        //                             //     justify-content: center;
        //                             //     align-items: center;

        //                             //     span {
        //                             //         color: var(--white);
        //                             //         font-family: Arial;
        //                             //         font-size: 20px;
        //                             //         font-style: normal;
        //                             //         font-weight: 400;
        //                             //         line-height: normal;
        //                             //     }
        //                             // }

        //                             // .Threats {
        //                             //     width: 100%;
        //                             //     height: 50px;
        //                             //     padding: 10px 61px;
        //                             //     border-radius: 10px;
        //                             //     background: linear-gradient(90deg, #FFC515 0%, #FDD55D 100%);
        //                             //     display: flex;
        //                             //     justify-content: center;
        //                             //     align-items: center;

        //                             //     span {
        //                             //         color: var(--white);
        //                             //         font-family: Arial;
        //                             //         font-size: 20px;
        //                             //         font-style: normal;
        //                             //         font-weight: 400;
        //                             //         line-height: normal;
        //                             //     }
        //                             // }
        //                             .purposeCard {

        //                                 .firstCard {
        //                                     padding: 15px 10px 6px 6px;
        //                                     border-radius: 12px;
        //                                     background: #E1EDD3;
        //                                     text-align: center;

        //                                     // text-align: center;

        //                                     h6 {
        //                                         color: #000;
        //                                         font-family: Arial;
        //                                         font-size: 14px;
        //                                         font-style: normal;
        //                                         font-weight: 400;
        //                                         line-height: normal;
        //                                     }

        //                                     .important-tag {
        //                                         display: flex;
        //                                         justify-content: end;

        //                                         .badgetag {
        //                                             width: 73.9px;
        //                                             // height: 17.186px;
        //                                             flex-shrink: 0;
        //                                             border-radius: 8.593px;
        //                                             background: #F00;
        //                                             display: flex;
        //                                             gap: 6px;
        //                                             // justify-content: space-between;
        //                                             align-items: center;

        //                                             // justify-content: end;
        //                                             span {
        //                                                 color: #FFF;
        //                                                 font-family: "Poppins";
        //                                                 font-size: 8.593px;
        //                                                 font-style: normal;
        //                                                 font-weight: 500;
        //                                                 line-height: normal;
        //                                                 text-transform: capitalize;
        //                                                 // padding-left: 6px;
        //                                             }
        //                                         }
        //                                     }
        //                                 }
        //                             }

        //                             .purposeCard1 {

        //                                 .firstCard {
        //                                     padding: 15px 10px 6px 6px;
        //                                     border-radius: 12px;
        //                                     // background: #E1EDD3;
        //                                     background: #F6D6D5;
        //                                     height: 53px;

        //                                     // text-align: center;

        //                                     h6 {
        //                                         color: #000;
        //                                         font-family: Arial;
        //                                         font-size: 14px;
        //                                         font-style: normal;
        //                                         font-weight: 400;
        //                                         line-height: normal;
        //                                         text-align: center;
        //                                     }

        //                                 }
        //                             }

        //                             .purposeCard2 {

        //                                 .firstCard {
        //                                     padding: 15px 10px 6px 6px;
        //                                     border-radius: 12px;
        //                                     // background: #E1EDD3;
        //                                     // background: #F6D6D5;
        //                                     background: #BFD6E8;
        //                                     height: 53px;

        //                                     // text-align: center;

        //                                     h6 {
        //                                         color: #000;
        //                                         font-family: Arial;
        //                                         font-size: 14px;
        //                                         font-style: normal;
        //                                         font-weight: 400;
        //                                         line-height: normal;
        //                                         text-align: center;
        //                                     }

        //                                 }
        //                             }

        //                             .purposeCard3 {

        //                                 .firstCard {
        //                                     padding: 15px 10px 6px 6px;
        //                                     border-radius: 12px;
        //                                     height: 53px;
        //                                     // background: #E1EDD3;
        //                                     // background: #F6D6D5;
        //                                     // background: #BFD6E8;
        //                                     background: #FAEEC9;

        //                                     // text-align: center;

        //                                     h6 {
        //                                         color: #000;
        //                                         font-family: Arial;
        //                                         font-size: 14px;
        //                                         font-style: normal;
        //                                         font-weight: 400;
        //                                         line-height: normal;
        //                                         text-align: center;
        //                                     }

        //                                 }
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
      }

      // .accordianSection {
      //     width: 100%;
      //     padding-top: 28px;

      //     .accordianmain {

      //         .accordianItem {
      //             // background: #F8F8F8;
      //             margin-top: 16px;

      //             .accordionHeader {

      //                 border-radius: 10px 10px 0px 0px;
      //                 background: var(--side-bar, linear-gradient(90deg, #00A2D6 0%, #0D4D80 100%));

      //                 // padding-left: 23px;
      //                 filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
      //                 // padding-right: 23px;
      //                 color: white;
      //                 font-family: "Poppins";
      //                 font-size: 18px;
      //                 font-style: normal;
      //                 font-weight: 500;
      //                 line-height: normal;
      //                 height: 53px;

      //                 .accordion-button {
      //                     // display: flex;
      //                     // justify-content: space-between !important;
      //                     // align-items: center;

      //                     height: 100%;
      //                     width: 100%;
      //                     padding: 0px 23px;

      //                     svg path {
      //                         stroke: white;
      //                     }
      //                 }

      //                 .collapsed {
      //                     background: #F8F8F8;
      //                     color: black !important;
      //                     width: 100%;
      //                     height: 100%;

      //                     svg path {
      //                         stroke: black;
      //                     }
      //                 }

      //             }

      //             .accordianBody {
      //                 background: #F8F8F8;

      //                 box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      //                 border-bottom-left-radius: 10px;
      //                 border-bottom-right-radius: 10px;

      //                 .accordianContent {
      //                     width: 100%;
      //                     padding: 24px 32px 32px 32px;

      //                     .accordiancard {
      //                         display: flex;
      //                         gap: 6px;

      //                         .firstCard {
      //                             width: 25%;
      //                             // height: 400px;
      //                             // background: gainsboro;

      //                             .strengthFcard {
      //                                 width: 100%;
      //                                 height: auto;
      //                                 margin-top: 6px;
      //                                 border-radius: 12px;
      //                                 background: #E1EDD3;
      //                                 padding: 15px 10px 6px 6px;
      //                                 display: flex;
      //                                 flex-direction: column;
      //                                 justify-content: space-between;

      //                                 .Cardtext {

      //                                     span {
      //                                         color: #000;
      //                                         font-family: Arial;
      //                                         font-size: 14px;
      //                                         font-style: normal;
      //                                         font-weight: 400;
      //                                         line-height: normal;
      //                                     }
      //                                 }

      //                                 .cardBadge {
      //                                     display: flex;
      //                                     justify-content: end;
      //                                     padding-top: 6px;

      //                                     .redBadge {
      //                                         width: 73.9px;
      //                                         height: auto;
      //                                         // height: 17.186px;
      //                                         flex-shrink: 0;
      //                                         border-radius: 8.593px;
      //                                         background: #F00;
      //                                         display: flex;
      //                                         align-items: center;

      //                                         span {
      //                                             color: #FFF;
      //                                             font-family: "Poppins";
      //                                             font-size: 8.593px;
      //                                             font-style: normal;
      //                                             font-weight: 500;
      //                                             line-height: normal;
      //                                             text-transform: capitalize;
      //                                         }
      //                                     }
      //                                 }
      //                             }

      //                             .strengthFcard2 {
      //                                 width: 100%;
      //                                 height: auto;
      //                                 margin-top: 6px;
      //                                 border-radius: 12px;
      //                                 background: #F6D6D5;
      //                                 padding: 15px 10px 6px 6px;
      //                                 display: flex;
      //                                 flex-direction: column;
      //                                 justify-content: space-between;

      //                                 .Cardtext {

      //                                     span {
      //                                         color: #000;
      //                                         font-family: Arial;
      //                                         font-size: 14px;
      //                                         font-style: normal;
      //                                         font-weight: 400;
      //                                         line-height: normal;
      //                                     }
      //                                 }

      //                                 .cardBadge {
      //                                     display: flex;
      //                                     justify-content: end;
      //                                     padding-top: 6px;

      //                                     .redBadge {
      //                                         width: 73.9px;
      //                                         height: auto;
      //                                         // height: 17.186px;
      //                                         flex-shrink: 0;
      //                                         border-radius: 8.593px;
      //                                         background: #F00;
      //                                         display: flex;
      //                                         align-items: center;

      //                                         span {
      //                                             color: #FFF;
      //                                             font-family: "Poppins";
      //                                             font-size: 8.593px;
      //                                             font-style: normal;
      //                                             font-weight: 500;
      //                                             line-height: normal;
      //                                             text-transform: capitalize;
      //                                         }
      //                                     }

      //                                     .clearBadge {
      //                                         width: 50px;
      //                                         height: auto;
      //                                         // height: 17.186px;
      //                                         flex-shrink: 0;
      //                                         border-radius: 8.593px;
      //                                         background: #9CCC65;
      //                                         display: flex;
      //                                         align-items: center;

      //                                         span {
      //                                             color: #FFF;
      //                                             font-family: "Poppins";
      //                                             font-size: 8.593px;
      //                                             font-style: normal;
      //                                             font-weight: 500;
      //                                             line-height: normal;
      //                                             text-transform: capitalize;
      //                                         }
      //                                     }
      //                                 }
      //                             }

      //                             .strengthFcard3 {
      //                                 width: 100%;
      //                                 height: auto;
      //                                 margin-top: 6px;
      //                                 border-radius: 12px;
      //                                 background: #BFD6E8;
      //                                 padding: 15px 10px 6px 6px;
      //                                 display: flex;
      //                                 flex-direction: column;
      //                                 justify-content: space-between;

      //                                 .Cardtext {

      //                                     span {
      //                                         color: #000;
      //                                         font-family: Arial;
      //                                         font-size: 14px;
      //                                         font-style: normal;
      //                                         font-weight: 400;
      //                                         line-height: normal;
      //                                     }
      //                                 }

      //                                 .cardBadge {
      //                                     display: flex;
      //                                     justify-content: end;
      //                                     padding-top: 6px;

      //                                     .redBadge {
      //                                         width: 73.9px;
      //                                         height: auto;
      //                                         // height: 17.186px;
      //                                         flex-shrink: 0;
      //                                         border-radius: 8.593px;
      //                                         background: #F00;
      //                                         display: flex;
      //                                         align-items: center;

      //                                         span {
      //                                             color: #FFF;
      //                                             font-family: "Poppins";
      //                                             font-size: 8.593px;
      //                                             font-style: normal;
      //                                             font-weight: 500;
      //                                             line-height: normal;
      //                                             text-transform: capitalize;
      //                                         }
      //                                     }
      //                                 }
      //                             }

      //                             .strengthFcard4 {
      //                                 width: 100%;
      //                                 height: auto;
      //                                 margin-top: 6px;
      //                                 border-radius: 12px;
      //                                 background: #FAEEC9;
      //                                 padding: 15px 10px 6px 6px;
      //                                 display: flex;
      //                                 flex-direction: column;
      //                                 justify-content: space-between;

      //                                 .Cardtext {

      //                                     span {
      //                                         color: #000;
      //                                         font-family: Arial;
      //                                         font-size: 14px;
      //                                         font-style: normal;
      //                                         font-weight: 400;
      //                                         line-height: normal;
      //                                     }
      //                                 }

      //                                 .cardBadge {
      //                                     display: flex;
      //                                     justify-content: end;
      //                                     padding-top: 6px;

      //                                     .redBadge {
      //                                         width: 73.9px;
      //                                         height: auto;
      //                                         // height: 17.186px;
      //                                         flex-shrink: 0;
      //                                         border-radius: 8.593px;
      //                                         background: #F00;
      //                                         display: flex;
      //                                         align-items: center;

      //                                         span {
      //                                             color: #FFF;
      //                                             font-family: "Poppins";
      //                                             font-size: 8.593px;
      //                                             font-style: normal;
      //                                             font-weight: 500;
      //                                             line-height: normal;
      //                                             text-transform: capitalize;
      //                                         }
      //                                     }
      //                                 }
      //                             }
      //                         }
      //                     }
      //                 }

      //             }

      //         }
      //     }
      // }
      .accordan {
        // padding-left: 104px;
        // padding-right: 104px;

        .accordianParent {
          border: none;

          .accordianItem1 {
            border: none;
            margin-top: 20px;
            border-radius: 10px;
            background: #f8f8f8;

            .accordianHeader {
              border: none;
              display: flex;
              justify-content: space-between;

              .accordion-button {
                background: var(
                  --side-bar,
                  linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
                );

                display: flex;
                justify-content: space-between;
                color: white;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                &::after {
                  display: none;
                }

                &:focus {
                  box-shadow: none;
                }
              }

              .collapsed {
                background: #f8f8f8;
                color: black;
              }
            }

            .accordion-content {
              background: #f8f8f8;
              filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
              border-radius: 0px 0px 10px 10px;
              padding: 24px 10px 32px 10px;

              .accordianContent {
                width: 100%;
                // padding: 24px 32px 32px 32px;

                .accordiancard {
                  display: flex;
                  flex-direction: column;
                  gap: 6px;

                  .firstCard {
                    width: 100%;
                    // width: 25%;
                    // height: 400px;
                    // background: gainsboro;

                    .strengthFcard {
                      width: 100%;
                      height: auto;
                      margin-top: 6px;
                      border-radius: 12px;
                      background: #e1edd3;
                      padding: 15px 10px 6px 6px;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;

                      .Cardtext {
                        span {
                          color: #000;
                          font-family: Arial;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                        }
                        ul {
                          list-style: DISC;
                          padding-left: 24px;
                        }
                      }

                      .cardBadge {
                        display: flex;
                        justify-content: end;
                        padding-top: 6px;

                        .redBadge {
                          width: 73.9px;
                          height: auto;
                          // height: 17.186px;
                          flex-shrink: 0;
                          border-radius: 8.593px;
                          background: #f00;
                          display: flex;
                          align-items: center;
                          gap: 2px;

                          span {
                            color: #fff;
                            font-family: "Poppins";
                            font-size: 8.593px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                          }
                        }
                      }
                    }
                    .consistentFcard {
                      width: 100%;
                      height: auto;
                      margin-top: 6px;
                      border-radius: 12px;
                      background: #6a7261;
                      padding: 15px 10px 6px 6px;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      -webkit-box-shadow: 0px 0px 15px -3px rgba(153, 142, 153, 1);
                      -moz-box-shadow: 0px 0px 15px -3px rgba(153, 142, 153, 1);
                      box-shadow: 0px 0px 15px -3px rgba(153, 142, 153, 1);

                      .Cardtext {
                        span {
                          color: #000;
                          font-family: Arial;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                        }
                        ul {
                          list-style: DISC;
                          padding-left: 24px;
                        }
                      }

                      .cardBadge {
                        display: flex;
                        justify-content: end;
                        padding-top: 6px;

                        .redBadge {
                          width: 73.9px;
                          height: auto;
                          // height: 17.186px;
                          flex-shrink: 0;
                          border-radius: 8.593px;
                          background: #f00;
                          display: flex;
                          align-items: center;
                          gap: 2px;

                          span {
                            color: #fff;
                            font-family: "Poppins";
                            font-size: 8.593px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                          }
                        }
                      }
                    }

                    .strengthFcard2 {
                      width: 100%;
                      height: auto;
                      margin-top: 6px;
                      border-radius: 12px;
                      background: #f6d6d5;
                      padding: 15px 10px 6px 6px;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;

                      .Cardtext {
                        span {
                          color: #000;
                          font-family: Arial;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                        }
                        ul {
                          list-style: DISC;
                          padding-left: 24px;
                        }
                      }

                      .cardBadge {
                        display: flex;
                        justify-content: end;
                        padding-top: 6px;

                        .redBadge {
                          width: 73.9px;
                          height: auto;
                          // height: 17.186px;
                          flex-shrink: 0;
                          border-radius: 8.593px;
                          background: #f00;
                          display: flex;
                          align-items: center;
                          gap: 2px;

                          span {
                            color: #fff;
                            font-family: "Poppins";
                            font-size: 8.593px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                          }
                        }

                        .clearBadge {
                          width: 50px;
                          height: auto;
                          // height: 17.186px;
                          flex-shrink: 0;
                          border-radius: 8.593px;
                          background: #9ccc65;
                          display: flex;
                          align-items: center;

                          span {
                            color: #fff;
                            font-family: "Poppins";
                            font-size: 8.593px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                          }
                        }
                      }
                    }

                    .strengthFcard3 {
                      width: 100%;
                      height: auto;
                      margin-top: 6px;
                      border-radius: 12px;
                      background: #bfd6e8;
                      padding: 15px 10px 6px 6px;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;

                      .Cardtext {
                        span {
                          color: #000;
                          font-family: Arial;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                        }
                        ul {
                          list-style: DISC;
                          padding-left: 24px;
                        }
                      }

                      .cardBadge {
                        display: flex;
                        justify-content: end;
                        padding-top: 6px;

                        .redBadge {
                          width: 73.9px;
                          height: auto;
                          // height: 17.186px;
                          flex-shrink: 0;
                          border-radius: 8.593px;
                          background: #f00;
                          display: flex;
                          align-items: center;
                          gap: 2px;

                          span {
                            color: #fff;
                            font-family: "Poppins";
                            font-size: 8.593px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                          }
                        }
                      }
                    }

                    .strengthFcard4 {
                      width: 100%;
                      height: auto;
                      margin-top: 6px;
                      border-radius: 12px;
                      background: #faeec9;
                      padding: 15px 10px 6px 6px;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;

                      .Cardtext {
                        span {
                          color: #000;
                          font-family: Arial;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                        }
                        ul {
                          list-style: DISC;
                          padding-left: 24px;
                        }
                      }

                      .cardBadge {
                        display: flex;
                        justify-content: end;
                        padding-top: 6px;

                        .redBadge {
                          width: 73.9px;
                          height: auto;
                          // height: 17.186px;
                          flex-shrink: 0;
                          border-radius: 8.593px;
                          background: #f00;
                          display: flex;
                          align-items: center;
                          gap: 2px;

                          span {
                            color: #fff;
                            font-family: "Poppins";
                            font-size: 8.593px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &:active {
            }
          }
        }
      }

      .accordianSubmission {
        padding-block: 30px;
        width: 100%;
        display: flex;
        justify-content: end;

        .Previous {
          border-radius: 4px;
          background: #8d8d8d;
          width: 84px;
          height: 25.714px;
          flex-shrink: 0;
          color: white;
          margin-right: 10px;
        }

        .save {
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: 84px;
          height: 25.714px;
          border-radius: 4px;
          background: linear-gradient(
            90deg,
            #00a2d6 0%,
            rgba(0, 162, 214, 0.58) 100%
          );
          color: white;
        }
      }
    }
  }
}
