.strength {
  width: 100%;
  height: 50px;
  padding: 10px 61px;
  border-radius: 10px;
  background: var(--green, #20ac27);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: var(--white);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.Weaknesses {
  width: 100%;
  height: 50px;
  padding: 10px 61px;
  border-radius: 10px;
  background: var(--red, #f44336);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: var(--white);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.Opportunities {
  width: 100%;
  height: 50px;
  padding: 10px 61px;
  border-radius: 10px;

  background: var(--blue, #0b5085);

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: var(--white);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.Threats {
  width: 100%;
  height: 50px;
  padding: 10px 61px;
  border-radius: 10px;
  background: var(--yellow, #ffc515);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: var(--white);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.Consistent {
  width: 100%;
  height: 50px;
  padding: 10px 61px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 15px -3px rgba(153, 142, 153, 1);
  -moz-box-shadow: 0px 0px 15px -3px rgba(153, 142, 153, 1);
  box-shadow: 0px 0px 15px -3px rgba(153, 142, 153, 1);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: var(--black);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.purposestat1 {
  border-radius: 12px;
  background: #e1edd3;
  padding: 15px 10px 6px 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #000;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
