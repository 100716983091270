.KanbanModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
    z-index: 999;
}

.KanbanModal-content {
    background: white;
    border-radius: 10px;
    max-width: 30%;
    min-width: 300px;
    min-height: min-content;
    max-height: 100vh;
    overflow-y: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* padding: 10px; */
}

.KanbanModal-close-btn:before {
    content: "x";
    color: black;
    font-weight: 300;
    font-family: Arial, sans-serif;
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
}

.KanbanModal-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 10px;
    padding-top: 50px;
}

.KanbanModal-label {
    padding: 0.5rem 0;
    font-weight: 500;
}

.KanbanModal-input-container {
    margin: 10px 0;
    width: 100%;
}

.KanbanModal-input {
    width: 100%;
    outline: none;
    border: 1px solid gray;
    border-radius: 6px;
    min-height: 2.5rem;
}

.resize-none {
    resize: none;
}

.KanbanModal-input:focus {
    border: 2px solid #00A2D6;
}

.KanbanModal-input-submit-btn {
    background-color: #00A2D6;
    opacity: 0.8;
    width: 100px;
    padding: 0.3rem;
    margin: 0.5rem;
    border: 1px solid white;
    border-radius: 10px;
    cursor: pointer;
    justify-self: center;
    color: white;
    letter-spacing: 3px;
}

.w-full {
    width: 100%;
}

@media only screen and (max-width: 1200px) {
    .KanbanModal-label {
        padding: 0.5rem 0;
        font-weight: 500;
    }

    .KanbanModal-input-container {
        margin: 0;
        width: 100%;
    }

    .KanbanModal-input {
        width: 100%;
        outline: none;
        border: 1px solid gray;
        border-radius: 6px;
        min-height: 1.5rem;
    }
}


@media only screen and (max-width: 600px) {
    .KanbanModal-input {
        width: 100%;
        outline: none;
        border: 1px solid gray;
        border-radius: 6px;

    }

    .kanban-input {
        height: 2rem;
    }

    .KanbanModal-label {
        padding: 0.1rem 0;
        font-weight: 500;
    }

    .KanbanModal-input-container {
        margin: 3px 0;
        width: 100%;
    }

    .KanbanEditForm-input-container {
        padding: 2px;
    }
}