.adminplan-section {
    width: 100%;
    height: 100vh;
    // height: 84.5vh;

    .adminplan-content {
        padding: 96px 423px 0px 423px;

        .adminplan-title {
            width: 100%;
            height: 80px;
            padding: 10px;
            text-align: center;
            border-bottom: 1px solid;
            border-image-source: linear-gradient(90deg, rgba(13, 77, 128, 0) 0%, #0D4D80 49.98%, rgba(13, 77, 128, 0) 100%);


            h6 {
                color: var(--primary-dark, #0D4D80);
                font-family: Arial;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }


        }

        .line-b {
            width: 100%;
            height: 2px;

            background: linear-gradient(270deg, rgba(13, 77, 128, 0) 0%, rgba(13, 77, 128, 1) 50%, rgba(13, 77, 128, 0) 100%);
        }

        .plan-fields {
            padding-top: 57px;

            .firstField {
                padding-left: 100px;
                padding-right: 100px;
                display: flex;

                align-items: center;

                gap: 10px;
                margin-bottom: 46px;

                .labels {
                    width: 40%;


                    label {
                        color: var(--primary-dark, #0D4D80);
                        font-family: Arial;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }

                .label-field {
                    width: 80%;

                    input {
                        border-radius: 11.165px;
                        background: var(--color-palette-neutral-05, #F3F3F3);
                        width: 100%;
                        height: 47px;
                        padding: 9.304px 14.887px;
                        border: none;

                        &::placeholder {
                            color: var(--color-palette-neutral-60, #6E6E70);
                            font-family: Arial;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20.469px;
                            /* 146.209% */
                        }
                    }
                }
            }

            .submit-btn {
                width: 100%;
                display: flex;
                justify-content: center;

                button {
                    border-radius: 8px;
                    background: #A7D176;
                    width: 112px;
                    height: 39px;
                    padding: 10px;
                    color: #FFF;
                    font-family: Arial;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }
    }
}

@media only screen and (max-width: 1670px) {
    .adminplan-section {
        width: 100%;
        height: 100vh;
        // height: 84.5vh;

        .adminplan-content {
            padding: 96px 60px 0px 60px;

            .adminplan-title {
                width: 100%;
                height: 80px;
                padding: 10px;
                text-align: center;
                border-bottom: 1px solid;
                border-image-source: linear-gradient(90deg, rgba(13, 77, 128, 0) 0%, #0D4D80 49.98%, rgba(13, 77, 128, 0) 100%);


                h6 {
                    color: var(--primary-dark, #0D4D80);
                    font-family: Arial;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }


            }

            .line-b {
                width: 100%;
                height: 2px;

                background: linear-gradient(270deg, rgba(13, 77, 128, 0) 0%, rgba(13, 77, 128, 1) 50%, rgba(13, 77, 128, 0) 100%);
            }

            .plan-fields {
                padding-top: 57px;

                .firstField {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 46px;
                    padding-left: 100px;
                    padding-right: 100px;

                    .labels {
                        width: 40%;

                        label {
                            color: var(--primary-dark, #0D4D80);
                            font-family: Arial;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                    }

                    .label-field {
                        width: 60%;

                        input {
                            border-radius: 11.165px;
                            background: var(--color-palette-neutral-05, #F3F3F3);
                            width: 100%;
                            height: 47px;
                            padding: 9.304px 14.887px;
                            border: none;

                            &::placeholder {
                                color: var(--color-palette-neutral-60, #6E6E70);
                                font-family: Arial;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20.469px;
                                /* 146.209% */
                            }
                        }
                    }
                }

                .submit-btn {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    button {
                        border-radius: 8px;
                        background: #A7D176;
                        width: 112px;
                        height: 39px;
                        padding: 10px;
                        color: #FFF;
                        font-family: Arial;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .adminplan-section {
        width: 100%;
        height: 100vh;
        // height: 84.5vh;

        .adminplan-content {
            padding: 96px 20px 0px 20px;


            .adminplan-title {
                width: 100%;
                height: 60px;
                padding: 10px;
                text-align: center;
                border-bottom: 1px solid;
                border-image-source: linear-gradient(90deg, rgba(13, 77, 128, 0) 0%, #0D4D80 49.98%, rgba(13, 77, 128, 0) 100%);


                h6 {
                    color: var(--primary-dark, #0D4D80);
                    font-family: Arial;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }


            }

            .line-b {
                width: 100%;
                height: 2px;

                background: linear-gradient(270deg, rgba(13, 77, 128, 0) 0%, rgba(13, 77, 128, 1) 50%, rgba(13, 77, 128, 0) 100%);
            }

            .plan-fields {
                padding-top: 30px;

                .firstField {
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    align-items: start;
                    // align-items: center;
                    gap: 10px;
                    margin-bottom: 20px;
                    padding-left: 0px;
                    padding-right: 0px;

                    .labels {
                        width: 100%;

                        label {
                            color: var(--primary-dark, #0D4D80);
                            font-family: Arial;
                            font-size: 19px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                    }

                    .label-field {
                        width: 100%;

                        input {
                            border-radius: 11.165px;
                            background: var(--color-palette-neutral-05, #F3F3F3);
                            width: 100%;
                            height: 47px;
                            padding: 9.304px 14.887px;
                            border: none;

                            &::placeholder {
                                color: var(--color-palette-neutral-60, #6E6E70);
                                font-family: Arial;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20.469px;
                                /* 146.209% */
                            }
                        }
                    }
                }

                .submit-btn {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    button {
                        border-radius: 8px;
                        background: #A7D176;
                        width: 100px;
                        height: 39px;
                        padding: 10px;
                        color: #FFF;
                        font-family: Arial;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
            }
        }
    }
}