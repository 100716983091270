.actionDropdown {
    .actionDropdown-first {
        display: flex;
        justify-content: end;

        .inputbox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 2px;
            // gap: 0px;
            width: 210px;
            height: 36px;
            // width: 90px;
            // height: 22px;
            padding: 0px 2px 0px 2px;
            // padding: 2px 2px 2px 6px;
            border-radius: 4px;
            background: #F0FFDF;
            cursor: pointer;
            position: relative;

            .leftside {
                color: #9CCC65;
                text-align: center;
                font-family: Arial;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                //   padding: 5px;
                margin-top: 2px;
                margin-left: 2px;
                // width: 52px;

            }

            .rightside {
                // width: 18px;
                // height: 18px;
                margin-right: -3px;


            }

            .actionDropdown {
                position: absolute;
                top: 30px;
                left: 0px;

                width: 210px;
                border-radius: 0px 0px 4px 4px;
                background: var(--side-back, #F8F8F8);
                z-index: 1;

                .firstdropdown {


                    // border-bottom: 0.2px solid #8D8D8D;

                    // height: 22.241px;
                    padding: 6px 6px 6px 3px;

                    // margin-block: 10px;
                    // margin-left: 5px;
                    // display: flex;
                    // align-items: center;

                    span {

                        color: var(--side-text, #C3C3C3);
                        text-align: center;
                        font-family: Arial;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }


                }

                .lastdropdown {


                    height: 22.241px;
                    padding: 2px 2px 2px 6px;
                    display: flex;
                    align-items: center;

                    span {
                        color: var(--side-text, #C3C3C3);
                        text-align: center;
                        font-family: Arial;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }


    }
}

@media only screen and (max-width: 1000px) {
    .actionDropdown {
        .actionDropdown-first {
            display: flex;
            justify-content: end;

            .inputbox {
                display: flex;
                align-items: center;
                justify-content: space-around;
                gap: 2px;
                // gap: 0px;
                width: 210px;
                height: 36px;
                // width: 90px;
                // height: 22px;
                padding: 0px 2px 0px 2px;
                // padding: 2px 2px 2px 6px;
                border-radius: 4px;
                background: #F0FFDF;
                cursor: pointer;
                position: relative;

                .leftside {
                    color: #9CCC65;
                    text-align: center;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    //   padding: 5px;
                    margin-top: 2px;
                    margin-left: 2px;
                    // width: 52px;

                }

                .rightside {
                    // width: 18px;
                    // height: 18px;
                    margin-right: -3px;


                }

                .actionDropdown {
                    position: absolute;
                    top: 30px;
                    left: 0px;

                    width: 210px;
                    border-radius: 0px 0px 4px 4px;
                    background: var(--side-back, #F8F8F8);
                    z-index: 1;

                    .firstdropdown {


                        padding: 6px 6px 6px 3px;

                     

                        span {

                            color: var(--side-text, #C3C3C3);
                            text-align: center;
                            font-family: Arial;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }


                    }

                    .lastdropdown {


                        height: 22.241px;
                        padding: 2px 2px 2px 6px;
                        display: flex;
                        align-items: center;

                        span {
                            color: var(--side-text, #C3C3C3);
                            text-align: center;
                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }


        }
    }
}