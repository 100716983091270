.KanbanTask {
  background-color: white;
  padding: 10px;
  height: min-content;
  min-width: 150px;
  /* width: 90%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  border-radius: 5px;
  /* opacity: 0.9; */
  text-align: left;
}

.KanbanTask-content {
  padding: 1rem 0;
  font-size: 0.8rem;
}

.KanbanTask-options {
  font-size: 0.9rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

/* /////////////////////////////////////////// */
/* //////////////////////////////////////// */
.kanban-card-container {
  padding-bottom: 20px;
  border-radius: 9.472px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 9.472px 0px rgba(0, 0, 0, 0.1);
}

.kanban-card-header {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px 10px 20px;
  position: relative;
  /* z-index: 1; */
}

.kanban-header-title {
  color: #000;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.kanban-card-data-container {
  padding: 0px 38px 0px 38px;
}

.kanban-select-status {
  display: flex;
  gap: 20px;
  align-items: center;
}

.kanban-status-select {
  cursor: pointer;
  border: 1px solid black;
  width: 170.909px;
  height: 36.623px;
  border-radius: 4.883px;
  background: #e4e4e4;
  padding: 0px 8px 0px 8px;
}

.kanban-status-select:focus {
  outline: none;
}

/* /////////////////////////////// */
.canban-card {
  box-shadow: 0px 0px 9.47218132019043px 0px #0000001a;
  width: 100%;
  border-radius: 0.8rem;
  padding: 0.7rem;
  position: relative;
}

.KanbanTask-btn-edit {
  position: absolute;
  top: 50px;
  right: 10px;
  width: 1.5rem;
  height: 1.5rem;
  padding: 4px;
  color: rgb(44, 46, 128);
  border-radius: 4px;
}

.KanbanTask-btn-delete {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 1.5rem;
  height: 1.5rem;
  padding: 4px;
  color: rgb(221, 51, 51);
  border-radius: 4px;
}

.KanbanTask-select-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.status-select {
  background-color: #e4e4e4;
  border-radius: 0.3rem;
  width: 70%;
  height: 2rem;
  cursor: pointer;
  display: block;
  outline: none;
}

.status-select:focus {
  outline: none;
}
.kanban-column-btn-add {
  padding: 4px 10px 4px 10px;
  height: 25.714px;
  border-radius: 4px;
  width: 100%;
  background: linear-gradient(90deg, #00a2d6 0%, rgba(0, 162, 214, 0.58) 100%);
  color: white;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
