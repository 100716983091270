.analysis {
  padding-top: 60px;
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/layerimg.png");

  .analysis-content {
    width: 100%;
    padding: 58px 73px 37px 104px;

    /* Add this line for x-axis scroll */

    .analysis-title {
      margin-bottom: 37px;
      width: 100%;
      padding: 19px 0px 19px 30px;
      border-bottom: 0.962px solid #eaecf0;
      background: #fff;
      box-shadow: 6px 6px 16px 0px rgba(0, 0, 0, 0.1),
        -2px -2px 16px 0px rgba(187, 210, 255, 0.5);
      // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      // justify-content: center;
      align-items: center;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      h6 {
        color: #0d4d80;
        font-family: Arial;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      span {
        color: #0d4d80;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        .icon {
          // padding-inline: 10px;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }

    .scrollbar {
      overflow-y: auto;
      height: 700px;
      padding: 10px;

      &::-webkit-scrollbar {
        display: none;
      }

      .first-analysis {
        cursor: pointer;
        display: flex;

        justify-content: space-between;
        align-items: center;
        background: #fff;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
        height: 82px;
        padding-left: 22px;
        padding-right: 118px;
        margin-bottom: 27px;

        .title {
          width: 100%;
          text-align: center;
          .disabled-button {
            background: #ababab !important;
            color: white;
            cursor: not-allowed;
          }
          p {
            color: #0d4d80;
            text-align: center;
            font-family: Arial;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            span {
              color: #00a2d6;
              font-family: Arial;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .dot {
              color: #c3c3c3;
              font-family: Arial;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .title {
              .custom-select {
                width: 199px !important;
                height: 106px;
                border-radius: 4px;
                background: #9ccc65;
                text-align: center;
                font-family: Arial;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                select {
                  width: 200px !important;
                }

                // .custom-select-dropdown {

                //     appearance: none;
                //     -webkit-appearance: none;
                //     -moz-appearance: none;
                //     border: 1px solid #ccc;
                //     /* Add a custom border */
                //     padding: 8px;
                //     /* Add padding to make it visually appealing */
                //     font-size: 14px;
                //     /* Add a custom font size */
                //     width: 100%;
                //     /* Make the select fill its container */
                //     box-sizing: border-box;
                //     /* Ensure padding and border are included in the width */
                //     background-color: transparent;
                //     /* Remove the background color */

                //     /* Set text color */
                //     .custom-select-dropdown option:checked {
                //         color: #9CCC65;
                //         text-align: center;
                //         font-family: Arial;
                //         font-size: 24px;
                //         font-style: normal;
                //         font-weight: 400;
                //         line-height: normal;
                //     }
                // }
              }

              /* Optional: Style for the selected option */
            }
          }

          button {
            width: fit-content;
            padding: 0 10px;
            height: 25.714px;
            border-radius: 4px;
            background: linear-gradient(
              90deg,
              #00a2d6 0%,
              rgba(0, 162, 214, 0.58) 100%
            );
            color: white;
            margin-top: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .analysis {
    padding-top: 60px;
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/images/layerimg.png");

    .analysis-content {
      width: 100%;
      padding: 58px 10px 37px 10px;
      overflow-x: auto;

      .analysis-title {
        margin-bottom: 37px;
        width: 100%;
        padding: 19px 0px 19px 30px;
        border-bottom: 0.962px solid #eaecf0;
        background: #fff;
        box-shadow: 6px 6px 16px 0px rgba(0, 0, 0, 0.1),
          -2px -2px 16px 0px rgba(187, 210, 255, 0.5);
        // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

        h6 {
          color: #0d4d80;
          font-family: Arial;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        span {
          color: #0d4d80;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          .icon {
            // padding-inline: 10px;
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }

      .scrollbar {
        height: 500px;

        .first-analysis {
          cursor: pointer;
          display: flex;

          justify-content: space-between;
          align-items: center;
          background: #fff;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
          height: 82px;
          padding-left: 10px;
          padding-right: 22px;
          margin-bottom: 27px;

          p {
            color: #0d4d80;
            text-align: center;
            font-family: Arial;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            span {
              color: #00a2d6;
              font-family: Arial;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .dot {
              color: #c3c3c3;
              font-family: Arial;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .title {
              .custom-select {
                width: 199px !important;
                height: 106px;
                border-radius: 4px;
                background: #9ccc65;
                text-align: center;
                font-family: Arial;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                select {
                  width: 200px !important;
                }

                // .custom-select-dropdown {

                //     appearance: none;
                //     -webkit-appearance: none;
                //     -moz-appearance: none;
                //     border: 1px solid #ccc;
                //     /* Add a custom border */
                //     padding: 8px;
                //     /* Add padding to make it visually appealing */
                //     font-size: 14px;
                //     /* Add a custom font size */
                //     width: 100%;
                //     /* Make the select fill its container */
                //     box-sizing: border-box;
                //     /* Ensure padding and border are included in the width */
                //     background-color: transparent;
                //     /* Remove the background color */

                //     /* Set text color */
                //     .custom-select-dropdown option:checked {
                //         color: #9CCC65;
                //         text-align: center;
                //         font-family: Arial;
                //         font-size: 24px;
                //         font-style: normal;
                //         font-weight: 400;
                //         line-height: normal;
                //     }
                // }
              }

              /* Optional: Style for the selected option */
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .analysis {
    padding-top: 60px;
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/images/layerimg.png");

    .analysis-content {
      width: 100%;
      padding: 58px 10px 37px 10px;
      overflow-x: auto;

      .analysis-title {
        margin-bottom: 37px;
        width: 100%;
        padding: 19px 0px 19px 30px;
        border-bottom: 0.962px solid #eaecf0;
        background: #fff;
        box-shadow: 6px 6px 16px 0px rgba(0, 0, 0, 0.1),
          -2px -2px 16px 0px rgba(187, 210, 255, 0.5);
        // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

        h6 {
          color: #0d4d80;
          font-family: Arial;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        span {
          color: #0d4d80;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          .icon {
            // padding-inline: 10px;
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }

      .scrollbar {
        .first-analysis {
          cursor: pointer;
          display: flex;

          justify-content: space-between;
          align-items: center;
          background: #fff;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
          height: 82px;
          padding-left: 10px;
          padding-right: 22px;
          margin-bottom: 27px;

          p {
            color: #0d4d80;
            text-align: center;
            font-family: Arial;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            span {
              color: #00a2d6;
              font-family: Arial;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .dot {
              color: #c3c3c3;
              font-family: Arial;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .title {
              .custom-select {
                width: 199px !important;
                height: 106px;
                border-radius: 4px;
                background: #9ccc65;
                text-align: center;
                font-family: Arial;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                select {
                  width: 200px !important;
                }

                // .custom-select-dropdown {

                //     appearance: none;
                //     -webkit-appearance: none;
                //     -moz-appearance: none;
                //     border: 1px solid #ccc;
                //     /* Add a custom border */
                //     padding: 8px;
                //     /* Add padding to make it visually appealing */
                //     font-size: 14px;
                //     /* Add a custom font size */
                //     width: 100%;
                //     /* Make the select fill its container */
                //     box-sizing: border-box;
                //     /* Ensure padding and border are included in the width */
                //     background-color: transparent;
                //     /* Remove the background color */

                //     /* Set text color */
                //     .custom-select-dropdown option:checked {
                //         color: #9CCC65;
                //         text-align: center;
                //         font-family: Arial;
                //         font-size: 24px;
                //         font-style: normal;
                //         font-weight: 400;
                //         line-height: normal;
                //     }
                // }
              }

              /* Optional: Style for the selected option */
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .analysis {
    padding-top: 60px;
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/images/layerimg.png");

    .analysis-content {
      width: 100%;
      padding: 30px 10px 30px 10px;

      .analysis-title {
        margin-bottom: 37px;
        width: 100%;
        padding: 19px 0px 19px 10px;
        border-bottom: 0.962px solid #eaecf0;
        background: #fff;
        box-shadow: 6px 6px 16px 0px rgba(0, 0, 0, 0.1),
          -2px -2px 16px 0px rgba(187, 210, 255, 0.5);
        // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

        h6 {
          color: #0d4d80;
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        span {
          color: #0d4d80;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          .icon {
            // padding-inline: 10px;
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }

      .scrollbar {
        overflow-x: auto;
        overflow-y: auto;

        height: 600px;
        width: 1000px;

        .first-analysis {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fff;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
          height: 82px;
          padding-left: 5px;
          padding-right: 5px;
          margin-bottom: 27px;

          p {
            color: #0d4d80;
            text-align: center;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            span {
              color: #00a2d6;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .dot {
              color: #c3c3c3;
              font-family: Arial;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .title {
              .custom-select {
                width: 199px !important;
                height: 106px;
                border-radius: 4px;
                background: #9ccc65;
                text-align: center;
                font-family: Arial;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                select {
                  width: 200px !important;
                }

                // .custom-select-dropdown {

                //     appearance: none;
                //     -webkit-appearance: none;
                //     -moz-appearance: none;
                //     border: 1px solid #ccc;
                //     /* Add a custom border */
                //     padding: 8px;
                //     /* Add padding to make it visually appealing */
                //     font-size: 14px;
                //     /* Add a custom font size */
                //     width: 100%;
                //     /* Make the select fill its container */
                //     box-sizing: border-box;
                //     /* Ensure padding and border are included in the width */
                //     background-color: transparent;
                //     /* Remove the background color */

                //     /* Set text color */
                //     .custom-select-dropdown option:checked {
                //         color: #9CCC65;
                //         text-align: center;
                //         font-family: Arial;
                //         font-size: 24px;
                //         font-style: normal;
                //         font-weight: 400;
                //         line-height: normal;
                //     }
                // }
              }

              /* Optional: Style for the selected option */
            }
          }
        }
      }
    }
  }
}
