.taskListWrapper {
  display: table;
  border-bottom: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
}

.taskListTableRow {
  display: table-row;
  /* text-overflow: ellipsis; */
}

.taskListTableRow:nth-of-type(even) {
  background-color: #f5f5f5;
}

.taskListCell {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
}

.taskListNameWrapper {
  display: flex;
  align-items: center;
}

.taskListExpander {
  color: rgb(86 86 86);
  font-size: 0.6rem;
  padding: 0.15rem 0.2rem 0rem 0.2rem;
  user-select: none;
  cursor: pointer;
}

.taskListEmptyExpander {
  font-size: 0.6rem;
  padding-left: 1rem;
  user-select: none;
}

.formcontaier {
  /* background: rgb(255, 180, 180); */
  box-shadow: 3px 9px 8px 0px rgba(151, 119, 238, 0.75);
  -webkit-box-shadow: 3px 9px 8px 0px rgba(115, 98, 159, 0.75);
  -moz-box-shadow: 3px 9px 8px 0px rgba(128, 90, 232, 0.75);
  margin: 3px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.formcontaier input {
  width: 100%;
  /* height: 30px; */
  /* border: none; */
  padding: 7px 3px;
  border-radius: 5px;
}

.addtask {
  display: flex;
  justify-content: center;
}

.formcontaier button {
  margin-left: 3px;
  width: 40%;
  padding-block: 10px;
  border-radius: 10px;
}

.addMorecontainer {
  height: 50px;
  display: flex;
  justify-content: end;
}

.addMore {
  background-color: #1572BD;
  color: white;
  /* padding: 5px; */
  border-radius: 5px;
  margin-top: 2px;
  height: 40px;
  width: 30%;
  padding: 3px;
  align-items: flex-end;
  font-weight: 500;
}