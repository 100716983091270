.onepageplan {
  width: 100%;
  background-image: url("../../assets/images/layerimg.png");
  padding-top: 60px;
  padding-left: 101px;
  padding-right: 101px;
  padding-bottom: 200px;

  .planContent {
    width: 100%;

    .title {
      width: 100%;
      padding-top: 13px;
      padding-bottom: 111px;
      text-align: center;

      h6 {
        color: #0d4d80;

        font-family: Arial;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      span {
        color: #8d8d8d;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .planCard {
      width: 100%;
      gap: 21px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .firstCard {
        border-radius: 12px;
        background: rgba(191, 214, 232, 0.44);
        padding: 0px 6px 15px 0px;

        .cardTitle {
          width: 169px;

          height: 50px;
          padding: 10px;
          border-radius: 10px;
          background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 5px;

          span {
            color: var(--side-back, #f8f8f8);
            font-family: Arial;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
        }

        p {
          color: #000;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          padding-left: 10px;
        }
      }

      .lastCard {
        border-radius: 12px;
        background: rgba(191, 214, 232, 0.44);
        padding: 0px 6px 15px 0px;

        .cardTitle {
          padding: 10px 20px;
          width: fit-content;
          height: 50px;
          border-radius: 10px;
          background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 5px;
          margin-bottom: 5px;

          span {
            color: var(--side-back, #f8f8f8);
            font-family: Arial;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
        }
        p {
          color: #000;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          padding-left: 10px;
        }

        .planbehave {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 10px;
          padding-right: 30px;
          padding-block: 13px;

          div {
            color: #000;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
        }
      }
    }

    .mainSection {
      padding-top: 42px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .pageGoal {
        width: 100%;
        margin-top: 20px;
        // padding-top: 16px;
        border-radius: 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

        // height: 53px;
        .goalheader {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 53px;
          border-radius: 10px 10px 0px 0px;
          background: var(
            --side-bar,
            linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
          );

          span {
            color: #fff;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
        }

        .goalmiddle {
          background: #f8f8f8;
          height: 53px;
          border-bottom: 1px solid rgba(226, 226, 226, 0.5);

          // border-bottom: 0.5px solid #E2E2E2;

          .qtr1 {
            height: 100%;
            width: 76px;
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 212px;
            flex-shrink: 0;
            background: #e6e6e6;

            span {
              color: #000;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }
          .onepageinp {
            outline: none;
            border: none;
            /* background: #e6e6e6; */
            background: #f8f8f8;
          }
        }

        .goalbottom {
          border-top: 1px solid #e2e2e2;
          background: #f8f8f8;
          height: 53px;
          border-radius: 0px 0px 10px 10px;
        }

        .strategicPriorities {
          padding-top: 16px;
          width: 100%;
          display: flex;
          gap: 42px;

          .customerExperience {
            width: 33.3%;

            .cutomerHeader {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 13px 27px;
              border-radius: 10px;
              background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);

              span {
                color: var(--side-back, #f8f8f8);
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
              }
            }

            .customerBottom {
              width: 100%;

              .bottom-div {
                display: flex;
                width: 100%;
                background: #b0d4f1;
                // border-radius: 10px 10px 0px 0px;

                .left {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40%;
                  height: 53px;
                  border-bottom: 1px solid #92b9d8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }

                .right {
                  width: 60%;
                  height: 53px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-left: 1px solid #92b9d8;
                  border-bottom: 1px solid #92b9d8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }
              }

              .bottom-div2 {
                display: flex;
                width: 100%;
                background: rgba(191, 214, 232, 0.44);

                // border-radius: 10px 10px 0px 0px;

                .left {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40%;
                  height: 53px;
                  border-bottom: 1px solid #92b9d8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }

                .right {
                  width: 60%;
                  height: 53px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-left: 1px solid #92b9d8;
                  border-bottom: 1px solid #92b9d8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }
              }
            }
          }
        }
      }

      .pageGoal2 {
        width: 100%;
        // padding-top: 16px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;

        // height: 53px;
        .goalheader {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 53px;
          border-radius: 10px 10px 0px 0px;
          background: var(
            --side-bar,
            linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
          );

          span {
            color: #fff;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
        }

        .goalmiddle {
          background: #f8f8f8;
          height: 53px;
          border-bottom: 1px solid rgba(226, 226, 226, 0.5);

          // border-bottom: 0.5px solid #E2E2E2;

          .qtr1 {
            height: 100%;
            width: 76px;
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 212px;
            flex-shrink: 0;
            background: #e6e6e6;

            span {
              color: #000;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }
          .onepageinp {
            outline: none;
            border: none;
            /* background: #e6e6e6; */
            background: #f8f8f8;
          }
        }

        .goalbottom {
          border-top: 1px solid #e2e2e2;
          background: #f8f8f8;
          height: 53px;
          border-radius: 0px 0px 10px 10px;
        }

        .strategicPriorities {
          padding-top: 16px;
          width: 100%;
          display: flex;
          gap: 42px;

          .customerExperience {
            width: 33.3%;

            .cutomerHeader {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 13px 27px;
              border-radius: 10px;
              background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);

              span {
                color: var(--side-back, #f8f8f8);
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
              }
            }

            .customerBottom {
              width: 100%;

              .bottom-div {
                display: flex;
                width: 100%;
                background: #b0d4f1;
                // border-radius: 10px 10px 0px 0px;

                .left {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40%;
                  height: 53px;
                  border-bottom: 1px solid #92b9d8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }

                .right {
                  width: 60%;
                  height: 53px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-left: 1px solid #92b9d8;
                  border-bottom: 1px solid #92b9d8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }
              }

              .bottom-div2 {
                display: flex;
                width: 100%;
                background: rgba(191, 214, 232, 0.44);

                // border-radius: 10px 10px 0px 0px;

                .left {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40%;
                  height: 53px;
                  border-bottom: 1px solid #92b9d8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }

                .right {
                  width: 60%;
                  height: 53px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-left: 1px solid #92b9d8;
                  border-bottom: 1px solid #92b9d8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }
              }
            }
          }
        }
      }

      .pageGoal3 {
        width: 100%;
        border-radius: 20px;
        // padding-top: 16px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

        // height: 53px;
        .goalheader {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 53px;
          border-radius: 10px 10px 0px 0px;
          background: var(
            --side-bar,
            linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
          );

          span {
            color: #fff;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
        }

        .goalmiddle {
          background: #f8f8f8;
          height: 53px;
          border-bottom: 1px solid rgba(226, 226, 226, 0.5);

          // border-bottom: 0.5px solid #E2E2E2;

          .qtr1 {
            height: 100%;
            width: 76px;
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 212px;
            flex-shrink: 0;
            background: #e6e6e6;

            span {
              color: #000;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }
          .onepageinp {
            outline: none;
            border: none;
            /* background: #e6e6e6; */
            background: #f8f8f8;
          }
        }

        .goalbottom {
          border-top: 1px solid #e2e2e2;
          background: #f8f8f8;
          height: 53px;
          border-radius: 0px 0px 10px 10px;
        }

        .strategicPriorities {
          padding-top: 16px;
          width: 100%;
          display: flex;
          gap: 42px;

          .customerExperience {
            width: 33.3%;

            .cutomerHeader {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 13px 27px;
              border-radius: 10px 10px 0px 0px;
              background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);

              span {
                color: var(--side-back, #f8f8f8);
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
              }
            }

            .customerBottom {
              width: 100%;

              .bottom-div {
                display: flex;
                width: 100%;
                background: #b0d4f1;
                // border-radius: 10px 10px 0px 0px;

                .left {
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  padding-left: 10px;
                  width: 40%;
                  height: 53px;
                  border-bottom: 1px solid #92b9d8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }

                .right {
                  width: 60%;
                  height: 53px;
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  padding-left: 10px;
                  border-left: 1px solid #92b9d8;
                  border-bottom: 1px solid #92b9d8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }
              }

              .bottom-div2 {
                // display: flex;
                width: 100%;
                background: rgba(191, 214, 232, 0.44);

                // border-radius: 10px 10px 0px 0px;

                .left {
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  padding-left: 10px;
                  width: 40%;
                  height: 53px;
                  border-bottom: 1px solid #92b9d8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }

                .right {
                  width: 60%;
                  // height: 53px;
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  padding-left: 10px;
                  border-left: 1px solid #92b9d8;
                  border-bottom: 1px solid #92b9d8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }
              }

              .bottom-div3 {
                display: flex;
                width: 100%;
                background: rgba(191, 214, 232, 0.44);
                border-radius: 0px 0px 12px 12px;

                // border-radius: 10px 10px 0px 0px;

                .left {
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  padding-left: 10px;
                  width: 40%;
                  height: 53px;

                  // border-bottom: 1px solid #92B9D8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }

                .right {
                  width: 60%;
                  height: 53px;
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  padding-left: 10px;
                  border-left: 1px solid #92b9d8;
                  // border-bottom: 1px solid #92B9D8;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }
              }
            }
          }
        }
      }

      .saveButton {
        display: flex;
        justify-content: end;
        width: 100%;
        padding-top: 10px;

        .savesubmit {
          border-radius: 4px;
          background: #9ccc65;
          padding: 4px 10px;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          color: white;
          margin-right: 10px;
        }

        .print {
          border-radius: 4px;
          background: #01a0d4;
          padding: 4px 10px;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: white;
        }
      }
    }
  }
}

.modalBody {
  .modal-header {
    display: flex;
    border: none;

    .titleModal {
      width: 75%;
      text-align: end;

      span {
        color: var(--primary-dark, #0d4d80);
        font-family: Arial;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 167.886%;
        /* 53.723px */
      }
    }

    .modlclose {
      span {
        cursor: pointer;
      }
    }

    // justify-content: center;
    // gap: 100px;
  }

  .modalCard {
    width: 100%;

    .modalContent {
      padding-top: 27px;
      padding-left: 91px;
      padding-right: 91px;
      padding-bottom: 60px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      button {
        // width: 401px;
        border-radius: 10px;
        background: var(--primary-dark, #0d4d80);
        height: 62px;
        padding: 10px;
        color: var(--side-back, #f8f8f8);
        font-family: Arial;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 167.886%;
        /* 40.293px */
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .onepageplan {
    padding-left: 50px;
    padding-right: 50px;

    .planContent {
      width: 100%;

      .planCard {
        .firstCard {
          .cardTitle {
            width: 169px;

            height: 50px;
            padding: 10px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 5px;

            span {
              color: var(--side-back, #f8f8f8);
              font-family: Arial;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }
        }

        .lastCard {
          .cardTitle {
            width: fit-content;

            white-space: nowrap;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .onepageplan {
    padding-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 200px;
  }
}

@media only screen and (max-width: 600px) {
  .onepageplan {
    width: 100%;
    background-image: url("../../assets/images/layerimg.png");
    padding-top: 60px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 200px;

    .planContent {
      width: 100%;

      .title {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;

        h6 {
          color: #0d4d80;

          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        span {
          color: #8d8d8d;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .planCard {
        width: 100%;
        gap: 10px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);

        .firstCard {
          border-radius: 12px;
          background: rgba(191, 214, 232, 0.44);
          padding: 0px 6px 15px 0px;
          width: 100%;

          .cardTitle {
            // width: 50%;

            height: 50px;
            padding: 10px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 5px;

            span {
              color: var(--side-back, #f8f8f8);
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          p {
            color: #000;
            font-family: Arial;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            padding-left: 10px;
          }
        }

        .lastCard {
          border-radius: 12px;
          background: rgba(191, 214, 232, 0.44);
          padding: 0px 6px 15px 0px;
          width: 100%;

          .cardTitle {
            padding: 10px 5px;
            width: 100%;
            height: 50px;

            border-radius: 10px;
            background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 5px;

            span {
              color: var(--side-back, #f8f8f8);
              font-family: Arial;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .planbehave {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 10px;
            padding-right: 5px;
            padding-block: 13px;

            div {
              color: #000;
              font-family: Arial;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }
        }
      }

      .mainSection {
        padding-top: 42px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .pageGoal {
          width: 100%;
          margin-top: 20px;
          // padding-top: 16px;
          border-radius: 20px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

          // height: 53px;
          .goalheader {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 53px;
            border-radius: 10px 10px 0px 0px;
            background: var(
              --side-bar,
              linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
            );

            span {
              color: #fff;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .goalmiddle {
            background: #f8f8f8;
            height: 53px;
            border-bottom: 1px solid rgba(226, 226, 226, 0.5);

            // border-bottom: 0.5px solid #E2E2E2;

            .qtr1 {
              height: 100%;
              width: 76px;
              display: flex;
              justify-content: center;
              align-items: center;
              // height: 212px;
              flex-shrink: 0;
              background: #e6e6e6;

              span {
                color: #000;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
              }
            }
            .onepageinp {
              outline: none;
              border: none;
              /* background: #e6e6e6; */
              background: #f8f8f8;
            }
          }

          .goalbottom {
            border-top: 1px solid #e2e2e2;
            background: #f8f8f8;
            height: 53px;
            border-radius: 0px 0px 10px 10px;
          }

          .strategicPriorities {
            padding-top: 16px;
            width: 100%;
            display: flex;
            gap: 42px;

            .customerExperience {
              width: 33.3%;

              .cutomerHeader {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 13px 27px;
                border-radius: 10px;
                background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);

                span {
                  color: var(--side-back, #f8f8f8);
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  text-transform: capitalize;
                }
              }

              .customerBottom {
                width: 100%;

                .bottom-div {
                  display: flex;
                  width: 100%;
                  background: #b0d4f1;
                  // border-radius: 10px 10px 0px 0px;

                  .left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40%;
                    height: 53px;
                    border-bottom: 1px solid #92b9d8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }

                  .right {
                    width: 60%;
                    height: 53px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-left: 1px solid #92b9d8;
                    border-bottom: 1px solid #92b9d8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }
                }

                .bottom-div2 {
                  display: flex;
                  width: 100%;
                  background: rgba(191, 214, 232, 0.44);

                  // border-radius: 10px 10px 0px 0px;

                  .left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40%;
                    height: 53px;
                    border-bottom: 1px solid #92b9d8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }

                  .right {
                    width: 60%;
                    height: 53px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-left: 1px solid #92b9d8;
                    border-bottom: 1px solid #92b9d8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }
                }
              }
            }
          }
        }

        .pageGoal2 {
          width: 100%;
          // padding-top: 16px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
          border-radius: 20px;

          // height: 53px;
          .goalheader {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 53px;
            border-radius: 10px 10px 0px 0px;
            background: var(
              --side-bar,
              linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
            );

            span {
              color: #fff;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .goalmiddle {
            background: #f8f8f8;
            height: 53px;
            border-bottom: 1px solid rgba(226, 226, 226, 0.5);

            // border-bottom: 0.5px solid #E2E2E2;

            .qtr1 {
              height: 100%;
              width: 76px;
              display: flex;
              justify-content: center;
              align-items: center;
              // height: 212px;
              flex-shrink: 0;
              background: #e6e6e6;

              span {
                color: #000;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
              }
            }
            .onepageinp {
              outline: none;
              border: none;
              /* background: #e6e6e6; */
              background: #f8f8f8;
            }
          }

          .goalbottom {
            border-top: 1px solid #e2e2e2;
            background: #f8f8f8;
            height: 53px;
            border-radius: 0px 0px 10px 10px;
          }

          .strategicPriorities {
            padding-top: 16px;
            width: 100%;
            display: flex;
            gap: 42px;

            .customerExperience {
              width: 33.3%;

              .cutomerHeader {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 13px 27px;
                border-radius: 10px;
                background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);

                span {
                  color: var(--side-back, #f8f8f8);
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  text-transform: capitalize;
                }
              }

              .customerBottom {
                width: 100%;

                .bottom-div {
                  display: flex;
                  width: 100%;
                  background: #b0d4f1;
                  // border-radius: 10px 10px 0px 0px;

                  .left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40%;
                    height: 53px;
                    border-bottom: 1px solid #92b9d8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }

                  .right {
                    width: 60%;
                    height: 53px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-left: 1px solid #92b9d8;
                    border-bottom: 1px solid #92b9d8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }
                }

                .bottom-div2 {
                  display: flex;
                  width: 100%;
                  background: rgba(191, 214, 232, 0.44);

                  // border-radius: 10px 10px 0px 0px;

                  .left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40%;
                    height: 53px;
                    border-bottom: 1px solid #92b9d8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }

                  .right {
                    width: 60%;
                    height: 53px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-left: 1px solid #92b9d8;
                    border-bottom: 1px solid #92b9d8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }
                }
              }
            }
          }
        }

        .pageGoal3 {
          width: 100%;
          border-radius: 20px;
          // padding-top: 16px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

          // height: 53px;
          .goalheader {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 53px;
            border-radius: 10px 10px 0px 0px;
            background: var(
              --side-bar,
              linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
            );

            span {
              color: #fff;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .goalmiddle {
            background: #f8f8f8;
            height: 53px;
            border-bottom: 1px solid rgba(226, 226, 226, 0.5);

            // border-bottom: 0.5px solid #E2E2E2;

            .qtr1 {
              height: 100%;
              width: 76px;
              display: flex;
              justify-content: center;
              align-items: center;
              // height: 212px;
              flex-shrink: 0;
              background: #e6e6e6;

              span {
                color: #000;
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
              }
            }
            .onepageinp {
              outline: none;
              border: none;
              /* background: #e6e6e6; */
              background: #f8f8f8;
            }
          }

          .goalbottom {
            border-top: 1px solid #e2e2e2;
            background: #f8f8f8;
            height: 53px;
            border-radius: 0px 0px 10px 10px;
          }

          .strategicPriorities {
            padding-top: 16px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .customerExperience {
              width: 100%;

              .cutomerHeader {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 13px 15px;
                border-radius: 10px;
                background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);

                span {
                  color: var(--side-back, #f8f8f8);
                  font-family: Arial;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  text-transform: capitalize;
                }
              }

              .customerBottom {
                width: 100%;

                .bottom-div {
                  display: flex;
                  width: 100%;
                  background: #b0d4f1;
                  // border-radius: 10px 10px 0px 0px;

                  .left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40%;
                    height: 53px;
                    border-bottom: 1px solid #92b9d8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }

                  .right {
                    width: 60%;
                    height: 53px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-left: 1px solid #92b9d8;
                    border-bottom: 1px solid #92b9d8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }
                }

                .bottom-div2 {
                  display: flex;
                  width: 100%;
                  background: rgba(191, 214, 232, 0.44);

                  // border-radius: 10px 10px 0px 0px;

                  .left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40%;
                    height: 53px;
                    border-bottom: 1px solid #92b9d8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }

                  .right {
                    width: 60%;
                    height: 53px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-left: 1px solid #92b9d8;
                    border-bottom: 1px solid #92b9d8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }
                }

                .bottom-div3 {
                  display: flex;
                  width: 100%;
                  background: rgba(191, 214, 232, 0.44);
                  border-radius: 0px 0px 12px 12px;

                  // border-radius: 10px 10px 0px 0px;

                  .left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // padding-left: 10px;
                    width: 40%;
                    height: 53px;

                    // border-bottom: 1px solid #92B9D8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }

                  .right {
                    width: 60%;
                    height: 53px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // padding-left: 10px;
                    border-left: 1px solid #92b9d8;
                    // border-bottom: 1px solid #92B9D8;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }
                }
              }
            }
          }
        }

        .saveButton {
          display: flex;
          justify-content: end;
          width: 100%;
          padding-top: 10px;

          .savesubmit {
            border-radius: 4px;
            background: #9ccc65;
            padding: 4px 10px;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            color: white;
            margin-right: 10px;
          }

          .print {
            border-radius: 4px;
            background: #01a0d4;
            padding: 4px 10px;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: white;
          }
        }
      }
    }
  }

  .modalBody {
    .modal-header {
      display: flex;
      border: none;

      .titleModal {
        width: 80%;
        text-align: start;

        span {
          color: var(--primary-dark, #0d4d80);
          font-family: Arial;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 167.886%;
          /* 53.723px */
        }
      }

      .modlclose {
        span {
          cursor: pointer;
        }
      }

      // justify-content: center;
      // gap: 100px;
    }

    .modalCard {
      width: 100%;

      .modalContent {
        padding-top: 27px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 60px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        button {
          // width: 401px;
          border-radius: 10px;
          background: var(--primary-dark, #0d4d80);
          height: 62px;
          padding: 10px;
          color: var(--side-back, #f8f8f8);
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 167.886%;
          /* 40.293px */
        }
      }
    }
  }
}
