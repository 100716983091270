.full_navbar{
    background: linear-gradient(90deg, #0b5186 0%, #1573be 72.31%);
}
 .fir span {
  color: #fff;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.logo_img img{ 
    width: 144px;
    height: 39.892px;
    flex-shrink: 0;
    border-radius: 0px 10px 10px 0px;

}
 .fir img {
    
  /* width: 27px;
    height: 21.211px; */
  flex-shrink: 0;
  width: 30px;
  height: 30.211px;
}
.left 
{
    gap: 15px;
}
.right {
  gap: 10px;
}
.right .setting {
  width: 32px;
  height: 32px;
}
.right .profile img {
  width: 32px;
  height: 32px;
}
@media only screen and (max-width: 1200px) {
    span {
        color: #fff;
        font-family: Arial;
        font-size: 17px;
        font-weight: 400;
      }
}
@media only screen and (max-width: 986px) {
  .fir {
    /* background-color:   #0d4d80; */
    background: linear-gradient(90deg, #0b5186 0%, #1573be 72.31%);
    border-radius: 15px;
    width: max-content;
  }
  .left {
    margin-left: auto;
    margin-right: auto;
  }
 span {
    color: #fff;
    font-family: Arial;
    font-size: 18px;
    font-weight: 500;
  }
 
}
