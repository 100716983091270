.KanbanEditForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 9.47218132019043px 0px #0000001A;
    padding: 0.7rem;
}

.KanbanEditForm-input-textarea {
    width: 100%;
    border-radius: 3px;
    outline: none;
    border: 1px solid gray;
}

.KanbanEditForm-label {
    padding: 0.5rem 0;
    font-weight: 500;

}

.KanbanEditForm-input {
    width: 100%;
    height: 2rem;
    border-radius: 3px;
    outline: none;
    border: 1px solid gray;
}

.KanbanEditForm-btn {
    font-size: 0.7rem;
    opacity: 0.8;
    padding: 0.2rem 0.4rem;
    border: 1px solid white;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 5px;
}

.kanban-input {
    height: 3rem;
    border-radius: 0.5rem;
    outline: none;
    border: 1px solid gray;
    padding-inline: 10px;
}

.kanban-input:focus {
    border: 2px solid #0089ED;
    border-radius: 0.5rem;
}

.KanbanEditForm-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.KanbanEditForm-btn-container {
    display: flex;
    justify-content: end;
    width: 100%;
}

.KanbanEditForm-btn {
    padding: 0.5rem 2rem;
    color: white;
    font-size: 1rem;
    letter-spacing: 4px;
    cursor: pointer;
}