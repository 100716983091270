.accountRecovery {
    width: 100%;
    height: 100vh;
    background: linear-gradient(86deg, #0D4D80 1.8%, rgba(0, 162, 214, 0.51) 99.57%);

    .signin-content {
        width: 100%;

        .signup-logo {
            position: absolute;
            // width: 100%;
            // height: 100%;

            top: 48px;

            .custom {

                // width: 220px;
                // height: 61.21px;
            }
        }

        .signup-card {
            width: 100%;

            .signupcard-content {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 79px 0px 0px 0px;
                // height: 100%;
                // padding: 79px 450px 80px 450px;
                // display: flex;
                // justify-content: center;

                // align-items: center;
                .main-card {
                    width: 539px;
                    // width: 100%;
                    // height: 100%;
                    // width: 539px;
                    // height: 741px;
                    flex-shrink: 0;
                    border-radius: 40px;
                    background: var(--white);
                    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
                    // padding: 50px 47px 110px 47px;
                    padding: 50px 47px 210px 47px;

                    .cardtitle {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        .leftside {
                            text-align: center;

                            h6 {
                                color: var(--black);
                                font-family: "Poppins";
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }

                        }

                        .rightside {
                            text-align: center;
                            // padding-block: 14px;

                            h6 {
                                color: var(--text-secondary);
                                font-family: "Poppins";
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;


                            }
                        }
                    }

                    .accountRecovery-middle {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 22px;
                        padding: 60px 0px 65px 0px;

                        .middleTop {

                            h6 {
                                color: var(--black);
                                font-family: "Poppins";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }

                        .middletext {
                            h6 {
                                color: var(--text-secondary);
                                text-align: center;
                                font-family: "Poppins";
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }

                        // .middle-code {
                        //     width: 100%;
                        //     display: flex;
                        //     gap: 18px;
                        //     justify-content: center;


                        //     .box1 {
                        //         width: 55px;
                        //         height: 60px;
                        //         border-radius: 9px;
                        //         background: var(--F6F6F6);
                        //         display: flex;
                        //         justify-content: center;
                        //         align-items: center;
                        //     }
                        // }
                        .middle-code {
                            width: 100%;
                            display: flex;
                            gap: 18px;
                            justify-content: center;
                        }

                        .box1 {
                            width: 55px;
                            height: 60px;
                            border-radius: 9px;
                            background: var(--F6F6F6);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .box1 input {
                            border: none;
                            // width: 100%;
                            // height: 100%;
                            // border-radius: 9px;
                            // padding: 8px;
                            padding-left: 20px;
                            box-sizing: border-box;
                            width: 55px;
                            height: 60px;
                            border-radius: 9px;
                            background: var(--F6F6F6);

                        }

                    }




                    .card-bottom {
                        width: 100%;



                        .signin-btn {
                            width: 100%;
                            padding-top: 25px;

                            button {
                                width: 100%;
                                height: 54px;
                                border-radius: 10px;
                                background: var(--text-primary);
                                box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
                                color: var(--white);
                                font-family: Arial;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .accountRecovery {
        width: 100%;
        height: 100vh;
        background: linear-gradient(86deg, #0D4D80 1.8%, rgba(0, 162, 214, 0.51) 99.57%);

        .signin-content {
            width: 100%;

            .signup-logo {
                position: absolute;
                top: 28px;

                .custom {}
            }

            .signup-card {
                width: 100%;

                .signupcard-content {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 50px 0px 0px 0px;


                    .main-card {
                        width: 539px;


                        flex-shrink: 0;
                        border-radius: 40px;
                        background: var(--white);
                        box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

                        padding: 50px 47px 50px 47px;

                        .cardtitle {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            .leftside {
                                text-align: center;

                                h6 {
                                    color: var(--black);
                                    font-family: "Poppins";
                                    font-size: 24px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                }

                            }

                            .rightside {
                                text-align: center;


                                h6 {
                                    color: var(--text-secondary);
                                    font-family: "Poppins";
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;


                                }
                            }
                        }

                        .accountRecovery-middle {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 22px;
                            padding: 60px 0px 65px 0px;

                            .middleTop {

                                h6 {
                                    color: var(--black);
                                    font-family: "Poppins";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            .middletext {
                                h6 {
                                    color: var(--text-secondary);
                                    text-align: center;
                                    font-family: "Poppins";
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            .middle-code {
                                width: 100%;
                                display: flex;
                                gap: 18px;
                                justify-content: center;
                            }

                            .box1 {
                                width: 55px;
                                height: 60px;
                                border-radius: 9px;
                                background: var(--F6F6F6);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .box1 input {
                                border: none;

                                padding-left: 20px;
                                box-sizing: border-box;
                                width: 55px;
                                height: 60px;
                                border-radius: 9px;
                                background: var(--F6F6F6);

                            }

                        }




                        .card-bottom {
                            width: 100%;



                            .signin-btn {
                                width: 100%;
                                padding-top: 25px;

                                button {
                                    width: 100%;
                                    height: 54px;
                                    border-radius: 10px;
                                    background: var(--text-primary);
                                    box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
                                    color: var(--white);
                                    font-family: Arial;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .accountRecovery {
        width: 100%;
        // height: 100%;
        // height: 100vh;
        background: linear-gradient(86deg, #0D4D80 1.8%, rgba(0, 162, 214, 0.51) 99.57%);

        .signin-content {
            width: 100%;
            padding: 100px 10px 0px 10px;

            .signup-logo {
                position: absolute;
                top: 10px;

                .custom {}
            }

            .signup-card {
                width: 100%;

                .signupcard-content {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 50px 0px 50px 0px;


                    .main-card {
                        width: 100%;
                        // width: 539px;


                        flex-shrink: 0;
                        border-radius: 40px;
                        background: var(--white);
                        box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

                        padding: 30px 10px 30px 10px;

                        .cardtitle {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            .leftside {
                                text-align: center;

                                h6 {
                                    color: var(--black);
                                    font-family: "Poppins";
                                    font-size: 24px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                }

                            }

                            .rightside {
                                text-align: center;


                                h6 {
                                    color: var(--text-secondary);
                                    font-family: "Poppins";
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;


                                }
                            }
                        }

                        .accountRecovery-middle {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 22px;
                            padding: 30px 0px 32px 0px;
                            // padding: 60px 0px 65px 0px;

                            .middleTop {

                                h6 {
                                    color: var(--black);
                                    font-family: "Poppins";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            .middletext {
                                h6 {
                                    color: var(--text-secondary);
                                    text-align: center;
                                    font-family: "Poppins";
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            .middle-code {
                                width: 100%;
                                display: flex;
                                gap: 18px;
                                justify-content: center;
                            }

                            .box1 {
                                width: 55px;
                                height: 60px;
                                border-radius: 9px;
                                background: var(--F6F6F6);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .box1 input {
                                border: none;

                                padding-left: 20px;
                                box-sizing: border-box;
                                width: 55px;
                                height: 60px;
                                border-radius: 9px;
                                background: var(--F6F6F6);

                            }

                        }




                        .card-bottom {
                            width: 100%;



                            .signin-btn {
                                width: 100%;
                                padding-top: 0px;

                                button {
                                    width: 100%;
                                    height: 54px;
                                    border-radius: 10px;
                                    background: var(--text-primary);
                                    box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
                                    color: var(--white);
                                    font-family: Arial;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}