.cover-background {
  position: relative !important;
  background-size: cover !important;
  overflow: hidden !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.p-0 {
  padding: 0 !important;
}
.notfoundSection {
  padding: 120px 0;
  overflow: hidden;
  background: #fff;
}

.error-page {
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(15px);
  padding: 80px 20px;
}
.text-center {
  text-align: center !important;
}
.error-page h1 {
  font-size: 200px;
  line-height: 1;
  font-weight: 600;
}
.text-secondary {
  color: #15395a !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.text-blue {
  color: #1573be;
  font-weight: bold;
}
