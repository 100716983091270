.resetPassword {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    86deg,
    #0d4d80 1.8%,
    rgba(0, 162, 214, 0.51) 99.57%
  );

  .signin-content {
    width: 100%;

    .signup-logo {
      position: absolute;
      // width: 100%;
      // height: 100%;

      top: 48px;

      .custom {
        // width: 220px;
        // height: 61.21px;
      }
    }

    .signup-card {
      width: 100%;

      .signupcard-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 79px 0px 0px 0px;

        .main-card {
          width: 539px;

          flex-shrink: 0;
          border-radius: 40px;
          background: var(--white);
          box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

          padding: 50px 47px 50px 47px;

          .cardtitle {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .leftside {
              text-align: center;

              h6 {
                color: var(--black);
                font-family: "Poppins";
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }

            .rightside {
              text-align: center;

              h6 {
                color: var(--text-secondary);
                font-family: "Poppins";
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }

          .accountRecovery-middle {
            width: 100%;
            display: flex;
            flex-direction: column;

            // gap: 22px;
            padding: 20px 0px 0px 0px;

            .firstfield {
              margin-bottom: 21px;

              .label {
                width: 100%;
                padding-bottom: 19px;

                label {
                  color: var(--black);
                  font-family: "Poppins";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .inputfield {
                width: 100%;
                // padding-bottom: 18px;
                position: relative;

                input {
                  width: 100%;
                  height: 57px;
                  border-radius: 9px;
                  border: 1px solid #adadad;
                  background: var(--white);
                  padding: 19px 50px 19px 19px;

                  &::placeholder {
                    color: var(--808080);
                    font-family: "Poppins";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                  }

                  &:focus {
                    border-radius: 9px;

                    border: 1px solid #4285f4 !important;
                    background: var(--white);
                  }
                }

                .eye-icon {
                  position: absolute;
                  top: 50%;
                  right: 10px;
                  transform: translateY(-50%);
                  cursor: pointer;
                }
              }
            }

            .firstfield2 {
              // margin-bottom: 21px;

              .label {
                width: 100%;
                padding-bottom: 19px;

                label {
                  color: var(--black);
                  font-family: "Poppins";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .inputfield {
                width: 100%;
                // padding-bottom: 18px;
                position: relative;

                input {
                  width: 100%;
                  height: 57px;
                  border-radius: 9px;
                  border: 1px solid #adadad;
                  background: var(--white);
                  padding: 19px 50px 19px 19px;

                  &::placeholder {
                    color: var(--808080);
                    font-family: "Poppins";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                  }

                  &:focus {
                    border-radius: 9px;

                    border: 1px solid #4285f4 !important;
                    background: var(--white);
                  }
                }

                .eye-icon {
                  position: absolute;
                  top: 50%;
                  right: 10px;
                  transform: translateY(-50%);
                  cursor: pointer;
                }
              }
            }
          }

          .card-bottom {
            width: 100%;

            .signin-btn {
              width: 100%;
              padding-top: 43px;

              button {
                width: 100%;
                height: 54px;
                border-radius: 10px;
                background: var(--text-primary);
                box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.3);
                color: var(--white);
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .resetPassword {
    width: 100%;
    height: 100vh;
    background: linear-gradient(
      86deg,
      #0d4d80 1.8%,
      rgba(0, 162, 214, 0.51) 99.57%
    );

    .signin-content {
      width: 100%;

      .signup-logo {
        position: absolute;
        top: 28px;

        .custom {
        }
      }

      .signup-card {
        width: 100%;

        .signupcard-content {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 50px 0px 0px 0px;

          .main-card {
            width: 539px;

            flex-shrink: 0;
            border-radius: 40px;
            background: var(--white);
            box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

            padding: 50px 47px 50px 47px;

            .cardtitle {
              display: flex;
              flex-direction: column;
              gap: 8px;

              .leftside {
                text-align: center;

                h6 {
                  color: var(--black);
                  font-family: "Poppins";
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
              }

              .rightside {
                text-align: center;

                h6 {
                  color: var(--text-secondary);
                  font-family: "Poppins";
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }

            .accountRecovery-middle {
              width: 100%;
              display: flex;
              flex-direction: column;

              // gap: 5px;
              padding: 30px 0px 0px 0px;

              .firstfield {
                // margin-bottom: 21px;

                .label {
                  width: 100%;
                  padding-bottom: 12px;

                  label {
                    color: var(--black);
                    font-family: "Poppins";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .inputfield {
                  width: 100%;
                  // padding-bottom: 18px;

                  input {
                    width: 100%;
                    height: 57px;
                    border-radius: 9px;
                    border: 1px solid #adadad;
                    background: var(--white);
                    padding: 19px 50px 19px 19px;

                    &::placeholder {
                      color: var(--808080);
                      font-family: "Poppins";
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 300;
                      line-height: normal;
                    }

                    &:focus {
                      border-radius: 9px;

                      border: 1px solid #4285f4 !important;
                      background: var(--white);
                    }
                  }

                  .eye-icon {
                    position: absolute;
                    top: 47%;
                    right: 10px;
                    transform: translateY(-50%);
                    cursor: pointer;
                  }
                }
                .inputfield2 {
                  width: 100%;
                  // padding-bottom: 18px;

                  input {
                    width: 100%;
                    height: 57px;
                    border-radius: 9px;
                    border: 1px solid #adadad;
                    background: var(--white);
                    padding: 19px 50px 19px 19px;

                    &::placeholder {
                      color: var(--808080);
                      font-family: "Poppins";
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 300;
                      line-height: normal;
                    }

                    &:focus {
                      border-radius: 9px;

                      border: 1px solid #4285f4 !important;
                      background: var(--white);
                    }
                  }

                  .eye-icon {
                    position: absolute;
                    top: 47%;
                    right: 10px;
                    transform: translateY(-50%);
                    cursor: pointer;
                  }
                }
              }
            }

            .card-bottom {
              width: 100%;

              .signin-btn {
                width: 100%;
                padding-top: 25px;

                button {
                  width: 100%;
                  height: 54px;
                  border-radius: 10px;
                  background: var(--text-primary);
                  box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.3);
                  color: var(--white);
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .resetPassword {
    width: 100%;
    // height: 100%;
    // height: 100vh;
    background: linear-gradient(
      86deg,
      #0d4d80 1.8%,
      rgba(0, 162, 214, 0.51) 99.57%
    );
    padding: 10px 10px 50px 10px;

    .signin-content {
      width: 100%;

      .signup-logo {
        position: absolute;

        top: 10px;

        .custom {
        }
      }

      .signup-card {
        width: 100%;

        .signupcard-content {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 100px 0px 0px 0px;

          .main-card {
            // width: 539px;
            width: 100%;

            flex-shrink: 0;
            border-radius: 40px;
            background: var(--white);
            box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

            padding: 40px 10px 50px 10px;

            .cardtitle {
              display: flex;
              flex-direction: column;
              gap: 8px;

              .leftside {
                text-align: center;

                h6 {
                  color: var(--black);
                  font-family: "Poppins";
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
              }

              .rightside {
                text-align: center;

                h6 {
                  color: var(--text-secondary);
                  font-family: "Poppins";
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }

            .accountRecovery-middle {
              width: 100%;
              display: flex;
              flex-direction: column;

              gap: 0px;
              padding: 30px 0px 0px 0px;

              .firstfield {
                .label {
                  width: 100%;
                  padding-bottom: 19px;

                  label {
                    color: var(--black);
                    font-family: "Poppins";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .inputfield {
                  width: 100%;
                  // padding-bottom: 18px;

                  input {
                    width: 100%;
                    height: 57px;
                    border-radius: 9px;
                    border: 1px solid #adadad;
                    background: var(--white);
                    padding: 19px 50px 19px 19px;

                    &::placeholder {
                      color: var(--808080);
                      font-family: "Poppins";
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 300;
                      line-height: normal;
                    }

                    &:focus {
                      border-radius: 9px;

                      border: 1px solid #4285f4 !important;
                      background: var(--white);
                    }
                  }

                  .eye-icon {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                    cursor: pointer;
                  }
                }
              }

              .firstfield2 {
                .label {
                  width: 100%;
                  padding-bottom: 19px;

                  label {
                    color: var(--black);
                    font-family: "Poppins";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .inputfield {
                  width: 100%;
                  // padding-bottom: 18px;

                  input {
                    width: 100%;
                    height: 57px;
                    border-radius: 9px;
                    border: 1px solid #adadad;
                    background: var(--white);
                    padding: 19px 50px 19px 19px;

                    &::placeholder {
                      color: var(--808080);
                      font-family: "Poppins";
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 300;
                      line-height: normal;
                    }

                    &:focus {
                      border-radius: 9px;

                      border: 1px solid #4285f4 !important;
                      background: var(--white);
                    }
                  }

                  .eye-icon {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                    cursor: pointer;
                  }
                }
              }
            }

            .card-bottom {
              width: 100%;

              .signin-btn {
                width: 100%;
                padding-top: 25px;

                button {
                  width: 100%;
                  height: 54px;
                  border-radius: 10px;
                  background: var(--text-primary);
                  box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.3);
                  color: var(--white);
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
        }
      }
    }
  }
}
