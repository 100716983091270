.gridRow {
  fill: #fff;
}

/* .gridRow:nth-child(even) {
  fill: #f5f5f5;
} */

.gridRowLine {
  stroke: gray;
}

.gridTick {
  stroke: gray;
}