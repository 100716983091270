.status-section {
  width: 100%;
  // padding-left: 74px;
  // padding-right: 74px;

  .status-content {
    width: 100%;
    border-radius: 8px;
    border: 1.11px solid #f0f1f3;
    display: flex;
    padding: 30px;
    justify-content: space-between;

    .leftside-status {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .rightside-status {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      gap: 5px;

      h5 {
        color: var(--Neutral-Black-Black-700, #333843);
        font-family: Arial;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.238px;
        /* 101.081% */
        letter-spacing: 0.11px;
      }

      h6 {
        color: var(--Neutral-Black-Black-700, #333843);
        font-family: Arial;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.238px;
        /* 101.081% */
        letter-spacing: 0.11px;
      }
    }
  }

  .status-bottom {
    padding-top: 23px;
    padding-bottom: 44px;

    .bottom-content {
      height: 233px;
      border-radius: 8px;
      border: 1.11px solid #f0f1f3;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 25px 34px;

      .bottom-title {
        h6 {
          color: var(--Neutral-Black-Black-700, #333843);
          font-family: Arial;
          font-size: 15.566px;
          font-style: normal;
          font-weight: 400;
          line-height: 22.238px;
          /* 142.857% */
          letter-spacing: 0.078px;
        }
      }

      .bottom-middle {
        .textarea-form {
          border-radius: 11.165px;
          background: var(--color-palette-neutral-05, #f3f3f3);

          &::placeholder {
            color: var(--color-palette-neutral-60, #6e6e70);
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20.469px;
            /* 146.209% */
          }
        }
      }

      .bottom-end {
        display: flex;
        justify-content: end;

        button {
          width: 76px;
          height: 35px;
          padding: 10px;
          border-radius: 8px;
          background: #a7d176;
          color: #fff;
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .status-section {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;

    .status-content {
      width: 100%;
      border-radius: 8px;
      border: 1.11px solid #f0f1f3;
      height: 100%;
      // height: 197px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;

      .leftside-status {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .rightside-status {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;

        h5 {
          color: var(--Neutral-Black-Black-700, #333843);
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 22.238px;
          /* 101.081% */
          letter-spacing: 0.11px;
        }

        h6 {
          color: var(--Neutral-Black-Black-700, #333843);
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 22.238px;
          /* 101.081% */
          letter-spacing: 0.11px;
        }
      }
    }

    .status-bottom {
      padding-top: 23px;
      padding-bottom: 44px;

      .bottom-content {
        height: 233px;
        border-radius: 8px;
        border: 1.11px solid #f0f1f3;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px 20px;

        .bottom-title {
          h6 {
            color: var(--Neutral-Black-Black-700, #333843);
            font-family: Arial;
            font-size: 15.566px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.238px;
            /* 142.857% */
            letter-spacing: 0.078px;
          }
        }

        .bottom-middle {
          .textarea-form {
            border-radius: 11.165px;
            background: var(--color-palette-neutral-05, #f3f3f3);

            &::placeholder {
              color: var(--color-palette-neutral-60, #6e6e70);
              font-family: Arial;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20.469px;
              /* 146.209% */
            }
          }
        }

        .bottom-end {
          display: flex;
          justify-content: end;

          button {
            width: 76px;
            height: 35px;
            padding: 10px;
            border-radius: 8px;
            background: #a7d176;
            color: #fff;
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
}
