.signupPage {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    86deg,
    #0d4d80 1.8%,
    rgba(0, 162, 214, 0.51) 99.57%
  );

  .signin-content {
    width: 100%;

    .signup-logo {
      position: absolute;
      // width: 100%;
      // height: 100%;

      top: 48px;

      .custom {
        // width: 220px;
        // height: 61.21px;
      }
    }

    .signup-card {
      width: 100%;

      .signupcard-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 79px 0px 0px 0px;
        // height: 100%;
        // padding: 79px 450px 80px 450px;
        // display: flex;
        // justify-content: center;

        // align-items: center;
        .main-card {
          width: 539px;
          margin-bottom: 20px;
          // width: 100%;
          // height: 100%;
          // width: 539px;
          // height: 741px;
          flex-shrink: 0;
          border-radius: 40px;
          background: var(--white);
          box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
          // padding: 50px 47px 110px 47px;
          padding: 50px 47px 56px 47px;

          .cardtitle {
            display: flex;
            justify-content: space-between;

            .leftside {
              h6 {
                color: var(--black);
                font-family: Arial;
                font-size: 52px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .rightside {
              padding-block: 14px;

              h6 {
                color: var(--text-secondary);
                font-family: Arial;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                span {
                  color: var(--primary);
                  font-family: Arial;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  cursor: pointer;
                }
              }
            }
          }

          // .cardSocialmedia {
          //     padding-top: 44px;
          //     padding-bottom: 58px;
          //     display: flex;
          //     gap: 20px;

          //     .googlebtn {

          //         button {
          //             width: 298px;
          //             height: 55px;
          //             border-radius: 9px;
          //             background: #E9F1FF;
          //             display: flex;
          //             gap: 21px;
          //             padding: 15px 0px 15px 32px;
          //             // justify-content: space-evenly;
          //             align-items: center;
          //             color: #4285F4;
          //             font-family: Arial;
          //             font-size: 16px;
          //             font-style: normal;
          //             font-weight: 400;
          //             line-height: normal;
          //         }
          //     }

          //     .facebook-btn {
          //         width: 60px;
          //         height: 55px;
          //         border-radius: 9px;
          //         background: #F6F6F6;
          //         display: flex;
          //         display: flex;
          //         justify-content: center;
          //         align-items: center;

          //     }
          // }

          .card-field {
            .firstfield {
              // padding-bottom: 18px;

              .label {
                width: 100%;
                padding-bottom: 19px;

                label {
                  color: var(--black);
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .inputfield {
                width: 100%;
                position: relative;
                padding-bottom: 18px;
                // display: flex;

                input {
                  width: 100%;
                  height: 57px;
                  border-radius: 9px;
                  border: 1px solid #adadad;
                  background: var(--white);
                  padding: 19px 50px 19px 19px;

                  &::placeholder {
                    // padding: 19px 25px 19px 19px;
                    color: (--808080);
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }

                  &:focus {
                    border-radius: 9px;
                    // outline: #4285F4;
                    border: 1px solid #4285f4 !important;
                    background: var(--white);
                  }
                }

                .eye-icon {
                  position: absolute;
                  top: 37%;
                  right: 10px;
                  transform: translateY(-50%);
                  cursor: pointer;
                }
              }
            }

            .firstfield-signup {
              .label {
                width: 100%;
                padding-bottom: 19px;
                padding-top: 63px;

                label {
                  color: var(--black);
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .inputfield {
                width: 100%;
                padding-bottom: 18px;

                input {
                  width: 100%;
                  height: 57px;
                  border-radius: 9px;
                  border: 1px solid #adadad;
                  background: var(--white);
                  padding: 19px 25px 19px 19px;

                  &::placeholder {
                    // padding: 19px 25px 19px 19px;
                    color: var(--808080);
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }

                  &:focus {
                    border-radius: 9px;
                    // outline: #4285F4;
                    border: 1px solid #4285f4 !important;
                    background: var(--white);
                  }
                }
              }
            }
          }

          .card-bottom {
            width: 100%;

            .bottom-title {
              padding-top: 46px;
              width: 100%;
              display: flex;
              justify-content: end;

              span {
                color: var(--4285F4);
                font-family: Arial;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
              }
            }

            .signin-btn {
              width: 100%;
              padding-top: 25px;

              button {
                width: 100%;
                height: 54px;
                border-radius: 10px;
                background: var(--primary);
                box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.3);
                color: var(--white);
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .signupPage {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(
      86deg,
      #0d4d80 1.8%,
      rgba(0, 162, 214, 0.51) 99.57%
    );

    .signin-content {
      width: 100%;

      .signup-logo {
        position: absolute;
        // width: 100%;
        // height: 100%;

        top: 20px;

        .custom {
          // width: 220px;
          // height: 61.21px;
        }
      }

      .signup-card {
        width: 100%;

        .signupcard-content {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 50px 0px 0px 0px;

          .main-card {
            width: 539px;
            margin-bottom: 20px;
            flex-shrink: 0;
            border-radius: 40px;
            background: var(--white);
            box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
            // padding: 10px 20px 20px 20px;
            padding: 20px 20px 30px 20px;

            .cardtitle {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .leftside {
                h6 {
                  color: var(--black);
                  font-family: Arial;
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .rightside {
                padding-block: 14px;

                h6 {
                  color: var(--text-secondary);
                  font-family: Arial;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;

                  span {
                    color: var(--primary);
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    cursor: pointer;
                  }
                }
              }
            }

            .card-field {
              .firstfield {
                .label {
                  width: 100%;
                  padding-bottom: 10px;

                  label {
                    color: var(--black);
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .inputfield {
                  width: 100%;
                  padding-bottom: 10px;

                  input {
                    width: 100%;
                    height: 47px;
                    border-radius: 9px;
                    border: 1px solid #adadad;
                    background: var(--white);
                    padding: 12px 50px 12px 19px;

                    &::placeholder {
                      // padding: 19px 25px 19px 19px;
                      color: (--808080);
                      font-family: Arial;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    }

                    &:focus {
                      border-radius: 9px;
                      // outline: #4285F4;
                      border: 1px solid #4285f4 !important;
                      background: var(--white);
                    }
                  }

                  .eye-icon {
                    position: absolute;
                    top: 42%;
                    right: 10px;
                    transform: translateY(-50%);
                    cursor: pointer;
                  }
                }
              }

              .firstfield-signup {
                .label {
                  width: 100%;
                  padding-bottom: 10px;
                  padding-top: 10px;

                  label {
                    color: var(--black);
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .inputfield {
                  width: 100%;
                  padding-bottom: 10px;

                  input {
                    width: 100%;
                    height: 47px;
                    border-radius: 9px;
                    border: 1px solid #adadad;
                    background: var(--white);
                    padding: 12px 25px 12px 19px;

                    &::placeholder {
                      // padding: 19px 25px 19px 19px;
                      color: var(--808080);
                      font-family: Arial;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    }

                    &:focus {
                      border-radius: 9px;
                      // outline: #4285F4;
                      border: 1px solid #4285f4 !important;
                      background: var(--white);
                    }
                  }
                }
              }
            }

            .card-bottom {
              width: 100%;

              .bottom-title {
                padding-top: 46px;
                width: 100%;
                display: flex;
                justify-content: end;

                span {
                  color: var(--4285F4);
                  font-family: Arial;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  cursor: pointer;
                }
              }

              .signin-btn {
                width: 100%;
                padding-top: 0px;

                button {
                  width: 100%;
                  height: 54px;
                  border-radius: 10px;
                  background: var(--primary);
                  box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.3);
                  color: var(--white);
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .signupPage {
    width: 100%;
    min-height: 100vh;
    padding: 10px 0px 50px 0px;
    // height: 100vh;
    background: linear-gradient(
      86deg,
      #0d4d80 1.8%,
      rgba(0, 162, 214, 0.51) 99.57%
    );

    .signin-content {
      width: 100%;

      .signup-logo {
        position: absolute;
        // width: 100%;
        // height: 100%;

        top: 18px;

        .custom {
          // width: 220px;
          // height: 61.21px;
        }
      }

      .signup-card {
        width: 100%;

        .signupcard-content {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 100px 10px 0px 10px;

          // align-items: center;
          .main-card {
            width: 100%;
            // width: 539px;
            // width: 100%;
            margin-bottom: 20px;
            flex-shrink: 0;
            border-radius: 40px;
            background: var(--white);
            box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
            // padding: 50px 47px 110px 47px;
            padding: 40px 10px 50px 10px;
            margin-left: 20px;
            margin-right: 20px;

            .cardtitle {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .leftside {
                h6 {
                  color: var(--black);
                  font-family: Arial;
                  font-size: 36px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .rightside {
                padding-block: 14px;

                h6 {
                  color: var(--text-secondary);
                  font-family: Arial;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;

                  span {
                    color: var(--primary);
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    cursor: pointer;
                  }
                }
              }
            }

            .card-field {
              .firstfield {
                .label {
                  width: 100%;
                  padding-bottom: 10px;

                  label {
                    color: var(--black);
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .inputfield {
                  width: 100%;
                  padding-bottom: 18px;

                  input {
                    width: 100%;
                    height: 57px;
                    border-radius: 9px;
                    border: 1px solid #adadad;
                    background: var(--white);
                    padding: 19px 50px 19px 19px;

                    &::placeholder {
                      // padding: 19px 25px 19px 19px;
                      color: (--808080);
                      font-family: Arial;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    }

                    &:focus {
                      border-radius: 9px;
                      // outline: #4285F4;
                      border: 1px solid #4285f4 !important;
                      background: var(--white);
                    }
                  }
                }
              }

              .firstfield-signup {
                .label {
                  width: 100%;
                  padding-bottom: 10px;
                  padding-top: 30px;

                  label {
                    color: var(--black);
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .inputfield {
                  width: 100%;
                  padding-bottom: 18px;

                  input {
                    width: 100%;
                    height: 57px;
                    border-radius: 9px;
                    border: 1px solid #adadad;
                    background: var(--white);
                    padding: 19px 25px 19px 19px;

                    &::placeholder {
                      // padding: 19px 25px 19px 19px;
                      color: var(--808080);
                      font-family: Arial;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    }

                    &:focus {
                      border-radius: 9px;
                      // outline: #4285F4;
                      border: 1px solid #4285f4 !important;
                      background: var(--white);
                    }
                  }
                }
              }
            }

            .card-bottom {
              width: 100%;

              .bottom-title {
                padding-top: 46px;
                width: 100%;
                display: flex;
                justify-content: end;

                span {
                  color: var(--4285F4);
                  font-family: Arial;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  cursor: pointer;
                }
              }

              .signin-btn {
                width: 100%;
                padding-top: 25px;

                button {
                  width: 100%;
                  height: 54px;
                  border-radius: 10px;
                  background: var(--primary);
                  box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.3);
                  color: var(--white);
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
        }
      }
    }
  }
}
