:root {
  --black: #000000;
  --white: #ffffff;



  --primary: #0089ed;
  --secondary: #e9f1ff;
  --text-primary: #0089ED;
  --text-secondary: #8D8D8D;
  --text-dark: #131313;
  --swotStrength : #E1EDD3;
  --swotWeakness : #F6D6D5;
  --swotOpportunity : #BFD6E8;
  --swotThreat : #FAEEC9;
  --0D4D80: #0D4D80;
  --00A2D6: #00A2D6;
  --c3c3c3: #c3c3c3;
  --0D4D80: #0D4D80;
  --4285F4: #4285F4;
  --808080: #808080;
  --F6F6F6: #F6F6F6;
}