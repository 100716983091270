.subscriptionplan {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 6.5vh) !important;
    // min-height: 100vh;
    // padding-top: 60px;
    // padding-bottom: 100px;
    // height: 100vh;
    background-image: url("../../assets/images/layerimg.png");
    // background-position: center;
    background-size: contain;

    .subscriptionContent {
        width: 100%;
        padding-top: 150px;
        padding-left: 235px;
        padding-right: 235px;

        .subscriptionCard {
            width: 100%;
            display: flex;
            // gap: 10px;
            justify-content: center;
            // border: 1px solid green;

            .firstCard {
                cursor: pointer;
                width: 333.843px;

                border-radius: 11.354px;
                background: #FFF;
                box-shadow: 0px 3.785px 94.621px 0px rgba(0, 0, 0, 0.25);

                .cardContent {
                    padding: 37px 40px 52px 40px;

                    .cardTitle {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 39px;

                        span {
                            color: var(--primary-dark, #0D4D80);
                            font-family: "Inter";
                            font-size: 29.881px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 128.023%;
                            /* 38.255px */
                        }
                    }

                    .cardContents {
                        display: flex;
                        flex-direction: column;

                        align-items: center;

                        .contentTitle {
                            width: 100%;

                            display: flex;
                            flex-direction: column;


                            .userdiv {
                                text-align: center;
                                padding: 9px 0px 0px 0px;








                                span {
                                    color: var(--primary-dark, #0D4D80);
                                    font-family: "Inter";
                                    font-size: 22.986px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 128.023%;


                                }

                                .linearbg {
                                    margin-top: 9px;
                                    opacity: 0.5;
                                    height: 1.849px;
                                    width: 100%;
                                    background: linear-gradient(90deg, rgba(1, 160, 212, 0) 0%, #0C598C 49.85%, rgba(1, 158, 210, 0) 100%);
                                }

                            }

                            .diskdiv {
                                padding: 9px 14px 9px 14px;
                                text-align: center;

                                .disk {
                                    color: var(--primary-dark, #0D4D80);
                                    font-family: "Inter";
                                    font-size: 22.986px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 128.023%;
                                    /* 29.427px */
                                }
                            }

                            .emailSupport {
                                padding: 9px 14px 9px 14px;
                                text-align: center;

                                span {
                                    color: var(--primary-dark, #0D4D80);
                                    font-family: "Inter";
                                    font-size: 22.986px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 128.023%;
                                    opacity: 0.5;
                                    text-decoration: line-through;

                                }

                                .linearbg {
                                    margin-top: 9px;
                                    opacity: 0.5;
                                    height: 1.849px;
                                    width: 100%;
                                    background: linear-gradient(90deg, rgba(1, 160, 212, 0) 0%, #0C598C 49.85%, rgba(1, 158, 210, 0) 100%);
                                }
                            }

                            .techsupport {
                                padding: 9px 14px 9px 14px;
                                text-align: center;

                                span {
                                    color: var(--primary-dark, #0D4D80);
                                    font-family: "Inter";
                                    font-size: 22.986px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 128.023%;
                                    /* 29.427px */
                                }

                                .linearbg {
                                    margin-top: 9px;
                                    // opacity: 0.5;
                                    height: 1.849px;
                                    width: 100%;
                                    background: linear-gradient(90deg, rgba(1, 160, 212, 0) 0%, #0C598C 49.85%, rgba(1, 158, 210, 0) 100%);
                                }
                            }

                            .month {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                padding: 35px 0px 50px 0px;

                                .dollar {
                                    color: var(--primary-dark, #0D4D80);
                                    font-family: "Inter";
                                    font-size: 29.881px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 128.023%;
                                    /* 38.255px */
                                }

                                .months {
                                    color: var(--primary-dark, #0D4D80);
                                    font-family: "Inter";
                                    font-size: 22.986px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 128.023%;
                                    /* 29.427px */
                                }
                            }

                            .purchaseButton {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 9px;
                                height: 39px;
                                padding: 12px 24px;
                                border-radius: 4px;
                                background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);
                                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);

                                .buttondiv {


                                    .purchase {
                                        color: #FFF;
                                        font-family: "Inter";
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 128.023%;



                                    }
                                }

                                .svgdiv {
                                    width: 20px;
                                    padding-left: 2px;

                                    height: 20px;

                                    border-radius: 65.967px;
                                    background: rgba(255, 255, 255, 0.60);
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    svg {
                                        width: 10px;
                                        height: 10px;

                                    }
                                }
                            }
                        }
                    }
                }

                &.selectedCard {
                    background: var(--side-bar, linear-gradient(90deg, #00A2D6 0%, #0D4D80 100%));
                    box-shadow: 0px 3.785px 94.621px 0px rgba(0, 0, 0, 0.15);

                    transform: translateY(-37px) scale(1.05);
                    // transform: translateY(-37px);


                    transition: background-color 0.3s ease-out, transform 0.3s ease-out;

                    .cardContent {


                        .cardTitle {


                            span {
                                color: white;

                            }
                        }

                        .cardContents {


                            .contentTitle {



                                .userdiv {









                                    span {
                                        color: white;



                                    }

                                    .linearbg {}

                                }

                                .diskdiv {


                                    .disk {
                                        color: white;

                                    }
                                }

                                .emailSupport {


                                    span {
                                        color: white;


                                    }

                                    .linearbg {}
                                }

                                .techsupport {


                                    span {
                                        color: white;

                                    }

                                    .linearbg {}
                                }

                                .month {


                                    .dollar {
                                        color: white;

                                    }

                                    .months {
                                        color: white;

                                    }
                                }

                                .purchaseButton {


                                    .buttondiv {


                                        .purchase {}
                                    }

                                    .svgdiv {


                                        svg {}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


    }
}

@media only screen and (max-width: 1600px) {
    .subscriptionplan {
        width: 100%;
        height: 100%;
        padding-bottom: 0px;
        min-height: calc(100vh - 6.5vh);
        // padding-top: 60px;
        // height: 100vh;
        background-image: url("../../assets/images/layerimg.png");
        // background-position: center;
        background-size: contain;

        .subscriptionContent {
            width: 100%;
            padding-top: 117px;
            padding-left: 10px;
            padding-right: 10px;

            .subscriptionCard {
                width: 100%;
                display: flex;
                // gap: 10px;
                justify-content: center;


                .firstCard {
                    cursor: pointer;
                    width: 333.843px;

                    border-radius: 11.354px;
                    background: #FFF;
                    box-shadow: 0px 3.785px 94.621px 0px rgba(0, 0, 0, 0.25);

                    .cardContent {
                        padding: 20px 20px 20px 20px;

                        .cardTitle {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 15px;

                            span {
                                color: var(--primary-dark, #0D4D80);
                                font-family: "Inter";
                                font-size: 23.881px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 128.023%;
                                /* 38.255px */
                            }
                        }

                        .cardContents {
                            display: flex;
                            flex-direction: column;

                            align-items: center;

                            .contentTitle {
                                width: 100%;

                                display: flex;
                                flex-direction: column;


                                .userdiv {
                                    text-align: center;
                                    padding: 9px 0px 0px 0px;








                                    span {
                                        color: var(--primary-dark, #0D4D80);
                                        font-family: "Inter";
                                        font-size: 20.986px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 128.023%;


                                    }

                                    .linearbg {
                                        margin-top: 9px;
                                        opacity: 0.5;
                                        height: 1.849px;
                                        width: 100%;
                                        background: linear-gradient(90deg, rgba(1, 160, 212, 0) 0%, #0C598C 49.85%, rgba(1, 158, 210, 0) 100%);
                                    }

                                }

                                .diskdiv {
                                    padding: 9px 14px 9px 14px;
                                    text-align: center;

                                    .disk {
                                        color: var(--primary-dark, #0D4D80);
                                        font-family: "Inter";
                                        font-size: 20.986px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 128.023%;
                                        /* 29.427px */
                                    }
                                }

                                .emailSupport {
                                    padding: 9px 14px 9px 14px;
                                    text-align: center;

                                    span {
                                        color: var(--primary-dark, #0D4D80);
                                        font-family: "Inter";
                                        font-size: 20.986px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 128.023%;
                                        opacity: 0.5;
                                        text-decoration: line-through;

                                    }

                                    .linearbg {
                                        margin-top: 9px;
                                        opacity: 0.5;
                                        height: 1.849px;
                                        width: 100%;
                                        background: linear-gradient(90deg, rgba(1, 160, 212, 0) 0%, #0C598C 49.85%, rgba(1, 158, 210, 0) 100%);
                                    }
                                }

                                .techsupport {
                                    padding: 9px 14px 9px 14px;
                                    text-align: center;

                                    span {
                                        color: var(--primary-dark, #0D4D80);
                                        font-family: "Inter";
                                        font-size: 20.986px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 128.023%;
                                        /* 29.427px */
                                    }

                                    .linearbg {
                                        margin-top: 9px;
                                        // opacity: 0.5;
                                        height: 1.849px;
                                        width: 100%;
                                        background: linear-gradient(90deg, rgba(1, 160, 212, 0) 0%, #0C598C 49.85%, rgba(1, 158, 210, 0) 100%);
                                    }
                                }

                                .month {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    padding: 15px 0px 10px 0px;

                                    .dollar {
                                        color: var(--primary-dark, #0D4D80);
                                        font-family: "Inter";
                                        font-size: 25.881px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 128.023%;
                                        /* 38.255px */
                                    }

                                    .months {
                                        color: var(--primary-dark, #0D4D80);
                                        font-family: "Inter";
                                        font-size: 20.986px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 128.023%;
                                        /* 29.427px */
                                    }
                                }

                                .purchaseButton {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 9px;
                                    height: 39px;
                                    padding: 12px 24px;
                                    border-radius: 4px;
                                    background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);
                                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);

                                    .buttondiv {


                                        .purchase {
                                            color: #FFF;
                                            font-family: "Inter";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 128.023%;



                                        }
                                    }

                                    .svgdiv {
                                        width: 20px;
                                        padding-left: 2px;

                                        height: 20px;

                                        border-radius: 65.967px;
                                        background: rgba(255, 255, 255, 0.60);
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        svg {
                                            width: 10px;
                                            height: 10px;

                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.selectedCard {
                        background: var(--side-bar, linear-gradient(90deg, #00A2D6 0%, #0D4D80 100%));
                        box-shadow: 0px 3.785px 94.621px 0px rgba(0, 0, 0, 0.15);

                        transform: translateY(-37px);
                        // transform: translateY(-37px);


                        transition: background-color 0.3s ease-out, transform 0.3s ease-out;

                        .cardContent {


                            .cardTitle {


                                span {
                                    color: white;

                                }
                            }

                            .cardContents {


                                .contentTitle {



                                    .userdiv {









                                        span {
                                            color: white;



                                        }

                                        .linearbg {}

                                    }

                                    .diskdiv {


                                        .disk {
                                            color: white;

                                        }
                                    }

                                    .emailSupport {


                                        span {
                                            color: white;


                                        }

                                        .linearbg {}
                                    }

                                    .techsupport {


                                        span {
                                            color: white;

                                        }

                                        .linearbg {}
                                    }

                                    .month {


                                        .dollar {
                                            color: white;

                                        }

                                        .months {
                                            color: white;

                                        }
                                    }

                                    .purchaseButton {


                                        .buttondiv {


                                            .purchase {}
                                        }

                                        .svgdiv {


                                            svg {}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


        }
    }
}

@media only screen and (max-width: 600px) {
    .subscriptionplan {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 50px;
        height: 100%;
        min-height: 100vh;
        background-image: url("../../assets/images/layerimg.png");
        // background-position: center;
        background-size: contain;

        .subscriptionContent {
            width: 100%;
            padding-top: 80px;
            padding-left: 10px;
            padding-right: 10px;

            .subscriptionCard {
                width: 100%;
                display: flex;
                flex-direction: column;
                // gap: 10px;
                justify-content: center;
                align-items: center;
                gap: 10px;


                .firstCard {
                    cursor: pointer;
                    width: 280.843px;

                    border-radius: 11.354px;
                    background: #FFF;
                    box-shadow: 0px 3.785px 94.621px 0px rgba(0, 0, 0, 0.25);

                    .cardContent {
                        padding: 37px 20px 52px 20px;

                        .cardTitle {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 39px;

                            span {

                                font-size: 22.881px;

                            }
                        }

                        .cardContents {
                            display: flex;
                            flex-direction: column;

                            align-items: center;

                            .contentTitle {
                                width: 100%;

                                display: flex;
                                flex-direction: column;


                                .userdiv {
                                    text-align: center;
                                    padding: 9px 0px 0px 0px;








                                    span {

                                        font-size: 19px;



                                    }

                                    .linearbg {
                                        margin-top: 9px;
                                        opacity: 0.5;
                                        height: 1.849px;
                                        width: 100%;
                                        background: linear-gradient(90deg, rgba(1, 160, 212, 0) 0%, #0C598C 49.85%, rgba(1, 158, 210, 0) 100%);
                                    }

                                }

                                .diskdiv {
                                    padding: 9px 3px 9px 3px;
                                    text-align: center;

                                    .disk {

                                        font-size: 19.986px;

                                        /* 29.427px */
                                    }
                                }

                                .emailSupport {
                                    padding: 9px 14px 9px 14px;
                                    text-align: center;

                                    span {

                                        font-size: 19px;


                                    }

                                    .linearbg {}
                                }

                                .techsupport {
                                    padding: 9px 14px 9px 14px;
                                    text-align: center;

                                    span {

                                        font-size: 19px;

                                    }

                                    .linearbg {}
                                }

                                .month {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    padding: 35px 0px 50px 0px;

                                    .dollar {

                                        font-size: 23.881px;


                                    }

                                    .months {

                                        font-size: 20.986px;


                                    }
                                }

                                .purchaseButton {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 9px;
                                    height: 39px;
                                    padding: 12px 24px;
                                    border-radius: 4px;
                                    background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);
                                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);

                                    .buttondiv {


                                        .purchase {
                                            color: #FFF;
                                            font-family: "Inter";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 128.023%;



                                        }
                                    }

                                    .svgdiv {
                                        width: 20px;
                                        padding-left: 2px;

                                        height: 20px;

                                        border-radius: 65.967px;
                                        background: rgba(255, 255, 255, 0.60);
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        svg {
                                            width: 10px;
                                            height: 10px;

                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.selectedCard {
                        background: var(--side-bar, linear-gradient(90deg, #00A2D6 0%, #0D4D80 100%));
                        box-shadow: 0px 3.785px 94.621px 0px rgba(0, 0, 0, 0.15);

                        transform: translateY(-10px) scale(1.1);
                        // transform: translateY(-37px);


                        transition: background-color 0.3s ease-out, transform 0.3s ease-out;

                        .cardContent {


                            .cardTitle {


                                span {
                                    color: white;

                                }
                            }

                            .cardContents {


                                .contentTitle {



                                    .userdiv {









                                        span {
                                            color: white;



                                        }

                                        .linearbg {}

                                    }

                                    .diskdiv {


                                        .disk {
                                            color: white;

                                        }
                                    }

                                    .emailSupport {


                                        span {
                                            color: white;


                                        }

                                        .linearbg {}
                                    }

                                    .techsupport {


                                        span {
                                            color: white;

                                        }

                                        .linearbg {}
                                    }

                                    .month {


                                        .dollar {
                                            color: white;

                                        }

                                        .months {
                                            color: white;

                                        }
                                    }

                                    .purchaseButton {


                                        .buttondiv {


                                            .purchase {}
                                        }

                                        .svgdiv {


                                            svg {}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}