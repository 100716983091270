.priorityDropdown {
    .first-goal {
        display: flex;
        justify-content: end;

        .inputbox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 2px;
            // gap: 0px;
            width: 310px;
            // width: 100%;
            // width: 90px;
            height: 30px;
            padding: 0px 3px 0px 2px;
            // padding: 2px 2px 2px 6px;
            border-radius: 4px;
            background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);
            cursor: pointer;
            position: relative;

            .leftside {
                color: #FFF;
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                //   padding: 5px;
                margin-top: 2px;
                margin-left: 2px;
                // width: 52px;

            }

            .rightside {
                // width: 18px;
                // height: 18px;
                margin-right: -3px;


            }

            .dropdown {
                position: absolute;
                top: 32px;
                left: 0px;
                // width: 100%;
                z-index: 999;

                .firstdropdown {
                    width: 100%;
                    border-bottom: 0.2px solid #8D8D8D;
                    background: #FFF;
                    height: 32.241px;
                    padding: 2px 2px 2px 6px;
                    display: flex;
                    align-items: center;

                    span {
                        color: #000;
                        font-family: Arial;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }


                }

                .lastdropdown {

                    background: #FFF;
                    height: 22.241px;
                    padding: 2px 2px 2px 6px;
                    display: flex;
                    align-items: center;

                    span {
                        color: #000;
                        font-family: Arial;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }

        // .inputboxGreen {
        //     background: #F0FFDF;
        //     color: #9CCC65;


        // }

        // .addinClr {

        //     background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);
        // }

        // .inputtextgreen {
        //     color: #9CCC65;
        // }


    }
}