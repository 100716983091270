.Kanban {
    height: min-content;
    padding: 1rem;
    margin-inline: 3rem;
    padding-inline: 3rem;
}

.Kanban-columns-container {
    min-height: min-content;
    height: 100%;
    margin: 2rem 0;
    display: flex;
    gap: 36px;
    align-items: flex-start;
    flex-wrap: wrap;
}

@media only screen and (max-width: 775px) {
    .Kanban {
        height: min-content;
        padding: 1rem;
        margin-inline: 0.2rem;
        padding-inline: 0.2rem;
    }
}