.chat-section {
  width: 100%;

  .chat-content {
    padding: 20px 20px 50px 20px;
    width: 100%;
    height: 100%;
    position: relative;
    .chat-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      background: #e4e3e3;
      // background: red;
      height: 65px;
      padding-left: 21px;
      padding-right: 21px;

      .left-section {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          color: var(--primary-dark, #0d4d80);
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 158.5%;
          /* 28.53px */
        }
      }

      .right-section {
        img {
          cursor: pointer;
        }
      }
    }

    .chat-expanded-content {
      height: 0;
      // height: 400px;

      // background: white;
      background: #f8f8f8;
      position: relative;
      border-radius: 0px 0px 8px 8px;
      overflow: hidden;
      transition: 0.5s ease-in-out !important;
      // overflow-y: auto;
      // height: 300px;
      // padding-bottom: 50px;
      box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.1),
        -2px -2px 16px rgba(187, 210, 255, 0.5);

      .first-promptanswer {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        padding-left: 21px;
        margin-bottom: 5px;
        margin-top: 5px;

        .left-question {
          border-radius: 0px 22.797px 22.797px 0px;
          background: var(--primary-dark, #0d4d80);
          padding: 9.317px 17.539px;

          span {
            color: #fff;
            font-family: Arial;
            font-size: 15.668px;
            font-style: normal;
            font-weight: 400;
            line-height: 158.5%;
            /* 24.834px */
          }
        }
      }

      .first-prompt {
        // height: 100%;
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 10px;
        padding-right: 21px;
        margin-bottom: 5px;
        padding-left: 40%;

        .left-question {
          height: 80%;
          border-radius: 22.795px 22.797px 0px 22.797px;
          background: var(--primary-light, #00a2d6);
          padding: 9.317px 17.539px;

          span {
            color: #fff;
            font-family: Arial;
            font-size: 15.668px;
            font-style: normal;
            font-weight: 400;
            line-height: 158.5%;
            /* 24.834px */
          }
        }
      }

      .date-div {
        width: 50%;
        display: flex;
        justify-content: end;
        margin-bottom: 5px;

        .date-content {
          padding: 0.548px 8.769px;
          border-radius: 25.76px;
          background: var(--side-text, #c3c3c3);

          span {
            color: #fff;
            font-family: Arial;
            font-size: 13.702px;
            font-style: normal;
            font-weight: 400;
            line-height: 158.5%;
            /* 21.718px */
          }
        }
      }

      // .bottom-section {
      //     background: var(--light-1, #F6F6F6);
      //     position: absolute;
      //     width: 100%;
      //     bottom: 0;
      //     left: 0;
      //     right: 0;
      //     height: 60px;
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     padding-left: 21px;
      //     padding-right: 21px;
      //     border-radius: 0px 0px 8px 8px;
      //     overflow: hidden;

      //     .bottom-left {
      //         width: 90%;
      //         height: 100%;

      //         input {
      //             width: 100%;
      //             height: 100%;
      //             padding-left: 80px;
      //             padding-right: 50px;
      //             border: none;
      //             background: var(--light-1, #F6F6F6);

      //             &::placeholder {
      //                 color: var(--side-text, #C3C3C3);
      //                 font-family: Poppins;
      //                 font-size: 15.668px;
      //                 font-style: normal;
      //                 font-weight: 500;
      //                 line-height: 158.5%;
      //                 /* 24.834px */
      //             }
      //         }
      //     }

      //     .bottom-right {
      //         width: 10%;
      //         height: 100%;
      //         display: flex;
      //         gap: 10px;
      //         align-items: center;
      //         justify-content: center;

      //         img {
      //             cursor: pointer;
      //         }
      //     }
      // }
    }

    .bottom-parent {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      // margin-left: 21px;
      // margin-right: 21px;
      // padding-right: 21px;
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      border-radius: 8px 8px 8px 8px;

      .bottom-section {
        background: #e4e3e3;
        // background: green
        // position: absolute;
        width: 100%;
        // bottom: 0;
        left: 0;
        right: 20px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding-left: 21px;
        // padding-right: 21px;
        // margin-left: 21px;
        border-radius: 8px 8px 8px 8px;
        // overflow: hidden;

        .bottom-left {
          width: 80%;
          height: 100%;
          border-radius: 8px 8px 8px 8px;

          input {
            width: 100%;
            height: 100%;
            padding-left: 40px;
            padding-right: 50px;
            border: none;
            background: #e4e3e3;
            // background: var(--light-1, #f6f6f6);
            // background: green;
            border-radius: 8px 8px 8px 8px;

            &::placeholder {
              color: var(--side-text, #a8a6a6);
              font-family: Poppins;
              font-size: 15.668px;
              font-style: normal;
              font-weight: 500;
              line-height: 158.5%;
              /* 24.834px */
            }
          }
        }

        .bottom-right {
          width: 20%;
          height: 100%;
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: end;
          padding-right: 20px;
          border-radius: 8px 8px 8px 8px;

          img {
            cursor: pointer;
          }
        }
      }
    }

    .setHeight {
      height: 100%;
      transition: 0.5s ease-in-out !important;
      // height: calc(488px - 60px);

      padding-bottom: 30px;
      // margin-bottom: 10px;

      .chat-container {
        height: calc(488px - 60px);
        overflow-y: auto;
        // padding-bottom: 65px;
      }
    }
  }
}

.goalModelBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--primary-dark, #0d4d80);
  height: 56px;
  padding: 10px;
  color: var(--side-back, #f8f8f8);
  font-family: Arial;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 167.886%;
  cursor: pointer;
}
.chatGoalSelect {
  width: 50%;
  background-color: #ede8e8;
  height: 57px;
  color: black !important;
  border: 1px solid #adadad;
  border-radius: 10px;
}
@media only screen and (max-width: 1600px) {
  .chat-section {
    width: 100%;

    .chat-content {
      padding: 10px;
      width: 100%;
      height: 100%;

      .chat-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        background: #f8f8f8;
        height: 55px;
        padding-left: 21px;
        padding-right: 21px;

        .left-section {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 10px;

          img {
            width: 30px;
            height: 30px;
          }

          span {
            color: var(--primary-dark, #0d4d80);
            font-family: Arial;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: 10%;
            /* 28.53px */
          }
        }

        .right-section {
          img {
            cursor: pointer;
            width: 20px;
            // height: 20px;
          }
        }
      }

      .chat-expanded-content {
        height: 0;
        // height: 400px;

        background: white;
        position: relative;
        border-radius: 0px 0px 8px 8px;
        overflow: hidden;
        transition: 0.5s ease-in-out !important;
        // overflow-y: auto;
        // height: 300px;
        // padding-bottom: 50px;

        .first-promptanswer {
          width: 70%;
          display: flex;
          align-items: center;
          gap: 10px;
          padding-left: 21px;
          margin-bottom: 5px;

          .left-question {
            border-radius: 0px 22.797px 22.797px 0px;
            background: var(--primary-dark, #0d4d80);
            padding: 9.317px 17.539px;

            span {
              color: #fff;
              font-family: Arial;
              font-size: 15.668px;
              font-style: normal;
              font-weight: 400;
              line-height: 158.5%;
              /* 24.834px */
            }
          }
        }

        .first-prompt {
          width: 100%;
          display: flex;
          justify-content: end;
          align-items: center;
          gap: 10px;
          padding-right: 21px;
          margin-bottom: 5px;
          padding-left: 70px;

          .left-question {
            border-radius: 22.795px 22.797px 0px 22.797px;
            background: var(--primary-light, #00a2d6);
            padding: 9.317px 17.539px;

            span {
              color: #fff;
              font-family: Arial;
              font-size: 15.668px;
              font-style: normal;
              font-weight: 400;
              line-height: 158.5%;
              /* 24.834px */
            }
          }
        }

        .date-div {
          width: 50%;
          display: flex;
          justify-content: end;
          margin-bottom: 5px;

          .date-content {
            padding: 0.548px 8.769px;
            border-radius: 25.76px;
            background: var(--side-text, #c3c3c3);

            span {
              color: #fff;
              font-family: Arial;
              font-size: 13.702px;
              font-style: normal;
              font-weight: 400;
              line-height: 158.5%;
              /* 21.718px */
            }
          }
        }

        // .bottom-section {
        //     background: var(--light-1, #F6F6F6);
        //     position: absolute;
        //     width: 100%;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     height: 60px;
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: center;
        //     padding-left: 21px;
        //     padding-right: 21px;
        //     border-radius: 0px 0px 8px 8px;
        //     overflow: hidden;

        //     .bottom-left {
        //         width: 90%;
        //         height: 100%;

        //         input {
        //             width: 100%;
        //             height: 100%;
        //             padding-left: 80px;
        //             padding-right: 50px;
        //             border: none;
        //             background: var(--light-1, #F6F6F6);

        //             &::placeholder {
        //                 color: var(--side-text, #C3C3C3);
        //                 font-family: Poppins;
        //                 font-size: 15.668px;
        //                 font-style: normal;
        //                 font-weight: 500;
        //                 line-height: 158.5%;
        //                 /* 24.834px */
        //             }
        //         }
        //     }

        //     .bottom-right {
        //         width: 10%;
        //         height: 100%;
        //         display: flex;
        //         gap: 10px;
        //         align-items: center;
        //         justify-content: center;

        //         img {
        //             cursor: pointer;
        //         }
        //     }
        // }
      }

      .bottom-parent {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        // margin-left: 21px;
        // margin-right: 21px;
        // padding-right: 21px;
        position: absolute;
        left: 0px;
        right: 0px;
        border-radius: 8px 8px 8px 8px;

        .bottom-section {
          background: var(--light-1, #f6f6f6);
          // position: absolute;
          width: 100%;
          // bottom: 0;
          left: 0;
          right: 20px;
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // padding-left: 21px;
          // padding-right: 21px;
          // margin-left: 21px;
          border-radius: 8px 8px 8px 8px;
          // overflow: hidden;

          .bottom-left {
            width: 80%;
            height: 100%;
            border-radius: 8px 8px 8px 8px;

            input {
              width: 100%;
              height: 100%;
              padding-left: 30px;
              padding-right: 50px;
              border: none;
              background: var(--light-1, #f6f6f6);
              border-radius: 8px 8px 8px 8px;

              &::placeholder {
                color: var(--side-text, #c3c3c3);
                font-family: Poppins;
                font-size: 15.668px;
                font-style: normal;
                font-weight: 500;
                line-height: 158.5%;
                /* 24.834px */
              }
            }
          }

          .bottom-right {
            width: 20%;
            height: 100%;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            border-radius: 8px 8px 8px 8px;

            img {
              cursor: pointer;
              width: 25px;
            }
          }
        }
      }

      .setHeight {
        height: 388px;
        transition: 0.5s ease-in-out !important;
        // height: calc(488px - 60px);
        overflow-y: auto;
        padding-bottom: 50px;
        margin-bottom: 10px;

        .chat-container {
          height: calc(488px - 60px);
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .chat-section {
    width: 100%;

    .chat-content {
      padding: 10px;
      width: 100%;
      height: 100%;
      margin-bottom: 100px;

      .chat-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        background: #f8f8f8;
        height: 65px;
        padding-left: 10px;
        padding-right: 10px;

        .left-section {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 40px;
            height: 40px;
          }

          span {
            color: var(--primary-dark, #0d4d80);
            font-family: Arial;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: 158.5%;
            /* 28.53px */
          }
        }

        .right-section {
          img {
            cursor: pointer;
          }
        }
      }

      .chat-expanded-content {
        height: 0;
        // height: 400px;

        background: white;
        position: relative;
        border-radius: 0px 0px 8px 8px;
        overflow: hidden;
        transition: 0.5s ease-in-out !important;
        // overflow-y: auto;
        // height: 300px;
        // padding-bottom: 50px;

        .first-promptanswer {
          width: 70%;
          display: flex;
          align-items: center;
          gap: 10px;
          padding-left: 10px;
          margin-bottom: 5px;

          img {
            width: 30px;
            height: 30px;
          }

          .left-question {
            border-radius: 0px 22.797px 22.797px 0px;
            background: var(--primary-dark, #0d4d80);
            padding: 5.317px 13.539px;

            span {
              color: #fff;
              font-family: Arial;
              font-size: 14.668px;
              font-style: normal;
              font-weight: 400;
              line-height: 158.5%;
              /* 24.834px */
            }
          }
        }

        .first-prompt {
          width: 100%;
          display: flex;
          justify-content: end;
          //   align-items: center;
          gap: 10px;
          padding-left: 100px;
          padding-right: 5px;
          margin-bottom: 5px;

          .left-question {
            border-radius: 22.795px 22.797px 0px 22.797px;
            background: var(--primary-light, #00a2d6);
            padding: 9.317px 17.539px;

            span {
              color: #fff;
              font-family: Arial;
              font-size: 15.668px;
              font-style: normal;
              font-weight: 400;
              line-height: 158.5%;
              /* 24.834px */
            }
          }
        }

        .date-div {
          width: 50%;
          display: flex;
          justify-content: end;
          margin-bottom: 5px;

          .date-content {
            padding: 0.548px 8.769px;
            border-radius: 25.76px;
            background: var(--side-text, #c3c3c3);

            span {
              color: #fff;
              font-family: Arial;
              font-size: 13.702px;
              font-style: normal;
              font-weight: 400;
              line-height: 158.5%;
              /* 21.718px */
            }
          }
        }

        // .bottom-section {
        //     background: var(--light-1, #F6F6F6);
        //     position: absolute;
        //     width: 100%;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     height: 60px;
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: center;
        //     padding-left: 21px;
        //     padding-right: 21px;
        //     border-radius: 0px 0px 8px 8px;
        //     overflow: hidden;

        //     .bottom-left {
        //         width: 90%;
        //         height: 100%;

        //         input {
        //             width: 100%;
        //             height: 100%;
        //             padding-left: 80px;
        //             padding-right: 50px;
        //             border: none;
        //             background: var(--light-1, #F6F6F6);

        //             &::placeholder {
        //                 color: var(--side-text, #C3C3C3);
        //                 font-family: Poppins;
        //                 font-size: 15.668px;
        //                 font-style: normal;
        //                 font-weight: 500;
        //                 line-height: 158.5%;
        //                 /* 24.834px */
        //             }
        //         }
        //     }

        //     .bottom-right {
        //         width: 10%;
        //         height: 100%;
        //         display: flex;
        //         gap: 10px;
        //         align-items: center;
        //         justify-content: center;

        //         img {
        //             cursor: pointer;
        //         }
        //     }
        // }
      }

      .bottom-parent {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;
        // margin-left: 21px;
        // margin-right: 21px;
        // padding-right: 21px;
        position: absolute;
        left: 0px;
        right: 0px;
        border-radius: 8px 8px 8px 8px;

        .bottom-section {
          background: var(--light-1, #f6f6f6);
          // position: absolute;
          width: 100%;
          // bottom: 0;
          left: 0;
          right: 20px;
          height: 60px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // padding-left: 21px;
          // padding-right: 21px;
          // margin-left: 21px;
          border-radius: 8px 8px 8px 8px;
          // overflow: hidden;

          .bottom-left {
            width: 80%;
            height: 100%;
            border-radius: 8px 8px 8px 8px;

            input {
              width: 100%;
              height: 100%;
              padding-left: 10px;
              padding-right: 50px;
              border: none;
              background: var(--light-1, #f6f6f6);
              border-radius: 8px 8px 8px 8px;

              &::placeholder {
                color: var(--side-text, #c3c3c3);
                font-family: Poppins;
                font-size: 15.668px;
                font-style: normal;
                font-weight: 500;
                line-height: 158.5%;
                /* 24.834px */
              }
            }
          }

          .bottom-right {
            width: 20%;
            height: 100%;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            border-radius: 8px 8px 8px 8px;

            img {
              cursor: pointer;
            }
          }
        }
      }

      .setHeight {
        height: 300px;
        transition: 0.5s ease-in-out !important;
        // height: calc(488px - 60px);
        overflow-y: auto;
        padding-bottom: 50px;
        margin-bottom: 10px;

        .chat-container {
          height: calc(488px - 60px);
        }
      }
    }
  }
}
