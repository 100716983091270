.abc {

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropbtn {
        background-color: #3498DB;
        color: black;
        padding: 16px;
        font-size: 16px;
        border: none;
        cursor: pointer;
        // height: 50px;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #F1F1F1;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    .draggable {
        padding: 8px 16px;
        // cursor: move;
        border-bottom: 1px solid #ddd;
        color: black;
        font-weight: 500;
    }

    .draggable:last-child {
        border-bottom: none;
    }

    .draggable:hover {
        background-color: #ddd;
    }
}