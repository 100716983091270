// * {
//   outline: 3px solid blue;
// }


.checkbox3 {
  // width: 100%;
  width: 310px;
  // width: 314px;
  height: 60px;
  background-color: #d0d0d0;
  border-radius: 30px;
  position: relative;
  color: black;
  overflow: hidden;
  border-radius: 19px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);


  .toggle {
    width: 100px;
    height: 50px;
    position: absolute;
    border-radius: 30px;
    left: 5px;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    // box-shadow: 0px 0px 3px rgb(142, 142, 142), 0px 0px 5px rgb(255, 255, 20);
  }

  .toggle-color-0 {
    background: linear-gradient(90deg,
        #9ccc65 0%,
        rgba(156, 204, 101, 0.74) 100%);
  }

  .toggle-color-1 {
    background: linear-gradient(90deg,
        #ef5350 0%,
        rgba(239, 83, 80, 0.74) 100%);
  }

  .toggle-color-2 {
    background: linear-gradient(90deg,
        #ffca28 0%,
        rgba(255, 202, 40, 0.74) 100%);
  }

  .slide {
    width: 314px;
    /* Increased width to accommodate three toggles */
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;

    .text {

      font-weight: 400;
      z-index: 100;
      cursor: pointer;
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 14px;
    }
  }

  &.toggle-state-1 .toggle {
    transform: translateX(100px);
  }

  &.toggle-state-2 .toggle {
    transform: translateX(200px);
  }
}

@media only screen and (max-width: 1800px) {
  .checkbox3 {
    // width: 100%;
    width: 315px;
    // width: 314px;
    height: 60px;
    background-color: #d0d0d0;
    border-radius: 30px;
    position: relative;
    color: black;
    overflow: hidden;
    border-radius: 19px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

    .toggle {
      width: 100px;
      height: 50px;
      position: absolute;
      border-radius: 30px;
      left: 5px;
      cursor: pointer;
      transition: 0.4s ease-in-out;
      // box-shadow: 0px 0px 3px rgb(142, 142, 142), 0px 0px 5px rgb(255, 255, 20);
    }

    .toggle-color-0 {
      background: linear-gradient(90deg,
          #9ccc65 0%,
          rgba(156, 204, 101, 0.74) 100%);
    }

    .toggle-color-1 {
      background: linear-gradient(90deg,
          #ef5350 0%,
          rgba(239, 83, 80, 0.74) 100%);
    }

    .toggle-color-2 {
      background: linear-gradient(90deg,
          #ffca28 0%,
          rgba(255, 202, 40, 0.74) 100%);
    }

    .slide {
      width: 314px;
      /* Increased width to accommodate three toggles */
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      .text {

        font-weight: 400;
        z-index: 100;
        cursor: pointer;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 14px;
      }
    }

    &.toggle-state-1 .toggle {
      transform: translateX(100px);
    }

    &.toggle-state-2 .toggle {
      transform: translateX(200px);
    }
  }
}

@media only screen and (max-width: 1700px) {
  .checkbox3 {
    // width: 100%;
    width: 320px;
    // width: 314px;
    height: 60px;
    background-color: #d0d0d0;
    border-radius: 30px;
    position: relative;
    color: black;
    overflow: hidden;
    border-radius: 19px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

    .toggle {
      width: 100px;
      height: 50px;
      position: absolute;
      border-radius: 30px;
      left: 5px;
      cursor: pointer;
      transition: 0.4s ease-in-out;
      // box-shadow: 0px 0px 3px rgb(142, 142, 142), 0px 0px 5px rgb(255, 255, 20);
    }

    .toggle-color-0 {
      background: linear-gradient(90deg,
          #9ccc65 0%,
          rgba(156, 204, 101, 0.74) 100%);
    }

    .toggle-color-1 {
      background: linear-gradient(90deg,
          #ef5350 0%,
          rgba(239, 83, 80, 0.74) 100%);
    }

    .toggle-color-2 {
      background: linear-gradient(90deg,
          #ffca28 0%,
          rgba(255, 202, 40, 0.74) 100%);
    }

    .slide {
      width: 314px;
      /* Increased width to accommodate three toggles */
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      .text {

        font-weight: 400;
        z-index: 100;
        cursor: pointer;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 14px;
      }
    }

    &.toggle-state-1 .toggle {
      transform: translateX(100px);
    }

    &.toggle-state-2 .toggle {
      transform: translateX(200px);
    }
  }
}

@media only screen and (max-width: 1650px) {
  .checkbox3 {
    // width: 100%;
    width: 324px;
    // width: 314px;
    height: 60px;
    background-color: #d0d0d0;
    border-radius: 30px;
    position: relative;
    color: black;
    overflow: hidden;
    border-radius: 19px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

    .toggle {
      width: 100px;
      height: 50px;
      position: absolute;
      border-radius: 30px;
      left: 5px;
      cursor: pointer;
      transition: 0.4s ease-in-out;
      // box-shadow: 0px 0px 3px rgb(142, 142, 142), 0px 0px 5px rgb(255, 255, 20);
    }

    .toggle-color-0 {
      background: linear-gradient(90deg,
          #9ccc65 0%,
          rgba(156, 204, 101, 0.74) 100%);
    }

    .toggle-color-1 {
      background: linear-gradient(90deg,
          #ef5350 0%,
          rgba(239, 83, 80, 0.74) 100%);
    }

    .toggle-color-2 {
      background: linear-gradient(90deg,
          #ffca28 0%,
          rgba(255, 202, 40, 0.74) 100%);
    }

    .slide {
      width: 314px;
      /* Increased width to accommodate three toggles */
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      .text {

        font-weight: 400;
        z-index: 100;
        cursor: pointer;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 14px;
      }
    }

    &.toggle-state-1 .toggle {
      transform: translateX(100px);
    }

    &.toggle-state-2 .toggle {
      transform: translateX(200px);
    }
  }
}

@media only screen and (max-width: 1500px) {
  .checkbox3 {
    // width: 100%;
    width: 330px;
    // width: 314px;
    height: 60px;
    background-color: #d0d0d0;
    border-radius: 30px;
    position: relative;
    color: black;
    overflow: hidden;
    border-radius: 19px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

    .toggle {
      width: 100px;
      height: 50px;
      position: absolute;
      border-radius: 30px;
      left: 5px;
      cursor: pointer;
      transition: 0.4s ease-in-out;
      // box-shadow: 0px 0px 3px rgb(142, 142, 142), 0px 0px 5px rgb(255, 255, 20);
    }

    .toggle-color-0 {
      background: linear-gradient(90deg,
          #9ccc65 0%,
          rgba(156, 204, 101, 0.74) 100%);
    }

    .toggle-color-1 {
      background: linear-gradient(90deg,
          #ef5350 0%,
          rgba(239, 83, 80, 0.74) 100%);
    }

    .toggle-color-2 {
      background: linear-gradient(90deg,
          #ffca28 0%,
          rgba(255, 202, 40, 0.74) 100%);
    }

    .slide {
      width: 314px;
      /* Increased width to accommodate three toggles */
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      .text {

        font-weight: 400;
        z-index: 100;
        cursor: pointer;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 14px;
      }
    }

    &.toggle-state-1 .toggle {
      transform: translateX(100px);
    }

    &.toggle-state-2 .toggle {
      transform: translateX(200px);
    }
  }
}

@media only screen and (max-width: 1450px) {
  .checkbox3 {
    // width: 100%;
    width: 340px;
    // width: 314px;
    height: 60px;
    background-color: #d0d0d0;
    border-radius: 30px;
    position: relative;
    color: black;
    overflow: hidden;
    border-radius: 19px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

    .toggle {
      width: 100px;
      height: 50px;
      position: absolute;
      border-radius: 30px;
      left: 5px;
      cursor: pointer;
      transition: 0.4s ease-in-out;
      // box-shadow: 0px 0px 3px rgb(142, 142, 142), 0px 0px 5px rgb(255, 255, 20);
    }

    .toggle-color-0 {
      background: linear-gradient(90deg,
          #9ccc65 0%,
          rgba(156, 204, 101, 0.74) 100%);
    }

    .toggle-color-1 {
      background: linear-gradient(90deg,
          #ef5350 0%,
          rgba(239, 83, 80, 0.74) 100%);
    }

    .toggle-color-2 {
      background: linear-gradient(90deg,
          #ffca28 0%,
          rgba(255, 202, 40, 0.74) 100%);
    }

    .slide {
      width: 314px;
      /* Increased width to accommodate three toggles */
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      .text {

        font-weight: 400;
        z-index: 100;
        cursor: pointer;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 14px;
      }
    }

    &.toggle-state-1 .toggle {
      transform: translateX(100px);
    }

    &.toggle-state-2 .toggle {
      transform: translateX(200px);
    }
  }
}

@media only screen and (max-width: 1350px) {
  .checkbox3 {
    // width: 100%;
    width: 360px;
    // width: 314px;
    height: 60px;
    background-color: #d0d0d0;
    border-radius: 30px;
    position: relative;
    color: black;
    overflow: hidden;
    border-radius: 19px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

    .toggle {
      width: 100px;
      height: 50px;
      position: absolute;
      border-radius: 30px;
      left: 5px;
      cursor: pointer;
      transition: 0.4s ease-in-out;
      // box-shadow: 0px 0px 3px rgb(142, 142, 142), 0px 0px 5px rgb(255, 255, 20);
    }

    .toggle-color-0 {
      background: linear-gradient(90deg,
          #9ccc65 0%,
          rgba(156, 204, 101, 0.74) 100%);
    }

    .toggle-color-1 {
      background: linear-gradient(90deg,
          #ef5350 0%,
          rgba(239, 83, 80, 0.74) 100%);
    }

    .toggle-color-2 {
      background: linear-gradient(90deg,
          #ffca28 0%,
          rgba(255, 202, 40, 0.74) 100%);
    }

    .slide {
      width: 314px;
      /* Increased width to accommodate three toggles */
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      .text {

        font-weight: 400;
        z-index: 100;
        cursor: pointer;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 14px;
      }
    }

    &.toggle-state-1 .toggle {
      transform: translateX(100px);
    }

    &.toggle-state-2 .toggle {
      transform: translateX(200px);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .checkbox3 {
    // width: 100%;
    width: 345px;
    // width: 314px;
    height: 60px;
    background-color: #d0d0d0;
    border-radius: 30px;
    position: relative;
    color: black;
    overflow: hidden;
    border-radius: 19px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

    .toggle {
      width: 100px;
      height: 50px;
      position: absolute;
      border-radius: 30px;
      left: 5px;
      cursor: pointer;
      transition: 0.4s ease-in-out;
      // box-shadow: 0px 0px 3px rgb(142, 142, 142), 0px 0px 5px rgb(255, 255, 20);
    }

    .toggle-color-0 {
      background: linear-gradient(90deg,
          #9ccc65 0%,
          rgba(156, 204, 101, 0.74) 100%);
    }

    .toggle-color-1 {
      background: linear-gradient(90deg,
          #ef5350 0%,
          rgba(239, 83, 80, 0.74) 100%);
    }

    .toggle-color-2 {
      background: linear-gradient(90deg,
          #ffca28 0%,
          rgba(255, 202, 40, 0.74) 100%);
    }

    .slide {
      width: 314px;
      /* Increased width to accommodate three toggles */
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      .text {

        font-weight: 400;
        z-index: 100;
        cursor: pointer;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 14px;
      }
    }

    &.toggle-state-1 .toggle {
      transform: translateX(100px);
    }

    &.toggle-state-2 .toggle {
      transform: translateX(200px);
    }
  }
}

@media only screen and (max-width: 1150px) {
  .checkbox3 {
    // width: 100%;
    width: 355px;
    // width: 314px;
    height: 60px;
    background-color: #d0d0d0;
    border-radius: 30px;
    position: relative;
    color: black;
    overflow: hidden;
    border-radius: 19px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

    .toggle {
      width: 100px;
      height: 50px;
      position: absolute;
      border-radius: 30px;
      left: 5px;
      cursor: pointer;
      transition: 0.4s ease-in-out;
      // box-shadow: 0px 0px 3px rgb(142, 142, 142), 0px 0px 5px rgb(255, 255, 20);
    }

    .toggle-color-0 {
      background: linear-gradient(90deg,
          #9ccc65 0%,
          rgba(156, 204, 101, 0.74) 100%);
    }

    .toggle-color-1 {
      background: linear-gradient(90deg,
          #ef5350 0%,
          rgba(239, 83, 80, 0.74) 100%);
    }

    .toggle-color-2 {
      background: linear-gradient(90deg,
          #ffca28 0%,
          rgba(255, 202, 40, 0.74) 100%);
    }

    .slide {
      width: 314px;
      /* Increased width to accommodate three toggles */
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      .text {

        font-weight: 400;
        z-index: 100;
        cursor: pointer;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 14px;
      }
    }

    &.toggle-state-1 .toggle {
      transform: translateX(100px);
    }

    &.toggle-state-2 .toggle {
      transform: translateX(200px);
    }
  }
}

@media only screen and (max-width: 1050px) {
  .checkbox3 {
    // width: 100%;
    width: 370px;
    // width: 314px;
    height: 60px;
    background-color: #d0d0d0;
    border-radius: 30px;
    position: relative;
    color: black;
    overflow: hidden;
    border-radius: 19px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

    .toggle {
      width: 100px;
      height: 50px;
      position: absolute;
      border-radius: 30px;
      left: 5px;
      cursor: pointer;
      transition: 0.4s ease-in-out;
      // box-shadow: 0px 0px 3px rgb(142, 142, 142), 0px 0px 5px rgb(255, 255, 20);
    }

    .toggle-color-0 {
      background: linear-gradient(90deg,
          #9ccc65 0%,
          rgba(156, 204, 101, 0.74) 100%);
    }

    .toggle-color-1 {
      background: linear-gradient(90deg,
          #ef5350 0%,
          rgba(239, 83, 80, 0.74) 100%);
    }

    .toggle-color-2 {
      background: linear-gradient(90deg,
          #ffca28 0%,
          rgba(255, 202, 40, 0.74) 100%);
    }

    .slide {
      width: 314px;
      /* Increased width to accommodate three toggles */
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      .text {

        font-weight: 400;
        z-index: 100;
        cursor: pointer;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 14px;
      }
    }

    &.toggle-state-1 .toggle {
      transform: translateX(100px);
    }

    &.toggle-state-2 .toggle {
      transform: translateX(200px);
    }
  }
}

@media only screen and (max-width: 600px) {
  .checkbox3 {

    // width: 100%;
    width: 300px;
    // width: 314px;
    height: 50px;
    background-color: #d0d0d0;
    border-radius: 30px;
    position: relative;
    color: black;
    overflow: hidden;
    border-radius: 19px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

    .toggle {
      width: 90px;
      height: 40px;
      position: absolute;
      border-radius: 30px;
      left: 4px;
      cursor: pointer;
      transition: 0.4s ease-in-out;
      // box-shadow: 0px 0px 3px rgb(142, 142, 142), 0px 0px 5px rgb(255, 255, 20);
    }

    .toggle-color-0 {
      background: linear-gradient(90deg,
          #9ccc65 0%,
          rgba(156, 204, 101, 0.74) 100%);
    }

    .toggle-color-1 {
      background: linear-gradient(90deg,
          #ef5350 0%,
          rgba(239, 83, 80, 0.74) 100%);
    }

    .toggle-color-2 {
      background: linear-gradient(90deg,
          #ffca28 0%,
          rgba(255, 202, 40, 0.74) 100%);
    }

    .slide {
      width: 100%;
      // width: 314px;
      /* Increased width to accommodate three toggles */
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      .text {

        font-weight: 400;
        z-index: 100;
        cursor: pointer;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 14px;
      }
    }

    &.toggle-state-1 .toggle {
      transform: translateX(100px);
    }

    &.toggle-state-2 .toggle {
      transform: translateX(200px);
    }
  }
}