.first-goal {
    display: flex;
    justify-content: end;

    .inputbox {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 2px;
        // gap: 0px;
        width: 90px;
        // height: 22px;
        padding: 0px 2px 0px 2px;
        // padding: 2px 2px 2px 6px;
        border-radius: 4px;
        background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);
        cursor: pointer;
        position: relative;

        .leftside {
            color: #FFF;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            //   padding: 5px;
            margin-top: 2px;
            margin-left: 2px;
            // width: 52px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }

        .rightside {
            // width: 18px;
            // height: 18px;
            margin-right: -3px;


        }

        .dropdown {
            position: absolute;
            top: 25px;
            width: 100%;
            z-index: 1;
            margin-top: 5px;

            .firstdropdown {
                width: 150%;
                white-space: nowrap;
                border-bottom: 0.2px solid #8D8D8D;
                background: #FFF;
                height: 30.241px;
                // padding: 2px 2px 2px 6px;
                padding-inline: 4px;
                padding-block: 15px;
                display: flex;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                span {
                    color: #000;
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // white-space: nowrap;
                }


            }

            .lastdropdown {

                background: #FFF;
                height: 22.241px;
                padding: 2px 2px 2px 6px;
                display: flex;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                span {
                    color: #000;
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }

    // .inputboxGreen {
    //     background: #F0FFDF;
    //     color: #9CCC65;


    // }

    // .addinClr {

    //     background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);
    // }

    // .inputtextgreen {
    //     color: #9CCC65;
    // }


}