.strategic-theme {
  width: 100%;
  padding-top: 60px;
  background-image: url("../../assets/images/layerimg.png");

  .strategic-content {
    .strategicTitle {
      width: 100%;
      padding: 0px 0px 50px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h5 {
        color: var(--primary-dark, #0d4d80);
        font-family: Arial;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .catageryPriority {
        display: flex;
        align-items: center;
        gap: 28px;
        padding-top: 23px;

        .Strength {
          display: flex;
          align-items: center;
          gap: 6px;

          span {
            color: #000;
            text-align: center;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .Weakness {
          display: flex;
          align-items: center;
          gap: 6px;

          span {
            color: #000;
            text-align: center;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .Opportunity {
          display: flex;
          align-items: center;
          gap: 6px;

          span {
            color: #000;
            text-align: center;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .Threat {
          display: flex;
          align-items: center;
          gap: 6px;

          span {
            color: #000;
            text-align: center;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }

    .themesection-top {
      padding: 0px 79px 43px 127px;

      .theme-template {
        width: 100%;
        // height: 160px;
        border-radius: 10px;
        background: #f8f8f8;
        // padding-bottom: 20px;

        .theme-header {
          width: 100%;
          height: 62px;
          border-radius: 10px 10px 0px 0px;
          background: var(
            --side-bar,
            linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
          );
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            color: #fff;
            text-align: center;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
        }

        .theme-bottm {
          padding: 18px 0px 18px 0px;

          .primary-section {
            width: 100%;
            // padding: 18px 21px 0px 21px;
            display: flex;
            align-items: center;
            gap: 30px;
            padding-left: 21px;
            // padding-top: 18px;

            button {
              padding: 2px 10px;
              border-radius: 43px;
              background: #01a0d4;
              color: #fff;
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
            }

            span {
              color: #000;
              text-align: center;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }

            hr {
              height: 1px;
              background: #e2e2e2;
              // margin-block: 5px;
              margin-block: 10px;
            }
          }

          .seconday-section {
            width: 100%;
            // padding: 18px 21px 0px 21px;
            display: flex;
            align-items: center;
            gap: 13px;
            padding-left: 21px;
            // padding-top: 18px;

            button {
              padding: 2px 10px;
              border-radius: 43px;
              background: #01a0d4;
              color: #fff;
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
            }

            span {
              color: #000;
              text-align: center;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }

            hr {
              height: 1px;
              background: #e2e2e2;
              // margin-block: 5px;
              margin-block: 10px;
            }
          }
        }
      }
    }

    .middle-section {
      width: 100%;
      padding-left: 192px;
      padding-right: 192px;

      .middle-heading {
        // h6 {
        //     color: var(--primary-dark, #0D4D80);
        //     font-family: Arial;
        //     font-size: 24px;
        //     font-style: normal;
        //     font-weight: 700;
        //     line-height: normal;
        //     text-align: center;
        // }

        // .priorities-tabs {
        //     display: flex;
        //     width: 100%;
        //     justify-content: center;
        //     gap: 22px;
        //     padding: 22px 0px 37px 0px;

        //     .strngth {
        //         color: #9CCC65;

        //         font-family: Arial;
        //         font-size: 20px;
        //         font-style: normal;
        //         font-weight: 400;
        //         line-height: normal;
        //     }

        //     .weakness {
        //         color: #EF5451;

        //         font-family: Arial;
        //         font-size: 20px;
        //         font-style: normal;
        //         font-weight: 400;
        //         line-height: normal;
        //     }

        //     .opportunity {
        //         color: var(--primary-dark, #0D4D80);
        //         text-align: center;
        //         font-family: Arial;
        //         font-size: 20px;
        //         font-style: normal;
        //         font-weight: 400;
        //         line-height: normal;
        //     }

        //     .threat {
        //         color: #FFC515;
        //         text-align: center;
        //         font-family: Arial;
        //         font-size: 20px;
        //         font-style: normal;
        //         font-weight: 400;
        //         line-height: normal;
        //     }
        // }

        .priorityCard {
          width: 100%;
          padding-bottom: 100px;

          .leftside-card {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 32px;

            .leftcard1 {
              width: 48%;
              // height: 199px;
              border-radius: 4px;
              background: #fff;
              box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
              padding: 10px;

              p {
                color: #000;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: left;
              }

              .card-btn {
                padding: 11px 0px 8px 0px;

                .additem {
                  padding: 4px;
                  border-radius: 4px;
                  border: 0.5px solid #9ccc65;
                  color: #9ccc65;
                  font-family: Arial;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  background: white;
                }

                .clear-startegic {
                  padding: 4px;
                  border-radius: 4px;
                  border: 0.5px solid #ef5350;
                  color: #ef5350;
                  font-family: Arial;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  background: white;
                  margin-left: 12px;
                }
              }

              .card-footer {
                border-top: 1px solid #d9d9d9;
                padding: 10px 18px;
                border-radius: 4px;
                background: #bfd6e8;

                .card-text {
                  color: #000;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                .card-textred {
                  color: #ef5451;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .card-footer2 {
                // border-top: 1px solid #D9D9D9;
                padding: 10px 18px;
                border-radius: 4px;
                background: #faeec9;
                margin-top: 14px;

                .card-text {
                  color: #000;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                .card-textred {
                  color: #ef5451;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .card-footer3 {
                border-top: 1px dashed #d9d9d9;
                padding: 10px 18px 10px 18px;

                span {
                  color: #d9d9d9;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .card-btns {
                display: flex;
                justify-content: end;
                margin-top: 10px;
                margin-bottom: 10px;

                .custom-select {
                  padding: 4px 4px 4px 6px;
                  width: 250px;
                  height: 30px;
                  // width: 100%;
                  // height: 48px;
                  // padding: 10px 24px;
                  /* Adjust height as needed */
                  position: relative;
                  display: inline-block;
                  border-radius: 6px;
                  // border: 1px solid var(--primary-dark, #0D4D80);

                  overflow: hidden;
                  border-radius: 4px;
                  background: linear-gradient(
                    90deg,
                    #00a2d6 0%,
                    rgba(0, 162, 214, 0.58) 100%
                  );

                  select {
                    // width: 100%;
                    // height: 100%;
                    // padding: 10px;
                    border: none;
                    background: transparent;
                    outline: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    cursor: pointer;
                    color: #fff;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    &::placeholder {
                      color: #fff;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    }
                  }

                  &::before {
                    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M8 12.586l-5.707-5.707 1.414-1.414L8 9.758l4.293-4.293 1.414 1.414z"/></svg>');
                    /* Unicode character for down arrow */
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                    font-size: 16px;
                    color: white;
                    // color: var(--primary-dark, #0D4D80);
                  }

                  /* Styling placeholder option */
                  option[value=""] {
                    color: #fff;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    .options {
                      color: #fff;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    }
                  }
                }

                .remove-item {
                  padding: 4px;
                  border-radius: 4px;
                  border: 0.5px solid #ef5350;
                  color: #ef5350;
                  font-family: Arial;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  background: white;
                  margin-left: 15px;
                }
              }
            }
          }

          .rightSide-card {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 32px;

            .leftcard1 {
              width: 100%;

              border-radius: 4px;
              background: #fff;
              box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
              padding: 10px;

              p {
                color: #000;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: left;
              }

              .card-btn {
                padding: 11px 0px 18px 0px;

                .additem {
                  padding: 4px;
                  border-radius: 4px;
                  border: 0.5px solid #9ccc65;
                  color: #9ccc65;
                  font-family: Arial;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  background: white;
                }

                .clear-startegic {
                  padding: 4px;
                  border-radius: 4px;
                  border: 0.5px solid #ef5350;
                  color: #ef5350;
                  font-family: Arial;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  background: white;
                  margin-left: 12px;
                }
              }

              .card-footer {
                // border-top: 1px solid #D9D9D9;
                padding: 10px 18px;

                border-radius: 4px;
                background: #bfd6e8;

                .card-text {
                  color: #000;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                .card-textFFC515 {
                  color: #ffc515;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                .card-textD9D9D9 {
                  color: #d9d9d9;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .card-footer2 {
                // border-top: 1px solid #D9D9D9;
                padding: 10px 18px;

                border-radius: 4px;
                background: #f6d6d5;

                .card-text {
                  color: #000;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                .card-textFFC515 {
                  color: #ffc515;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                .card-textD9D9D9 {
                  color: #d9d9d9;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .card-btns {
                display: flex;
                justify-content: end;
                margin-top: 10px;
                margin-bottom: 10px;
                .custom-select {
                  padding: 4px 4px 4px 6px;
                  width: 250px;
                  height: 30px;

                  position: relative;
                  display: inline-block;
                  border-radius: 6px;

                  overflow: hidden;
                  border-radius: 4px;
                  background: linear-gradient(
                    90deg,
                    #00a2d6 0%,
                    rgba(0, 162, 214, 0.58) 100%
                  );

                  select {
                    border: none;
                    background: transparent;
                    outline: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    cursor: pointer;
                    color: #fff;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    &::placeholder {
                      color: #fff;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    }
                  }

                  &::before {
                    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M8 12.586l-5.707-5.707 1.414-1.414L8 9.758l4.293-4.293 1.414 1.414z"/></svg>');

                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                    font-size: 16px;
                    color: white;
                  }

                  option[value=""] {
                    color: #fff;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    .options {
                      color: #fff;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    }
                  }
                }

                .remove-item {
                  padding: 4px;
                  border-radius: 4px;
                  border: 0.5px solid #ef5350;
                  color: #ef5350;
                  font-family: Arial;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  background: white;
                  margin-left: 15px;
                }
              }
            }

            .submitbtn-section {
              width: 100%;
              display: flex;
              justify-content: end;
              padding-top: 44px;
              // padding-bottom: 59px;

              .submit-btn {
                padding: 4px 10px;
                border-radius: 4px;
                background: #9ccc65;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
                color: white;
                margin-right: 24px;
              }

              .save-change {
                padding: 4px 10px;
                border-radius: 4px;
                background: #01a0d4;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .strategic-theme {
    width: 100%;
    padding-top: 60px;
    background-image: url("../../assets/images/layerimg.png");

    .strategic-content {
      .themesection-top {
        padding: 0px 50px 43px 50px;
      }

      .middle-section {
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;

        .middle-heading {
          .priorityCard {
            gap: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .strategic-theme {
    width: 100%;
    padding-top: 60px;
    background-image: url("../../assets/images/layerimg.png");

    .strategic-content {
      .strategicTitle {
        width: 100%;
        padding: 0px 0px 50px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h5 {
          color: var(--primary-dark, #0d4d80);
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .catageryPriority {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 12px;
          padding-top: 23px;

          .Strength {
            display: flex;
            align-items: center;
            gap: 6px;

            span {
              font-size: 16px;
            }

            svg {
              width: 16px;
              height: 16px;
            }
          }

          .Weakness {
            display: flex;
            align-items: center;
            gap: 6px;

            span {
              font-size: 16px;
            }

            svg {
              width: 16px;
              height: 16px;
            }
          }

          .Opportunity {
            display: flex;
            align-items: center;
            gap: 6px;

            span {
              font-size: 16px;
            }

            svg {
              width: 16px;
              height: 16px;
            }
          }

          .Threat {
            display: flex;
            align-items: center;
            gap: 6px;

            span {
              font-size: 16px;
            }

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      .themesection-top {
        padding: 0px 10px 43px 10px;

        .theme-template {
          width: 100%;
          // height: 160px;
          border-radius: 10px;
          background: #f8f8f8;
          // padding-bottom: 20px;

          .theme-header {
            width: 100%;
            height: 62px;
            border-radius: 10px 10px 0px 0px;
            background: var(
              --side-bar,
              linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
            );
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: #fff;
              text-align: center;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .theme-bottm {
            padding: 18px 0px 18px 0px;

            .primary-section {
              width: 100%;
              // padding: 18px 21px 0px 21px;
              display: flex;
              flex-direction: column;
              align-items: start;
              gap: 15px;
              padding-left: 10px;
              padding-right: 10px;
              // padding-top: 18px;

              button {
                padding: 2px 10px;
                border-radius: 43px;
                background: #01a0d4;
                color: #fff;
                font-family: "Poppins";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
              }

              span {
                font-size: 16px;
              }

              hr {
                height: 1px;
                background: #e2e2e2;
                // margin-block: 5px;
                margin-block: 10px;
              }
            }

            .seconday-section {
              width: 100%;
              // padding: 18px 21px 0px 21px;
              display: flex;
              flex-direction: column;
              align-items: start;
              justify-content: start;
              gap: 13px;
              padding-left: 21px;
              // padding-top: 18px;

              button {
                padding: 2px 10px;
                border-radius: 43px;
                background: #01a0d4;
                color: #fff;
                font-family: "Poppins";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
              }

              span {
                color: #000;
                text-align: center;
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
              }

              hr {
                height: 1px;
                background: #e2e2e2;
                // margin-block: 5px;
                margin-block: 10px;
              }
            }
          }
        }
      }

      .middle-section {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;

        .middle-heading {
          .priorityCard {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-bottom: 100px;

            .leftside-card {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 32px;

              .leftcard1 {
                width: 100%;
                // height: 199px;
                border-radius: 4px;
                background: #fff;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
                padding: 10px;

                p {
                  color: #000;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  text-align: left;
                }

                .card-btn {
                  padding: 11px 0px 8px 0px;
                  display: flex;
                  justify-content: space-between;

                  .additem {
                    padding: 4px;
                    border-radius: 4px;
                    border: 0.5px solid #9ccc65;
                    color: #9ccc65;
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background: white;
                  }

                  .clear-startegic {
                    padding: 4px;
                    border-radius: 4px;
                    border: 0.5px solid #ef5350;
                    color: #ef5350;
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background: white;
                    margin-left: 12px;
                  }
                }

                .card-footer {
                  border-top: 1px solid #d9d9d9;
                  padding: 10px 18px;
                  border-radius: 4px;
                  background: #bfd6e8;

                  .card-text {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }

                  .card-textred {
                    color: #ef5451;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .card-footer2 {
                  // border-top: 1px solid #D9D9D9;
                  padding: 10px 18px;
                  border-radius: 4px;
                  background: #faeec9;
                  margin-top: 14px;

                  .card-text {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }

                  .card-textred {
                    color: #ef5451;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .card-footer3 {
                  border-top: 1px dashed #d9d9d9;
                  padding: 10px 0px 10px 0px;

                  span {
                    color: #d9d9d9;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .card-btns {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  gap: 10px;
                  align-items: center;
                  margin-top: 10px;

                  .custom-select {
                    padding: 4px 4px 4px 6px;
                    width: 250px;
                    height: 30px;
                    // width: 100%;
                    // height: 48px;
                    // padding: 10px 24px;
                    /* Adjust height as needed */
                    position: relative;
                    display: inline-block;
                    border-radius: 6px;
                    // border: 1px solid var(--primary-dark, #0D4D80);

                    overflow: hidden;
                    border-radius: 4px;
                    background: linear-gradient(
                      90deg,
                      #00a2d6 0%,
                      rgba(0, 162, 214, 0.58) 100%
                    );

                    select {
                      // width: 100%;
                      // height: 100%;
                      // padding: 10px;
                      border: none;
                      background: transparent;
                      outline: none;
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      appearance: none;
                      cursor: pointer;
                      color: #fff;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;

                      &::placeholder {
                        color: #fff;
                        font-family: Arial;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                      }
                    }

                    &::before {
                      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M8 12.586l-5.707-5.707 1.414-1.414L8 9.758l4.293-4.293 1.414 1.414z"/></svg>');
                      /* Unicode character for down arrow */
                      position: absolute;
                      top: 50%;
                      right: 10px;
                      transform: translateY(-50%);
                      font-size: 16px;
                      color: white;
                      // color: var(--primary-dark, #0D4D80);
                    }

                    /* Styling placeholder option */
                    option[value=""] {
                      color: #fff;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;

                      .options {
                        color: #fff;
                        font-family: Arial;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                      }
                    }
                  }

                  .remove-item {
                    padding: 4px;
                    border-radius: 4px;
                    border: 0.5px solid #ef5350;
                    color: #ef5350;
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background: white;
                    margin-left: 15px;
                  }
                }
              }
            }

            .rightSide-card {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 32px;

              .leftcard1 {
                width: 100%;

                border-radius: 4px;
                background: #fff;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
                padding: 10px;

                p {
                  color: #000;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  text-align: left;
                }

                .card-btn {
                  padding: 11px 0px 18px 0px;
                  display: flex;
                  justify-content: space-between;

                  .additem {
                    padding: 4px;
                    border-radius: 4px;
                    border: 0.5px solid #9ccc65;
                    color: #9ccc65;
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background: white;
                  }

                  .clear-startegic {
                    padding: 4px;
                    border-radius: 4px;
                    border: 0.5px solid #ef5350;
                    color: #ef5350;
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background: white;
                    margin-left: 12px;
                  }
                }

                .card-footer {
                  // border-top: 1px solid #D9D9D9;
                  padding: 10px 18px;

                  border-radius: 4px;
                  background: #bfd6e8;

                  .card-text {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }

                  .card-textFFC515 {
                    color: #ffc515;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }

                  .card-textD9D9D9 {
                    color: #d9d9d9;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .card-footer2 {
                  // border-top: 1px solid #D9D9D9;
                  padding: 10px 18px;

                  border-radius: 4px;
                  background: #f6d6d5;

                  .card-text {
                    color: #000;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }

                  .card-textFFC515 {
                    color: #ffc515;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }

                  .card-textD9D9D9 {
                    color: #d9d9d9;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .card-btns {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  margin-top: 10px;

                  .custom-select {
                    padding: 4px 4px 4px 6px;
                    width: 250px;
                    height: 30px;

                    position: relative;
                    display: inline-block;
                    border-radius: 6px;

                    overflow: hidden;
                    border-radius: 4px;
                    background: linear-gradient(
                      90deg,
                      #00a2d6 0%,
                      rgba(0, 162, 214, 0.58) 100%
                    );

                    select {
                      border: none;
                      background: transparent;
                      outline: none;
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      appearance: none;
                      cursor: pointer;
                      color: #fff;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;

                      &::placeholder {
                        color: #fff;
                        font-family: Arial;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                      }
                    }

                    &::before {
                      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M8 12.586l-5.707-5.707 1.414-1.414L8 9.758l4.293-4.293 1.414 1.414z"/></svg>');

                      position: absolute;
                      top: 50%;
                      right: 10px;
                      transform: translateY(-50%);
                      font-size: 16px;
                      color: white;
                    }

                    option[value=""] {
                      color: #fff;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;

                      .options {
                        color: #fff;
                        font-family: Arial;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                      }
                    }
                  }

                  .remove-item {
                    padding: 4px;
                    border-radius: 4px;
                    border: 0.5px solid #ef5350;
                    color: #ef5350;
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background: white;
                    margin-left: 15px;
                  }
                }
              }

              .submitbtn-section {
                width: 100%;
                display: flex;
                gap: 10px;
                flex-direction: column;
                justify-content: center;
                padding: 0px 10px 10px 10px;

                .submit-btn {
                  height: 40px;
                  margin-right: 1px;
                }

                .save-change {
                  height: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}
