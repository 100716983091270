.Purpose {
  width: 100%;

  .purposeContent {
    padding: 38px 133px 70px 72px;

    .purposeTitle {
      width: 100%;
      text-align: center;
      // width: 233px;
      // border-right: 1px solid #D9D9D9;

      h6 {
        color: var(--primary-dark, #0d4d80);
        font-family: Arial;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      // hr {
      //     height: 1px;
      //     width: 100%;
      //     background: #D9D9D9;
      //     box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10);
      //     margin-block: 5px;
      // }

      span {
        color: var(--primary-dark, #0d4d80);
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .purposeField {
      padding: 30px 0px 10px 32px;

      .FieldContent {
        display: flex;
        flex-direction: column;
        gap: 28px;

        .firstfield {
          // margin-bottom: 28px;
          // width: 909px;
          width: 100%;
          // height: 64px;
          flex-shrink: 0;
          border-radius: 10px;

          background: #fff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 13px 30px 13px 20px;
          box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.1),
            -2px -2px 16px rgba(187, 210, 255, 0.5);

          span {
            color: var(--black);
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            //  border: 2px solid red !important;
            width: 80%;
          }
        }

        .testParent {
          display: flex;
          flex-direction: column;

          .test1 {
            display: flex;
            flex-direction: column;
            gap: 28px;
            // margin-top: 20px;
            margin-bottom: 20px;
          }
        }

        .ttt {
          display: flex;
          flex-direction: column;
          // margin-bottom: 28px;
          // margin-top: 28px;
          // padding-bottom: 28px;
          gap: 28px;
        }

        .secondField {
          // margin-bottom: 28px;
          // height: 128px;
          border-radius: 10px;
          overflow: hidden;
          background: #fff;
          padding: 14px 20px 8px 20px;
          box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.1),
            -2px -2px 16px rgba(187, 210, 255, 0.5);

          span {
            color: var(--black);
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .secondfieldline {
            margin: 5px 0px 5px 0px;
            color: #d9d9d9;
          }

          .borderTextfield {
            border-top: 1px dashed #d9d9d9;
            margin-top: 8px;

            // .textarea {
            //     padding-top: 8px;
            //     width: 100%;

            //     &::placeholder {
            //         color: #D9D9D9;
            //         font-family: Arial;
            //         font-size: 18px;
            //         font-style: normal;
            //         font-weight: 400;
            //         line-height: normal;
            //     }

            //     border: none;

            //     &:active {
            //         border: none;
            //     }
            // }

            .textField {
              resize: none;
              padding-top: 8px;
              padding-left: 10px;

              width: 100%;
              border: none;
              outline: none;

              &::placeholder {
                color: #d9d9d9;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              &:active {
                border: none;
                // outline: none;
              }
            }
          }
        }

        .newreason {
          color: #5ad2ee;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          // cursor: pointer;
          display: flex;
          align-items: center;
          gap: 5px;
        }

        .purposebutton {
          display: flex;
          justify-content: end;

          .buttndiv {
            .previous {
              width: 84px;
              height: 25.714px;
              border-radius: 4px;
              background: #8d8d8d;
              margin-right: 14px;
              color: #fff;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .save {
              padding: 4px 10px 4px 10px;
              // width: 84px;
              height: 25.714px;
              border-radius: 4px;
              background: linear-gradient(90deg,
                  #00a2d6 0%,
                  rgba(0, 162, 214, 0.58) 100%);
              color: white;

              // background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

.modalContent {
  width: 100%;

  .mainModal {
    width: 100%;

    .addQuestion {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .questionLabel {
        label {
          color: #000;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .questionInput {
        display: flex;
        gap: 20px;

        textarea {
          width: 100%;
          height: auto;
          min-height: 60px;
          font-size: 16px;
          line-height: 20px;
          padding: 10px;
          box-sizing: border-box;
          resize: none;
        }

        input {
          border: 1px solid #d9d9d9;
          padding: 10px;
          width: 100%;
          // border-radius: 10px;

          ::placeholder {
            color: #d9d9d9;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        button {
          color: #000;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: 80px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  // .purposeContent {
  //     padding-inline: 20px !important;

  // }
  .Purpose {
    width: 100%;

    .purposeContent {
      // padding: 30px 10px 70px 10px !important;

      .purposeTitle {
        width: 100%;
        text-align: center;
        // width: 233px;
        // border-right: 1px solid #D9D9D9;

        h6 {
          color: var(--primary-dark, #0d4d80);
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        // hr {
        //     height: 1px;
        //     width: 100%;
        //     background: #D9D9D9;
        //     box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10);
        //     margin-block: 5px;
        // }

        span {
          color: var(--primary-dark, #0d4d80);
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .purposeField {
        padding: 30px 0px 10px 0px;

        .FieldContent {
          display: flex;
          flex-direction: column;
          gap: 28px;

          .firstfield {
            width: 100%;
            min-height: 64px;
            height: 100%;
            flex-shrink: 0;
            border-radius: 10px;
            background: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 10px 11px 10px;
            box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.1),
              -2px -2px 16px rgba(187, 210, 255, 0.5);

            span {
              color: var(--black);
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .secondField {
            min-height: 128px;
            height: 100%;
            border-radius: 10px;
            background: #fff;
            padding: 14px 20px 8px 20px;
            box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.1),
              -2px -2px 16px rgba(187, 210, 255, 0.5);

            span {
              color: var(--black);
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .secondfieldline {
              margin: 5px 0px 5px 0px;
              color: #d9d9d9;
            }

            .borderTextfield {
              border-top: 1px dashed #d9d9d9;
              margin-top: 8px;

              .textField {
                padding-top: 8px;
                // border-top: 1px solid #D9D9D9;
                width: 100%;
                border: none;
                word-wrap: break-word;

                &::placeholder {
                  color: #d9d9d9;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }

          .newreason {
            color: #5ad2ee;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            // cursor: pointer;
          }

          .purposebutton {
            display: flex;
            justify-content: end;

            .buttndiv {
              .previous {
                width: 84px;
                height: 25.714px;
                border-radius: 4px;
                background: #8d8d8d;
                margin-right: 14px;
                color: #fff;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .save {
                padding: 4px 10px 4px 10px;
                // width: 84px;
                height: 25.714px;
                border-radius: 4px;
                background: linear-gradient(90deg,
                    #00a2d6 0%,
                    rgba(0, 162, 214, 0.58) 100%);
                color: white;

                // background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .purposeContent {
    padding-inline: 30px !important;
  }
}

@media only screen and (max-width: 600px) {
  // .purposeContent {
  //     padding-inline: 20px !important;

  // }
  .Purpose {
    width: 100%;

    .purposeContent {
      padding: 30px 10px 70px 10px !important;

      .purposeTitle {
        width: 100%;
        text-align: center;
        // width: 233px;
        // border-right: 1px solid #D9D9D9;

        h6 {
          color: var(--primary-dark, #0d4d80);
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        // hr {
        //     height: 1px;
        //     width: 100%;
        //     background: #D9D9D9;
        //     box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10);
        //     margin-block: 5px;
        // }

        span {
          color: var(--primary-dark, #0d4d80);
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .purposeField {
        padding: 30px 0px 10px 0px;

        .FieldContent {
          display: flex;
          flex-direction: column;
          gap: 28px;

          .firstfield {
            width: 100%;
            min-height: 64px;
            height: 100%;
            flex-shrink: 0;
            border-radius: 10px;
            background: #fff;
            display: flex;
            gap: 20px;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            padding: 10px 10px 11px 10px;
            box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.1),
              -2px -2px 16px rgba(187, 210, 255, 0.5);

            span {
              color: var(--black);
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .secondField {
            min-height: 128px;
            height: 100%;
            border-radius: 10px;
            background: #fff;
            padding: 14px 20px 8px 20px;
            box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.1),
              -2px -2px 16px rgba(187, 210, 255, 0.5);

            span {
              color: var(--black);
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .secondfieldline {
              margin: 5px 0px 5px 0px;
              color: #d9d9d9;
            }

            .borderTextfield {
              border-top: 1px dashed #d9d9d9;
              margin-top: 8px;

              .textField {
                padding-top: 8px;
                // border-top: 1px solid #D9D9D9;
                width: 100%;
                border: none;
                word-wrap: break-word;

                &::placeholder {
                  color: #d9d9d9;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }

          .newreason {
            color: #5ad2ee;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            // cursor: pointer;
          }

          .purposebutton {
            display: flex;
            justify-content: end;

            .buttndiv {
              .previous {
                width: 84px;
                height: 25.714px;
                border-radius: 4px;
                background: #8d8d8d;
                margin-right: 14px;
                color: #fff;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .save {
                // width: 84px;
                padding: 4px 10px 4px 10px;
                height: 25.714px;
                border-radius: 4px;
                background: linear-gradient(90deg,
                    #00a2d6 0%,
                    rgba(0, 162, 214, 0.58) 100%);
                color: white;

                // background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}