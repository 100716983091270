.form_edit .edit p {
  text-align: center;
  color: var(--primary-dark, #0d4d80);
  text-align: center;
  font-family: Inter;
  font-size: 19.674px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 15px;
}
.form_edit {
  width: 561px;
  height: 541px;
  flex-shrink: 0;
  border-radius: 24.345px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
}
.form_edit .edit {
  display: flex;
  width: 522px;
  height: 315px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 21px 15.575px;
  flex-shrink: 0;
  flex-wrap: wrap;
  color: #b4b4b4;
}

@media only screen and (max-width: 1200px) {
  .dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .form_edit {
    width: 100%;
    height: 555px;
  }
  .form_edit .edit {
    width: 320px;
  }
}
