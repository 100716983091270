.background {
  height: 100vh;
}
.first {
  margin-top: 5%;
  margin-left: 1%;
  width: 98%;
  height: 100px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  display: flex;
}

.add_coaches {
  display: flex;
  width: 385px;
  height: 103px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
  position: relative;
  border-radius: 25px;
  border: 2px dashed var(--side-bar, #00a2d6);

  /* margin-top: %; */
}
/* .admindashboard .left{
    display: inline-block;
  
   margin-top: 2%;
} */
.admindashboard {
  display: flex;
  flex-direction: row !important;
  width: auto;
}
@media screen and (max-width: 600px) {
  .admindashboard {
    /* display: flex;
    flex-direction: row !important; */
    width: 100%;
    justify-content: center;
  }
}
.admindashboard svg {
  width: 27px;
  height: 21.211px;
  flex-shrink: 0;
  color: #0a4d68;
}
.admindashboard span {
  color: #0a4d68;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.add_coaches span {
  color: var(--primary-dark, #0d4d80);
  font-family: Campton;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/* .add_coaches svg {
  width: 26px;
  height: 26px;
  flex-shrink: 0;
} */
.add_coaches svg {
  width: 30px;
  height: 31px;
  flex-shrink: 0;
  margin-bottom: 7px;
}

@media only screen and (max-width: 600px) {
  .add_coaches {
    width: 285px;
    height: 73px;
    font-size: 24px;
  }
}
