.startplan {
    width: 100%;
    height: 100vh;
    padding-top: 60px;
    background: linear-gradient(0deg, #0D4D80 0%, rgba(255, 255, 255), #0D4D80);



    .startplan-content {
        width: 100%;
        height: 100%;

        display: flex;

        text-align: center;
        justify-content: center;
        align-items: center;

        .title {
            h6 {
                color: #0D4D80;
                font-family: Arial;
                font-size: 48px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 23px;
            }

            span {
                color: #8D8D8D;
                font-family: Arial;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

            }

            .questionstart {
                background: linear-gradient(90deg, #00A2D6 -25.37%, #0D4D80 102.83%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-top: 63px;

                p {
                    font-family: Arial;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .startplan {
        width: 100%;
        height: 100vh;
        padding-top: 60px;
        background: linear-gradient(0deg, #0D4D80 0%, rgba(255, 255, 255), #0D4D80);

        .startplan-content {
            width: 100%;
            height: 100%;

            display: flex;

            text-align: center;
            justify-content: center;
            align-items: center;

            .title {
                h6 {
                    color: #0D4D80;
                    font-family: Arial;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 23px;
                }

                span {
                    color: #8D8D8D;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                }

                .questionstart {
                    background: linear-gradient(90deg, #00A2D6 -25.37%, #0D4D80 102.83%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-top: 23px;

                    p {
                        font-family: Arial;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }
    }
}