.dashboard {
  width: 100%;
  height: 100%;
  /* max-height: 100vh; */
  /* border: 1px solid red; */
  /* display: flex; */
  /* justify-content: center; */
  /* background: #00a2d6; */
  background-image: url("../../assets/images/bbbb.svg");
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.dashboard .menuDiv {
  display: none;
  height: 10%;
  width: 10%;
}

.dashboard .general-content {
  width: 100%;
  padding: 84px 502px 38px 502px;
  padding-inline: 0px;
  text-align: center;
}

.general-content h6 {
  color: #0d4d80;
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.general-content span {
  color: #8d8d8d;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard .left {
  width: 100%;
  /* height: 100px; */
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  /* Use overflow-y for vertical scrollbar */
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
  padding: 0px 0px 17px 0px;
  /* border: 1px solid green; */
}

.dashboard .menuIcon {
  width: 30px;
  margin-block: 5px;
}

/* .dashboard .heading {
  color: black;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  margin-top: 30px;
  padding-inline: 10px;
} */
/* .accHide .accordion-button::after {
  display: none;
  border: 1px solid red;
} */

/* .accHide .accordion-button::before {
  display: none;
} */
/* .accHide .accordion-button {
  color: white;
  background: black;
} */

/* .custom-accordion .accitem .accHide {
  background: #2d2d2d;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-block: 40px;
  padding-inline: 20px;
} */

.sidebarPAdding {
  padding: 17px 12px 0px 12px;
  display: flex;
  gap: 16px;
  white-space: nowrap;
  overflow-x: scroll;
  /* Enable a horizontal scrollbar if content overflows */
}

.sidebarPAdding .subHeading {
  /* padding: 10px 30px 10px 30px; */
  padding: 10px 56px 10px 30px;
  width: 100%;
  height: 71px;
  border-radius: 15px;
  background: #edecec;
  margin-bottom: 8px;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #979393;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.subHeading span {
  color: #ada8a8;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* .sidebarPAdding li::marker {
  color: transparent;
} */

/* .dashboard .tab {
    padding: 20px;
    cursor: pointer;
    color: #4b4b4b;
    font-size: 15px;
    font-family: Poppins;
    font-weight: 500;
    margin-left: 0px;
    list-style-type: none;
    border-bottom: 1px solid rgb(207, 207, 207);
  } */

.tab.activetab {
  cursor: pointer;
  color: var(--white);
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* width: 100%; */
  background: var(--side-bar, linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%));
  border-radius: 15px;
  transition: 0.3s ease-in-out;
}

.tab.activetab span {
  color: var(--white);
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard .backBtn-div {
  display: none;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  margin-top: 35px;
  margin-left: 0px;
  gap: 0px;
}

/* .dashboard .subHeadingLogout {
  color: #4b4b4b;
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 500;
  list-style-type: none;
} */

/* .dashboard .logoutDiv {
  display: flex;
  align-items: center;
  gap: 10px;
} */

/* .dashboard .right .rightBgimg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.rightBgimg .backimg {
  width: 100%;
} */
.dashboard .right .rightHeading {
  width: 100%;
  text-align: center;
}

.right .rightHeading h5 {
  color: var(--0D4D80);
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.right .rightHeading span {
  color: #8d8d8d;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.dashboard .right {
  width: 100%;
  height: 100%;

  /* background-color: hotpink; */
  /* width: calc(100% - 300px); */
  /* background: linear-gradient(180deg, #e4e4e4 0%, #ebebeb 100%); */
  /* padding: 30px; */
  /* padding-top: 50px; */
  /* height: 98vh; */
  /* height: 100%; */
  /* height: auto; */
  overflow-y: auto;
  /* position: absolute; */
  /* left: 300px; */
  /* margin-top: 110px; */
  /* top: 110px; */
  /* border: 1px solid yellow; */
}

@media screen and (max-width: 1400px) {
  .custom-accordion .accitem .accHide button {
    font-size: 17.102px;
  }
}

@media screen and (max-width: 1200px) {
  .dashboard {
    display: flex;
    flex-direction: column;
  }

  .dashboard .left {
    /* z-index: 2; */
    /* width: 80%; */
    /* padding-left: 15px; */
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    transition: transform 0.3s ease-in-out;
  }

  .dashboard .headingBack {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dashboard .menuDiv {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 10px 0;
  }

  .dashboard .left .backBtn-div {
    display: block;
    margin-right: 20px;
  }

  .dashboard .right {
    width: 100%;
    height: fit-content;
    padding: 20px 0;
    /* background: white; */
    overflow-y: auto;
  }
}

@media screen and (max-width: 600px) {
  .general-content h6 {
    color: #0d4d80;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .general-content span {
    color: #8d8d8d;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.trans-100 {
  transform: translateX(-1200px);
  transition: 0.1s ease-in-out;
}

.trans-0 {
  transform: translateX(0px);
  transition: 0.1s ease-in-out;
}

.addAnswer-btn button {

  color: #FFF;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
  border-radius: 4px;
  background: linear-gradient(90deg, #02A3D6 0%, rgba(2, 163, 214, 0) 131.65%);

}

.newInputBox {
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid #0D4D80;
  padding: 10px;
}