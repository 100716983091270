.accordan {
  // padding-left: 104px;
  // padding-right: 104px;

  .accordianParent {
    border: none;

    .accordianItem1 {
      border: none;
      margin-top: 20px;
      border-radius: 10px;
      background: #f8f8f8;

      .accordianHeader {
        border: none;
        display: flex;
        justify-content: space-between;

        .accordion-button {
          background: var(--side-bar,
              linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)) !important;

          display: flex;
          justify-content: space-between;
          color: white;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

          &::after {
            display: none;
          }

          &:focus {
            box-shadow: none;
          }
        }

        .collapsed {
          background: #f8f8f8;
          color: black;
          z-index: 1;
        }
      }
    }
  }
}