* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // border: 1px solid hotpink;
}

#root {
  width: 100% !important;
}

body {
  margin-inline: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
a,
p,
.row,
.col {
  padding: 0;
  margin: 0;
  margin-bottom: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  border: none;
}

// ---------------custom margins

.mt-30 {
  margin-top: 30px;
}

// ---------------custom image sizes
.img-100 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

input {
  outline: none;

  // &::-webkit-inner-spin-button {
  //   display: none;
  // }

  &::-webkit-outer-spin-button {
    display: none;
  }
}

.goalsHeading {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px 0px;
  justify-content: center;
  align-items: space-between;
  gap: 100px;
}

.goalDiv {
  padding: 10px 100px;
  background: var(--side-bar, linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%));
  border-radius: 10px;
}

.goalDiv:hover {
  background: var(--side-bar, linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%));
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00a2d6;
}

// .breadcrumbs {
//   max-width: 1200px;
//   margin: 20px auto;
// }
// .breadcrumbs > * {
//   display: inline-block;
//   margin-right: 10px;
// }
// .breadcrumbs .crumb:after {
//   content: ">";
//   margin-left: 10px;
// }
// .breadcrumbs .crumb:last-child:after {
//   display: none;
// }
// .breadcrumbs .crumb a {
//   color: #ddd;
// }

//loader

.spinner {
  width: 56px;
  height: 56px;
  display: grid;
  border: 4.5px solid rgba(0, 162, 214, 0.58);
  border-radius: 50%;
  border-color: rgba(0, 162, 214, 0.58) transparent #00a2d6 transparent;
  animation: spinner-e04l1k 1s infinite linear;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2.2px;
  border: inherit;
  border-radius: 50%;
}

.spinner::before {
  border-color: #00a2d6 transparent rgba(0, 162, 214, 0.58) transparent;
  // background: linear-gradient(90deg, #00a2d6 0%, rgba(0, 162, 214, 0.58) 100%);
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.spinner::after {
  margin: 8.9px;
}

.buttonAddStep {
  display: inline-flex;
  height: 38px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #01a0d4;
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.table-container {
  border: 1.112px solid var(--Neutral-Gray-Gray-50, #f0f1f3);
  margin-top: 20px;
}

@keyframes spinner-e04l1k {
  100% {
    transform: rotate(1turn);
  }
}

.themesName {
  padding: 1px;
}

.dropdown-item {
  opacity: 1 !important;
}
.breadCrumbs {
  color: var(--primary-dark, #0d4d80);
  font-family: "Arial";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28.146px; /* 175.91% */
  display: flex;
  align-items: center;
  gap: 5px;
}
.arrowCrumbs {
  width: 18px;
  height: 18px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00a1d5;
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--side-bar, linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%));
}

.pointer {
  cursor: pointer;
}
