.meetingoctaloop {
  padding-top: 70px;
  width: 100%;

  .octaloop-content {
    padding-left: 89px;
    padding-right: 89px;
    .meetingAgendaDropdown {
      width: 250px;
      background-color: #01a0d4;
      border: none;
    }
    .meetingAgendadropdownMenu {
      max-height: 300px;
      overflow-y: auto;
      .meetingAgendaDropdownItem {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .content-header {
      margin-top: 54px;
      margin-bottom: 34px;
      width: 100%;
      height: 81px;
      background: #fff;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;

      h6 {
        color: #0a4d68;

        font-family: Arial;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.66px;

        span {
          color: #0a4d68;

          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.54px;
          opacity: 0.8;
        }
      }
    }

    .content-firstsection {
      width: 100%;
      // height: 197px;
      // padding: 20.014px 54px;
      padding-right: 34px;
      border-radius: 8px;
      border: 1.112px solid var(--Neutral-Gray-Gray-50, #f0f1f3);

      .firstsection {
        display: flex;
        align-items: center;
        margin-bottom: 9px;
        padding-top: 19px;
        padding-bottom: 19px;

        .leftside {
          width: 30%;
          // padding-top: 44px;
          padding-left: 44px;

          span {
            color: var(--Neutral-Black-Black-700, #333843);
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.238px;
            /* 123.543% */
            letter-spacing: 0.09px;
          }
        }

        .rightside {
          width: 65%;
          margin-right: 20px;

          .inputfield {
            position: relative;

            input {
              width: 100%;
              border-radius: 11.165px;
              background: var(--color-palette-neutral-05, #f3f3f3);
              height: 47px;
              padding: 9.304px 14.887px;
              padding-right: 120px;
              border: none;
            }

            span {
              position: absolute;
              right: 10px;
              bottom: 13px;
              color: var(--color-palette-neutral-60, #6e6e70);
              font-family: Arial;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20.469px;
              opacity: 0.5;
              /* 146.209% */
            }
          }
        }
      }

      .firstsection {
        display: flex;
        align-items: center;
        margin-bottom: 9px;
        padding-top: 19px;
        padding-bottom: 19px;

        .leftside {
          width: 30%;
          // padding-top: 44px;
          padding-left: 44px;

          span {
            color: var(--Neutral-Black-Black-700, #333843);
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.238px;
            /* 123.543% */
            letter-spacing: 0.09px;
          }
        }

        .rightside {
          width: 65%;
          margin-right: 20px;

          .inputfield {
            position: relative;

            input {
              width: 100%;
              border-radius: 11.165px;
              background: var(--color-palette-neutral-05, #f3f3f3);
              height: 47px;
              padding: 9.304px 14.887px;
              padding-right: 120px;
              border: none;
            }

            span {
              position: absolute;
              right: 10px;
              bottom: 13px;
              color: var(--color-palette-neutral-60, #6e6e70);
              font-family: Arial;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20.469px;
              opacity: 0.5;
              /* 146.209% */
            }

            table {
              width: 100%;
            }

            td,
            th {
              border: 1px solid var(--color-palette-neutral-05, #f3f3f3);
              text-align: left;
              padding: 8px;
            }

            tr:nth-child(even) {
              background-color: var(--color-palette-neutral-05, #f3f3f3);

              td {
                border: 1px solid #ddd;
              }
            }
          }
        }
      }
    }

    .content-secondsection {
      width: 100%;
      // height: 197px;
      // padding: 20.014px 54px;
      padding-right: 34px;
      border-radius: 8px;
      border: 1.112px solid var(--Neutral-Gray-Gray-50, #f0f1f3);
      margin-top: 16px;
      .pagination-table {
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-end;
        // align-items: end;
      }
      .firstsection {
        display: flex;
        align-items: center;
        margin-bottom: 9px;
        padding-top: 19px;
        padding-bottom: 19px;

        .leftside {
          width: 30%;
          // padding-top: 44px;
          padding-left: 44px;

          span {
            color: var(--Neutral-Black-Black-700, #333843);
            font-family: Arial;
            font-size: 15.566px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.238px;
            /* 142.857% */
            letter-spacing: 0.078px;
          }
        }

        .rightside {
          width: 65%;
          margin-right: 20px;

          .inputfield {
            position: relative;

            input {
              width: 100%;
              border-radius: 11.165px;
              background: var(--color-palette-neutral-05, #f3f3f3);
              height: 47px;
              padding: 9.304px 14.887px;
              padding-right: 120px;
              border: none;
            }

            span {
              position: absolute;
              right: 10px;
              bottom: 13px;
              color: var(--color-palette-neutral-60, #6e6e70);
              font-family: Arial;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20.469px;
              opacity: 0.5;
              /* 146.209% */
            }
          }

          .right-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h5 {
              color: var(--Neutral-Black-Black-700, #333843);
              font-family: Arial;
              font-size: 22px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.238px;
              /* 101.081% */
              letter-spacing: 0.11px;
            }

            h6 {
              color: var(--Neutral-Black-Black-700, #333843);
              font-family: Arial;
              font-size: 22px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.238px;
              /* 101.081% */
              letter-spacing: 0.11px;
            }
          }
        }
      }

      .firstsection {
        display: flex;
        align-items: center;
        margin-bottom: 9px;
        padding-top: 19px;
        padding-bottom: 19px;

        .leftside {
          width: 30%;
          // padding-top: 44px;
          padding-left: 44px;

          span {
            color: var(--Neutral-Black-Black-700, #333843);
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.238px;
            /* 123.543% */
            letter-spacing: 0.09px;
          }
        }

        .rightside {
          width: 65%;
          margin-right: 20px;

          .inputfield {
            position: relative;

            input {
              width: 100%;
              border-radius: 11.165px;
              background: var(--color-palette-neutral-05, #f3f3f3);
              height: 47px;
              padding: 9.304px 14.887px;
              border: none;
            }

            span {
              position: absolute;
              right: 10px;
              bottom: 13px;
              color: var(--color-palette-neutral-60, #6e6e70);
              font-family: Arial;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20.469px;
              opacity: 0.5;
              /* 146.209% */
            }
          }
        }
      }
    }

    .bottom-div {
      margin-block: 51px;
      width: 100%;
      height: 2px;
      background: linear-gradient(
        90deg,
        rgba(240, 241, 243, 0) 0%,
        #c7c7c7 48%,
        rgba(240, 241, 243, 0) 100%
      );
    }
  }
}

@media only screen and (max-width: 1600px) {
  .meetingoctaloop {
    padding-top: 70px;
    width: 100%;

    .octaloop-content {
      padding-left: 80px;
      padding-right: 80px;

      .content-header {
        margin-top: 54px;
        margin-bottom: 34px;
        width: 100%;
        height: 81px;
        background: #fff;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;

        h6 {
          color: #0a4d68;

          font-family: Arial;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.66px;

          span {
            color: #0a4d68;

            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.54px;
            opacity: 0.8;
          }
        }
      }

      .content-firstsection {
        width: 100%;
        // height: 197px;
        // padding: 20.014px 54px;
        padding-right: 34px;
        border-radius: 8px;
        border: 1.112px solid var(--Neutral-Gray-Gray-50, #f0f1f3);

        .firstsection {
          display: flex;
          align-items: center;
          margin-bottom: 9px;
          padding-top: 19px;
          padding-bottom: 19px;

          .leftside {
            width: 30%;
            // padding-top: 44px;
            padding-left: 44px;

            span {
              color: var(--Neutral-Black-Black-700, #333843);
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.238px;
              /* 123.543% */
              letter-spacing: 0.09px;
            }
          }

          .rightside {
            width: 65%;
            margin-right: 20px;

            .inputfield {
              position: relative;

              input {
                width: 100%;
                border-radius: 11.165px;
                background: var(--color-palette-neutral-05, #f3f3f3);
                height: 47px;
                padding: 9.304px 14.887px;
                padding-right: 120px;
                border: none;
              }

              span {
                position: absolute;
                right: 10px;
                bottom: 13px;
                color: var(--color-palette-neutral-60, #6e6e70);
                font-family: Arial;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20.469px;
                opacity: 0.5;
                /* 146.209% */
              }
            }
          }
        }

        .firstsection {
          display: flex;
          align-items: center;
          margin-bottom: 9px;
          padding-top: 19px;
          padding-bottom: 19px;

          .leftside {
            width: 30%;
            // padding-top: 44px;
            padding-left: 44px;

            span {
              color: var(--Neutral-Black-Black-700, #333843);
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.238px;
              /* 123.543% */
              letter-spacing: 0.09px;
            }
          }

          .rightside {
            width: 65%;
            margin-right: 20px;

            .inputfield {
              position: relative;

              input {
                width: 100%;
                border-radius: 11.165px;
                background: var(--color-palette-neutral-05, #f3f3f3);
                height: 47px;
                padding: 9.304px 14.887px;
                padding-right: 120px;
                border: none;
              }

              span {
                position: absolute;
                right: 10px;
                bottom: 13px;
                color: var(--color-palette-neutral-60, #6e6e70);
                font-family: Arial;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20.469px;
                opacity: 0.5;
                /* 146.209% */
              }
            }
          }
        }
      }

      .content-secondsection {
        width: 100%;
        // height: 197px;
        // padding: 20.014px 54px;
        padding-right: 34px;
        border-radius: 8px;
        border: 1.112px solid var(--Neutral-Gray-Gray-50, #f0f1f3);
        margin-top: 16px;

        .firstsection {
          display: flex;
          align-items: center;
          margin-bottom: 9px;
          padding-top: 19px;
          padding-bottom: 19px;

          .leftside {
            width: 30%;
            // padding-top: 44px;
            padding-left: 44px;

            span {
              color: var(--Neutral-Black-Black-700, #333843);
              font-family: Arial;
              font-size: 15.566px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.238px;
              /* 142.857% */
              letter-spacing: 0.078px;
            }
          }

          .rightside {
            width: 65%;
            margin-right: 20px;

            .inputfield {
              position: relative;

              input {
                width: 100%;
                border-radius: 11.165px;
                background: var(--color-palette-neutral-05, #f3f3f3);
                height: 47px;
                padding: 9.304px 14.887px;
                padding-right: 120px;
                border: none;
              }

              span {
                position: absolute;
                right: 10px;
                bottom: 13px;
                color: var(--color-palette-neutral-60, #6e6e70);
                font-family: Arial;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20.469px;
                opacity: 0.5;
                /* 146.209% */
              }
            }

            .right-content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              h5 {
                color: var(--Neutral-Black-Black-700, #333843);
                font-family: Arial;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 22.238px;
                /* 101.081% */
                letter-spacing: 0.11px;
              }

              h6 {
                color: var(--Neutral-Black-Black-700, #333843);
                font-family: Arial;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 22.238px;
                /* 101.081% */
                letter-spacing: 0.11px;
              }
            }
          }
        }

        .firstsection {
          display: flex;
          align-items: center;
          margin-bottom: 9px;
          padding-top: 19px;
          padding-bottom: 19px;

          .leftside {
            width: 30%;
            // padding-top: 44px;
            padding-left: 44px;

            span {
              color: var(--Neutral-Black-Black-700, #333843);
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.238px;
              /* 123.543% */
              letter-spacing: 0.09px;
            }
          }

          .rightside {
            width: 65%;
            margin-right: 20px;

            .inputfield {
              position: relative;

              input {
                width: 100%;
                border-radius: 11.165px;
                background: var(--color-palette-neutral-05, #f3f3f3);
                height: 47px;
                padding: 9.304px 14.887px;
                padding-right: 120px;
                border: none;
              }

              span {
                position: absolute;
                right: 10px;
                bottom: 13px;
                color: var(--color-palette-neutral-60, #6e6e70);
                font-family: Arial;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20.469px;
                opacity: 0.5;
                /* 146.209% */
              }
            }
          }
        }
      }

      .bottom-div {
        margin-block: 51px;
        width: 100%;
        height: 2px;
        background: linear-gradient(
          90deg,
          rgba(240, 241, 243, 0) 0%,
          #c7c7c7 48%,
          rgba(240, 241, 243, 0) 100%
        );
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .meetingoctaloop {
    padding-top: 70px;
    width: 100%;

    .octaloop-content {
      padding-left: 10px;
      padding-right: 10px;

      .content-header {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 100%;
        height: 81px;
        background: #fff;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;

        h6 {
          color: #0a4d68;

          font-family: Arial;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.66px;

          span {
            color: #0a4d68;

            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.54px;
            opacity: 0.8;
          }
        }
      }

      .content-firstsection {
        width: 100%;
        // height: 197px;
        // padding: 20.014px 54px;
        padding-right: 5px;
        border-radius: 8px;
        border: 1.112px solid var(--Neutral-Gray-Gray-50, #f0f1f3);

        .firstsection {
          display: flex;
          // flex-direction: column;
          justify-content: start;
          align-items: start;
          margin-bottom: 9px;
          padding-top: 19px;
          padding-bottom: 19px;

          .leftside {
            width: 100%;
            // padding-top: 44px;
            padding-left: 20px;

            span {
              color: var(--Neutral-Black-Black-700, #333843);
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.238px;
              /* 123.543% */
              letter-spacing: 0.09px;
            }
          }

          .rightside {
            width: 70%;
            margin-right: 20px;

            .inputfield {
              position: relative;

              input {
                width: 100%;
                border-radius: 11.165px;
                background: var(--color-palette-neutral-05, #f3f3f3);
                height: 47px;
                padding: 9.304px 14.887px;
                padding-right: 120px;
                border: none;
              }

              span {
                position: absolute;
                right: 10px;
                bottom: 13px;
                color: var(--color-palette-neutral-60, #6e6e70);
                font-family: Arial;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20.469px;
                opacity: 0.5;
                /* 146.209% */
              }
            }
          }
        }

        .firstsection {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: start;
          margin-bottom: 9px;
          padding: 20px;
          // padding-top: 19px;
          // padding-bottom: 19px;

          .leftside {
            width: 100%;
            // padding-top: 44px;
            padding-left: 0px;

            span {
              color: var(--Neutral-Black-Black-700, #333843);
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.238px;
              /* 123.543% */
              letter-spacing: 0.09px;
            }
          }

          .rightside {
            width: 100%;
            margin-right: 0px;
            margin-left: 0px;

            .inputfield {
              position: relative;

              input {
                width: 100%;
                border-radius: 11.165px;
                background: var(--color-palette-neutral-05, #f3f3f3);
                height: 47px;
                padding: 9.304px 14.887px;
                padding-right: 120px;
                border: none;
              }

              span {
                position: absolute;
                right: 10px;
                bottom: 13px;
                color: var(--color-palette-neutral-60, #6e6e70);
                font-family: Arial;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20.469px;
                opacity: 0.5;
                /* 146.209% */
              }
            }
          }
        }
      }

      .content-secondsection {
        width: 100%;
        // height: 197px;
        // padding: 20.014px 54px;
        padding-right: 0px;
        border-radius: 8px;
        border: 1.112px solid var(--Neutral-Gray-Gray-50, #f0f1f3);
        margin-top: 16px;

        .firstsection {
          display: flex;
          align-items: center;
          margin-bottom: 9px;
          padding-top: 19px;
          padding-bottom: 19px;

          .leftside {
            width: 30%;
            // padding-top: 44px;
            padding-left: 44px;

            span {
              color: var(--Neutral-Black-Black-700, #333843);
              font-family: Arial;
              font-size: 15.566px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.238px;
              /* 142.857% */
              letter-spacing: 0.078px;
            }
          }

          .rightside {
            width: 65%;
            margin-right: 20px;

            .inputfield {
              position: relative;

              input {
                width: 100%;
                border-radius: 11.165px;
                background: var(--color-palette-neutral-05, #f3f3f3);
                height: 47px;
                padding: 9.304px 14.887px;
                padding-right: 120px;
                border: none;
              }

              span {
                position: absolute;
                right: 10px;
                bottom: 13px;
                color: var(--color-palette-neutral-60, #6e6e70);
                font-family: Arial;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20.469px;
                opacity: 0.5;
                /* 146.209% */
              }
            }

            .right-content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              h5 {
                color: var(--Neutral-Black-Black-700, #333843);
                font-family: Arial;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 22.238px;
                /* 101.081% */
                letter-spacing: 0.11px;
              }

              h6 {
                color: var(--Neutral-Black-Black-700, #333843);
                font-family: Arial;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 22.238px;
                /* 101.081% */
                letter-spacing: 0.11px;
              }
            }
          }
        }

        .firstsection {
          display: flex;
          align-items: center;
          margin-bottom: 9px;
          padding-top: 19px;
          padding-bottom: 19px;

          .leftside {
            width: 40%;
            // padding-top: 44px;
            padding-left: 20px;

            span {
              color: var(--Neutral-Black-Black-700, #333843);
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.238px;
              /* 123.543% */
              letter-spacing: 0.09px;
            }
          }

          .rightside {
            width: 60%;
            margin-right: 20px;

            .inputfield {
              position: relative;

              input {
                width: 100%;
                border-radius: 11.165px;
                background: var(--color-palette-neutral-05, #f3f3f3);
                height: 47px;
                padding: 9.304px 14.887px;
                padding-right: 120px;
                border: none;
              }

              span {
                position: absolute;
                right: 10px;
                bottom: 13px;
                color: var(--color-palette-neutral-60, #6e6e70);
                font-family: Arial;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20.469px;
                opacity: 0.5;
                /* 146.209% */
              }
            }
          }
        }
      }

      .bottom-div {
        margin-block: 51px;
        width: 100%;
        height: 2px;
        background: linear-gradient(
          90deg,
          rgba(240, 241, 243, 0) 0%,
          #c7c7c7 48%,
          rgba(240, 241, 243, 0) 100%
        );
      }
    }
  }
}

.closedIssue {
  background-color: rgba(255, 154, 154, 0.75);
  backdrop-filter: blur(19px) saturate(180%);
  border: 1px solid rgba(255, 255, 255, 0.125);
  color: white !important;
}
