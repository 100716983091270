.addteam-section {
    height: 100vh;
    padding-top: 70px;

    .addteam-content {
        padding: 54px 289px 0px 289px;

        .title {
            background: #FFF;
            height: 100px;
            width: 100%;
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
            display: flex;
            align-items: center;
            justify-content: space-between !important;
            gap: 59px;
            margin-bottom: 25px;

            .title-left {
                width: 35%;
                padding-left: 59px;

                h6 {
                    color: #0A4D68;
                    font-family: Arial;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .title-right {
                width: 100%;
                padding-right: 31px;
                display: flex;
                align-items: center;
                gap: 10px;

                input {
                    width: 67%;
                    height: 47px;
                    padding: 9.304px 14.887px;
                    border-radius: 11.165px;
                    background: var(--color-palette-neutral-05, #F3F3F3);
                    border: none;

                    .halfLeftAlign::placeholder {
                        text-align: left;
                    }

                    .halfRightAlign::placeholder {
                        text-align: right;
                    }
                }

                .addtask {
                    display: flex;
                    align-items: center;
                    width: 76px;
                    height: 35px;
                    padding: 10px;
                    border-radius: 8px;
                    background: #A7D176;
                    color: #FFF;
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        .team-table {
            .table-content {
                table {
                    width: 100%;
                    // border: 0.5px solid #000;

                    .firstRow {
                        // border: 1px solid red;
                        // border-right: 0.5px solid #000;
                        // border-bottom: 0.5px solid #000;
                        // border-left: 0.5px solid #000;
                        // background: #FFF;
                        // padding: 10px;
                        border-radius: 0px 10px 0px 0px;
                        border-bottom: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                        background: rgba(0, 162, 214, 0.40);

                        .firstTh {
                            // border: 1px solid green;
                            width: 25%;
                            height: 58px;
                            // height: 58px;
                            // padding-bottom: 3px;
                            // border-radius: 10px 10px 0px 0px;
                            background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                            // text-align: center;

                            span {
                                color: #FFF;
                                font-family: Arial;
                                font-size: 22px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                text-transform: capitalize;
                            }
                        }

                        .secondTh {
                            // border: 1px solid green;
                            width: 25%;

                            padding: 26px 27px;
                            color: var(--Neutral-Black-Black-700, #333843);
                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 22.238px;
                            /* 123.543% */
                            letter-spacing: 0.09px;
                        }

                        .thirdTh {
                            // border: 1px solid green;
                            width: 25%;
                            padding: 26px 27px;
                            color: var(--Neutral-Black-Black-700, #333843);
                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 22.238px;
                            /* 123.543% */
                            letter-spacing: 0.09px;
                        }

                        .fourthTh {
                            // border: 1px solid green;
                            width: 25%;
                            padding: 26px 27px;
                            color: var(--Neutral-Black-Black-700, #333843);
                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 22.238px;
                            /* 123.543% */
                            letter-spacing: 0.09px;

                        }


                    }

                    .secondRowP {
                        // border: 1px solid red;
                        // border-right: 0.5px solid #000;
                        // border-bottom: 0.5px solid #000;
                        // border-left: 0.5px solid #000;
                        // background: #FFF;
                        // padding: 10px;
                        // background: hotpink;
                        // box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
                        border-left: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                        border-right: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                        border-bottom: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);

                        // .firstTh {
                        //     border: 1px solid green;
                        //     width: 25%;
                        //     height: 58px;
                        //     // height: 58px;
                        //     // padding-bottom: 3px;
                        //     // border-radius: 10px 10px 0px 0px;
                        //     background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                        //     // text-align: center;

                        //     span {
                        //         color: #FFF;
                        //         font-family: Arial;
                        //         font-size: 22px;
                        //         font-style: normal;
                        //         font-weight: 700;
                        //         line-height: normal;
                        //         text-transform: capitalize;
                        //     }
                        // }

                        .secondTh {
                            // border: 1px solid green;
                            width: 25%;
                            // width: 50%;
                            // height: 58px;
                            // height: 35px;

                            // display: flex;
                            // justify-content: center;
                            // align-items: center;
                            // border-bottom: 0.5px solid #000;
                            // border-right: 0.5px solid #000;
                            padding: 26px 27px;
                            color: var(--Neutral-Black-Black-700, #333843);
                            font-family: Arial;
                            font-size: 15.566px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22.238px;
                            /* 142.857% */
                            letter-spacing: 0.078px;

                            .savePlanbtn {
                                display: flex;
                                align-items: center;
                                width: 76px;
                                height: 35px;
                                padding: 10px;
                                border-radius: 8px;
                                background: #A7D176;
                                color: #FFF;
                                font-family: Arial;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }

                        }

                    }

                    .secondRowPP {
                        background: rgba(0, 162, 214, 0.20);


                        // border: 1px solid red;
                        // border-right: 0.5px solid #000;
                        // border-bottom: 0.5px solid #000;
                        // border-left: 0.5px solid #000;
                        // background: #FFF;
                        // padding: 10px;
                        // background: hotpink;
                        // box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
                        border-left: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                        border-right: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                        border-bottom: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);

                        // .firstTh {
                        //     border: 1px solid green;
                        //     width: 25%;
                        //     height: 58px;
                        //     // height: 58px;
                        //     // padding-bottom: 3px;
                        //     // border-radius: 10px 10px 0px 0px;
                        //     background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                        //     // text-align: center;

                        //     span {
                        //         color: #FFF;
                        //         font-family: Arial;
                        //         font-size: 22px;
                        //         font-style: normal;
                        //         font-weight: 700;
                        //         line-height: normal;
                        //         text-transform: capitalize;
                        //     }
                        // }

                        .secondTh {
                            // border: 1px solid green;
                            width: 25%;
                            // width: 50%;
                            // height: 58px;
                            // height: 35px;

                            // display: flex;
                            // justify-content: center;
                            // align-items: center;
                            // border-bottom: 0.5px solid #000;
                            // border-right: 0.5px solid #000;
                            padding: 26px 27px;
                            color: var(--Neutral-Black-Black-700, #333843);
                            font-family: Arial;
                            font-size: 15.566px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22.238px;
                            /* 142.857% */
                            letter-spacing: 0.078px;


                        }

                    }
                }

            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .addteam-section {
        height: 100vh;
        padding-top: 70px;

        .addteam-content {
            padding: 54px 60px 0px 60px;

            .title {
                background: #FFF;
                height: 81px;
                width: 100%;
                box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                gap: 59px;
                margin-bottom: 25px;

                .title-left {
                    width: 35%;
                    padding-left: 59px;

                    h6 {
                        color: #0A4D68;
                        font-family: Arial;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }

                .title-right {
                    width: 65%;
                    padding-right: 31px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    input {
                        width: 100%;
                        height: 47px;
                        padding: 9.304px 14.887px;
                        border-radius: 11.165px;
                        background: var(--color-palette-neutral-05, #F3F3F3);
                        border: none;

                        .halfLeftAlign::placeholder {
                            text-align: left;
                        }

                        .halfRightAlign::placeholder {
                            text-align: right;
                        }
                    }

                    .addtask {
                        display: flex;
                        align-items: center;
                        width: 76px;
                        height: 35px;
                        padding: 10px;
                        border-radius: 8px;
                        background: #A7D176;
                        color: #FFF;
                        font-family: Arial;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }

            .team-table {
                .table-content {
                    table {
                        width: 100%;
                        // border: 0.5px solid #000;

                        .firstRow {
                            // border: 1px solid red;
                            // border-right: 0.5px solid #000;
                            // border-bottom: 0.5px solid #000;
                            // border-left: 0.5px solid #000;
                            // background: #FFF;
                            // padding: 10px;
                            border-radius: 0px 10px 0px 0px;
                            border-bottom: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                            background: rgba(0, 162, 214, 0.40);

                            .firstTh {
                                // border: 1px solid green;
                                width: 25%;
                                height: 58px;
                                // height: 58px;
                                // padding-bottom: 3px;
                                // border-radius: 10px 10px 0px 0px;
                                background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                                // text-align: center;

                                span {
                                    color: #FFF;
                                    font-family: Arial;
                                    font-size: 22px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: normal;
                                    text-transform: capitalize;
                                }
                            }

                            .secondTh {
                                // border: 1px solid green;
                                width: 25%;

                                padding: 26px 27px;
                                color: var(--Neutral-Black-Black-700, #333843);
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 22.238px;
                                /* 123.543% */
                                letter-spacing: 0.09px;
                            }

                            .thirdTh {
                                // border: 1px solid green;
                                width: 25%;
                                padding: 26px 27px;
                                color: var(--Neutral-Black-Black-700, #333843);
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 22.238px;
                                /* 123.543% */
                                letter-spacing: 0.09px;
                            }

                            .fourthTh {
                                // border: 1px solid green;
                                width: 25%;
                                padding: 26px 27px;
                                color: var(--Neutral-Black-Black-700, #333843);
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 22.238px;
                                /* 123.543% */
                                letter-spacing: 0.09px;

                            }


                        }

                        .secondRowP {
                            // border: 1px solid red;
                            // border-right: 0.5px solid #000;
                            // border-bottom: 0.5px solid #000;
                            // border-left: 0.5px solid #000;
                            // background: #FFF;
                            // padding: 10px;
                            // background: hotpink;
                            // box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
                            border-left: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                            border-right: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                            border-bottom: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);

                            // .firstTh {
                            //     border: 1px solid green;
                            //     width: 25%;
                            //     height: 58px;
                            //     // height: 58px;
                            //     // padding-bottom: 3px;
                            //     // border-radius: 10px 10px 0px 0px;
                            //     background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                            //     // text-align: center;

                            //     span {
                            //         color: #FFF;
                            //         font-family: Arial;
                            //         font-size: 22px;
                            //         font-style: normal;
                            //         font-weight: 700;
                            //         line-height: normal;
                            //         text-transform: capitalize;
                            //     }
                            // }

                            .secondTh {
                                // border: 1px solid green;
                                width: 25%;
                                // width: 50%;
                                // height: 58px;
                                // height: 35px;

                                // display: flex;
                                // justify-content: center;
                                // align-items: center;
                                // border-bottom: 0.5px solid #000;
                                // border-right: 0.5px solid #000;
                                padding: 26px 27px;
                                color: var(--Neutral-Black-Black-700, #333843);
                                font-family: Arial;
                                font-size: 15.566px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22.238px;
                                /* 142.857% */
                                letter-spacing: 0.078px;


                            }

                        }

                        .secondRowPP {
                            background: rgba(0, 162, 214, 0.20);


                            // border: 1px solid red;
                            // border-right: 0.5px solid #000;
                            // border-bottom: 0.5px solid #000;
                            // border-left: 0.5px solid #000;
                            // background: #FFF;
                            // padding: 10px;
                            // background: hotpink;
                            // box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
                            border-left: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                            border-right: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                            border-bottom: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);

                            // .firstTh {
                            //     border: 1px solid green;
                            //     width: 25%;
                            //     height: 58px;
                            //     // height: 58px;
                            //     // padding-bottom: 3px;
                            //     // border-radius: 10px 10px 0px 0px;
                            //     background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                            //     // text-align: center;

                            //     span {
                            //         color: #FFF;
                            //         font-family: Arial;
                            //         font-size: 22px;
                            //         font-style: normal;
                            //         font-weight: 700;
                            //         line-height: normal;
                            //         text-transform: capitalize;
                            //     }
                            // }

                            .secondTh {
                                // border: 1px solid green;
                                width: 25%;
                                // width: 50%;
                                // height: 58px;
                                // height: 35px;

                                // display: flex;
                                // justify-content: center;
                                // align-items: center;
                                // border-bottom: 0.5px solid #000;
                                // border-right: 0.5px solid #000;
                                padding: 26px 27px;
                                color: var(--Neutral-Black-Black-700, #333843);
                                font-family: Arial;
                                font-size: 15.566px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22.238px;
                                /* 142.857% */
                                letter-spacing: 0.078px;


                            }

                        }
                    }

                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .addteam-section {
        height: 100vh;
        padding-top: 70px;

        .addteam-content {
            padding: 40px 10px 0px 10px;


            .title {
                background: #FFF;
                height: 100%;
                padding: 10px;
                // height: 81px;
                width: 100%;
                box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 10px;
                margin-bottom: 25px;

                .title-left {
                    width: 100%;
                    padding-left: 5px;

                    h6 {
                        color: #0A4D68;
                        font-family: Arial;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }

                .title-right {
                    width: 100%;
                    padding-right: 5px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    input {
                        width: 100%;
                        height: 47px;
                        padding: 9.304px 14.887px;
                        border-radius: 11.165px;
                        background: var(--color-palette-neutral-05, #F3F3F3);
                        border: none;

                        .halfLeftAlign::placeholder {
                            text-align: left;
                        }

                        .halfRightAlign::placeholder {
                            text-align: right;
                        }
                    }

                    .addtask {
                        display: flex;
                        align-items: center;
                        width: 76px;
                        height: 35px;
                        padding: 10px;
                        border-radius: 8px;
                        background: #A7D176;
                        color: #FFF;
                        font-family: Arial;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }

            .team-table {
                .table-content {
                    overflow-x: auto;
                    /* Make the table horizontally scrollable */
                    width: 100%;

                    table {
                        width: 100%;
                        // border: 0.5px solid #000;

                        .firstRow {
                            // border: 1px solid red;
                            // border-right: 0.5px solid #000;
                            // border-bottom: 0.5px solid #000;
                            // border-left: 0.5px solid #000;
                            // background: #FFF;
                            // padding: 10px;
                            border-radius: 0px 10px 0px 0px;
                            border-bottom: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                            background: rgba(0, 162, 214, 0.40);

                            .firstTh {
                                // border: 1px solid green;
                                width: 25%;
                                height: 58px;
                                // height: 58px;
                                // padding-bottom: 3px;
                                // border-radius: 10px 10px 0px 0px;
                                background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                                // text-align: center;

                                span {
                                    color: #FFF;
                                    font-family: Arial;
                                    font-size: 22px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: normal;
                                    text-transform: capitalize;
                                }
                            }

                            .secondTh {
                                // border: 1px solid green;
                                width: 25%;

                                padding: 26px 27px;
                                color: var(--Neutral-Black-Black-700, #333843);
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 22.238px;
                                /* 123.543% */
                                letter-spacing: 0.09px;
                            }

                            .thirdTh {
                                // border: 1px solid green;
                                width: 25%;
                                padding: 26px 27px;
                                color: var(--Neutral-Black-Black-700, #333843);
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 22.238px;
                                /* 123.543% */
                                letter-spacing: 0.09px;
                            }

                            .fourthTh {
                                // border: 1px solid green;
                                width: 25%;
                                padding: 26px 27px;
                                color: var(--Neutral-Black-Black-700, #333843);
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 22.238px;
                                /* 123.543% */
                                letter-spacing: 0.09px;

                            }


                        }

                        .secondRowP {
                            // border: 1px solid red;
                            // border-right: 0.5px solid #000;
                            // border-bottom: 0.5px solid #000;
                            // border-left: 0.5px solid #000;
                            // background: #FFF;
                            // padding: 10px;
                            // background: hotpink;
                            // box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
                            border-left: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                            border-right: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                            border-bottom: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);

                            // .firstTh {
                            //     border: 1px solid green;
                            //     width: 25%;
                            //     height: 58px;
                            //     // height: 58px;
                            //     // padding-bottom: 3px;
                            //     // border-radius: 10px 10px 0px 0px;
                            //     background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                            //     // text-align: center;

                            //     span {
                            //         color: #FFF;
                            //         font-family: Arial;
                            //         font-size: 22px;
                            //         font-style: normal;
                            //         font-weight: 700;
                            //         line-height: normal;
                            //         text-transform: capitalize;
                            //     }
                            // }

                            .secondTh {
                                // border: 1px solid green;
                                width: 25%;
                                // width: 50%;
                                // height: 58px;
                                // height: 35px;

                                // display: flex;
                                // justify-content: center;
                                // align-items: center;
                                // border-bottom: 0.5px solid #000;
                                // border-right: 0.5px solid #000;
                                padding: 26px 27px;
                                color: var(--Neutral-Black-Black-700, #333843);
                                font-family: Arial;
                                font-size: 15.566px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22.238px;
                                /* 142.857% */
                                letter-spacing: 0.078px;


                            }

                        }

                        .secondRowPP {
                            background: rgba(0, 162, 214, 0.20);


                            // border: 1px solid red;
                            // border-right: 0.5px solid #000;
                            // border-bottom: 0.5px solid #000;
                            // border-left: 0.5px solid #000;
                            // background: #FFF;
                            // padding: 10px;
                            // background: hotpink;
                            // box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
                            border-left: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                            border-right: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
                            border-bottom: 1.112px solid var(--Neutral-Gray-Gray-50, #F0F1F3);

                            // .firstTh {
                            //     border: 1px solid green;
                            //     width: 25%;
                            //     height: 58px;
                            //     // height: 58px;
                            //     // padding-bottom: 3px;
                            //     // border-radius: 10px 10px 0px 0px;
                            //     background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                            //     // text-align: center;

                            //     span {
                            //         color: #FFF;
                            //         font-family: Arial;
                            //         font-size: 22px;
                            //         font-style: normal;
                            //         font-weight: 700;
                            //         line-height: normal;
                            //         text-transform: capitalize;
                            //     }
                            // }

                            .secondTh {
                                // border: 1px solid green;
                                width: 25%;
                                // width: 50%;
                                // height: 58px;
                                // height: 35px;

                                // display: flex;
                                // justify-content: center;
                                // align-items: center;
                                // border-bottom: 0.5px solid #000;
                                // border-right: 0.5px solid #000;
                                padding: 26px 27px;
                                color: var(--Neutral-Black-Black-700, #333843);
                                font-family: Arial;
                                font-size: 15.566px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22.238px;
                                /* 142.857% */
                                letter-spacing: 0.078px;


                            }

                        }
                    }

                }
            }
        }
    }
}