// .Questionnaire {
//   min-height: 100vh;

//   .dashboard {
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     border: 1px solid green;
//   }

//   .dashboard .menuDiv {
//     display: none;
//     height: 10%;
//     width: 10%;
//   }

//   .dashboard .left {
//     // width: 150%;
//     min-width: 287px;
//     margin-right: 10px;
//     background-color: #f2f2f2;
//     height: 98vh;
//     height: 100%;
//     box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
//       rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
//   }

//   .dashboard .menuIcon {
//     width: 30px;
//     margin-block: 5px;
//   }

//   .dashboard .heading {
//     color: black;
//     font-size: 18px;
//     font-family: "Poppins";
//     font-weight: 600;
//     margin-top: 30px;
//     padding-inline: 10px;
//   }

//   .sidebarPAdding {
//     padding: 0px;
//   }

//   .sidebarPAdding li::marker {
//     color: transparent;
//   }

//   .dashboard .tab {
//     padding: 20px;
//     padding-block: 20px !important;
//     margin-block-end: 10px;
//     cursor: pointer;
//     color: #4b4b4b;
//     font-size: 15px;
//     font-family: Poppins;
//     font-weight: 500;
//     margin-left: 0px;
//     list-style-type: none;
//     border-radius: 15px;
//     border-bottom: 1px solid rgb(207, 207, 207);
//   }

//   .activetab {
//     background-color: rgb(180 237 255);
//     background-color: var(--primary);
//     color: white !important;
//     transition: 0.3s ease-in-out;

//     border-radius: 15px;
//     background: var(--side-bar,
//         linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%));
//     box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
//   }

//   .dashboard .backBtn-div {
//     display: none;
//     padding-top: 5px;
//     padding-bottom: 5px;
//     align-items: center;
//     margin-top: 35px;
//     margin-left: 0px;
//     gap: 0px;
//   }

//   .dashboard .subHeadingLogout {
//     color: #4b4b4b;
//     font-size: 15px;
//     font-family: Poppins;
//     font-weight: 500;
//     list-style-type: none;
//   }

//   .dashboard .logoutDiv {
//     display: flex;
//     align-items: center;
//     gap: 10px;
//   }

//   .dashboard .right {
//     width: 100%;
//     background: #ffffff;
//     /* padding: 30px; */
//     /* padding-top: 50px; */
//     // height: 97vh;
//     height: 100%;
//     overflow-y: auto;
//   }

//   .right .questionaire-title {
//     width: 100%;
//   }

//   .questionaire-title .subheading {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     flex-direction: column;
//     align-items: center;
//   }

//   .subheading .swotReview {
//     color: #0D4D80;
//     text-align: center;
//     font-family: Arial;
//     font-size: 24px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: normal;
//   }

//   .subheading .andrew {
//     color: #8D8D8D;
//     font-family: Arial;
//     font-size: 18px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//   }
// }

// @media screen and (max-width: 800px) {
//   .dashboard {
//     display: flex;
//     flex-direction: column;
//   }

//   .dashboard .left {
//     z-index: 2;
//     width: 80%;
//     padding-left: 15px;
//     position: absolute;
//     top: 0;
//     left: 0;
//     transition: transform 0.3s ease-in-out;
//   }

//   .dashboard .headingBack {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }

//   .dashboard .menuDiv {
//     width: 100%;
//     display: flex;
//     justify-content: end;
//     padding: 10px 0;
//   }

//   .dashboard .left .backBtn-div {
//     display: block;
//     margin-right: 20px;
//   }

//   .dashboard .right {
//     width: 100%;
//     height: fit-content;
//     padding: 20px 0;
//     background: white;
//     overflow-y: auto;
//   }
// }

// .trans-100 {
//   transform: translateX(-800px);
//   transition: 0.1s ease-in-out;
// }

// .trans-0 {
//   transform: translateX(0px);
//   transition: 0.1s ease-in-out;
// }
// .dashboard {
//   width: 100%;
//   display: flex;

//   .menuDiv {
//     display: none;
//     height: 10%;
//     width: 10%;
//   }

//   .left {
//     width: 300px;

//     border-radius: 0px 10px 10px 0px;
//     background: var(--white);
//     box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
//     margin: 30px 0px 0px 0px;

//     margin-top: 110px;
//   }

//   .menuIcon {
//     width: 30px;
//     margin-block: 5px;
//   }
// }

// .sidebarPAdding {
//   padding: 19px 12px 0px 12px;

//   .subHeading {
//     padding: 10px 30px 10px 30px;
//     width: 100%;
//     height: 71px;
//     border-radius: 15px;
//     background: #f8f8f8;
//     margin-bottom: 8px;
//     font-family: "Poppins";
//     font-size: 18px;
//     font-style: normal;
//     font-weight: 500;
//     line-height: normal;
//     color: var(--c3c3c3);
//     cursor: pointer;
//   }
// }

// .subHeading span {
//   color: var(--c3c3c3);
//   font-family: "Poppins";
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: normal;
// }

// .tab.activetab {
//   cursor: pointer;
//   color: var(--white);
//   font-family: "Poppins";
//   font-size: 18px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: normal;

//   background: var(--side-bar, linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%));
//   border-radius: 15px;
//   transition: 0.3s ease-in-out;

//   span {
//     color: var(--white);
//     font-family: "Poppins";
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//   }
// }

// .dashboard {
//   .backBtn-div {
//     display: none;
//     padding-top: 5px;
//     padding-bottom: 5px;
//     align-items: center;
//     margin-top: 35px;
//     margin-left: 0px;
//     gap: 0px;
//   }

//   .right .rightHeading {
//     width: 100%;
//     text-align: center;
//   }
// }

// .right .questionaire-title {
//   width: 100%;
// }

// .questionaire-title .subheading {
//   display: flex;
//   justify-content: center;
// }

// .subheading .swotReview {
//   color: #0D4D80;
//   text-align: center;
//   font-family: Arial;
//   font-size: 24px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: normal;
// }

// .subheading .andrew {
//   color: #8D8D8D;
//   font-family: Arial;
//   font-size: 18px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: normal;
// }

// .dashboard .right {
//   width: calc(100% - 300px);
//   background: linear-gradient(180deg, #e4e4e4 0%, #ebebeb 100%);

//   height: auto;
//   overflow-y: auto;

//   margin-top: 110px;

// }

// .right {
//   width: 100%;
//   height: 6833px;
//   flex-shrink: 0;
//   background: linear-gradient(180deg, #e4e4e4 0%, #ebebeb 100%);
// }

// @media screen and (max-width: 1400px) {
//   .custom-accordion .accitem .accHide button {
//     font-size: 17.102px;
//   }
// }

// @media screen and (max-width: 1200px) {
//   .dashboard {
//     display: flex;
//     flex-direction: column;

//     .left {
//       z-index: 2;
//       width: 80%;
//       padding-left: 15px;
//       position: absolute;
//       top: 0;
//       left: 0;
//       transition: transform 0.3s ease-in-out;
//     }

//     .headingBack {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//     }

//     .menuDiv {
//       width: 100%;
//       display: flex;
//       justify-content: end;
//       padding: 10px 0;
//     }

//     .left .backBtn-div {
//       display: block;
//       margin-right: 20px;
//     }

//     .right {
//       width: 100%;
//       height: fit-content;
//       padding: 20px 0;
//       background: white;
//       overflow-y: auto;
//     }
//   }
// }

// .trans-100 {
//   transform: translateX(-1200px);
//   transition: 0.1s ease-in-out;
// }

// .trans-0 {
//   transform: translateX(0px);
//   transition: 0.1s ease-in-out;
// }
.dashboard-question {
  width: 100%;
  height: 100%;
  // max-height: 100vh;
  // border: 1px solid red;
  // display: flex;
  // justify-content: center;
  background-image: url("../../assets/images/bbbb.svg");
  // background-repeat: no-repeat;
  // background-size: cover;

  .menuDiv {
    display: none;
    height: 10%;
    width: 10%;
  }

  .general-content {
    width: 100%;
    padding: 84px 0px 38px 0px;
    text-align: center;

    h6 {
      color: #0d4d80;
      font-family: Arial;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    span {
      color: #8d8d8d;
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .left {
    width: 100%;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 0px 17px 0px;
    // border: 1px solid green;
  }

  .menuIcon {
    width: 30px;
    margin-block: 5px;
  }

  .sidebarPAdding {
    padding: 17px 12px 0px 12px;
    display: flex;
    gap: 16px;
    white-space: nowrap;
    overflow-x: auto;
  }

  .subHeading {
    padding: 10px 56px 10px 30px;
    width: 100%;
    height: auto !important;
    // height: 71px;
    border-radius: 15px;
    background: #edecec;
    margin-bottom: 8px;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #979393;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: var(--c3c3c3);
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .tab {
    &.activetab {
      cursor: pointer;
      color: var(--white);
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background: var(
        --side-bar,
        linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
      );
      border-radius: 15px;
      transition: 0.3s ease-in-out;

      span {
        color: var(--white);
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .right {
    width: 100%;
    height: 100%;
    // background: linear-gradient(180deg, #e4e4e4 0%, #ebebeb 100%);
    overflow-y: auto;

    .rightHeading {
      width: 100%;
      text-align: center;

      h5 {
        color: var(--0D4D80);
        font-family: Arial;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      span {
        color: #8d8d8d;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;

    .left {
      transition: transform 0.3s ease-in-out;
    }

    .headingBack {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .menuDiv {
      width: 100%;
      display: flex;
      justify-content: end;
      padding: 10px 0;
    }

    .backBtn-div {
      display: block;
      margin-right: 20px;
    }

    .right {
      width: 100%;
      height: fit-content;
      padding: 20px 0;
      overflow-y: auto;
    }
  }

  .trans-100 {
    transform: translateX(-1200px);
    transition: 0.1s ease-in-out;
  }

  .trans-0 {
    transform: translateX(0px);
    transition: 0.1s ease-in-out;
  }
}

.list-question {
  background: linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%);
}
