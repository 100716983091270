.result {
    width: 100%;
    background: linear-gradient(0deg, #0D4D80 0%, rgba(255, 255, 255), #0D4D80);

    .resultContent {
        width: 100%;
        height: 100vh;
        padding-top: 60px;


        // height: 100%;

        .mainContent {
            display: flex;
            flex-direction: column;
            gap: 30px;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            .title {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 30px;

                h5 {
                    color: #0D4D80;

                    font-family: Arial;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                h6 {
                    color: #0D4D80;

                    font-family: Arial;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .resultCard {
                width: 100%;
                display: flex;
                justify-content: center;
                gap: 30px;
                // padding: 0px 361px 0px 361px;

                .leftCard {
                    cursor: pointer;
                    text-align: center;
                    padding: 10px;
                    width: 316px;

                    border-radius: 10px;
                    background: linear-gradient(90deg, #9CCC65 0%, rgba(156, 204, 101, 0.74) 100%);
                    transition: box-shadow 0.3s ease;

                    /* Add a smooth transition effect */
                    &:hover {
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
                        /* Add the box shadow only at the bottom on hover */
                        /* Add the box shadow on hover */
                    }

                    .cardContent {
                        h6 {
                            color: #FFF;
                            text-align: center;
                            font-family: Arial;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            /* 150% */
                        }

                        span {
                            color: #FFF;
                            font-family: Arial;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                        }
                    }


                }

                .rightCard {
                    cursor: pointer;
                    text-align: center;
                    padding: 10px;
                    border-radius: 10px;
                    background: linear-gradient(93deg, #00A2D6 0%, rgba(0, 162, 214, 0.84) 100%);
                    transition: box-shadow 0.3s ease;

                    &:hover {
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
                        /* Add the box shadow only at the bottom on hover */
                        /* Add the box shadow on hover */
                    }

                    .cardContent {
                        h6 {
                            color: #FFF;
                            text-align: center;
                            font-family: Arial;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            /* 150% */
                        }

                        span {
                            color: #FFF;
                            font-family: Arial;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                        }
                    }

                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .result {
        width: 100%;
        background: linear-gradient(0deg, #0D4D80 0%, rgba(255, 255, 255), #0D4D80);

        .resultContent {
            width: 100%;
            height: 100vh;
            padding-top: 60px;


            // height: 100%;

            .mainContent {
                display: flex;
                flex-direction: column;
                gap: 30px;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                .title {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    width: 100%;

                    h5 {
                        color: #0D4D80;

                        font-family: Arial;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                    h6 {
                        color: #0D4D80;

                        font-family: Arial;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }

                .resultCard {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 10px;
                    padding: 10px 10px 10px 10px;


                    .leftCard {
                        cursor: pointer;
                        text-align: center;
                        padding: 10px;
                        width: 100%;

                        border-radius: 10px;
                        background: linear-gradient(90deg, #9CCC65 0%, rgba(156, 204, 101, 0.74) 100%);
                        transition: box-shadow 0.3s ease;

                        /* Add a smooth transition effect */
                        &:hover {
                            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
                            /* Add the box shadow only at the bottom on hover */
                            /* Add the box shadow on hover */
                        }

                        .cardContent {
                            h6 {
                                color: #FFF;
                                text-align: center;
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 30px;
                                /* 150% */
                            }

                            span {
                                color: #FFF;
                                font-family: Arial;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 30px;
                            }
                        }


                    }

                    .rightCard {
                        cursor: pointer;
                        text-align: center;
                        padding: 10px;
                        border-radius: 10px;
                        background: linear-gradient(93deg, #00A2D6 0%, rgba(0, 162, 214, 0.84) 100%);
                        transition: box-shadow 0.3s ease;

                        &:hover {
                            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
                            /* Add the box shadow only at the bottom on hover */
                            /* Add the box shadow on hover */
                        }

                        .cardContent {
                            h6 {
                                color: #FFF;
                                text-align: center;
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 30px;
                                /* 150% */
                            }

                            span {
                                color: #FFF;
                                font-family: Arial;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 30px;
                            }
                        }

                    }
                }
            }
        }
    }
}