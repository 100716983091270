.chatpage {
    width: 100%;

    .chartpageContent {
        width: 100%;
        padding-top: 60px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 200px;

        .topSection {
            padding-top: 54px;
            padding-bottom: 36px;

            .chartHeader {
                width: 100%;
                height: 100px;
                background: #FFF;
                box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                padding-left: 44px;

                span {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 10px;
                    }

                    color: #0A4D68;
                    font-family: Arial;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }

        .bottomSection {
            width: 100%;
            display: flex;
            gap: 56px;

            .leftSection {
                width: 40%;
                // height: 545px;
                background: #FFF;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);

                .img-div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // width: 100%;
                    // height: 100%;
                    // width: 100%;
                    // height: 100%;
                    // height: 545px;


                    .firstImg {

                        // width: 100%;
                        // height: 100%;
                        // object-fit: contain;


                    }
                }
            }

            .rightSection {
                width: 60%;

                .rightTop {
                    width: 100%;
                    // height: 394px;
                    background: #FFF;
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);

                    .imgDiv {
                        width: 100%;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%;

                        }
                    }
                }

                .right-bottom {
                    width: 100%;
                    display: flex;
                    gap: 31px;
                    padding-top: 10px;

                    .bottomLeft {
                        width: 50%;
                        // height: 141px;
                        background: #FFF;
                        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .chatpage {
        width: 100%;

        .chartpageContent {
            width: 100%;
            padding-top: 60px;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 200px;

            .topSection {
                padding-top: 30px;
                padding-bottom: 36px;

                .chartHeader {
                    width: 100%;
                    height: 100px;
                    background: #FFF;
                    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
                    display: flex;
                    align-items: center;
                    padding-left: 20px;

                    span {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 10px;
                        }

                        color: #0A4D68;
                        font-family: Arial;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
            }

            .bottomSection {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .leftSection {
                    width: 100%;
                    // height: 545px;
                    background: #FFF;
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);

                }

                .rightSection {
                    width: 100%;

                    .rightTop {
                        width: 100%;
                        // height: 394px;
                        background: #FFF;
                        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
                    }

                    .right-bottom {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        padding-top: 10px;

                        .bottomLeft {
                            width: 100%;
                            // height: 141px;
                            background: #FFF;
                            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
                        }
                    }

                }
            }
        }
    }
}