.ninetydplan {
  width: 100%;
  padding-top: 60px;
  background-image: url("../../assets/images/layerimg.png");

  .ninetyplanContent {
    width: 100%;

    .ninetyplan-title {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 55px;

      h6 {
        color: #0d4d80;

        font-family: Arial;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 128.214%;
        /* 33.336px */
      }

      span {
        color: #8d8d8d;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 128.214%;
      }
    }

    .planscard {
      padding-left: 56px;
      padding-right: 56px;
      padding-bottom: 30px;
      width: 100%;
      display: flex;
      gap: 36px;

      .leftCard {
        width: 100%;
        // height: 160px;
        border-radius: 10px;
        background: #f8f8f8;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

        .goalHeader {
          width: 295.187px;
          height: 58px;
          padding: 10px;
          border-radius: 10px;
          background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            color: #fff;
            font-family: Arial;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
          }
        }

        .sale-section {
          padding: 18px 29px 9px 29px;

          span {
            color: #000;
            text-align: center;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .goalFooter {
          height: 54px;
          padding: 21px 29px 12px 29px;

          border-top: 1px solid #e2e2e2;

          span {
            color: #000;

            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .rightCard {
        width: 100%;
        // height: 160px;
        border-radius: 10px;
        background: #f8f8f8;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

        .goalHeader {
          width: 295.187px;
          height: 58px;
          padding: 10px;
          border-radius: 10px;
          background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            color: #fff;
            font-family: Arial;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
          }
        }

        .sale-section {
          padding: 18px 29px 9px 29px;

          span {
            color: #000;
            text-align: center;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .goalFooter {
          height: 54px;
          padding: 21px 29px 12px 29px;

          border-top: 1px solid #e2e2e2;

          span {
            color: #000;

            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }

    .strategicpriorities {
      width: 100%;
      padding-left: 111px;
      padding-right: 111px;
      padding-bottom: 500px;

      .prioritiesContent {
        width: 100%;

        .prioritiesHeader {
          width: 100%;
          height: 58px;
          padding: 10px;
          border-radius: 10px;
          background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 36px;

          span {
            color: #fff;
            font-family: Arial;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
          }
        }

        .prioritiesBottom {
          width: 100%;
          display: flex;
          gap: 36px;

          .bottomLeft {
            width: 33.3%;
            // height: 571px;
            border-radius: 8px;
            background: #fff;
            box-shadow: 0px 0px 9.472px 0px rgba(0, 0, 0, 0.1);
            padding-bottom: 31px;

            .headerSection {
              width: 100%;
              height: 55px;
              padding: 15px 9.472px 0px 36px;
              border-radius: 8px 8px 0px 0px;
              background: #e4e4e4;

              span {
                color: #000;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }

            .headerSecond {
              // height: 58px;
              padding: 18px 10px 15px 36px;
              border-bottom: 0.5px solid #000;
              display: flex;
              gap: 20px;

              .goalone {
                color: #000;
                font-family: Arial;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
              }

              .goalprocess {
                color: #000;
                font-family: Arial;
                font-size: 17px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                // text-transform: lowercase;
              }
            }

            .prioritiesCard {
              .cardContent {
                padding: 9.472px 36px 9.472px 36px;
                display: flex;
                flex-direction: column;
                gap: 13px;

                .title {
                  width: 100%;
                  padding: 12px 43px 0px 12px;

                  span {
                    color: #000;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }

                .firstCard {
                  padding-bottom: 20px;
                  // height: 186px;
                  border-radius: 9.472px;
                  background: #fff;
                  box-shadow: 0px 0px 9.472px 0px rgba(0, 0, 0, 0.1);

                  .card-header {
                    display: flex;
                    justify-content: space-between;
                    padding: 12px 20px 0px 20px;

                    .meet {
                      color: #000;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }

                  .cardBottom {
                    padding: 0px 38px 0px 38px;

                    h6 {
                      color: #000;
                      font-family: Arial;
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                      padding-bottom: 8px;
                    }
                  }

                  .second-quarter {
                    padding: 0px 0px 0px 38px;
                    position: relative;

                    .inputbox {
                      display: flex;
                      justify-content: space-between;
                      cursor: pointer;
                      align-items: center;
                      width: 170.909px;
                      height: 36.623px;
                      border-radius: 4.883px;
                      background: #e4e4e4;
                      padding: 0px 8px 0px 8px;

                      .leftside {
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // Add your styling for the left side here
                      }

                      .rightside {
                        margin-left: 10px; // Adjust as needed

                        svg {
                          width: 10.104px;
                          height: 10.052px;
                          fill: none;
                          stroke: black;
                          stroke-width: 3;
                          stroke-linecap: round;
                        }
                      }
                    }

                    .dropdown {
                      display: flex;
                      flex-direction: column;
                      position: absolute;
                      bottom: -145px;
                      left: 38px;

                      width: 170px;
                      z-index: 1;
                      border-radius: 8px;
                      background: #e4e4e4;
                      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                      // top: 0px;

                      .firstdropdown {
                        padding: 10px;
                        cursor: pointer;
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // color: #333;
                        // font-family: "Montserrat";
                        // font-size: 16px;
                        // font-style: normal;
                        // font-weight: 400;
                        // line-height: normal;

                        &:hover {
                          color: #333;
                          font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;
                        }

                        span {
                          // Add your styling for the dropdown items here
                        }
                      }
                    }
                  }

                  .second-quarters {
                    padding: 0px 0px 0px 38px;
                    position: relative;

                    .inputbox {
                      display: flex;
                      justify-content: space-between;
                      cursor: pointer;
                      align-items: center;
                      width: 170.909px;
                      height: 36.623px;
                      border-radius: 4.883px;
                      background: #e4e4e4;
                      padding: 0px 8px 0px 8px;

                      .leftside {
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // Add your styling for the left side here
                      }

                      .rightside {
                        margin-left: 10px; // Adjust as needed

                        svg {
                          width: 10.104px;
                          height: 10.052px;
                          fill: none;
                          stroke: black;
                          stroke-width: 3;
                          stroke-linecap: round;
                        }
                      }
                    }

                    .dropdown {
                      display: flex;
                      flex-direction: column;
                      position: absolute;
                      bottom: -145px;
                      left: 39px;

                      width: 170px;
                      z-index: 1;
                      border-radius: 8px;
                      background: #e4e4e4;
                      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                      // top: 0px;

                      .firstdropdown {
                        padding: 10px;
                        cursor: pointer;
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // color: #333;
                        // font-family: "Montserrat";
                        // font-size: 16px;
                        // font-style: normal;
                        // font-weight: 400;
                        // line-height: normal;

                        &:hover {
                          color: #333;
                          font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;
                        }

                        span {
                          // Add your styling for the dropdown items here
                        }
                      }
                    }
                  }

                  .second-quartersz {
                    padding: 0px 0px 0px 38px;
                    position: relative;

                    .inputbox {
                      display: flex;
                      justify-content: space-between;
                      cursor: pointer;
                      align-items: center;
                      width: 170.909px;
                      height: 36.623px;
                      border-radius: 4.883px;
                      background: #e4e4e4;
                      padding: 0px 8px 0px 8px;

                      .leftside {
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // Add your styling for the left side here
                      }

                      .rightside {
                        margin-left: 10px; // Adjust as needed

                        svg {
                          width: 10.104px;
                          height: 10.052px;
                          fill: none;
                          stroke: black;
                          stroke-width: 3;
                          stroke-linecap: round;
                        }
                      }
                    }

                    .dropdown {
                      display: flex;
                      flex-direction: column;
                      position: absolute;
                      bottom: -145px;
                      left: 39px;

                      width: 170px;
                      z-index: 1;
                      border-radius: 8px;
                      background: #e4e4e4;
                      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                      // top: 0px;

                      .firstdropdown {
                        padding: 10px;
                        cursor: pointer;
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // color: #333;
                        // font-family: "Montserrat";
                        // font-size: 16px;
                        // font-style: normal;
                        // font-weight: 400;
                        // line-height: normal;

                        &:hover {
                          color: #333;
                          font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;
                        }

                        span {
                          // Add your styling for the dropdown items here
                        }
                      }
                    }
                  }

                  .second-quarterss {
                    padding: 0px 0px 0px 38px;
                    position: relative;

                    .inputbox {
                      display: flex;
                      justify-content: space-between;
                      cursor: pointer;
                      align-items: center;
                      width: 170.909px;
                      height: 36.623px;
                      border-radius: 4.883px;
                      background: #e4e4e4;
                      padding: 0px 8px 0px 8px;

                      .leftside {
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // Add your styling for the left side here
                      }

                      .rightside {
                        margin-left: 10px; // Adjust as needed

                        svg {
                          width: 10.104px;
                          height: 10.052px;
                          fill: none;
                          stroke: black;
                          stroke-width: 3;
                          stroke-linecap: round;
                        }
                      }
                    }

                    .dropdown {
                      display: flex;
                      flex-direction: column;
                      position: absolute;
                      bottom: -143px;
                      left: 38px;

                      width: 170px;
                      z-index: 1;
                      border-radius: 8px;
                      background: #e4e4e4;
                      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                      // top: 0px;

                      .firstdropdown {
                        padding: 10px;
                        cursor: pointer;
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // color: #333;
                        // font-family: "Montserrat";
                        // font-size: 16px;
                        // font-style: normal;
                        // font-weight: 400;
                        // line-height: normal;

                        &:hover {
                          color: #333;
                          font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;
                        }

                        span {
                          // Add your styling for the dropdown items here
                        }
                      }
                    }
                  }

                  .second-quartersss {
                    padding: 0px 0px 0px 38px;
                    position: relative;

                    .inputbox {
                      display: flex;
                      justify-content: space-between;
                      cursor: pointer;
                      align-items: center;
                      width: 170.909px;
                      height: 36.623px;
                      border-radius: 4.883px;
                      background: #e4e4e4;
                      padding: 0px 8px 0px 8px;

                      .leftside {
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // Add your styling for the left side here
                      }

                      .rightside {
                        margin-left: 10px; // Adjust as needed

                        svg {
                          width: 10.104px;
                          height: 10.052px;
                          fill: none;
                          stroke: black;
                          stroke-width: 3;
                          stroke-linecap: round;
                        }
                      }
                    }

                    .dropdown {
                      display: flex;
                      flex-direction: column;
                      position: absolute;
                      bottom: -145px;
                      left: 39px;
                      // left: 370px;

                      width: 170px;
                      z-index: 1;
                      border-radius: 8px;
                      background: #e4e4e4;
                      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                      // top: 0px;

                      .firstdropdown {
                        padding: 10px;
                        cursor: pointer;
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // color: #333;
                        // font-family: "Montserrat";
                        // font-size: 16px;
                        // font-style: normal;
                        // font-weight: 400;
                        // line-height: normal;

                        &:hover {
                          color: #333;
                          font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;
                        }

                        span {
                          // Add your styling for the dropdown items here
                        }
                      }
                    }
                  }

                  .second-quartrs {
                    padding: 0px 0px 0px 38px;

                    .inputbox {
                      display: flex;
                      justify-content: space-between;
                      cursor: pointer;
                      align-items: center;
                      width: 170.909px;
                      height: 36.623px;
                      border-radius: 4.883px;
                      background: #e4e4e4;
                      padding: 0px 8px 0px 8px;

                      .leftside {
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // Add your styling for the left side here
                      }

                      .rightside {
                        margin-left: 10px; // Adjust as needed

                        svg {
                          width: 10.104px;
                          height: 10.052px;
                          fill: none;
                          stroke: black;
                          stroke-width: 3;
                          stroke-linecap: round;
                        }
                      }
                    }

                    .dropdown {
                      display: flex;
                      flex-direction: column;
                      position: absolute;
                      bottom: -190px;
                      left: 760px;
                      // left: 370px;

                      width: 170px;
                      z-index: 1;
                      border-radius: 8px;
                      background: #e4e4e4;
                      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                      // top: 0px;

                      .firstdropdown {
                        padding: 10px;
                        cursor: pointer;
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // color: #333;
                        // font-family: "Montserrat";
                        // font-size: 16px;
                        // font-style: normal;
                        // font-weight: 400;
                        // line-height: normal;

                        &:hover {
                          color: #333;
                          font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;
                        }

                        span {
                          // Add your styling for the dropdown items here
                        }
                      }
                    }
                  }

                  .second-quarterend {
                    padding: 0px 0px 0px 38px;
                    position: relative;

                    .inputbox {
                      display: flex;
                      justify-content: space-between;
                      cursor: pointer;
                      align-items: center;
                      width: 170.909px;
                      height: 36.623px;
                      border-radius: 4.883px;
                      background: #e4e4e4;
                      padding: 0px 8px 0px 8px;

                      .leftside {
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // Add your styling for the left side here
                      }

                      .rightside {
                        margin-left: 10px; // Adjust as needed

                        svg {
                          width: 10.104px;
                          height: 10.052px;
                          fill: none;
                          stroke: black;
                          stroke-width: 3;
                          stroke-linecap: round;
                        }
                      }
                    }

                    .dropdown {
                      display: flex;
                      flex-direction: column;
                      position: absolute;
                      bottom: -145px;
                      left: 39px;

                      width: 170px;
                      z-index: 1;
                      border-radius: 8px;
                      background: #e4e4e4;
                      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                      // top: 0px;

                      .firstdropdown {
                        padding: 10px;
                        cursor: pointer;
                        color: #666;
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        // color: #333;
                        // font-family: "Montserrat";
                        // font-size: 16px;
                        // font-style: normal;
                        // font-weight: 400;
                        // line-height: normal;

                        &:hover {
                          color: #333;
                          font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;
                        }

                        span {
                          // Add your styling for the dropdown items here
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .ninetydplan {
    width: 100%;
    padding-top: 60px;
    background-image: url("../../assets/images/layerimg.png");

    .ninetyplanContent {
      width: 100%;

      .ninetyplan-title {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 55px;

        h6 {
          color: #0d4d80;

          font-family: Arial;
          font-size: 26px;
          font-style: normal;
          font-weight: 700;
          line-height: 128.214%;
          /* 33.336px */
        }

        span {
          color: #8d8d8d;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 128.214%;
        }
      }

      .planscard {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
        width: 100%;
        display: flex;
        gap: 36px;

        .leftCard {
          width: 100%;
          // height: 160px;
          border-radius: 10px;
          background: #f8f8f8;
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

          .goalHeader {
            width: 295.187px;
            height: 58px;
            padding: 10px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: #fff;
              font-family: Arial;
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .sale-section {
            padding: 18px 29px 9px 29px;

            span {
              color: #000;
              text-align: center;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .goalFooter {
            height: 54px;
            padding: 21px 29px 12px 29px;

            border-top: 1px solid #e2e2e2;

            span {
              color: #000;

              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .rightCard {
          width: 100%;
          // height: 160px;
          border-radius: 10px;
          background: #f8f8f8;
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

          .goalHeader {
            width: 295.187px;
            height: 58px;
            padding: 10px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: #fff;
              font-family: Arial;
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .sale-section {
            padding: 18px 29px 9px 29px;

            span {
              color: #000;
              text-align: center;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .goalFooter {
            height: 54px;
            padding: 21px 29px 12px 29px;

            border-top: 1px solid #e2e2e2;

            span {
              color: #000;

              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }

      .strategicpriorities {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 500px;

        // .prioritiesContent {
        //     width: 100%;

        //     .prioritiesHeader {
        //         width: 100%;
        //         height: 58px;
        //         padding: 10px;
        //         border-radius: 10px;
        //         background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         margin-bottom: 36px;

        //         span {
        //             color: #FFF;
        //             font-family: Arial;
        //             font-size: 22px;
        //             font-style: normal;
        //             font-weight: 700;
        //             line-height: normal;
        //             text-transform: capitalize;
        //         }
        //     }

        //     .prioritiesBottom {
        //         width: 100%;
        //         display: flex;
        //         gap: 36px;

        //         .bottomLeft {
        //             width: 33.3%;
        //             // height: 571px;
        //             border-radius: 8px;
        //             background: #FFF;
        //             box-shadow: 0px 0px 9.472px 0px rgba(0, 0, 0, 0.10);
        //             padding-bottom: 31px;

        //             .headerSection {
        //                 width: 100%;
        //                 height: 55px;
        //                 padding: 15px 9.472px 0px 36px;
        //                 border-radius: 8px 8px 0px 0px;
        //                 background: #E4E4E4;

        //                 span {
        //                     color: #000;
        //                     font-family: Arial;
        //                     font-size: 18px;
        //                     font-style: normal;
        //                     font-weight: 700;
        //                     line-height: normal;
        //                 }
        //             }

        //             .headerSecond {
        //                 // height: 58px;
        //                 padding: 18px 10px 15px 36px;
        //                 border-bottom: 0.5px solid #000;
        //                 display: flex;
        //                 gap: 20px;

        //                 .goalone {
        //                     color: #000;
        //                     font-family: Arial;
        //                     font-size: 17px;
        //                     font-style: normal;
        //                     font-weight: 700;
        //                     line-height: normal;
        //                     text-transform: capitalize;
        //                 }

        //                 .goalprocess {
        //                     color: #000;
        //                     font-family: Arial;
        //                     font-size: 17px;
        //                     font-style: normal;
        //                     font-weight: 400;
        //                     line-height: normal;
        //                     // text-transform: lowercase;
        //                 }
        //             }

        //             .prioritiesCard {
        //                 .cardContent {
        //                     padding: 9.472px 36px 9.472px 36px;
        //                     display: flex;
        //                     flex-direction: column;
        //                     gap: 13px;

        //                     .title {
        //                         width: 100%;
        //                         padding: 12px 43px 0px 12px;

        //                         span {
        //                             color: #000;
        //                             font-family: Arial;
        //                             font-size: 16px;
        //                             font-style: normal;
        //                             font-weight: 700;
        //                             line-height: normal;
        //                             text-transform: capitalize;
        //                         }
        //                     }

        //                     .firstCard {
        //                         padding-bottom: 20px;
        //                         // height: 186px;
        //                         border-radius: 9.472px;
        //                         background: #FFF;
        //                         box-shadow: 0px 0px 9.472px 0px rgba(0, 0, 0, 0.10);

        //                         .card-header {
        //                             display: flex;
        //                             justify-content: space-between;
        //                             padding: 12px 20px 0px 20px;

        //                             .meet {
        //                                 color: #000;
        //                                 font-family: Arial;
        //                                 font-size: 16px;
        //                                 font-style: normal;
        //                                 font-weight: 400;
        //                                 line-height: normal;
        //                                 text-transform: capitalize;
        //                             }
        //                         }

        //                         .cardBottom {
        //                             padding: 0px 38px 0px 38px;

        //                             h6 {
        //                                 color: #000;
        //                                 font-family: Arial;
        //                                 font-size: 13px;
        //                                 font-style: normal;
        //                                 font-weight: 400;
        //                                 line-height: normal;
        //                                 text-transform: capitalize;
        //                                 padding-bottom: 8px;
        //                             }
        //                         }

        //                         .second-quarter {
        //                             padding: 0px 0px 0px 38px;
        //                             position: relative;

        //                             .inputbox {
        //                                 display: flex;
        //                                 justify-content: space-between;
        //                                 cursor: pointer;
        //                                 align-items: center;
        //                                 width: 170.909px;
        //                                 height: 36.623px;
        //                                 border-radius: 4.883px;
        //                                 background: #E4E4E4;
        //                                 padding: 0px 8px 0px 8px;

        //                                 .leftside {
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // Add your styling for the left side here
        //                                 }

        //                                 .rightside {
        //                                     margin-left: 10px; // Adjust as needed

        //                                     svg {
        //                                         width: 10.104px;
        //                                         height: 10.052px;
        //                                         fill: none;
        //                                         stroke: black;
        //                                         stroke-width: 3;
        //                                         stroke-linecap: round;
        //                                     }
        //                                 }
        //                             }

        //                             .dropdown {
        //                                 display: flex;
        //                                 flex-direction: column;
        //                                 position: absolute;
        //                                 bottom: -145px;
        //                                 left: 38px;

        //                                 width: 170px;
        //                                 z-index: 1;
        //                                 border-radius: 8px;
        //                                 background: #E4E4E4;
        //                                 box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
        //                                 // top: 0px;

        //                                 .firstdropdown {
        //                                     padding: 10px;
        //                                     cursor: pointer;
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // color: #333;
        //                                     // font-family: "Montserrat";
        //                                     // font-size: 16px;
        //                                     // font-style: normal;
        //                                     // font-weight: 400;
        //                                     // line-height: normal;

        //                                     &:hover {
        //                                         color: #333;
        //                                         font-family: "Montserrat";
        //                                         // font-size: 16px;
        //                                         // font-style: normal;
        //                                         // font-weight: 400;
        //                                         // line-height: normal;
        //                                     }

        //                                     span {

        //                                         // Add your styling for the dropdown items here
        //                                     }
        //                                 }
        //                             }
        //                         }

        //                         .second-quarters {
        //                             padding: 0px 0px 0px 38px;
        //                             position: relative;

        //                             .inputbox {
        //                                 display: flex;
        //                                 justify-content: space-between;
        //                                 cursor: pointer;
        //                                 align-items: center;
        //                                 width: 170.909px;
        //                                 height: 36.623px;
        //                                 border-radius: 4.883px;
        //                                 background: #E4E4E4;
        //                                 padding: 0px 8px 0px 8px;

        //                                 .leftside {
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // Add your styling for the left side here
        //                                 }

        //                                 .rightside {
        //                                     margin-left: 10px; // Adjust as needed

        //                                     svg {
        //                                         width: 10.104px;
        //                                         height: 10.052px;
        //                                         fill: none;
        //                                         stroke: black;
        //                                         stroke-width: 3;
        //                                         stroke-linecap: round;
        //                                     }
        //                                 }
        //                             }

        //                             .dropdown {
        //                                 display: flex;
        //                                 flex-direction: column;
        //                                 position: absolute;
        //                                 bottom: -145px;
        //                                 left: 39px;

        //                                 width: 170px;
        //                                 z-index: 1;
        //                                 border-radius: 8px;
        //                                 background: #E4E4E4;
        //                                 box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
        //                                 // top: 0px;

        //                                 .firstdropdown {
        //                                     padding: 10px;
        //                                     cursor: pointer;
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // color: #333;
        //                                     // font-family: "Montserrat";
        //                                     // font-size: 16px;
        //                                     // font-style: normal;
        //                                     // font-weight: 400;
        //                                     // line-height: normal;

        //                                     &:hover {
        //                                         color: #333;
        //                                         font-family: "Montserrat";
        //                                         // font-size: 16px;
        //                                         // font-style: normal;
        //                                         // font-weight: 400;
        //                                         // line-height: normal;
        //                                     }

        //                                     span {

        //                                         // Add your styling for the dropdown items here
        //                                     }
        //                                 }
        //                             }
        //                         }

        //                         .second-quartersz {
        //                             padding: 0px 0px 0px 38px;
        //                             position: relative;

        //                             .inputbox {
        //                                 display: flex;
        //                                 justify-content: space-between;
        //                                 cursor: pointer;
        //                                 align-items: center;
        //                                 width: 170.909px;
        //                                 height: 36.623px;
        //                                 border-radius: 4.883px;
        //                                 background: #E4E4E4;
        //                                 padding: 0px 8px 0px 8px;

        //                                 .leftside {
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // Add your styling for the left side here
        //                                 }

        //                                 .rightside {
        //                                     margin-left: 10px; // Adjust as needed

        //                                     svg {
        //                                         width: 10.104px;
        //                                         height: 10.052px;
        //                                         fill: none;
        //                                         stroke: black;
        //                                         stroke-width: 3;
        //                                         stroke-linecap: round;
        //                                     }
        //                                 }
        //                             }

        //                             .dropdown {
        //                                 display: flex;
        //                                 flex-direction: column;
        //                                 position: absolute;
        //                                 bottom: -145px;
        //                                 left: 39px;

        //                                 width: 170px;
        //                                 z-index: 1;
        //                                 border-radius: 8px;
        //                                 background: #E4E4E4;
        //                                 box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
        //                                 // top: 0px;

        //                                 .firstdropdown {
        //                                     padding: 10px;
        //                                     cursor: pointer;
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // color: #333;
        //                                     // font-family: "Montserrat";
        //                                     // font-size: 16px;
        //                                     // font-style: normal;
        //                                     // font-weight: 400;
        //                                     // line-height: normal;

        //                                     &:hover {
        //                                         color: #333;
        //                                         font-family: "Montserrat";
        //                                         // font-size: 16px;
        //                                         // font-style: normal;
        //                                         // font-weight: 400;
        //                                         // line-height: normal;
        //                                     }

        //                                     span {

        //                                         // Add your styling for the dropdown items here
        //                                     }
        //                                 }
        //                             }
        //                         }

        //                         .second-quarterss {
        //                             padding: 0px 0px 0px 38px;
        //                             position: relative;

        //                             .inputbox {
        //                                 display: flex;
        //                                 justify-content: space-between;
        //                                 cursor: pointer;
        //                                 align-items: center;
        //                                 width: 170.909px;
        //                                 height: 36.623px;
        //                                 border-radius: 4.883px;
        //                                 background: #E4E4E4;
        //                                 padding: 0px 8px 0px 8px;

        //                                 .leftside {
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // Add your styling for the left side here
        //                                 }

        //                                 .rightside {
        //                                     margin-left: 10px; // Adjust as needed

        //                                     svg {
        //                                         width: 10.104px;
        //                                         height: 10.052px;
        //                                         fill: none;
        //                                         stroke: black;
        //                                         stroke-width: 3;
        //                                         stroke-linecap: round;
        //                                     }
        //                                 }
        //                             }

        //                             .dropdown {
        //                                 display: flex;
        //                                 flex-direction: column;
        //                                 position: absolute;
        //                                 bottom: -143px;
        //                                 left: 38px;

        //                                 width: 170px;
        //                                 z-index: 1;
        //                                 border-radius: 8px;
        //                                 background: #E4E4E4;
        //                                 box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
        //                                 // top: 0px;

        //                                 .firstdropdown {
        //                                     padding: 10px;
        //                                     cursor: pointer;
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // color: #333;
        //                                     // font-family: "Montserrat";
        //                                     // font-size: 16px;
        //                                     // font-style: normal;
        //                                     // font-weight: 400;
        //                                     // line-height: normal;

        //                                     &:hover {
        //                                         color: #333;
        //                                         font-family: "Montserrat";
        //                                         // font-size: 16px;
        //                                         // font-style: normal;
        //                                         // font-weight: 400;
        //                                         // line-height: normal;
        //                                     }

        //                                     span {

        //                                         // Add your styling for the dropdown items here
        //                                     }
        //                                 }
        //                             }
        //                         }

        //                         .second-quartersss {
        //                             padding: 0px 0px 0px 38px;
        //                             position: relative;

        //                             .inputbox {
        //                                 display: flex;
        //                                 justify-content: space-between;
        //                                 cursor: pointer;
        //                                 align-items: center;
        //                                 width: 170.909px;
        //                                 height: 36.623px;
        //                                 border-radius: 4.883px;
        //                                 background: #E4E4E4;
        //                                 padding: 0px 8px 0px 8px;

        //                                 .leftside {
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // Add your styling for the left side here
        //                                 }

        //                                 .rightside {
        //                                     margin-left: 10px; // Adjust as needed

        //                                     svg {
        //                                         width: 10.104px;
        //                                         height: 10.052px;
        //                                         fill: none;
        //                                         stroke: black;
        //                                         stroke-width: 3;
        //                                         stroke-linecap: round;
        //                                     }
        //                                 }
        //                             }

        //                             .dropdown {
        //                                 display: flex;
        //                                 flex-direction: column;
        //                                 position: absolute;
        //                                 bottom: -145px;
        //                                 left: 39px;
        //                                 // left: 370px;

        //                                 width: 170px;
        //                                 z-index: 1;
        //                                 border-radius: 8px;
        //                                 background: #E4E4E4;
        //                                 box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
        //                                 // top: 0px;

        //                                 .firstdropdown {
        //                                     padding: 10px;
        //                                     cursor: pointer;
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // color: #333;
        //                                     // font-family: "Montserrat";
        //                                     // font-size: 16px;
        //                                     // font-style: normal;
        //                                     // font-weight: 400;
        //                                     // line-height: normal;

        //                                     &:hover {
        //                                         color: #333;
        //                                         font-family: "Montserrat";
        //                                         // font-size: 16px;
        //                                         // font-style: normal;
        //                                         // font-weight: 400;
        //                                         // line-height: normal;
        //                                     }

        //                                     span {

        //                                         // Add your styling for the dropdown items here
        //                                     }
        //                                 }
        //                             }
        //                         }

        //                         .second-quartrs {
        //                             padding: 0px 0px 0px 38px;

        //                             .inputbox {
        //                                 display: flex;
        //                                 justify-content: space-between;
        //                                 cursor: pointer;
        //                                 align-items: center;
        //                                 width: 170.909px;
        //                                 height: 36.623px;
        //                                 border-radius: 4.883px;
        //                                 background: #E4E4E4;
        //                                 padding: 0px 8px 0px 8px;

        //                                 .leftside {
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // Add your styling for the left side here
        //                                 }

        //                                 .rightside {
        //                                     margin-left: 10px; // Adjust as needed

        //                                     svg {
        //                                         width: 10.104px;
        //                                         height: 10.052px;
        //                                         fill: none;
        //                                         stroke: black;
        //                                         stroke-width: 3;
        //                                         stroke-linecap: round;
        //                                     }
        //                                 }
        //                             }

        //                             .dropdown {
        //                                 display: flex;
        //                                 flex-direction: column;
        //                                 position: absolute;
        //                                 bottom: -190px;
        //                                 left: 760px;
        //                                 // left: 370px;

        //                                 width: 170px;
        //                                 z-index: 1;
        //                                 border-radius: 8px;
        //                                 background: #E4E4E4;
        //                                 box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
        //                                 // top: 0px;

        //                                 .firstdropdown {
        //                                     padding: 10px;
        //                                     cursor: pointer;
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // color: #333;
        //                                     // font-family: "Montserrat";
        //                                     // font-size: 16px;
        //                                     // font-style: normal;
        //                                     // font-weight: 400;
        //                                     // line-height: normal;

        //                                     &:hover {
        //                                         color: #333;
        //                                         font-family: "Montserrat";
        //                                         // font-size: 16px;
        //                                         // font-style: normal;
        //                                         // font-weight: 400;
        //                                         // line-height: normal;
        //                                     }

        //                                     span {

        //                                         // Add your styling for the dropdown items here
        //                                     }
        //                                 }
        //                             }
        //                         }

        //                         .second-quarterend {
        //                             padding: 0px 0px 0px 38px;
        //                             position: relative;

        //                             .inputbox {
        //                                 display: flex;
        //                                 justify-content: space-between;
        //                                 cursor: pointer;
        //                                 align-items: center;
        //                                 width: 170.909px;
        //                                 height: 36.623px;
        //                                 border-radius: 4.883px;
        //                                 background: #E4E4E4;
        //                                 padding: 0px 8px 0px 8px;

        //                                 .leftside {
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // Add your styling for the left side here
        //                                 }

        //                                 .rightside {
        //                                     margin-left: 10px; // Adjust as needed

        //                                     svg {
        //                                         width: 10.104px;
        //                                         height: 10.052px;
        //                                         fill: none;
        //                                         stroke: black;
        //                                         stroke-width: 3;
        //                                         stroke-linecap: round;
        //                                     }
        //                                 }
        //                             }

        //                             .dropdown {
        //                                 display: flex;
        //                                 flex-direction: column;
        //                                 position: absolute;
        //                                 bottom: -145px;
        //                                 left: 39px;

        //                                 width: 170px;
        //                                 z-index: 1;
        //                                 border-radius: 8px;
        //                                 background: #E4E4E4;
        //                                 box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
        //                                 // top: 0px;

        //                                 .firstdropdown {
        //                                     padding: 10px;
        //                                     cursor: pointer;
        //                                     color: #666;
        //                                     font-family: "Montserrat";
        //                                     font-size: 12px;
        //                                     font-style: normal;
        //                                     font-weight: 400;
        //                                     line-height: normal;
        //                                     // color: #333;
        //                                     // font-family: "Montserrat";
        //                                     // font-size: 16px;
        //                                     // font-style: normal;
        //                                     // font-weight: 400;
        //                                     // line-height: normal;

        //                                     &:hover {
        //                                         color: #333;
        //                                         font-family: "Montserrat";
        //                                         // font-size: 16px;
        //                                         // font-style: normal;
        //                                         // font-weight: 400;
        //                                         // line-height: normal;
        //                                     }

        //                                     span {

        //                                         // Add your styling for the dropdown items here
        //                                     }
        //                                 }
        //                             }
        //                         }

        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .ninetydplan {
    width: 100%;
    padding-top: 60px;
    background-image: url("../../assets/images/layerimg.png");

    .ninetyplanContent {
      width: 100%;

      .ninetyplan-title {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 55px;

        h6 {
          color: #0d4d80;

          font-family: Arial;
          font-size: 26px;
          font-style: normal;
          font-weight: 700;
          line-height: 128.214%;
          /* 33.336px */
        }

        span {
          color: #8d8d8d;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 128.214%;
        }
      }

      .planscard {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
        width: 100%;
        display: flex;
        gap: 10px;

        .leftCard {
          width: 100%;
          // height: 160px;
          border-radius: 10px;
          background: #f8f8f8;
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

          .goalHeader {
            width: 295.187px;
            height: 58px;
            padding: 10px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: #fff;
              font-family: Arial;
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .sale-section {
            padding: 18px 29px 9px 29px;

            span {
              color: #000;
              text-align: center;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .goalFooter {
            height: 54px;
            padding: 21px 29px 12px 29px;

            border-top: 1px solid #e2e2e2;

            span {
              color: #000;

              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .rightCard {
          width: 100%;
          // height: 160px;
          border-radius: 10px;
          background: #f8f8f8;
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

          .goalHeader {
            width: 295.187px;
            height: 58px;
            padding: 10px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: #fff;
              font-family: Arial;
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .sale-section {
            padding: 18px 29px 9px 29px;

            span {
              color: #000;
              text-align: center;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .goalFooter {
            height: 54px;
            padding: 21px 29px 12px 29px;

            border-top: 1px solid #e2e2e2;

            span {
              color: #000;

              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }

      .strategicpriorities {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 500px;

        .prioritiesContent {
          width: 100%;

          .prioritiesHeader {
            width: 100%;
            height: 58px;
            padding: 10px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 36px;

            span {
              color: #fff;
              font-family: Arial;
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .prioritiesBottom {
            width: 100%;
            display: flex;
            gap: 10px;

            .bottomLeft {
              width: 33.3%;
              // height: 571px;
              border-radius: 8px;
              background: #fff;
              box-shadow: 0px 0px 9.472px 0px rgba(0, 0, 0, 0.1);
              padding-bottom: 31px;

              .headerSection {
                width: 100%;
                height: auto;
                // height: 75px;
                padding: 15px 9.472px 15px 36px;
                border-radius: 8px 8px 0px 0px;
                background: #e4e4e4;

                span {
                  color: #000;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                }
              }

              .headerSecond {
                // height: 58px;
                padding: 18px 10px 15px 36px;
                border-bottom: 0.5px solid #000;
                display: flex;
                gap: 20px;

                .goalone {
                  color: #000;
                  font-family: Arial;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  text-transform: capitalize;
                }

                .goalprocess {
                  color: #000;
                  font-family: Arial;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  // text-transform: lowercase;
                }
              }

              .prioritiesCard {
                .cardContent {
                  padding: 9.472px 36px 9.472px 36px;
                  display: flex;
                  flex-direction: column;
                  gap: 13px;

                  .title {
                    width: 100%;
                    padding: 12px 43px 0px 12px;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }

                  .firstCard {
                    padding-bottom: 20px;
                    // height: 186px;
                    border-radius: 9.472px;
                    background: #fff;
                    box-shadow: 0px 0px 9.472px 0px rgba(0, 0, 0, 0.1);

                    .card-header {
                      display: flex;
                      justify-content: space-between;
                      padding: 12px 20px 0px 20px;

                      .meet {
                        color: #000;
                        font-family: Arial;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                      }
                    }

                    .cardBottom {
                      padding: 0px 38px 0px 38px;

                      h6 {
                        color: #000;
                        font-family: Arial;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                        padding-bottom: 8px;
                      }
                    }

                    .second-quarter {
                      padding: 0px 0px 0px 38px;
                      position: relative;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -145px;
                        left: 38px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }

                    .second-quarters {
                      padding: 0px 0px 0px 38px;
                      position: relative;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -145px;
                        left: 39px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                            // font-size: 16px;
                            // font-style: normal;
                            // font-weight: 400;
                            // line-height: normal;
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }

                    .second-quartersz {
                      padding: 0px 0px 0px 38px;
                      position: relative;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -145px;
                        left: 39px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                            // font-size: 16px;
                            // font-style: normal;
                            // font-weight: 400;
                            // line-height: normal;
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }

                    .second-quarterss {
                      padding: 0px 0px 0px 38px;
                      position: relative;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -143px;
                        left: 38px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                            // font-size: 16px;
                            // font-style: normal;
                            // font-weight: 400;
                            // line-height: normal;
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }

                    .second-quartersss {
                      padding: 0px 0px 0px 38px;
                      position: relative;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -145px;
                        left: 39px;
                        // left: 370px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                            // font-size: 16px;
                            // font-style: normal;
                            // font-weight: 400;
                            // line-height: normal;
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }

                    .second-quartrs {
                      padding: 0px 0px 0px 38px;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -190px;
                        left: 760px;
                        // left: 370px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                            // font-size: 16px;
                            // font-style: normal;
                            // font-weight: 400;
                            // line-height: normal;
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }

                    .second-quarterend {
                      padding: 0px 0px 0px 38px;
                      position: relative;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -145px;
                        left: 39px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                            // font-size: 16px;
                            // font-style: normal;
                            // font-weight: 400;
                            // line-height: normal;
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .ninetydplan {
    width: 100%;
    padding-top: 60px;
    background-image: url("../../assets/images/layerimg.png");

    .ninetyplanContent {
      width: 100%;

      .ninetyplan-title {
        text-align: center;
        padding-top: 28px;
        padding-bottom: 28px;

        h6 {
          color: #0d4d80;

          font-family: Arial;
          font-size: 23px;
          font-style: normal;
          font-weight: 700;
          line-height: 128.214%;
          /* 33.336px */
        }

        span {
          color: #8d8d8d;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 128.214%;
        }
      }

      .planscard {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 36px;

        .leftCard {
          width: 100%;
          // height: 160px;
          border-radius: 10px;
          background: #f8f8f8;
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

          .goalHeader {
            width: 100%;
            // width: 295.187px;
            height: 58px;
            padding: 10px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: #fff;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .sale-section {
            padding: 18px 29px 9px 29px;

            span {
              color: #000;
              text-align: center;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .goalFooter {
            height: 54px;
            padding: 21px 29px 12px 29px;

            border-top: 1px solid #e2e2e2;

            span {
              color: #000;

              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .rightCard {
          width: 100%;
          // height: 160px;
          border-radius: 10px;
          background: #f8f8f8;
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

          .goalHeader {
            width: 100%;
            // width: 295.187px;
            height: 58px;
            padding: 10px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: #fff;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .sale-section {
            padding: 18px 29px 9px 29px;

            span {
              color: #000;
              text-align: center;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .goalFooter {
            height: 100%;
            padding: 21px 29px 12px 29px;

            border-top: 1px solid #e2e2e2;

            span {
              color: #000;

              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }

      .strategicpriorities {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 500px;

        .prioritiesContent {
          width: 100%;

          .prioritiesHeader {
            width: 100%;
            height: 58px;
            padding: 10px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 36px;

            span {
              color: #fff;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .prioritiesBottom {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .bottomLeft {
              width: 100%;
              // height: 571px;
              border-radius: 8px;
              background: #fff;
              box-shadow: 0px 0px 9.472px 0px rgba(0, 0, 0, 0.1);
              padding-bottom: 31px;

              .headerSection {
                width: 100%;
                height: auto;
                // height: 75px;
                padding: 15px 9.472px 15px 15px;
                border-radius: 8px 8px 0px 0px;
                background: #e4e4e4;

                span {
                  color: #000;
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                }
              }

              .headerSecond {
                // height: 58px;
                padding: 18px 10px 15px 15px;
                border-bottom: 0.5px solid #000;
                display: flex;
                gap: 20px;

                .goalone {
                  color: #000;
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  text-transform: capitalize;
                }

                .goalprocess {
                  color: #000;
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  // text-transform: lowercase;
                }
              }

              .prioritiesCard {
                .cardContent {
                  padding: 9.472px 15px 9.472px 15px;
                  display: flex;
                  flex-direction: column;
                  gap: 13px;

                  .title {
                    width: 100%;
                    padding: 12px 43px 0px 12px;

                    span {
                      color: #000;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }

                  .firstCard {
                    padding-bottom: 20px;
                    // height: 186px;
                    border-radius: 9.472px;
                    background: #fff;
                    box-shadow: 0px 0px 9.472px 0px rgba(0, 0, 0, 0.1);

                    .card-header {
                      display: flex;
                      justify-content: space-between;
                      padding: 12px 20px 0px 20px;

                      .meet {
                        color: #000;
                        font-family: Arial;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                      }
                    }

                    .cardBottom {
                      padding: 0px 38px 0px 38px;

                      h6 {
                        color: #000;
                        font-family: Arial;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                        padding-bottom: 8px;
                      }
                    }

                    .second-quarter {
                      padding: 0px 0px 0px 38px;
                      position: relative;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -145px;
                        left: 38px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                            // font-size: 16px;
                            // font-style: normal;
                            // font-weight: 400;
                            // line-height: normal;
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }

                    .second-quarters {
                      padding: 0px 0px 0px 38px;
                      position: relative;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -145px;
                        left: 39px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                            // font-size: 16px;
                            // font-style: normal;
                            // font-weight: 400;
                            // line-height: normal;
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }

                    .second-quartersz {
                      padding: 0px 0px 0px 38px;
                      position: relative;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -145px;
                        left: 39px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                            // font-size: 16px;
                            // font-style: normal;
                            // font-weight: 400;
                            // line-height: normal;
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }

                    .second-quarterss {
                      padding: 0px 0px 0px 38px;
                      position: relative;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -143px;
                        left: 38px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                            // font-size: 16px;
                            // font-style: normal;
                            // font-weight: 400;
                            // line-height: normal;
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }

                    .second-quartersss {
                      padding: 0px 0px 0px 38px;
                      position: relative;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -145px;
                        left: 39px;
                        // left: 370px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                            // font-size: 16px;
                            // font-style: normal;
                            // font-weight: 400;
                            // line-height: normal;
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }

                    .second-quartrs {
                      padding: 0px 0px 0px 38px;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -190px;
                        left: 760px;
                        // left: 370px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                            // font-size: 16px;
                            // font-style: normal;
                            // font-weight: 400;
                            // line-height: normal;
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }

                    .second-quarterend {
                      padding: 0px 0px 0px 38px;
                      position: relative;

                      .inputbox {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        align-items: center;
                        width: 170.909px;
                        height: 36.623px;
                        border-radius: 4.883px;
                        background: #e4e4e4;
                        padding: 0px 8px 0px 8px;

                        .leftside {
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // Add your styling for the left side here
                        }

                        .rightside {
                          margin-left: 10px; // Adjust as needed

                          svg {
                            width: 10.104px;
                            height: 10.052px;
                            fill: none;
                            stroke: black;
                            stroke-width: 3;
                            stroke-linecap: round;
                          }
                        }
                      }

                      .dropdown {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        bottom: -145px;
                        left: 39px;

                        width: 170px;
                        z-index: 1;
                        border-radius: 8px;
                        background: #e4e4e4;
                        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
                        // top: 0px;

                        .firstdropdown {
                          padding: 10px;
                          cursor: pointer;
                          color: #666;
                          font-family: "Montserrat";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          // color: #333;
                          // font-family: "Montserrat";
                          // font-size: 16px;
                          // font-style: normal;
                          // font-weight: 400;
                          // line-height: normal;

                          &:hover {
                            color: #333;
                            font-family: "Montserrat";
                            // font-size: 16px;
                            // font-style: normal;
                            // font-weight: 400;
                            // line-height: normal;
                          }

                          span {
                            // Add your styling for the dropdown items here
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
