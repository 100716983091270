.box1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
  background-image: url("../assets/images/Group2235.png");
}

.box2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 9px;
  height: auto;
}

.text-sm {
  font-size: 24px;
}

.text-hint {
  font-size: 24px;
  margin-top: 10px;
  color: #0D4D80;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-lg {
  color: #0D4D80;
  font-size: 48px;
}

.div {
  width: 60%;
  display: flex;
  border-radius: 10px;
  border: 1px solid var(--primary-dark, #0D4D80);
  overflow: hidden;
  padding-right: 20px;

  .custom-input {
    // width: 60%;
    width: 100%;
    height: 60px;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    /* Space for the arrow */
    /* Include padding in the width calculation */
    //position: relative;
    // background-image: url("../assets/images/arrow_forward.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 23px) center;
    background-size: 40px 40px;
    // border-radius: 10px;
    // border: 1px solid var(--primary-dark, #0D4D80);

    ::placeholder {
      color: #8D8D8D;
      font-family: Arial;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }



  }

  button {
    background: none;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.steps {
  width: 60%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
  padding-right: 7px;
  padding-top: 7px;
  color: #8D8D8D;
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 600px) {
  .box1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    gap: 10px;
    background-image: url("../assets/images/Group2235.png");
  }

  .box2 {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    height: auto;
    gap: 0px;
  }

  .text-sm {
    font-size: 18px;
    text-align: center;
    padding-left: 13px;
    padding-right: 13px;
  }

  .text-hint {
    font-size: 18px;
    margin-top: 10px;
    color: #0D4D80;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .text-lg {
    color: #0D4D80;
    font-size: 18px;
  }

  // .custom-input {

  //   width: 90%;
  //   height: 60px;
  //   padding-left: 15px;
  //   padding-right: 15px;

  //   // background-image: url("../assets/images/arrow_forward.png");
  //   background-repeat: no-repeat;
  //   background-position: calc(100% - 23px) center;
  //   background-size: 40px 40px;
  //   border-radius: 10px;
  //   border: 1px solid var(--primary-dark, #0D4D80);
  //   padding-right: 100px;

  //   ::placeholder {
  //     color: #8D8D8D;
  //     font-family: Arial;
  //     font-size: 24px;
  //     font-style: normal;
  //     font-weight: 400;
  //     line-height: normal;
  //   }



  // }
  .div {
    width: 80%;
    display: flex;
    border-radius: 10px;
    border: 1px solid var(--primary-dark, #0D4D80);
    overflow: hidden;
    padding-right: 20px;

    .custom-input {
      // width: 60%;
      width: 100%;
      height: 60px;
      padding-left: 30px;
      padding-right: 30px;
      border: none;
      /* Space for the arrow */
      /* Include padding in the width calculation */
      //position: relative;
      // background-image: url("../assets/images/arrow_forward.png");
      background-repeat: no-repeat;
      background-position: calc(100% - 23px) center;
      background-size: 40px 40px;
      // border-radius: 10px;
      // border: 1px solid var(--primary-dark, #0D4D80);

      ::placeholder {
        color: #8D8D8D;
        font-family: Arial;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }



    }

    button {
      background: none;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .steps {
    width: 80%;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    margin-top: 0px;
    padding-right: 7px;
    padding-top: 7px;
    color: #8D8D8D;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}