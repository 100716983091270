.theme {
  // padding-top: 60px;
  width: 100%;
  // height: 100vh;
  background-image: url("../../assets/images/layerimg.png");

  .theme-content {
    width: 100%;
    padding: 72px 92px 100px 92px;
    padding-inline: 30px;
    padding-block: 0px;

    .theme-card {
      width: 100%;
      // height: 932px;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding-bottom: 100px;

      .header-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px 10px 0px 0px;
        background: var(
          --side-bar,
          linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
        );
        height: 76px;
        padding-left: 22px;
        padding-right: 22px;

        .leftsection {
          span {
            color: #fff;
            font-family: Arial;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: lowercase;
          }
        }
      }

      .innerContent {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 11px;
        padding: 62px 45px 0px 45px;
        padding-inline: 0px;
        .s {
          .first-card {
            margin-top: 10px;
            padding: 10px;
            // height: 76px;
            border-radius: 10px;
            background: #e1edd3;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // flex-direction: column;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;
            span {
              color: #000;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .category {
              background: #0297ca;
              width: fit-content;
              padding: 10px;
              border-radius: 10px;
              color: #fff;
            }
          }
        }

        .s {
          .first-card2 {
            padding: 10px;
            margin-top: 10px;
            // height: 76px;
            border-radius: 10px;
            background: #f6d6d5;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;

            span {
              color: #000;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .category {
              background: #0297ca;
              width: fit-content;
              padding: 10px;
              border-radius: 10px;
              color: #fff;
            }
            .first-goal {
              display: flex;
              justify-content: space-between;
            }
          }
        }

        .s {
          .first-card3 {
            padding: 10px;
            margin-top: 10px;
            // height: 76px;
            border-radius: 10px;
            background: #bfd6e8;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;

            span {
              color: #000;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .category {
              background: #0297ca;
              width: fit-content;
              padding: 10px;
              border-radius: 10px;
              color: #fff;
            }
            .first-goal {
              display: flex;
              justify-content: space-between;
            }
          }
        }

        .s {
          .first-card4 {
            padding: 10px;
            margin-top: 10px;
            // height: 76px;
            border-radius: 10px;
            background: #faeec9;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;

            span {
              color: #000;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .category {
              background: #0297ca;
              width: fit-content;
              padding: 10px;
              border-radius: 10px;
              color: #fff;
            }
            .first-goal {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .theme {
    .theme-content {
      width: 100%;
      padding: 72px 50px 100px 50px;
    }
  }
}

.first-card {
  margin-top: 10px;
  padding: 10px;
  // height: 76px;
  border-radius: 10px;
  background: #e1edd3;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .category {
    background: #0297ca;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
  }
}
.first-card2 {
  padding: 10px;
  margin-top: 10px;
  // height: 76px;
  border-radius: 10px;
  background: #f6d6d5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .category {
    background: #0297ca;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
  }
  .first-goal {
    display: flex;
    justify-content: space-between;
  }
}
.first-card3 {
  padding: 10px;
  margin-top: 10px;
  // height: 76px;
  border-radius: 10px;
  background: #bfd6e8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .category {
    background: #0297ca;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
  }
  .first-goal {
    display: flex;
    justify-content: space-between;
  }
}
.first-card4 {
  padding: 10px;
  margin-top: 10px;
  // height: 76px;
  border-radius: 10px;
  background: #faeec9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .first-goal {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1600px) {
  .theme {
    .theme-content {
      padding: 72px 30px 100px 30px;

      .theme-card {
        .innerContent {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 11px;
          padding: 62px 20px 0px 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .theme {
    padding-top: 60px;
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/images/layerimg.png");

    .theme-content {
      width: 100%;
      padding: 50px 10px 50px 10px;

      .theme-card {
        width: 100%;
        // height: 932px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding-bottom: 100px;

        .header-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 10px 10px 0px 0px;
          background: var(
            --side-bar,
            linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
          );
          // height: 76px;
          padding-top: 15px;
          padding-left: 15px;
          padding-right: 10px;
          padding-bottom: 15px;

          .leftsection {
            span {
              color: #fff;
              font-family: Arial;
              font-size: 15px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: lowercase;
            }
          }
        }

        .innerContent {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 11px;
          padding: 50px 10px 0px 10px;

          .first-card {
            padding: 10px;
            // height: 76px;
            border-radius: 10px;
            background: #e1edd3;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // flex-direction: column;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;

            span {
              color: #000;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .category {
              background: #0297ca;
              width: fit-content;
              padding: 10px;
              border-radius: 10px;
              color: #fff;
            }
          }

          .first-card2 {
            padding: 10px;
            // height: 76px;
            border-radius: 10px;
            background: #f6d6d5;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;

            span {
              color: #000;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .category {
              background: #0297ca;
              width: fit-content;
              padding: 10px;
              border-radius: 10px;
              color: #fff;
            }
            .first-goal {
              display: flex;
              justify-content: space-between;
            }
          }

          .first-card3 {
            padding: 10px;
            // height: 76px;
            border-radius: 10px;
            background: #bfd6e8;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;

            span {
              color: #000;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .category {
              background: #0297ca;
              width: fit-content;
              padding: 10px;
              border-radius: 10px;
              color: #fff;
            }
            .first-goal {
              display: flex;
              justify-content: space-between;
            }
          }

          .first-card4 {
            padding: 10px;
            // height: 76px;
            border-radius: 10px;
            background: #faeec9;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;

            span {
              color: #000;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .category {
              background: #0297ca;
              width: fit-content;
              padding: 10px;
              border-radius: 10px;
              color: #fff;
            }
            .first-goal {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
