.congratsswot {
    width: 100%;

    .congratswot-content {
        width: 100%;
        padding-top: 84px;
        padding-bottom: 171px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 23px;

        .congrats-title {
            width: 100%;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;

            .heading {
                padding-bottom: 40px;

                h4 {
                    color: #0D4D80;
                    text-align: center;
                    font-family: Arial;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .subheading {
                p {
                    color: #0D4D80;
                    text-align: center;
                    font-family: Arial;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }

        .submitSwot {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            // width: 287px;
            height: 74.475px;
            padding: 10px;
            border-radius: 10px;
            background: linear-gradient(90deg, #9CCC65 0%, rgba(156, 204, 101, 0.74) 100%);
            cursor: pointer;
            transition: box-shadow 0.3s ease;

            &:hover {
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
                /* Add the box shadow only at the bottom on hover */
                /* Add the box shadow on hover */
            }

            h6 {
                color: #FFF;
                text-align: center;
                font-family: Arial;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                /* 150% */
            }

            p {
                color: #FFF;
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
            }
        }

        .submitBtn {
            display: flex;
            gap: 14px;

            .Previous {
                width: 84px;
                height: 25.714px;
                flex-shrink: 0;
                border-radius: 4px;
                background: #8D8D8D;
                color: #FFF;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .Save {
                color: white;
                width: 84px;
                height: 25.714px;
                flex-shrink: 0;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 4px;
                background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);



            }


        }

        .successMessage {
            color: var(--green, #20AC27);
            font-family: Arial;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
            text-transform: lowercase;
        }
    }
}

@media only screen and (max-width: 600px) {
    .congratsswot {
        width: 100%;

        .congratswot-content {
            width: 100%;
            padding-top: 50px;
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 171px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 23px;

            .congrats-title {
                width: 100%;
                // display: flex;
                // flex-direction: column;
                // justify-content: center;
                // align-items: center;

                .heading {
                    padding-bottom: 10px;

                    h4 {
                        color: #0D4D80;
                        text-align: center;
                        font-family: Arial;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }

                .subheading {
                    p {
                        color: #0D4D80;
                        text-align: center;
                        font-family: Arial;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
            }

            .submitSwot {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                // width: 287px;
                height: 74.475px;
                padding: 10px;
                border-radius: 10px;
                background: linear-gradient(90deg, #9CCC65 0%, rgba(156, 204, 101, 0.74) 100%);
                cursor: pointer;
                transition: box-shadow 0.3s ease;

                &:hover {
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
                    /* Add the box shadow only at the bottom on hover */
                    /* Add the box shadow on hover */
                }

                h6 {
                    color: #FFF;
                    text-align: center;
                    font-family: Arial;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                    /* 150% */
                }

                p {
                    color: #FFF;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px;
                }
            }

            .submitBtn {
                display: flex;
                gap: 14px;

                .Previous {
                    width: 84px;
                    height: 25.714px;
                    flex-shrink: 0;
                    border-radius: 4px;
                    background: #8D8D8D;
                    color: #FFF;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .Save {
                    color: white;
                    width: 84px;
                    height: 25.714px;
                    flex-shrink: 0;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    border-radius: 4px;
                    background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);



                }


            }

            .successMessage {
                color: var(--green, #20AC27);
                font-family: Arial;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                text-transform: lowercase;
            }
        }
    }
}