// .simpli-navbar {
//   .navbar-nav {
//     .nav-item {
//       a {
//         &.active {
//         }
//       }
//     }
//   }
// }
.playbook-Header {

  .mainnavbar {
    width: 100%;
    background: linear-gradient(90deg, #0B5186 0%, #1573BE 72.31%);
    // background: var(--0D4D80);
    // background: #0D4D80;
    // height: 80px;
    position: fixed;
    padding: 27px 0px 26px 0px;
    z-index: 999;
    padding: 0px;


    .maincontainer {
      padding: 0px;

      .navbar-toggler {
        // background: white;

        &:focus {
          box-shadow: none;
        }

      }

      .profile-bg {
        // border: 4px dashed red !important;
        position: absolute;
        // width: 20%;
        right: 0;
        top: 0;
        height: 100%;
        z-index: -2;
      }

      // padding: 0;
      .uldiv {
        // padding-left: 130px;

        .navul {
          display: flex;
          gap: 20px;
          align-items: center;
          padding-left: 75px;




          .nav-item {
            .nav-link {}

            a {
              color: var(--white);
              // color: #FFF;
              font-family: Arial;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              cursor: pointer;
              display: flex;
              align-items: center;

              .firstsvg {
                margin-right: 8px;
              }

              &:hover {
                color: #00A2D6;
                color: var(--00A2D6);
                font-family: Arial;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                .firstsvg {
                  // margin-right: 8px;

                  path {
                    fill: #00A2D6;

                  }
                }

              }

              // &:hover {
              //   color: #00A2D6;

              //   font-family: Arial;
              //   font-size: 20px;
              //   font-style: normal;
              //   font-weight: 700;
              //   line-height: normal;
              // }
            }

            &:active {
              color: red;
            }

          }

        }

        .navbar-nav-scroll .nav-link.active {
          color: red;
          /* Set your desired color here */
        }

        .navbarLast {
          display: flex;
          align-items: center;
          justify-content: center;
          // padding-right: 95px;
          padding-right: 75px;
          gap: 10px;


          // .imgline {

          //   position: absolute;
          //   // right: 300px;
          //   height: 100%;
          //   // width: 30%;
          //   width: 350px;
          //   z-index: -1;
          // }
          .dropdown-container {
            position: relative;
            // width: 100%;
            // height: 100%;






            .firstsvg {
              cursor: pointer;


            }

            .bottomdiv {
              position: absolute;
              width: 173px;
              padding-block: 10px;
              // padding: 10px;
              /* Set top to 100% to position it below the SVG */
              top: 0;
              right: 0;
              // bottom: 0;
              left: 0;
              border-radius: 4px;
              background: #FFF;
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              // border: 1px solid black;
              margin-top: 50px;
              z-index: 6 !important;

              .Notification {
                padding-block: 10px;
                text-align: center;
                // padding: 10px;
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);

                span {
                  color: #0D4D80;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .noNotification {
                padding: 10px;
                // border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);
                text-align: center;

                span {
                  color: #0D4D80;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }

          .dropdown-containerss {
            position: relative;
            z-index: 1;
            // width: 100%;
            // height: 100%;
            cursor: pointer;

            .firstsvg {
              cursor: pointer;


            }

            .bottomdiv {
              position: absolute;
              width: 173px;
              padding-block: 10px;
              // padding: 10px;
              /* Set top to 100% to position it below the SVG */
              top: 0;
              right: 0;
              // bottom: 0;
              left: 1;
              border-radius: 4px;
              background: #FFF;
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              // border: 1px solid black;


              margin-top: 40px;
              z-index: 6;

              .Notification {
                padding-block: 10px;
                padding-left: 10px;

                // padding: 10px;
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);

                span {
                  color: #0D4D80;
                  font-family: Arial;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .noNotification {
                padding: 10px;
                // border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);


                span {
                  color: #0D4D80;
                  font-family: Arial;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }




        }

      }
    }
  }
}


.closediv {
  // background: rgba(255, 0, 0, 0.377);
  position: fixed;
  width: 200vw;
  left: -100vw;
  height: 120vh;
  z-index: 5 !important;
  top: -50px;
}

@media only screen and (max-width: 1600px) {
  .playbook-Header {

    .mainnavbar {
      width: 100%;
      background: var(--0D4D80);
      // background: #0D4D80;
      // height: 110px;
      position: fixed;
      z-index: 999;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 10px;

      .maincontainer {
        display: flex;
        justify-content: flex-end;

        .navbar-brand {
          img {
            width: 180px;
            height: auto;
          }
        }

        .navbar-toggler {
          // background: white;

          &:focus {
            box-shadow: none;
          }

        }

        .profile-bg {

          display: none;
        }

        // padding: 0;
        .uldiv {
          // padding-left: 130px;

          .navul {
            display: flex;
            gap: 0px;
            align-items: start;
            padding-left: 50px;


            .nav-item {
              a {
                color: var(--white);
                // color: #FFF;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                cursor: pointer;
                display: flex;
                align-items: center;

                .firstsvg {
                  margin-right: 8px;
                  width: 22px;
                  height: 22px;
                }

                &:hover {
                  // color: #00A2D6;
                  color: var(--00A2D6);
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;

                  .firstsvg {
                    // margin-right: 8px;

                    path {
                      fill: #00A2D6;

                    }
                  }

                }

                // &:hover {
                //   color: #00A2D6;

                //   font-family: Arial;
                //   font-size: 20px;
                //   font-style: normal;
                //   font-weight: 700;
                //   line-height: normal;
                // }
              }


            }

          }

          .navbarLast {
            display: flex;
            align-items: center;
            justify-content: start;
            // width: 100%;
            // height: 100px;

            // svg {
            //   width: 40px;
            //   height: 40px;
            // }

            .dropdown-container {
              position: relative;

              // width: 100%;
              // height: 100%;
              .firstsvg {
                cursor: pointer;
                width: 43px !important;
                height: 40px;

              }

              .closediv {




                .bottomdiv {
                  // position: absolute;
                  width: 173px;
                  padding-block: 10px;
                  // padding: 10px;
                  /* Set top to 100% to position it below the SVG */
                  top: 0;
                  right: 0;
                  // bottom: 0;
                  left: 0;
                  border-radius: 4px;
                  background: #FFF;
                  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                  // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                  // border: 1px solid black;


                  margin-top: 50px;
                  z-index: 6;

                  .Notification {
                    padding-block: 10px;
                    text-align: center;
                    // padding: 10px;
                    border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);

                    span {
                      color: #0D4D80;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    }
                  }

                  .noNotification {
                    padding: 10px;
                    // border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);
                    text-align: center;

                    span {
                      color: #0D4D80;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    }
                  }
                }
              }
            }


            .dropdown-containerss {
              position: relative;
              // width: 100%;
              // height: 100%;
              cursor: pointer;

              .firstsvg {
                cursor: pointer;
                // width: 43px;


              }

              img {
                width: 22px;
              }

              .bottomdiv {
                position: absolute;
                width: 173px;
                padding-block: 10px;
                // padding: 10px;
                /* Set top to 100% to position it below the SVG */
                top: 0;
                right: 0;
                // bottom: 0;
                left: 1;
                border-radius: 4px;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                // border: 1px solid black;


                margin-top: 40px;
                z-index: 6;

                .Notification {
                  padding-block: 10px;
                  padding-left: 10px;

                  // padding: 10px;
                  border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);

                  span {
                    color: #0D4D80;
                    font-family: Arial;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .noNotification {
                  padding: 10px;
                  // border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);


                  span {
                    color: #0D4D80;
                    font-family: Arial;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }
              }
            }



          }

        }
      }
    }
  }

  .closediv {
    // background: rgba(255, 0, 0, 0.377);
    position: fixed;
    width: 200vw;
    left: -100vw;
    height: 120vh;
    z-index: 5 !important;
    top: -50px;
  }
}

@media only screen and (max-width: 1300px) {
  .playbook-Header {

    .mainnavbar {
      width: 100%;
      background: var(--0D4D80);
      // background: #0D4D80;
      // height: 110px;
      position: fixed;
      z-index: 999;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 10px;

      .maincontainer {
        display: flex;
        justify-content: flex-end;

        .navbar-brand {
          img {
            width: 180px;
            height: auto;
          }
        }

        .navbar-toggler {
          // background: white;

          &:focus {
            box-shadow: none;
          }

        }

        .profile-bg {

          display: none;
        }

        // padding: 0;
        .uldiv {
          // padding-left: 130px;

          .navul {
            display: flex;
            gap: 5px;
            align-items: start;
            padding-left: 30px;


            .nav-item {
              a {
                color: var(--white);
                // color: #FFF;
                font-family: Arial;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                cursor: pointer;
                display: flex;
                align-items: center;

                .firstsvg {
                  margin-right: 4px;
                  width: 20px;
                  height: 20px;
                }

                &:hover {
                  // color: #00A2D6;
                  color: var(--00A2D6);
                  font-family: Arial;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;

                  .firstsvg {
                    // margin-right: 8px;

                    path {
                      fill: #00A2D6;

                    }
                  }

                }

                // &:hover {
                //   color: #00A2D6;

                //   font-family: Arial;
                //   font-size: 20px;
                //   font-style: normal;
                //   font-weight: 700;
                //   line-height: normal;
                // }
              }


            }

          }

          .navbarLast {
            display: flex;
            align-items: center;
            justify-content: start;
            padding-right: 100px;
            // width: 100%;
            // height: 100px;

            // svg {
            //   width: 40px;
            //   height: 40px;
            // }

            .dropdown-container {
              position: relative;

              // width: 100%;
              // height: 100%;
              .firstsvg {
                cursor: pointer;
                width: 43px !important;
                height: 40px;

              }

              .closediv {




                .bottomdiv {
                  // position: absolute;
                  width: 173px;
                  padding-block: 10px;
                  // padding: 10px;
                  /* Set top to 100% to position it below the SVG */
                  top: 0;
                  right: 0;
                  // bottom: 0;
                  left: 0;
                  border-radius: 4px;
                  background: #FFF;
                  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                  // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                  // border: 1px solid black;


                  margin-top: 50px;
                  z-index: 6;

                  .Notification {
                    padding-block: 10px;
                    text-align: center;
                    // padding: 10px;
                    border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);

                    span {
                      color: #0D4D80;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    }
                  }

                  .noNotification {
                    padding: 10px;
                    // border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);
                    text-align: center;

                    span {
                      color: #0D4D80;
                      font-family: Arial;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    }
                  }
                }
              }
            }


            .dropdown-containerss {
              position: relative;
              // width: 100%;
              // height: 100%;
              cursor: pointer;

              .firstsvg {
                cursor: pointer;
                // width: 43px;


              }

              img {
                width: 22px;
              }

              .bottomdiv {
                position: absolute;
                width: 173px;
                padding-block: 10px;
                // padding: 10px;
                /* Set top to 100% to position it below the SVG */
                top: 0;
                right: 0;
                // bottom: 0;
                left: 1;
                border-radius: 4px;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                // border: 1px solid black;


                margin-top: 40px;
                z-index: 6;

                .Notification {
                  padding-block: 10px;
                  padding-left: 10px;

                  // padding: 10px;
                  border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);

                  span {
                    color: #0D4D80;
                    font-family: Arial;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .noNotification {
                  padding: 10px;
                  // border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);


                  span {
                    color: #0D4D80;
                    font-family: Arial;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }
              }
            }



          }

        }
      }
    }
  }

  .closediv {
    // background: rgba(255, 0, 0, 0.377);
    position: fixed;
    width: 200vw;
    left: -100vw;
    height: 120vh;
    z-index: 5 !important;
    top: -50px;
  }
}

@media only screen and (max-width: 600px) {
  .playbook-Header {

    .mainnavbar {
      width: 100%;
      background: var(--0D4D80);
      // background: #0D4D80;
      // height: 110px;
      position: fixed;
      z-index: 999;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 10px;

      .maincontainer {
        display: flex;
        justify-content: flex-end;

        .navbar-brand {
          img {
            width: 180px;
            height: auto;
          }
        }

        .navbar-toggler {
          // background: white;

          &:focus {
            box-shadow: none;
          }

        }

        .profile-bg {

          display: none;
        }

        // padding: 0;
        .uldiv {
          // padding-left: 130px;

          .navul {
            display: flex;
            gap: 5px;
            align-items: start;
            padding-left: 10px;


            .nav-item {
              a {
                color: var(--white);
                // color: #FFF;
                font-family: Arial;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                cursor: pointer;
                display: flex;
                align-items: center;

                .firstsvg {
                  margin-right: 8px;
                  // width: 20px;
                  // height: 20px;
                }

                &:hover {
                  // color: #00A2D6;
                  color: var(--00A2D6);
                  font-family: Arial;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;

                  .firstsvg {
                    // margin-right: 8px;

                    path {
                      fill: #00A2D6;

                    }
                  }

                }

                // &:hover {
                //   color: #00A2D6;

                //   font-family: Arial;
                //   font-size: 20px;
                //   font-style: normal;
                //   font-weight: 700;
                //   line-height: normal;
                // }
              }


            }

          }

          .navbarLast {
            display: flex;
            align-items: center;
            justify-content: start;
            width: 100%;
            gap: 0px;
            // height: 100px;

            // svg {
            //   width: 40px;
            //   height: 40px;
            // }

            .dropdown-container {
              position: relative;
              // width: 100%;
              // height: 100%;

              .firstsvg {
                cursor: pointer;
                width: 40px;
                height: 40px;

              }

              .bottomdiv {
                // position: absolute;
                width: 173px;
                padding-block: 10px;
                // padding: 10px;
                /* Set top to 100% to position it below the SVG */
                top: 0;
                right: 0;
                // bottom: 0;
                left: 0;
                border-radius: 4px;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                // border: 1px solid black;


                margin-top: 50px;
                z-index: 6;

                .Notification {
                  padding-block: 10px;
                  text-align: center;
                  // padding: 10px;
                  border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);

                  span {
                    color: #0D4D80;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .noNotification {
                  padding: 10px;
                  // border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);
                  text-align: center;

                  span {
                    color: #0D4D80;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }
              }
            }


            .dropdown-containerss {
              position: relative;
              // width: 100%;
              // height: 100%;
              cursor: pointer;

              img {
                width: 22px;
                // height: 40px;
              }

              .firstsvg {
                cursor: pointer;


              }

              .bottomdiv {
                position: absolute;
                width: 173px;
                padding-block: 10px;
                // padding: 10px;
                /* Set top to 100% to position it below the SVG */
                top: 0;
                right: 0;
                // bottom: 0;
                left: 0;
                border-radius: 4px;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                // border: 1px solid black;


                margin-top: 40px;
                z-index: 6;

                .Notification {
                  padding-block: 10px;
                  padding-left: 10px;

                  // padding: 10px;
                  border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);

                  span {
                    color: #0D4D80;
                    font-family: Arial;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .noNotification {
                  padding: 10px;
                  // border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);


                  span {
                    color: #0D4D80;
                    font-family: Arial;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }
              }
            }



          }

        }
      }
    }
  }

  .closediv {
    // background: rgba(255, 0, 0, 0.377);
    position: fixed;
    width: 200vw;
    left: -100vw;
    height: 120vh;
    z-index: 5 !important;
    top: -50px;
  }
}

// @media only screen and (max-width: 1300px) {
//   .playbook-Header {

//     .mainnavbar {
//       width: 100%;
//       background: var(--0D4D80);
//       // background: #0D4D80;
//       // height: 110px;
//       position: fixed;
//       z-index: 999;

//       .maincontainer {

//         .navbar-brand {
//           img {
//             width: 180px;
//             height: auto;
//           }
//         }

//         .navbar-toggler {
//           background: white;

//           &:focus {
//             box-shadow: none;
//           }

//         }

//         // padding: 0;
//         .uldiv {
//           // padding-left: 130px;

//           .navul {
//             display: flex;
//             gap: 5px;
//             align-items: start;


//             .nav-item {
//               a {
//                 color: var(--white);
//                 // color: #FFF;
//                 font-family: Arial;
//                 font-size: 15px;
//                 font-style: normal;
//                 font-weight: 700;
//                 line-height: normal;
//                 cursor: pointer;
//                 display: flex;
//                 align-items: center;

//                 .firstsvg {
//                   margin-right: 8px;
//                   // width: 20px;
//                   // height: 20px;
//                 }

//                 &:hover {
//                   // color: #00A2D6;
//                   color: var(--00A2D6);
//                   font-family: Arial;
//                   font-size: 15px;
//                   font-style: normal;
//                   font-weight: 700;
//                   line-height: normal;

//                   .firstsvg {
//                     // margin-right: 8px;

//                     path {
//                       fill: #00A2D6;

//                     }
//                   }

//                 }

//                 // &:hover {
//                 //   color: #00A2D6;

//                 //   font-family: Arial;
//                 //   font-size: 20px;
//                 //   font-style: normal;
//                 //   font-weight: 700;
//                 //   line-height: normal;
//                 // }
//               }


//             }

//           }

//           .navbarLast {
//             display: flex;
//             align-items: center;
//             justify-content: start;

//             svg {
//               width: 40px;
//               height: 40px;
//             }

//           }

//         }
//       }
//     }
//   }
// }