.bottomdiv {
  position: absolute;
  width: 102px;
  padding-block: 17px;
  top: 5px;
  right: 0;
  left: auto;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  // border: 1px solid black;
  margin-top: 50px;
  z-index: 6 !important;

  .Notification {
    padding-block: 10px;
    text-align: center;
    // padding: 10px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);

    span {
      color: #0d4d80;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .noNotification {
    padding-top: 10px;
    // border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);
    //   text-align: center;

    span {
      color: #0d4d80;
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.Notification {
  padding-block: 10px;
  text-align: center;
  // padding: 10px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);

  span {
    color: #0d4d80;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.dropdown-containerss {
    // position: relative;
    // z-index: 1;
    // width: 100%;
    // height: 100%;
    // cursor: pointer;

  .firstsvg {
    cursor: pointer;
  }

  .bottomdiv {
    position: absolute;
    width: 93px;
    padding-block: 10px;
    // padding: 10px;
    /* Set top to 100% to position it below the SVG */
    top: 0;
    right: 0;
    // bottom: 0;
    left: auto;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    // border: 1px solid black;

    margin-top: 40px;
    z-index: 6;

    .Notification {
      padding-block: 10px;
      padding-left: 10px;

      // padding: 10px;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);

      span {
        color: #0d4d80;
        font-family: Arial;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .noNotification {
      padding: 10px;
      // border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);

        span {
          color: #0D4D80;
          font-family: Arial;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  @media only screen and (max-width:998px) {

    .bottomdiv {
      left: 0px;
      top: auto;
      display: inline-block;
    }
    
  }