.footer {
    width: 100%;
    // display: none;
    // position: sticky;
    // z-index: 1000;

    .footerContent {
        width: 100%;

        display: flex;
        justify-content: center;
        padding: 18px 0px 16px 0px;

        span {
            color: #8D8D8D;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 167.886%;
            /* 26.862px */
        }
    }
}

@media only screen and (max-width: 1600px) {
    .footer {
        width: 100%;
        // position: sticky;
        // z-index: 1000;

        .footerContent {
            width: 100%;

            display: flex;
            justify-content: center;
            padding: 0px 0px 16px 0px;

            span {
                color: #8D8D8D;
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 167.886%;
                /* 26.862px */
            }
        }
    }
}