.loader_container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  display: block;
  --height-of-loader: 15px;
  --loader-color: #32b4de;
  width: 150px;
  height: var(--height-of-loader);
  border-radius: 30px;
  background: linear-gradient(90deg, #0b5186, #1573be 72.31%);
  position: relative;
}

.loader::before {
  content: "";
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 1s ease-in-out infinite;
}

@keyframes moving {
  50% {
    width: 100%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}
