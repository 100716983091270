.checkbox2 {
  // width: 400px;
  width: 209px;
  // width: 300px;
  height: 60px;
  background-color: #d0d0d0;
  border-radius: 30px;
  position: relative;
  color: black;
  overflow: hidden;
  border-radius: 19px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);


  .toggle {
    width: 100px;
    /* Adjusted width for two toggles */
    height: 50px;
    position: absolute;
    border-radius: 30px;
    left: 5px;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    // box-shadow: 0px 0px 3px rgb(142, 142, 142), 0px 0px 5px rgb(255, 255, 20);
  }

  .toggle2-color-0 {
    background: linear-gradient(90deg,
        #9ccc65 0%,
        rgba(156, 204, 101, 0.74) 100%);

  }

  .toggle2-color-1 {
    background: linear-gradient(90deg,
        #ef5350 0%,
        rgba(239, 83, 80, 0.74) 100%);
    color: white;
  }

  .slide {
    // width: 300px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;


    .text {
      font-size: 14px;
      font-weight: 400;
      z-index: 100;
      cursor: pointer;
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-family: "Poppins";

      // width: 100px;

    }
  }

  &.toggle2-state-1 .toggle {
    transform: translateX(100px);

    /* Adjusted distance for two toggles */
  }
}

@media only screen and (max-width: 600px) {
  .checkbox2 {
    // width: 400px;
    width: 150px;
    // width: 300px;
    height: 50px;
    background-color: #d0d0d0;
    border-radius: 30px;
    position: relative;
    color: black;
    overflow: hidden;
    border-radius: 19px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

    .toggle {
      width: 70px;
      /* Adjusted width for two toggles */
      height: 40px;
      position: absolute;
      border-radius: 30px;
      left: 5px;
      cursor: pointer;
      transition: 0.4s ease-in-out;
      // box-shadow: 0px 0px 3px rgb(142, 142, 142), 0px 0px 5px rgb(255, 255, 20);
    }

    .toggle2-color-0 {
      background: linear-gradient(90deg,
          #9ccc65 0%,
          rgba(156, 204, 101, 0.74) 100%);

    }

    .toggle2-color-1 {
      background: linear-gradient(90deg,
          #ef5350 0%,
          rgba(239, 83, 80, 0.74) 100%);
      color: white;
    }

    .slide {
      // width: 300px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;


      .text {
        font-size: 14px;
        font-weight: 400;
        z-index: 100;
        cursor: pointer;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-family: "Poppins";

        // width: 100px;

      }
    }

    &.toggle2-state-1 .toggle {
      transform: translateX(70px);

      /* Adjusted distance for two toggles */
    }
  }
}