.ninetydplan {
    width: 100%;
    padding-top: 60px;
    background-image: url("../../assets/images/layerimg.png");

    .ninetyplanContent {
        width: 100%;

        .ninetyplan-title {
            text-align: center;
            padding-top: 28px;
            padding-bottom: 55px;

            h6 {
                color: #0D4D80;

                font-family: Arial;
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: 128.214%;
                /* 33.336px */
            }

            span {
                color: #8D8D8D;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 128.214%;
            }
        }

        .planscard {
            padding-left: 56px;
            padding-right: 56px;
            padding-bottom: 30px;
            width: 100%;
            display: flex;
            gap: 36px;

            .leftCard {
                width: 100%;
                // height: 160px;
                border-radius: 10px;
                background: #F8F8F8;
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                .goalHeader {
                    width: 295.187px;
                    height: 58px;
                    padding: 10px;
                    border-radius: 10px;
                    background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        color: #FFF;
                        font-family: Arial;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-transform: capitalize;
                    }
                }

                .sale-section {
                    padding: 18px 29px 9px 29px;

                    span {
                        color: #000;
                        text-align: center;
                        font-family: Arial;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                .goalFooter {
                    height: 54px;
                    padding: 21px 29px 12px 29px;

                    border-top: 1px solid #E2E2E2;

                    span {
                        color: #000;

                        font-family: Arial;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }

            .rightCard {
                width: 100%;
                // height: 160px;
                border-radius: 10px;
                background: #F8F8F8;
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                .goalHeader {
                    width: 295.187px;
                    height: 58px;
                    padding: 10px;
                    border-radius: 10px;
                    background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        color: #FFF;
                        font-family: Arial;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-transform: capitalize;
                    }
                }

                .sale-section {
                    padding: 18px 29px 9px 29px;

                    span {
                        color: #000;
                        text-align: center;
                        font-family: Arial;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                .goalFooter {
                    height: 54px;
                    padding: 21px 29px 12px 29px;

                    border-top: 1px solid #E2E2E2;

                    span {
                        color: #000;

                        font-family: Arial;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }

        .threemonthPlan {
            width: 100%;
            padding-left: 56px;
            padding-right: 56px;
            padding-bottom: 200px;

            .threemonthContent {
                width: 100%;


                .tableheader {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 30px;

                    .dateDiv {
                        padding: 12px 108px 12px 10px;
                        border-radius: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.20);

                        span {
                            color: #000;
                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }

                    .filterdiv {
                        padding: 12px 10px;
                        border-radius: 10px;
                        background: var(--primary-light, #00A2D6);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;

                        span {
                            color: #FFF;
                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: capitalize;

                            // display: flex;
                            // align-items: center;
                        }
                    }



                }

                //  older table css start here
                // table {
                //     width: 100%;
                //     border: 0.5px solid #000;

                //     .firstRow {
                //         border-right: 0.5px solid #000;
                //         border-bottom: 0.5px solid #000;
                //         border-left: 0.5px solid #000;
                //         background: #FFF;
                //         padding: 10px;

                //         .firstTh {
                //             // width: 30%;
                //             height: 58px;
                //             // height: 58px;
                //             // padding-bottom: 3px;
                //             // border-radius: 10px 10px 0px 0px;
                //             background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                //             text-align: center;

                //             span {
                //                 color: #FFF;
                //                 font-family: Arial;
                //                 font-size: 22px;
                //                 font-style: normal;
                //                 font-weight: 700;
                //                 line-height: normal;
                //                 text-transform: capitalize;
                //             }
                //         }

                //         .secondTh {

                //             // width: 50%;
                //             height: 58px;
                //             // height: 35px;
                //             padding: 10px;
                //             display: flex;
                //             justify-content: center;
                //             align-items: center;
                //             // border-bottom: 0.5px solid #000;
                //             border-right: 0.5px solid #000;
                //             color: #000;
                //             font-family: Arial;
                //             font-size: 18px;
                //             font-style: normal;
                //             font-weight: 400;
                //             line-height: normal;
                //             text-transform: capitalize;
                //         }

                //         .thirdTh {
                //             height: 58px;
                //             // width: 25%;
                //             // height: 35px;
                //             padding: 10px;
                //             text-align: center;

                //             border-bottom: 0.5px solid #000;
                //             border-right: 0.5px solid #000;
                //             color: #000;
                //             font-family: Arial;
                //             font-size: 18px;
                //             font-style: normal;
                //             font-weight: 400;
                //             line-height: normal;
                //             text-transform: capitalize;
                //         }

                //         .fourthTh {
                //             text-align: center;
                //             height: 58px;
                //             // width: 17.5%;
                //             // height: 35px;
                //             padding: 10px;
                //             border-bottom: 0.5px solid #000;
                //             border-right: 0.5px solid #000;


                //             color: #000;
                //             font-family: Arial;
                //             font-size: 18px;
                //             font-style: normal;
                //             font-weight: 400;
                //             line-height: normal;
                //             text-transform: capitalize;

                //         }

                //         .fifthTh {
                //             text-align: center;
                //             height: 58px;
                //             // width: 17.5%;
                //             // height: 35px;
                //             padding: 10px;
                //             border-bottom: 0.5px solid #000;
                //             // border-right: 0.5px solid #000;


                //             color: #000;
                //             font-family: Arial;
                //             font-size: 18px;
                //             font-style: normal;
                //             font-weight: 400;
                //             line-height: normal;
                //             text-transform: capitalize;

                //         }
                //     }

                //     .secondRowP {
                //         height: 58px;
                //         border-bottom: 0.5px solid #000;

                //         .develop {
                //             text-align: center;

                //             // padding-left: 10px;
                //             span {}
                //         }

                //         .firstTh {
                //             border-right: 0.5px solid #000;
                //             border-bottom: 0.5px solid #000;
                //             border-left: 0.5px solid #000;
                //             background: white;
                //             color: #000;
                //             font-family: Arial;
                //             font-size: 18px;
                //             font-style: normal;
                //             font-weight: 700;
                //             line-height: normal;
                //             // padding: 10px;

                //             .parent {
                //                 width: 100%;

                //                 .columA {
                //                     // width: 100%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columB {
                //                     // width: 100%;
                //                     // width: 40%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columC {
                //                     // width: 100%;
                //                     // width: 50%;
                //                     width: 100%;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }
                //             }


                //         }

                //         .secondTh {
                //             text-align: center;
                //             border-right: 0.5px solid #000;
                //             color: #000;
                //             font-family: Arial;
                //             font-size: 16px;
                //             font-style: normal;
                //             font-weight: 400;
                //             line-height: normal;
                //             text-transform: capitalize;
                //             padding: 10px;

                //             button {
                //                 border-radius: 10px;
                //                 background: var(--primary-light, #00A2D6);
                //                 padding: 6px 8px;
                //                 color: #FFF;
                //                 font-family: Arial;
                //                 font-size: 14px;
                //                 font-style: normal;
                //                 font-weight: 400;
                //                 line-height: normal;
                //                 text-transform: capitalize;
                //             }
                //         }

                //         .thirdTh {
                //             border-right: 0.5px solid #000;

                //             .parent {
                //                 width: 100%;

                //                 .columA {
                //                     // width: 100%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columB {
                //                     // width: 100%;
                //                     // width: 40%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columC {
                //                     // width: 100%;
                //                     // width: 50%;
                //                     width: 100%;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }
                //             }
                //         }

                //         .fourthTh {
                //             border-right: 0.5px solid #000;

                //             .parent {
                //                 width: 100%;

                //                 .columA {
                //                     // width: 100%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columB {
                //                     // width: 100%;
                //                     // width: 40%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columC {
                //                     // width: 100%;
                //                     // width: 50%;
                //                     width: 100%;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }
                //             }
                //         }

                //         .fifthTh {
                //             .parent {
                //                 width: 100%;

                //                 .columA {
                //                     // width: 100%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columB {
                //                     // width: 100%;
                //                     // width: 40%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columC {
                //                     // width: 100%;
                //                     // width: 50%;
                //                     width: 100%;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }
                //             }
                //         }

                //     }

                //     .secondRowgoal {
                //         height: 58px;
                //         border-bottom: 0.5px solid #000;
                //         background: #B0D4F1;

                //         .develop {
                //             text-align: center;

                //             // padding-left: 10px;
                //             span {}
                //         }

                //         .firstTh {
                //             border-right: 0.5px solid #000;
                //             border-bottom: 0.5px solid #000;
                //             border-left: 0.5px solid #000;
                //             // background: white;
                //             background: #B0D4F1;
                //             color: #000;
                //             font-family: Arial;
                //             font-size: 18px;
                //             font-style: normal;
                //             font-weight: 700;
                //             line-height: normal;
                //             // padding: 10px;

                //             .parent {
                //                 width: 100%;

                //                 .columA {
                //                     // width: 100%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columB {
                //                     // width: 100%;
                //                     // width: 40%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columC {
                //                     // width: 100%;
                //                     // width: 50%;
                //                     width: 100%;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }
                //             }


                //         }

                //         .firstThs {
                //             border-right: 0.5px solid #000;
                //             border-bottom: 0.5px solid #000;
                //             border-left: 0.5px solid #000;
                //             // background: white;
                //             background: #D2E7F7;
                //             color: #000;
                //             font-family: Arial;
                //             font-size: 18px;
                //             font-style: normal;
                //             font-weight: 700;
                //             line-height: normal;
                //             // padding: 10px;

                //             .parent {
                //                 width: 100%;

                //                 .columA {
                //                     // width: 100%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columB {
                //                     // width: 100%;
                //                     // width: 40%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columC {
                //                     // width: 100%;
                //                     // width: 50%;
                //                     width: 100%;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }
                //             }


                //         }

                //         .secondTh {
                //             text-align: center;
                //             border-right: 0.5px solid #000;
                //             color: #000;
                //             font-family: Arial;
                //             font-size: 16px;
                //             font-style: normal;
                //             font-weight: 400;
                //             line-height: normal;
                //             text-transform: capitalize;
                //             padding: 10px;

                //             button {
                //                 border-radius: 10px;
                //                 background: var(--primary-light, #00A2D6);
                //                 padding: 6px 8px;
                //                 color: #FFF;
                //                 font-family: Arial;
                //                 font-size: 14px;
                //                 font-style: normal;
                //                 font-weight: 400;
                //                 line-height: normal;
                //                 text-transform: capitalize;
                //             }
                //         }

                //         .thirdTh {
                //             border-right: 0.5px solid #000;

                //             .parent {
                //                 width: 100%;

                //                 .columA {
                //                     // width: 100%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columB {
                //                     // width: 100%;
                //                     // width: 40%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columC {
                //                     // width: 100%;
                //                     // width: 50%;
                //                     width: 100%;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }
                //             }
                //         }

                //         .fourthTh {
                //             border-right: 0.5px solid #000;

                //             .parent {
                //                 width: 100%;

                //                 .columA {
                //                     // width: 100%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columB {
                //                     // width: 100%;
                //                     // width: 40%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columC {
                //                     // width: 100%;
                //                     // width: 50%;
                //                     width: 100%;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }
                //             }
                //         }

                //         .fifthTh {
                //             .parent {
                //                 width: 100%;

                //                 .columA {
                //                     // width: 100%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columB {
                //                     // width: 100%;
                //                     // width: 40%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columC {
                //                     // width: 100%;
                //                     // width: 50%;
                //                     width: 100%;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }
                //             }
                //         }

                //     }

                //     // .secondRow {
                //     //     height: 58px;
                //     //     border-bottom: 0.5px solid #000;
                //     //     background: #B0D4F1;

                //     //     .firstTh {
                //     //         border-right: 0.5px solid #000;
                //     //         border-bottom: 0.5px solid #000;
                //     //         border-left: 0.5px solid #000;
                //     //         background: #B0D4F1;
                //     //         color: #000;
                //     //         font-family: Arial;
                //     //         font-size: 18px;
                //     //         font-style: normal;
                //     //         font-weight: 700;
                //     //         line-height: normal;
                //     //         padding: 10px;

                //     //         p {
                //     //             color: #000;
                //     //             font-family: Arial;
                //     //             font-size: 18px;
                //     //             font-style: normal;
                //     //             font-weight: 400;
                //     //             line-height: normal;
                //     //             text-transform: capitalize;
                //     //         }

                //     //         span {
                //     //             color: #000;
                //     //             font-family: Arial;
                //     //             font-size: 18px;
                //     //             font-style: normal;
                //     //             font-weight: 400;
                //     //             line-height: normal;
                //     //             text-transform: lowercase;
                //     //         }
                //     //     }

                //     //     .secondTh {
                //     //         text-align: center;
                //     //         border-right: 0.5px solid #000;
                //     //         color: #000;
                //     //         font-family: Arial;
                //     //         font-size: 16px;
                //     //         font-style: normal;
                //     //         font-weight: 400;
                //     //         line-height: normal;
                //     //         text-transform: capitalize;
                //     //     }

                //     //     .thirdTh {
                //     //         border-right: 0.5px solid #000;

                //     //         .parent {
                //     //             width: 100%;

                //     //             .columA {
                //     //                 // width: 100%;
                //     //                 width: 100%;
                //     //                 border-right: 0.5px solid #000;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }

                //     //             .columB {
                //     //                 // width: 100%;
                //     //                 // width: 40%;
                //     //                 width: 100%;
                //     //                 border-right: 0.5px solid #000;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }

                //     //             .columC {
                //     //                 // width: 100%;
                //     //                 // width: 50%;
                //     //                 width: 100%;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }
                //     //         }
                //     //     }

                //     //     .fourthTh {
                //     //         border-right: 0.5px solid #000;

                //     //         .parent {
                //     //             width: 100%;

                //     //             .columA {
                //     //                 // width: 100%;
                //     //                 width: 100%;
                //     //                 border-right: 0.5px solid #000;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }

                //     //             .columB {
                //     //                 // width: 100%;
                //     //                 // width: 40%;
                //     //                 width: 100%;
                //     //                 border-right: 0.5px solid #000;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }

                //     //             .columC {
                //     //                 // width: 100%;
                //     //                 // width: 50%;
                //     //                 width: 100%;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }
                //     //         }
                //     //     }

                //     //     .fifthTh {
                //     //         .parent {
                //     //             width: 100%;

                //     //             .columA {
                //     //                 // width: 100%;
                //     //                 width: 100%;
                //     //                 border-right: 0.5px solid #000;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }

                //     //             .columB {
                //     //                 // width: 100%;
                //     //                 // width: 40%;
                //     //                 width: 100%;
                //     //                 border-right: 0.5px solid #000;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }

                //     //             .columC {
                //     //                 // width: 100%;
                //     //                 // width: 50%;
                //     //                 width: 100%;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }
                //     //         }
                //     //     }

                //     // }

                //     // .thirdRow {
                //     //     height: 58px;
                //     //     border-bottom: 0.5px solid #000;
                //     //     background: #B0D4F1;

                //     //     .firstTh {
                //     //         border-right: 0.5px solid #000;
                //     //         border-bottom: 0.5px solid #000;
                //     //         border-left: 0.5px solid #000;
                //     //         background: #D2E7F7;
                //     //         color: #000;
                //     //         font-family: Arial;
                //     //         font-size: 18px;
                //     //         font-style: normal;
                //     //         font-weight: 700;
                //     //         line-height: normal;
                //     //         padding: 10px;

                //     //         .person {
                //     //             padding-left: 25px;
                //     //         }

                //     //         .accountable {
                //     //             // display: flex;
                //     //             // justify-content: end;
                //     //             // padding-left: 97px;
                //     //             // padding-left: 25px;

                //     //         }

                //     //         p {
                //     //             color: #000;
                //     //             font-family: Arial;
                //     //             font-size: 18px;
                //     //             font-style: normal;
                //     //             font-weight: 400;
                //     //             line-height: normal;
                //     //             text-transform: capitalize;
                //     //         }

                //     //         span {
                //     //             color: #000;
                //     //             font-family: Arial;
                //     //             font-size: 18px;
                //     //             font-style: normal;
                //     //             font-weight: 400;
                //     //             line-height: normal;
                //     //             text-transform: lowercase;
                //     //         }
                //     //     }

                //     //     .secondTh {
                //     //         text-align: center;
                //     //         border-right: 0.5px solid #000;
                //     //         color: #000;
                //     //         font-family: Arial;
                //     //         font-size: 16px;
                //     //         font-style: normal;
                //     //         font-weight: 400;
                //     //         line-height: normal;
                //     //         text-transform: capitalize;
                //     //     }

                //     //     .thirdTh {
                //     //         border-right: 0.5px solid #000;

                //     //         .parent {
                //     //             width: 100%;

                //     //             .columA {
                //     //                 // width: 100%;
                //     //                 width: 100%;
                //     //                 border-right: 0.5px solid #000;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }

                //     //             .columB {
                //     //                 // width: 100%;
                //     //                 // width: 40%;
                //     //                 width: 100%;
                //     //                 border-right: 0.5px solid #000;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }

                //     //             .columC {
                //     //                 // width: 100%;
                //     //                 // width: 50%;
                //     //                 width: 100%;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }
                //     //         }
                //     //     }

                //     //     .fourthTh {
                //     //         border-right: 0.5px solid #000;

                //     //         .parent {
                //     //             width: 100%;

                //     //             .columA {
                //     //                 // width: 100%;
                //     //                 width: 100%;
                //     //                 border-right: 0.5px solid #000;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }

                //     //             .columB {
                //     //                 // width: 100%;
                //     //                 // width: 40%;
                //     //                 width: 100%;
                //     //                 border-right: 0.5px solid #000;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }

                //     //             .columC {
                //     //                 // width: 100%;
                //     //                 // width: 50%;
                //     //                 width: 100%;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }
                //     //         }
                //     //     }

                //     //     .fifthTh {
                //     //         .parent {
                //     //             width: 100%;

                //     //             .columA {
                //     //                 // width: 100%;
                //     //                 width: 100%;
                //     //                 border-right: 0.5px solid #000;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }

                //     //             .columB {
                //     //                 // width: 100%;
                //     //                 // width: 40%;
                //     //                 width: 100%;
                //     //                 border-right: 0.5px solid #000;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }

                //     //             .columC {
                //     //                 // width: 100%;
                //     //                 // width: 50%;
                //     //                 width: 100%;
                //     //                 height: 58px;
                //     //                 text-align: center;
                //     //                 color: #000;
                //     //                 font-family: Arial;
                //     //                 font-size: 16px;
                //     //                 font-style: normal;
                //     //                 font-weight: 400;
                //     //                 line-height: normal;
                //     //                 text-transform: capitalize;
                //     //                 display: flex;
                //     //                 justify-content: center;
                //     //                 align-items: center;
                //     //             }
                //     //         }
                //     //     }

                //     // }

                //     .secondRowend {
                //         height: 58px;
                //         border-bottom: 0.5px solid #000;

                //         .develop {
                //             padding-left: 10px;
                //         }

                //         .firstTh {
                //             border-right: 0.5px solid #000;
                //             border-bottom: 0.5px solid #000;
                //             border-left: 0.5px solid #000;
                //             background: white;
                //             color: #000;
                //             font-family: Arial;
                //             font-size: 18px;
                //             font-style: normal;
                //             font-weight: 700;
                //             line-height: normal;
                //             // padding: 10px;

                //             .parent {
                //                 width: 100%;

                //                 .columA {
                //                     // width: 100%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columB {
                //                     // width: 100%;
                //                     // width: 40%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columC {
                //                     // width: 100%;
                //                     // width: 50%;
                //                     width: 100%;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }
                //             }


                //         }

                //         .secondTh {
                //             background: #B0D4F1;
                //             text-align: center;
                //             border-right: 0.5px solid #000;
                //             color: #000;
                //             font-family: Arial;
                //             font-size: 16px;
                //             font-style: normal;
                //             font-weight: 400;
                //             line-height: normal;
                //             text-transform: capitalize;

                //             button {
                //                 border-radius: 10px;
                //                 background: var(--primary-light, #00A2D6);
                //                 padding: 6px 8px;
                //                 color: #FFF;
                //                 font-family: Arial;
                //                 font-size: 14px;
                //                 font-style: normal;
                //                 font-weight: 400;
                //                 line-height: normal;
                //                 text-transform: capitalize;
                //             }
                //         }

                //         .thirdTh {
                //             background: #B0D4F1;
                //             border-right: 0.5px solid #000;

                //             .parent {
                //                 width: 100%;

                //                 .columA {
                //                     // width: 100%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columB {
                //                     // width: 100%;
                //                     // width: 40%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columC {
                //                     // width: 100%;
                //                     // width: 50%;
                //                     width: 100%;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }
                //             }
                //         }

                //         .fourthTh {
                //             background: #B0D4F1;
                //             border-right: 0.5px solid #000;

                //             .parent {
                //                 width: 100%;

                //                 .columA {
                //                     // width: 100%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columB {
                //                     // width: 100%;
                //                     // width: 40%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columC {
                //                     // width: 100%;
                //                     // width: 50%;
                //                     width: 100%;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }
                //             }
                //         }

                //         .fifthTh {
                //             background: #B0D4F1;
                //             border-radius: 0px 0px 10px 0px;

                //             .parent {
                //                 width: 100%;

                //                 .columA {
                //                     // width: 100%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columB {
                //                     // width: 100%;
                //                     // width: 40%;
                //                     width: 100%;
                //                     border-right: 0.5px solid #000;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }

                //                 .columC {
                //                     // width: 100%;
                //                     // width: 50%;
                //                     width: 100%;
                //                     height: 58px;
                //                     text-align: center;
                //                     color: #000;
                //                     font-family: Arial;
                //                     font-size: 16px;
                //                     font-style: normal;
                //                     font-weight: 400;
                //                     line-height: normal;
                //                     text-transform: capitalize;
                //                     display: flex;
                //                     justify-content: center;
                //                     align-items: center;
                //                 }
                //             }
                //         }

                //     }



                // }


            }

            .planButton-next {
                width: 100%;
                padding-block: 30px;
                display: flex;
                justify-content: end;

                button {
                    height: 39px;
                    padding: 4px 10px;
                    border-radius: 4px;
                    background: #01A0D4;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: white;
                }

            }


        }
    }
}


//  standalones
.planButton-next {
    width: 100%;
    padding-block: 30px;
    display: flex;
    justify-content: end;

    button {
        height: 39px;
        padding: 4px 10px;
        border-radius: 4px;
        background: #01A0D4;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: white;
    }

}

@media only screen and (max-width: 1600px) {
    .ninetydplan {
        width: 100%;
        padding-top: 60px;
        background-image: url("../../assets/images/layerimg.png");

        .ninetyplanContent {
            width: 100%;

            .ninetyplan-title {
                text-align: center;
                padding-top: 28px;
                padding-bottom: 55px;

                h6 {
                    color: #0D4D80;

                    font-family: Arial;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 128.214%;
                    /* 33.336px */
                }

                span {
                    color: #8D8D8D;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 128.214%;
                }
            }

            .planscard {
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 30px;
                width: 100%;
                display: flex;
                gap: 36px;

                .leftCard {
                    width: 100%;
                    // height: 160px;
                    border-radius: 10px;
                    background: #F8F8F8;
                    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                    .goalHeader {
                        width: 295.187px;
                        height: 58px;
                        padding: 10px;
                        border-radius: 10px;
                        background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: #FFF;
                            font-family: Arial;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }

                    .sale-section {
                        padding: 18px 29px 9px 29px;

                        span {
                            color: #000;
                            text-align: center;
                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }

                    .goalFooter {
                        height: 54px;
                        padding: 21px 29px 12px 29px;

                        border-top: 1px solid #E2E2E2;

                        span {
                            color: #000;

                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }

                .rightCard {
                    width: 100%;
                    // height: 160px;
                    border-radius: 10px;
                    background: #F8F8F8;
                    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                    .goalHeader {
                        width: 295.187px;
                        height: 58px;
                        padding: 10px;
                        border-radius: 10px;
                        background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: #FFF;
                            font-family: Arial;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }

                    .sale-section {
                        padding: 18px 29px 9px 29px;

                        span {
                            color: #000;
                            text-align: center;
                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }

                    .goalFooter {
                        height: 54px;
                        padding: 21px 29px 12px 29px;

                        border-top: 1px solid #E2E2E2;

                        span {
                            color: #000;

                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }

            .threemonthPlan {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 200px;

                .threemonthContent {
                    width: 100%;

                    .tableheader {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 30px;

                        .dateDiv {
                            padding: 12px 108px 12px 10px;
                            border-radius: 10px;
                            border: 1px solid rgba(0, 0, 0, 0.20);

                            span {
                                color: #000;
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-transform: capitalize;
                            }
                        }

                        .filterdiv {
                            padding: 12px 10px;
                            border-radius: 10px;
                            background: var(--primary-light, #00A2D6);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;

                            span {
                                color: #FFF;
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-transform: capitalize;

                                // display: flex;
                                // align-items: center;
                            }
                        }


                    }
                }


            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .ninetydplan {
        width: 100%;
        padding-top: 60px;
        background-image: url("../../assets/images/layerimg.png");

        .ninetyplanContent {
            width: 100%;

            .ninetyplan-title {
                text-align: center;
                padding-top: 28px;
                padding-bottom: 55px;

                h6 {
                    color: #0D4D80;

                    font-family: Arial;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 128.214%;
                    /* 33.336px */
                }

                span {
                    color: #8D8D8D;
                    font-family: Arial;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 128.214%;
                }
            }

            .planscard {
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 30px;
                width: 100%;
                display: flex;
                gap: 10px;

                .leftCard {
                    width: 100%;
                    // height: 160px;
                    border-radius: 10px;
                    background: #F8F8F8;
                    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                    .goalHeader {
                        width: 295.187px;
                        height: 58px;
                        padding: 10px;
                        border-radius: 10px;
                        background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: #FFF;
                            font-family: Arial;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }

                    .sale-section {
                        padding: 18px 29px 9px 29px;

                        span {
                            color: #000;
                            text-align: center;
                            font-family: Arial;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }

                    .goalFooter {
                        height: 54px;
                        padding: 21px 29px 12px 29px;

                        border-top: 1px solid #E2E2E2;

                        span {
                            color: #000;

                            font-family: Arial;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }

                .rightCard {
                    width: 100%;
                    // height: 160px;
                    border-radius: 10px;
                    background: #F8F8F8;
                    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                    .goalHeader {
                        width: 295.187px;
                        height: 58px;
                        padding: 10px;
                        border-radius: 10px;
                        background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: #FFF;
                            font-family: Arial;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }

                    .sale-section {
                        padding: 18px 29px 9px 29px;

                        span {
                            color: #000;
                            text-align: center;
                            font-family: Arial;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }

                    .goalFooter {
                        height: 54px;
                        padding: 21px 29px 12px 29px;

                        border-top: 1px solid #E2E2E2;

                        span {
                            color: #000;

                            font-family: Arial;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }

            .threemonthPlan {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 200px;
                overflow: scroll;

                .threemonthContent {
                    // width: 1200px;
                    // overflow-x: scroll;
                    width: 1200px;
                    overflow: scroll;

                    .tableheader {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 30px;

                        .dateDiv {
                            padding: 12px 108px 12px 10px;
                            border-radius: 10px;
                            border: 1px solid rgba(0, 0, 0, 0.20);

                            span {
                                color: #000;
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-transform: capitalize;
                            }
                        }

                        .filterdiv {
                            padding: 12px 10px;
                            border-radius: 10px;
                            background: var(--primary-light, #00A2D6);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;

                            span {
                                color: #FFF;
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-transform: capitalize;

                                // display: flex;
                                // align-items: center;
                            }
                        }



                    }

                    // table {
                    //     width: 100%;
                    //     border: 0.5px solid #000;

                    //     .firstRow {
                    //         border-right: 0.5px solid #000;
                    //         border-bottom: 0.5px solid #000;
                    //         border-left: 0.5px solid #000;
                    //         background: #FFF;
                    //         padding: 10px;

                    //         .firstTh {
                    //             // width: 30%;
                    //             height: 58px;
                    //             // height: 58px;
                    //             // padding-bottom: 3px;
                    //             // border-radius: 10px 10px 0px 0px;
                    //             background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                    //             text-align: center;

                    //             span {
                    //                 color: #FFF;
                    //                 font-family: Arial;
                    //                 font-size: 22px;
                    //                 font-style: normal;
                    //                 font-weight: 700;
                    //                 line-height: normal;
                    //                 text-transform: capitalize;
                    //             }
                    //         }

                    //         .secondTh {

                    //             // width: 50%;
                    //             height: 58px;
                    //             // height: 35px;
                    //             padding: 10px;
                    //             display: flex;
                    //             justify-content: center;
                    //             align-items: center;
                    //             // border-bottom: 0.5px solid #000;
                    //             border-right: 0.5px solid #000;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;
                    //         }

                    //         .thirdTh {
                    //             height: 58px;
                    //             // width: 25%;
                    //             // height: 35px;
                    //             padding: 10px;
                    //             text-align: center;

                    //             border-bottom: 0.5px solid #000;
                    //             border-right: 0.5px solid #000;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;
                    //         }

                    //         .fourthTh {
                    //             text-align: center;
                    //             height: 58px;
                    //             // width: 17.5%;
                    //             // height: 35px;
                    //             padding: 10px;
                    //             border-bottom: 0.5px solid #000;
                    //             border-right: 0.5px solid #000;


                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;

                    //         }

                    //         .fifthTh {
                    //             text-align: center;
                    //             height: 58px;
                    //             // width: 17.5%;
                    //             // height: 35px;
                    //             padding: 10px;
                    //             border-bottom: 0.5px solid #000;
                    //             // border-right: 0.5px solid #000;


                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;

                    //         }
                    //     }

                    //     .secondRowP {
                    //         height: 58px;
                    //         border-bottom: 0.5px solid #000;

                    //         .develop {
                    //             text-align: center;

                    //             // padding-left: 10px;
                    //             span {}
                    //         }

                    //         .firstTh {
                    //             border-right: 0.5px solid #000;
                    //             border-bottom: 0.5px solid #000;
                    //             border-left: 0.5px solid #000;
                    //             background: white;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 700;
                    //             line-height: normal;
                    //             // padding: 10px;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }


                    //         }

                    //         .secondTh {
                    //             text-align: center;
                    //             border-right: 0.5px solid #000;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 16px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;
                    //             padding: 10px;

                    //             button {
                    //                 border-radius: 10px;
                    //                 background: var(--primary-light, #00A2D6);
                    //                 padding: 6px 8px;
                    //                 color: #FFF;
                    //                 font-family: Arial;
                    //                 font-size: 14px;
                    //                 font-style: normal;
                    //                 font-weight: 400;
                    //                 line-height: normal;
                    //                 text-transform: capitalize;
                    //             }
                    //         }

                    //         .thirdTh {
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fourthTh {
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fifthTh {
                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //     }

                    //     .secondRow {
                    //         height: 58px;
                    //         border-bottom: 0.5px solid #000;
                    //         background: #B0D4F1;

                    //         .firstTh {
                    //             border-right: 0.5px solid #000;
                    //             border-bottom: 0.5px solid #000;
                    //             border-left: 0.5px solid #000;
                    //             background: #B0D4F1;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 700;
                    //             line-height: normal;
                    //             padding: 10px;


                    //             p {
                    //                 color: #000;
                    //                 font-family: Arial;
                    //                 font-size: 18px;
                    //                 font-style: normal;
                    //                 font-weight: 400;
                    //                 line-height: normal;
                    //                 text-transform: capitalize;
                    //             }

                    //             span {
                    //                 color: #000;
                    //                 font-family: Arial;
                    //                 font-size: 18px;
                    //                 font-style: normal;
                    //                 font-weight: 400;
                    //                 line-height: normal;
                    //                 text-transform: lowercase;
                    //             }
                    //         }

                    //         .secondTh {
                    //             text-align: center;
                    //             border-right: 0.5px solid #000;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 16px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;
                    //         }

                    //         .thirdTh {
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fourthTh {
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fifthTh {
                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //     }

                    //     .thirdRow {
                    //         height: 58px;
                    //         border-bottom: 0.5px solid #000;
                    //         background: #B0D4F1;

                    //         .firstTh {
                    //             border-right: 0.5px solid #000;
                    //             border-bottom: 0.5px solid #000;
                    //             border-left: 0.5px solid #000;
                    //             background: #D2E7F7;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 700;
                    //             line-height: normal;
                    //             padding: 10px;

                    //             p {
                    //                 color: #000;
                    //                 font-family: Arial;
                    //                 font-size: 18px;
                    //                 font-style: normal;
                    //                 font-weight: 400;
                    //                 line-height: normal;
                    //                 text-transform: capitalize;
                    //             }

                    //             span {
                    //                 color: #000;
                    //                 font-family: Arial;
                    //                 font-size: 18px;
                    //                 font-style: normal;
                    //                 font-weight: 400;
                    //                 line-height: normal;
                    //                 text-transform: lowercase;
                    //             }
                    //         }

                    //         .secondTh {
                    //             text-align: center;
                    //             border-right: 0.5px solid #000;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 16px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;
                    //         }

                    //         .thirdTh {
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fourthTh {
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fifthTh {
                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //     }

                    //     .secondRowend {
                    //         height: 58px;
                    //         border-bottom: 0.5px solid #000;

                    //         .develop {
                    //             padding-left: 10px;
                    //         }

                    //         .firstTh {
                    //             border-right: 0.5px solid #000;
                    //             border-bottom: 0.5px solid #000;
                    //             border-left: 0.5px solid #000;
                    //             background: white;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 700;
                    //             line-height: normal;
                    //             // padding: 10px;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }


                    //         }

                    //         .secondTh {
                    //             background: #B0D4F1;
                    //             text-align: center;
                    //             border-right: 0.5px solid #000;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 16px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;

                    //             button {
                    //                 border-radius: 10px;
                    //                 background: var(--primary-light, #00A2D6);
                    //                 padding: 6px 8px;
                    //                 color: #FFF;
                    //                 font-family: Arial;
                    //                 font-size: 14px;
                    //                 font-style: normal;
                    //                 font-weight: 400;
                    //                 line-height: normal;
                    //                 text-transform: capitalize;
                    //             }
                    //         }

                    //         .thirdTh {
                    //             background: #B0D4F1;
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fourthTh {
                    //             background: #B0D4F1;
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fifthTh {
                    //             background: #B0D4F1;
                    //             border-radius: 0px 0px 10px 0px;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //     }


                    // }
                }


            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .ninetydplan {
        width: 100%;
        padding-top: 60px;
        background-image: url("../../assets/images/layerimg.png");

        .ninetyplanContent {
            width: 100%;

            .ninetyplan-title {
                text-align: center;
                padding-top: 28px;
                padding-bottom: 28px;

                h6 {
                    color: #0D4D80;

                    font-family: Arial;
                    font-size: 23px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 128.214%;
                    /* 33.336px */
                }

                span {
                    color: #8D8D8D;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 128.214%;
                }
            }

            .planscard {
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 30px;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 36px;

                .leftCard {
                    width: 100%;
                    // height: 160px;
                    border-radius: 10px;
                    background: #F8F8F8;
                    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                    .goalHeader {
                        width: 100%;
                        // width: 295.187px;
                        height: 58px;
                        padding: 10px;
                        border-radius: 10px;
                        background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: #FFF;
                            font-family: Arial;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }

                    .sale-section {
                        padding: 18px 29px 9px 29px;

                        span {
                            color: #000;
                            text-align: center;
                            font-family: Arial;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }

                    .goalFooter {
                        height: 54px;
                        padding: 21px 29px 12px 29px;

                        border-top: 1px solid #E2E2E2;

                        span {
                            color: #000;

                            font-family: Arial;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }

                .rightCard {
                    width: 100%;
                    // height: 160px;
                    border-radius: 10px;
                    background: #F8F8F8;
                    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                    .goalHeader {
                        width: 100%;
                        // width: 295.187px;
                        height: 58px;
                        padding: 10px;
                        border-radius: 10px;
                        background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            color: #FFF;
                            font-family: Arial;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }

                    .sale-section {
                        padding: 18px 29px 9px 29px;

                        span {
                            color: #000;
                            text-align: center;
                            font-family: Arial;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }

                    .goalFooter {
                        height: 100%;
                        padding: 21px 29px 12px 29px;

                        border-top: 1px solid #E2E2E2;

                        span {
                            color: #000;

                            font-family: Arial;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }

            .threemonthPlan {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 200px;
                overflow: scroll;

                .threemonthContent {
                    // width: 1200px;
                    // overflow-x: scroll;
                    width: 1200px;
                    overflow: scroll;

                    .tableheader {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 30px;

                        .dateDiv {
                            padding: 12px 108px 12px 10px;
                            border-radius: 10px;
                            border: 1px solid rgba(0, 0, 0, 0.20);

                            span {
                                color: #000;
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-transform: capitalize;
                            }
                        }

                        .filterdiv {
                            padding: 12px 10px;
                            border-radius: 10px;
                            background: var(--primary-light, #00A2D6);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;

                            span {
                                color: #FFF;
                                font-family: Arial;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-transform: capitalize;

                                // display: flex;
                                // align-items: center;
                            }
                        }



                    }

                    // table {
                    //     width: 100%;
                    //     border: 0.5px solid #000;

                    //     .firstRow {
                    //         border-right: 0.5px solid #000;
                    //         border-bottom: 0.5px solid #000;
                    //         border-left: 0.5px solid #000;
                    //         background: #FFF;
                    //         padding: 10px;

                    //         .firstTh {
                    //             // width: 30%;
                    //             height: 58px;
                    //             // height: 58px;
                    //             // padding-bottom: 3px;
                    //             // border-radius: 10px 10px 0px 0px;
                    //             background: linear-gradient(90deg, #0B5085 0%, #1573BE 100%);
                    //             text-align: center;

                    //             span {
                    //                 color: #FFF;
                    //                 font-family: Arial;
                    //                 font-size: 22px;
                    //                 font-style: normal;
                    //                 font-weight: 700;
                    //                 line-height: normal;
                    //                 text-transform: capitalize;
                    //             }
                    //         }

                    //         .secondTh {

                    //             // width: 50%;
                    //             height: 58px;
                    //             // height: 35px;
                    //             padding: 10px;
                    //             display: flex;
                    //             justify-content: center;
                    //             align-items: center;
                    //             // border-bottom: 0.5px solid #000;
                    //             border-right: 0.5px solid #000;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;
                    //         }

                    //         .thirdTh {
                    //             height: 58px;
                    //             // width: 25%;
                    //             // height: 35px;
                    //             padding: 10px;
                    //             text-align: center;

                    //             border-bottom: 0.5px solid #000;
                    //             border-right: 0.5px solid #000;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;
                    //         }

                    //         .fourthTh {
                    //             text-align: center;
                    //             height: 58px;
                    //             // width: 17.5%;
                    //             // height: 35px;
                    //             padding: 10px;
                    //             border-bottom: 0.5px solid #000;
                    //             border-right: 0.5px solid #000;


                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;

                    //         }

                    //         .fifthTh {
                    //             text-align: center;
                    //             height: 58px;
                    //             // width: 17.5%;
                    //             // height: 35px;
                    //             padding: 10px;
                    //             border-bottom: 0.5px solid #000;
                    //             // border-right: 0.5px solid #000;


                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;

                    //         }
                    //     }

                    //     .secondRowP {
                    //         height: 58px;
                    //         border-bottom: 0.5px solid #000;

                    //         .develop {
                    //             text-align: center;

                    //             // padding-left: 10px;
                    //             span {}
                    //         }

                    //         .firstTh {
                    //             border-right: 0.5px solid #000;
                    //             border-bottom: 0.5px solid #000;
                    //             border-left: 0.5px solid #000;
                    //             background: white;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 700;
                    //             line-height: normal;
                    //             // padding: 10px;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }


                    //         }

                    //         .secondTh {
                    //             text-align: center;
                    //             border-right: 0.5px solid #000;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 16px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;
                    //             padding: 10px;

                    //             button {
                    //                 border-radius: 10px;
                    //                 background: var(--primary-light, #00A2D6);
                    //                 padding: 6px 8px;
                    //                 color: #FFF;
                    //                 font-family: Arial;
                    //                 font-size: 14px;
                    //                 font-style: normal;
                    //                 font-weight: 400;
                    //                 line-height: normal;
                    //                 text-transform: capitalize;
                    //             }
                    //         }

                    //         .thirdTh {
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fourthTh {
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fifthTh {
                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //     }

                    //     .secondRow {
                    //         height: 58px;
                    //         border-bottom: 0.5px solid #000;
                    //         background: #B0D4F1;

                    //         .firstTh {
                    //             border-right: 0.5px solid #000;
                    //             border-bottom: 0.5px solid #000;
                    //             border-left: 0.5px solid #000;
                    //             background: #B0D4F1;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 700;
                    //             line-height: normal;
                    //             padding: 10px;

                    //             p {
                    //                 color: #000;
                    //                 font-family: Arial;
                    //                 font-size: 18px;
                    //                 font-style: normal;
                    //                 font-weight: 400;
                    //                 line-height: normal;
                    //                 text-transform: capitalize;
                    //             }

                    //             span {
                    //                 color: #000;
                    //                 font-family: Arial;
                    //                 font-size: 18px;
                    //                 font-style: normal;
                    //                 font-weight: 400;
                    //                 line-height: normal;
                    //                 text-transform: lowercase;
                    //             }
                    //         }

                    //         .secondTh {
                    //             text-align: center;
                    //             border-right: 0.5px solid #000;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 16px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;
                    //         }

                    //         .thirdTh {
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fourthTh {
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fifthTh {
                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //     }

                    //     .thirdRow {
                    //         height: 58px;
                    //         border-bottom: 0.5px solid #000;
                    //         background: #B0D4F1;

                    //         .firstTh {
                    //             border-right: 0.5px solid #000;
                    //             border-bottom: 0.5px solid #000;
                    //             border-left: 0.5px solid #000;
                    //             background: #D2E7F7;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 700;
                    //             line-height: normal;
                    //             padding: 10px;

                    //             p {
                    //                 color: #000;
                    //                 font-family: Arial;
                    //                 font-size: 18px;
                    //                 font-style: normal;
                    //                 font-weight: 400;
                    //                 line-height: normal;
                    //                 text-transform: capitalize;
                    //             }

                    //             span {
                    //                 color: #000;
                    //                 font-family: Arial;
                    //                 font-size: 18px;
                    //                 font-style: normal;
                    //                 font-weight: 400;
                    //                 line-height: normal;
                    //                 text-transform: lowercase;
                    //             }
                    //         }

                    //         .secondTh {
                    //             text-align: center;
                    //             border-right: 0.5px solid #000;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 16px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;
                    //         }

                    //         .thirdTh {
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fourthTh {
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fifthTh {
                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //     }

                    //     .secondRowend {
                    //         height: 58px;
                    //         border-bottom: 0.5px solid #000;

                    //         .develop {
                    //             padding-left: 10px;
                    //         }

                    //         .firstTh {
                    //             border-right: 0.5px solid #000;
                    //             border-bottom: 0.5px solid #000;
                    //             border-left: 0.5px solid #000;
                    //             background: white;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 18px;
                    //             font-style: normal;
                    //             font-weight: 700;
                    //             line-height: normal;
                    //             // padding: 10px;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }


                    //         }

                    //         .secondTh {
                    //             background: #B0D4F1;
                    //             text-align: center;
                    //             border-right: 0.5px solid #000;
                    //             color: #000;
                    //             font-family: Arial;
                    //             font-size: 16px;
                    //             font-style: normal;
                    //             font-weight: 400;
                    //             line-height: normal;
                    //             text-transform: capitalize;

                    //             button {
                    //                 border-radius: 10px;
                    //                 background: var(--primary-light, #00A2D6);
                    //                 padding: 6px 8px;
                    //                 color: #FFF;
                    //                 font-family: Arial;
                    //                 font-size: 14px;
                    //                 font-style: normal;
                    //                 font-weight: 400;
                    //                 line-height: normal;
                    //                 text-transform: capitalize;
                    //             }
                    //         }

                    //         .thirdTh {
                    //             background: #B0D4F1;
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fourthTh {
                    //             background: #B0D4F1;
                    //             border-right: 0.5px solid #000;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //         .fifthTh {
                    //             background: #B0D4F1;
                    //             border-radius: 0px 0px 10px 0px;

                    //             .parent {
                    //                 width: 100%;

                    //                 .columA {
                    //                     // width: 100%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columB {
                    //                     // width: 100%;
                    //                     // width: 40%;
                    //                     width: 100%;
                    //                     border-right: 0.5px solid #000;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }

                    //                 .columC {
                    //                     // width: 100%;
                    //                     // width: 50%;
                    //                     width: 100%;
                    //                     height: 58px;
                    //                     text-align: center;
                    //                     color: #000;
                    //                     font-family: Arial;
                    //                     font-size: 16px;
                    //                     font-style: normal;
                    //                     font-weight: 400;
                    //                     line-height: normal;
                    //                     text-transform: capitalize;
                    //                     display: flex;
                    //                     justify-content: center;
                    //                     align-items: center;
                    //                 }
                    //             }
                    //         }

                    //     }


                    // }
                }


            }
        }
    }
}