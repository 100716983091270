.formm {
  /* width: 1253px; */
  /* width: 100%; */
  /* display: flex;
  justify-content: center; */
  margin-left: 5%;
  width: 90%;
  /* align-items: center; */
  height: 541px;
  flex-shrink: 0;
  border-radius: 24.345px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
}
.formm .second h3 {
  color: var(--primary-dark, #0d4d80);
  text-align: center;
  font-family: Inter;
  font-size: 19.674px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.59px;
  margin-right: 13px;
}
.contact_div {
  width: 100%;
}
.formm .contact_div label {
  color: var(--primary-dark, #0d4d80);
  font-family: Inter;
  font-size: 16.395px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.formm .contact_div .first_btn {
  display: flex;
  padding: 1.197px 12.674px;
  justify-content: center;
  align-items: center;
  /* gap: 7.455px; */
  border-radius: 7px;
  /* border: 2.5px solid var(--side-bar, #00a2d6); */
  /* color: #00a2d6; */
  color: #0d4d80;
  background: white;
}
.formm .contact_div .second_btn {
  /* display: flex;
  padding: 4.197px 12.674px;
  justify-content: center;
  align-items: center;
  gap: 7.455px;
  border-radius: 21.473px;

  color: #2b373b;
  margin-left: 7px; */

  display: flex;
  align-items: center;
  justify-content: center;
  color: #4e4e4e;
  text-align: center;
  font-family: Inter;
  font-size: 13.116px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  border: 2.5px solid var(--side-bar, #505a5e);
  border-radius: 10px;
  /* padding: 3px; */
  padding: 1.197px 7.674px;

}
.formm .contact_div .second_btn :hover {
  background-color: gray;
  color: rebeccapurple;
}

@media only screen and (max-width: 993px) {
  .formm .second {
    width: 100%;
    height: 555px;
  }
  .formm .second {
    width: 320px;
  }
  .formm .second .last_div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .formm {
    height: 641px;
  }
  .formm .second h3 {
    font-size: 17px;
  }
  .formm .contact_div label {
    font-size: 15px;
  }
  .formm .contact_div .first_btn {
    /* display: inline-block;
    padding: 4.197px 10.674px;
    justify-content: center;
    align-items: center;
    gap: 4.455px;
    border-radius: 4.473px;
    border: 2.5px solid var(--side-bar, #00a2d6);
    color: #00a2d6;
    font-size: 12px;
    margin-top: 4%; */
    /* margin: 2%; */
    font-weight: 600;
    gap: 9.455px;
  }
}
@media only screen and (max-width: 993px) {
  .formm .second h3 {
    font-size: 17px;
    padding-bottom: 5px;
  }
  .formm .second .last_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180px;
    align-items: center;
  }

}
.forgradientwrap {
  background: linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%);
  border-radius: 10px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons{
  /* margin-bottom: 10px; */
  gap: 10px;
}