.KanbanColumn {
  width: 31.5%;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 300px;
  background-color: white;
  box-shadow: 0px 0px 9.47218132019043px 0px #0000001a;
}

.goal-containers {
  box-shadow: 0px 0px 9.472px 0px rgba(0, 0, 0, 0.1);
}

.KanbanColumns-tasks-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  flex-grow: 1;
}

.kanbanColoumn-header {
  width: 100%;
  height: 55px;
  padding: 15px 9.472px 0px 36px;
  border-radius: 8px 8px 0px 0px;
  background: #b0d4f1;
}

.KanbanColumn-name {
  color: #000;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: start;
}

.kanban-goal-container {
  border-bottom: 0.5px solid black;
  padding: 18px 10px 15px 36px;
  text-align: start;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.kanban-goal {
  text-align: start;
  color: #000;
  font-family: Arial;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.kanban-goalprocess {
  color: #000;
  font-family: Arial;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.kanban-list-conatiner {
  text-align: start;
  width: 100%;
  padding: 12px 43px 0px 50px;
}

.kanban-list-title {
  color: #000;
  font-family: Arial;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.KanbanColumn-footer {
  margin-top: 2rem;
  width: 100%;
}

.KanbanColumn-btn-add {
  padding: 0.3rem;
  border-radius: 5px 5px 10px 10px;
  cursor: pointer;
  width: 100%;
  color: #ffffff;
  letter-spacing: 3px;
  text-transform: capitalize;
  font-weight: 600;
  background-color: rgb(87, 87, 224);
  transition: 0.5s;
}

.KanbanColumn-btn-add:hover {
  background-color: #e4e4e4;
}

.kanban-card-data-container h6 {
  color: #000;
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding-bottom: 8px;
}

@media only screen and (max-width: 839px) {
  .KanbanColumn {
    width: 90%;
    min-width: 100%;
    text-align: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    min-height: 300px;
    background-color: white;
    box-shadow: 0px 0px 9.47218132019043px 0px #0000001a;
  }
}

.onTrack {
  background-color: green;
  color: white !important;
}

.scheduled {
  background-color: purple;
  color: rgb(255, 255, 255) !important;
}

.delayed {
  background-color: #ffce08;
  color: rgb(12, 12, 11) !important;
}

.wont_be_done {
  background-color: red;
  color: black !important;
}

.completed {
  background-color: blue;
  color: white !important;
}

.max-card {
  width: 100%;
  max-width: 600px;
}

.kanban-columns-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  justify-content: center;
  text-align: center;
  /* padding: 10px; */
  margin-top: 10px;
}

.kanban-column {
  background-color: #f0f0f0;
  padding: 8px;
  border-radius: 4px;
  height: fit-content;
}

.kanban-tasks {
  margin-top: 8px;
}

.kanban-tasks-container {
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
}