.add {
  display: inline-flex;
  height: 38px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #01a0d4;
  color: #fff;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.remove {
  height: 20px;
  padding: 0px 10px;
  border-radius: 43px;
  background: #ef5350;
  color: #fff;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.goalContainer {
  height: 53px;
  border-radius: 10px 10px 0px 0px;
  background: var(--side-bar, linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%));
  color: #fff;
}

.accordianContent {
  width: 100%;
  // padding-bottom: 24px;

  .leftside {
    width: 100%;
    // height: 100%;
    position: relative;

    .customerExperience {
      padding: 0px 70px 15px 10px;
      width: 100%;
      border-radius: 12px;
      background: #f6d6d5;

      .header {
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
        color: var(--side-back, #f8f8f8);
        font-family: Arial;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin-bottom: 8px;
      }

      p {
        color: #000;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.btnAddGoal {
  background-color: #0774a8;
  color: #fff;
}

.tactic {
  width: 100%;
  padding-top: 60px;
  background-image: url("../../assets/images/layerimg.png");

  .tacticContent {
    width: 100%;

    justify-content: center;

    .title {
      padding: 0px 619px 82px 619px;
      text-align: center;

      h6 {
        color: #0d4d80;
        text-align: center;
        font-family: Arial;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      span {
        color: #8d8d8d;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .goalsContent {
      width: 100%;
      display: flex;
      gap: 21px;
      padding-left: 104px;
      padding-right: 104px;
      padding-bottom: 30px;

      .leftSide {
        width: 50%;
        // height: 53px;

        .header {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 53px;
          border-radius: 10px 10px 0px 0px;
          background: var(
            --side-bar,
            linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
          );

          span {
            color: #fff;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
        }

        .middle {
          height: 53px;
          padding: 20px 24px 12px 24px;
          background: #f8f8f8;

          span {
            color: #000;

            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .bottom {
          height: 53px;
          padding: 20px 24px 12px 24px;
          background: #f8f8f8;
          border-radius: 0px 0px 10px 10px;
          border-top: 1px solid #e2e2e2;

          span {
            color: #000;
            text-align: center;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .rightSide {
        width: 50%;

        .header {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 53px;
          border-radius: 10px 10px 0px 0px;
          background: var(
            --side-bar,
            linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
          );

          span {
            color: #fff;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
        }

        .middle {
          height: 53px;
          padding: 20px 24px 12px 24px;
          background: #f8f8f8;

          .primary {
            padding: 0px 10px;
            border-radius: 43px;
            background: #01a0d4;
            color: #fff;
            font-family: "Poppins";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            margin-right: 28px;
          }

          span {
            color: #000;

            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .bottom {
          height: 53px;
          padding: 20px 24px 12px 24px;
          background: #f8f8f8;
          border-radius: 0px 0px 10px 10px;
          border-top: 1px solid #e2e2e2;

          .primary {
            padding: 0px 10px;
            border-radius: 43px;
            background: #01a0d4;
            color: #fff;
            font-family: "Poppins";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            margin-right: 5px;
          }

          span {
            color: #000;
            text-align: center;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }

    .accordan {
      padding-left: 104px;
      padding-right: 104px;

      .accordianParent {
        border: none;

        .accordianItem1 {
          border: none;
          margin-top: 20px;
          border-radius: 10px;
          background: #f8f8f8;

          .accordianHeader {
            border: none;
            display: flex;
            justify-content: space-between;

            .accordion-button {
              background: var(
                --side-bar,
                linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
              );

              display: flex;
              justify-content: space-between;
              color: white;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
              filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

              &::after {
                display: none;
              }

              &:focus {
                box-shadow: none;
              }
            }

            .collapsed {
              background: #f8f8f8;
              color: black;
              // z-index: 1;
            }
          }

          .accordion-content {
            background: white;
            // background: #F8F8F8;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

            border-radius: 0px 0px 10px 10px;
            // padding-bottom: 24px;
            padding-bottom: 65px;

            .accordianContent {
              width: 100%;
              display: flex;
              gap: 20px;
              // padding-bottom: 24px;

              .leftside {
                width: 100%;
                // height: 100%;
                position: relative;
                // display: flex;
                // justify-content: end;
                // align-items: end;

                .chat-compoent {
                  width: 100%;
                  // height: 100%;
                }

                .customerExperience {
                  padding: 0px 70px 15px 10px;
                  width: 100%;
                  border-radius: 12px;
                  background: #f6d6d5;

                  .header {
                    width: fit-content;
                    padding: 10px;
                    border-radius: 10px;
                    background: linear-gradient(
                      90deg,
                      #0b5085 0%,
                      #1573be 100%
                    );
                    color: var(--side-back, #f8f8f8);
                    font-family: Arial;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                    margin-bottom: 8px;
                  }

                  p {
                    color: #000;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }
              }

              .rightside {
                width: 100%;

                .rightHeader {
                  padding: 10px 61px 10px 10px;
                  width: 100%;
                  border-radius: 10px;
                  background: linear-gradient(90deg, #0b5085 0%, #1573be 100%);
                  height: 50px;

                  span {
                    color: var(--side-back, #f8f8f8);
                    font-family: Arial;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                  }
                }

                .quarter {
                  width: 100%;
                  // margin-top: 16px;

                  .quarter-header {
                    width: 100%;
                    padding-left: 11px;
                    padding-right: 11px;
                    display: flex;
                    align-items: center;
                    margin-top: 16px;

                    height: 53px;
                    border-radius: 10px 10px 0px 0px;
                    background: var(
                      --side-bar,
                      linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
                    );

                    .goal1 {
                      width: 33.3%;

                      span {
                        color: #fff;
                        font-family: Arial;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                      }
                    }

                    .quarter {
                      width: 33.3%;

                      span {
                        color: #fff;
                        font-family: Arial;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                      }
                    }

                    .removegoal {
                      width: 33.3%;
                      text-align: center;

                      button {
                        padding: 0px 10px;
                        border-radius: 43px;
                        background: #ef5350;
                        color: #fff;
                        font-family: "Poppins";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                      }
                    }

                    .removegoal2 {
                      width: 33.3%;
                      text-align: center;

                      button {
                        border-radius: 43px;
                        background: #01a0d4;
                        padding: 0px 10px;
                        color: #fff;
                        font-family: "Poppins";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                      }
                    }
                  }

                  .quarter-bottom {
                    width: 100%;
                    // display: flex;
                    padding-left: 11px;
                    padding-right: 11px;
                    // display: flex;
                    // justify-content: space-between;
                    // align-items: center;
                    border-radius: 0px 0px 10px 10px;
                    background: #f8f8f8;
                    // height: 55px;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
                    padding-top: 10px;
                    padding-bottom: 10px;

                    // filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                    .currentProcess {
                      width: 100%;

                      span {
                        color: #000;
                        font-family: Arial;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                      }
                    }

                    .selectQuarter {
                      width: 33.3%;

                      .first-quarter {
                        display: flex;
                        width: 100%;
                        background-color: #e6e6e6;
                        // justify-content: end;

                        .inputbox {
                          display: flex;
                          align-items: center;
                          justify-content: space-around;
                          gap: 2px;
                          width: 100%;
                          // gap: 0px;
                          // width: 90px;
                          // height: 22px;
                          padding: 0px 2px 0px 2px;
                          // padding: 2px 2px 2px 6px;
                          border-radius: 4px;
                          background: #e6e6e6;
                          cursor: pointer;
                          position: relative;

                          .leftside {
                            color: #000;
                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: capitalize;
                            //   padding: 5px;
                            margin-top: 2px;
                            margin-left: 2px;
                            // width: 52px;
                          }

                          .rightside {
                            width: 20px;
                            // width: 18px;
                            // height: 18px;
                            margin-right: 5px;
                          }

                          .dropdown {
                            position: absolute;
                            top: 25px;
                            width: 100%;
                            z-index: 9;

                            .firstdropdown {
                              width: 100%;
                              border-bottom: 0.2px solid #8d8d8d;
                              background: #fff;
                              height: 22.241px;
                              padding: 2px 2px 2px 6px;
                              display: flex;
                              align-items: center;

                              span {
                                color: #000;
                                font-family: Arial;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                              }
                            }

                            .lastdropdown {
                              background: #fff;
                              height: 22.241px;
                              padding: 2px 2px 2px 6px;
                              display: flex;
                              align-items: center;

                              span {
                                color: #000;
                                font-family: Arial;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                              }
                            }
                          }
                        }

                        // .inputboxGreen {
                        //     background: #F0FFDF;
                        //     color: #9CCC65;

                        // }

                        // .addinClr {

                        //     background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);
                        // }

                        // .inputtextgreen {
                        //     color: #9CCC65;
                        // }
                      }
                    }

                    .currentProcess {
                      width: 100%;
                      text-align: center;

                      input {
                        display: flex;
                        width: 100%;
                        height: 54px;
                        padding: 10px 16px;
                        align-items: center;
                        gap: 8px;
                        border-radius: 12px;
                        background: #e6e6e6;
                        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
                        border: none;

                        &::placeholder {
                          opacity: 0.6;
                          color: #999;
                          font-family: Arial;
                          font-size: 18px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                        }
                      }

                      button {
                        border-radius: 43px;
                        background: #ef5350;
                        padding: 0px 10px;
                        color: #fff;
                        font-family: "Poppins";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                      }
                    }
                  }

                  .newquarter-sectin {
                    background-color: white;
                    width: 100%;
                    // height: 100px;
                    margin-top: 16px;
                    margin-bottom: 16px;

                    .header-quarter {
                      padding-left: 11px;
                      padding-right: 11px;
                      width: 100%;
                      display: flex;
                      // justify-content: space-between;
                      align-items: center;
                      height: 53px;
                      border-radius: 10px 10px 0px 0px;
                      background: var(
                        --side-bar,
                        linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
                      );

                      .leftHeaders {
                        width: 33.3%;

                        span {
                          color: #fff;
                          font-family: Arial;
                          font-size: 18px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }

                      .middleHeaders {
                        width: 33.3%;

                        span {
                          color: #fff;
                          font-family: Arial;
                          font-size: 18px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }

                      .rightHeaders {
                        width: 33.3%;

                        span {
                          button {
                            padding: 0px 10px;
                            border-radius: 43px;
                            background: #01a0d4;
                            color: #fff;
                            font-family: "Poppins";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                          }
                        }
                      }
                    }

                    .middle-quarter {
                      padding-left: 11px;
                      padding-right: 11px;
                      width: 100%;
                      display: flex;
                      // justify-content: space-between;
                      align-items: center;
                      height: 55px;
                      // border-radius: 10px 10px 0px 0px;
                      background: #f8f8f8;
                      margin-top: 10px;
                      margin-bottom: 10px;

                      .leftHeaders {
                        width: 85%;
                        padding-right: 10px;

                        input {
                          width: 100%;
                          background-color: #f8f8f8;
                          height: 100%;
                          padding: 17px 11px 17px 11px;
                          border: none;

                          &::placeholder {
                            color: #999;
                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: capitalize;
                          }
                        }
                      }

                      .middleHeaders {
                        width: 33.3%;
                        padding-right: 10px;

                        .tactical-dropdown {
                          width: 100%;
                          height: 100%;

                          .tactical-dropdowntoggle {
                            width: 100%;
                            height: 100%;
                            background: #e6e6e6;
                            border: none;
                            color: black;
                            color: #000;
                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: capitalize;
                          }

                          .dropdown-menu.show {
                            width: 100%;
                          }
                        }
                      }

                      .rightHeaders {
                        width: 33.3%;

                        span {
                          button {
                            height: 20px;
                            padding: 0px 10px;
                            border-radius: 43px;
                            background: #ef5350;
                            color: #fff;
                            font-family: "Poppins";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                          }
                        }
                      }
                    }
                  }

                  .addstep-section {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    // gap: 10px;
                    padding: 5px 0;

                    .left-sectionprompt {
                      width: 85%;

                      input {
                        display: flex;
                        width: 100%;
                        height: 54px;
                        padding: 10px 16px;
                        align-items: center;
                        gap: 8px;
                        border-radius: 12px;
                        background: #e6e6e6;
                        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
                        border: none;

                        &::placeholder {
                          opacity: 0.6;
                          color: #999;
                          font-family: Arial;
                          font-size: 18px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                        }
                      }
                    }

                    .right-sectionprompt {
                      width: 15%;
                      display: flex;
                      justify-content: center;

                      button {
                        display: inline-flex;
                        height: 38px;
                        padding: 0px 10px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 8px;
                        background: #01a0d4;
                        color: #fff;
                        font-family: "Poppins";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                      }
                    }
                  }
                }
              }
            }
          }

          &:active {
          }
        }
      }
    }

    .submitButton {
      padding-left: 102px;
      padding-right: 102px;
      padding-top: 38px;
      padding-bottom: 38px;
      width: 100%;
      display: flex;
      justify-content: end;

      .tacticSubmit {
        display: inline-flex;
        padding: 4px 10px;
        border-radius: 4px;
        background: #9ccc65;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        color: white;
        margin-right: 10px;
      }

      .tacticsave {
        border-radius: 4px;
        background: #01a0d4;
        padding: 4px 10px;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: white;
      }
    }
  }
}

.chatbot-section {
  width: 100%;
  position: relative;

  .chatbot-content {
    width: 50px;
    height: 50px;

    .divsss {
      width: 40%;
      // background-color: #00a2d6;
      position: fixed;
      // left: 0px;
      // right: 150px;
      left: 90px;
      bottom: 50px;
      z-index: 999;
      // cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .tactic {
    width: 100%;
    padding-top: 60px;
    background-image: url("../../assets/images/layerimg.png");

    .tacticContent {
      width: 100%;

      justify-content: center;

      .title {
        padding: 0px 100px 82px 100px;
        text-align: center;

        h6 {
          color: #0d4d80;
          text-align: center;
          font-family: Arial;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        span {
          color: #8d8d8d;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .goalsContent {
        width: 100%;
        display: flex;
        gap: 21px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 30px;
      }

      .accordan {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }

  .chatbot-section {
    width: 100%;
    position: relative;

    .chatbot-content {
      width: 50px;
      height: 50px;

      .divsss {
        width: 40%;
        // background-color: #00a2d6;
        position: fixed;
        // left: 0px;
        // right: 150px;
        left: 40px;
        bottom: 50px;
        z-index: 999;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 1350px) {
  .tactic {
    width: 100%;
    padding-top: 60px;
    background-image: url("../../assets/images/layerimg.png");

    .tacticContent {
      width: 100%;

      justify-content: center;

      .title {
        padding: 0px 100px 82px 100px;
        text-align: center;
      }

      .goalsContent {
        width: 100%;
        display: flex;
        gap: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;

        .leftSide {
          width: 50%;
          // height: 53px;

          .header {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 53px;
            border-radius: 10px 10px 0px 0px;
            background: var(
              --side-bar,
              linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
            );

            span {
              color: #fff;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .middle {
            height: 70px;
            padding: 20px 24px 12px 24px;
            background: #f8f8f8;

            span {
              color: #000;

              font-family: Arial;
              font-size: 17px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .bottom {
            height: 53px;
            padding: 20px 24px 12px 24px;
            background: #f8f8f8;
            border-radius: 0px 0px 10px 10px;
            border-top: 1px solid #e2e2e2;

            span {
              color: #000;
              text-align: center;
              font-family: Arial;
              font-size: 17px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .rightSide {
          width: 50%;

          .header {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 53px;
            border-radius: 10px 10px 0px 0px;
            background: var(
              --side-bar,
              linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
            );

            span {
              color: #fff;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .middle {
            height: 70px;
            padding: 20px 10px 12px 10px;
            background: #f8f8f8;

            .primary {
              padding: 0px 10px;
              border-radius: 43px;
              background: #01a0d4;
              color: #fff;
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
              margin-right: 28px;
            }

            span {
              color: #000;

              font-family: Arial;
              font-size: 17px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .bottom {
            height: 53px;
            padding: 20px 10px 12px 10px;
            background: #f8f8f8;
            border-radius: 0px 0px 10px 10px;
            border-top: 1px solid #e2e2e2;

            .primary {
              padding: 0px 10px;
              border-radius: 43px;
              background: #01a0d4;
              color: #fff;
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
              margin-right: 5px;
            }

            span {
              color: #000;
              text-align: center;
              font-family: Arial;
              font-size: 17px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }

      .accordan {
        padding-left: 20px;
        padding-right: 20px;

        .accordianParent {
          border: none;

          .accordianItem1 {
            border: none;
            margin-top: 20px;
            border-radius: 10px;
            background: #f8f8f8;

            .accordianHeader {
              border: none;
              display: flex;
              justify-content: space-between;

              .accordion-button {
                background: var(
                  --side-bar,
                  linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
                );

                display: flex;
                justify-content: space-between;
                color: white;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                &::after {
                  display: none;
                }

                &:focus {
                  box-shadow: none;
                }
              }

              .collapsed {
                background: #f8f8f8;
                color: black;
              }
            }

            .accordion-content {
              background: #f8f8f8;
              // filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
              border-radius: 0px 0px 10px 10px;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

              .accordianContent {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .leftside {
                  width: 100%;

                  .customerExperience {
                    padding: 0px 70px 15px 10px;
                    width: 100%;
                    border-radius: 12px;
                    background: #f6d6d5;

                    .header {
                      width: fit-content;
                      padding: 10px;
                      border-radius: 10px;
                      background: linear-gradient(
                        90deg,
                        #0b5085 0%,
                        #1573be 100%
                      );
                      color: var(--side-back, #f8f8f8);
                      font-family: Arial;
                      font-size: 20px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                      margin-bottom: 8px;
                    }

                    p {
                      color: #000;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    }
                  }
                }

                .rightside {
                  width: 100%;

                  .rightHeader {
                    padding: 10px 61px 10px 10px;
                    width: 100%;
                    border-radius: 10px;
                    background: linear-gradient(
                      90deg,
                      #0b5085 0%,
                      #1573be 100%
                    );
                    height: 50px;

                    span {
                      color: var(--side-back, #f8f8f8);
                      font-family: Arial;
                      font-size: 20px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }

                  .quarter {
                    width: 100%;
                    // margin-top: 16px;

                    .quarter-header {
                      width: 100%;
                      padding-left: 11px;
                      padding-right: 11px;
                      display: flex;
                      gap: 20px;
                      align-items: center;
                      margin-top: 16px;

                      height: 53px;
                      border-radius: 10px 10px 0px 0px;
                      background: var(
                        --side-bar,
                        linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
                      );

                      .goal1 {
                        width: 33.3%;

                        span {
                          color: #fff;
                          font-family: Arial;
                          font-size: 18px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }

                      .quarter {
                        width: 33.3%;

                        span {
                          color: #fff;
                          font-family: Arial;
                          font-size: 18px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }

                      .removegoal {
                        width: 33.3%;
                        text-align: start;

                        button {
                          padding: 0px 10px;
                          border-radius: 43px;
                          background: #ef5350;
                          color: #fff;
                          font-family: "Poppins";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }

                      .removegoal2 {
                        width: 33.3%;
                        text-align: start;

                        button {
                          border-radius: 43px;
                          background: #01a0d4;
                          padding: 0px 10px;
                          color: #fff;
                          font-family: "Poppins";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }
                    }

                    .quarter-bottom {
                      //   display: flex;
                      padding-left: 11px;
                      padding-right: 11px;
                      gap: 20px;
                      //   justify-content: space-between;
                      //   align-items: center;
                      border-radius: 0px 0px 10px 10px;
                      background: #f8f8f8;
                      height: 55px;
                      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

                      // filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                      .currentProcess {
                        width: 33.3%;

                        span {
                          color: #000;
                          font-family: Arial;
                          font-size: 18px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          text-transform: capitalize;

                          .addstep-section {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            // gap: 10px;
                            padding: 5px 0;

                            .left-sectionprompt {
                              width: 85%;

                              input {
                                display: flex;
                                width: 100%;
                                height: 54px;
                                padding: 10px 16px;
                                align-items: center;
                                gap: 8px;
                                border-radius: 12px;
                                background: #e6e6e6;
                                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
                                border: none;

                                &::placeholder {
                                  opacity: 0.6;
                                  color: #999;
                                  font-family: Arial;
                                  font-size: 18px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: normal;
                                }
                              }
                            }

                            .right-sectionprompt {
                              width: 15%;
                              display: flex;
                              justify-content: center;

                              button {
                                display: inline-flex;
                                height: 38px;
                                padding: 0px 10px;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                border-radius: 8px;
                                background: #01a0d4;
                                color: #fff;
                                font-family: "Poppins";
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: capitalize;
                              }
                            }
                          }
                        }
                      }

                      .selectQuarter {
                        width: 33.3%;

                        .first-quarter {
                          display: flex;
                          width: 100%;
                          background-color: #e6e6e6;
                          // justify-content: end;

                          .inputbox {
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                            gap: 2px;
                            width: 100%;
                            // gap: 0px;
                            // width: 90px;
                            // height: 22px;
                            padding: 0px 2px 0px 2px;
                            // padding: 2px 2px 2px 6px;
                            border-radius: 4px;
                            background: #e6e6e6;
                            cursor: pointer;
                            position: relative;

                            .leftside {
                              color: #000;
                              font-family: Arial;
                              font-size: 18px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: normal;
                              text-transform: capitalize;
                              //   padding: 5px;
                              margin-top: 2px;
                              margin-left: 2px;
                              // width: 52px;
                            }

                            .rightside {
                              width: 20px;
                              // width: 18px;
                              // height: 18px;
                              margin-right: 5px;
                            }

                            .dropdown {
                              position: absolute;
                              top: 25px;
                              width: 100%;
                              z-index: 9;

                              .firstdropdown {
                                width: 100%;
                                border-bottom: 0.2px solid #8d8d8d;
                                background: #fff;
                                height: 22.241px;
                                padding: 2px 2px 2px 6px;
                                display: flex;
                                align-items: center;

                                span {
                                  color: #000;
                                  font-family: Arial;
                                  font-size: 14px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: normal;
                                }
                              }

                              .lastdropdown {
                                background: #fff;
                                height: 22.241px;
                                padding: 2px 2px 2px 6px;
                                display: flex;
                                align-items: center;

                                span {
                                  color: #000;
                                  font-family: Arial;
                                  font-size: 14px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: normal;
                                }
                              }
                            }
                          }

                          // .inputboxGreen {
                          //     background: #F0FFDF;
                          //     color: #9CCC65;

                          // }

                          // .addinClr {

                          //     background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);
                          // }

                          // .inputtextgreen {
                          //     color: #9CCC65;
                          // }
                        }
                      }

                      .currentProcess {
                        width: 33.3%;
                        text-align: start;

                        button {
                          border-radius: 43px;
                          background: #ef5350;
                          padding: 0px 10px;
                          color: #fff;
                          font-family: "Poppins";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }
                    }
                  }
                }
              }
            }

            &:active {
            }
          }
        }
      }

      .submitButton {
        padding-left: 102px;
        padding-right: 102px;
        padding-top: 38px;
        padding-bottom: 38px;
        width: 100%;
        display: flex;
        justify-content: end;

        .tacticSubmit {
          display: inline-flex;
          padding: 4px 10px;
          border-radius: 4px;
          background: #9ccc65;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          color: white;
          margin-right: 10px;
        }

        .tacticsave {
          border-radius: 4px;
          background: #01a0d4;
          padding: 4px 10px;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: white;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .tactic {
    width: 100%;
    padding-top: 60px;
    background-image: url("../../assets/images/layerimg.png");

    .tacticContent {
      width: 100%;

      justify-content: center;

      .title {
        padding: 30px 10px 30px 10px;
        text-align: center;
      }

      .goalsContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 30px;

        .leftSide {
          width: 100%;
          // height: 53px;

          .header {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 53px;
            border-radius: 10px 10px 0px 0px;
            background: var(
              --side-bar,
              linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
            );

            span {
              color: #fff;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .middle {
            height: 53px;
            padding: 20px 24px 12px 24px;
            background: #f8f8f8;

            span {
              color: #000;

              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .bottom {
            height: 53px;
            padding: 20px 24px 12px 24px;
            background: #f8f8f8;
            border-radius: 0px 0px 10px 10px;
            border-top: 1px solid #e2e2e2;

            span {
              color: #000;
              text-align: center;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .rightSide {
          width: 100%;

          .header {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 53px;
            border-radius: 10px 10px 0px 0px;
            background: var(
              --side-bar,
              linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
            );

            span {
              color: #fff;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .middle {
            height: 63px;
            padding: 10px 0px 12px 10px;
            background: #f8f8f8;

            .primary {
              padding: 0px 10px;
              border-radius: 43px;
              background: #01a0d4;
              color: #fff;
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
              margin-right: 10px;
            }

            span {
              color: #000;

              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .bottom {
            height: 100%;
            // height: 53px;
            padding: 10px 10px 12px 10px;
            background: #f8f8f8;
            border-radius: 0px 0px 10px 10px;
            border-top: 1px solid #e2e2e2;

            .primary {
              padding: 0px 10px;
              border-radius: 43px;
              background: #01a0d4;
              color: #fff;
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
              margin-right: 5px;
            }

            span {
              color: #000;
              text-align: center;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }

      .accordan {
        padding-left: 10px;
        padding-right: 0px;

        .accordianParent {
          border: none;

          .accordianItem1 {
            border: none;
            margin-top: 20px;
            border-radius: 10px;
            background: #f8f8f8;

            .accordianHeader {
              border: none;
              display: flex;
              justify-content: space-between;

              .accordion-button {
                background: var(
                  --side-bar,
                  linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
                );

                display: flex;
                justify-content: space-between;
                color: white;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                &::after {
                  display: none;
                }

                &:focus {
                  box-shadow: none;
                }
              }

              .collapsed {
                background: #f8f8f8;
                color: black;
              }
            }

            .accordion-content {
              // overflow-x: scroll;

              // width: 550px;

              background: #f8f8f8;
              background: #f8f8f8;
              // filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
              border-radius: 0px 0px 10px 10px;
              padding: 3px;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

              .accordianContent {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .leftside {
                  width: 100%;

                  .customerExperience {
                    padding: 0px 0px 15px 0px;
                    width: 100%;
                    border-radius: 12px;
                    background: #f6d6d5;

                    .header {
                      width: 100%;
                      padding: 10px;
                      border-radius: 10px;
                      background: linear-gradient(
                        90deg,
                        #0b5085 0%,
                        #1573be 100%
                      );
                      color: var(--side-back, #f8f8f8);
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                      margin-bottom: 8px;
                    }

                    p {
                      color: #000;
                      font-family: Arial;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      padding-left: 10px;
                      // text-align: center;
                    }
                  }
                }

                .rightside {
                  width: 100%;

                  .rightHeader {
                    padding: 10px 10px 10px 10px;
                    width: 100%;
                    border-radius: 10px;
                    background: linear-gradient(
                      90deg,
                      #0b5085 0%,
                      #1573be 100%
                    );
                    height: 50px;

                    span {
                      color: var(--side-back, #f8f8f8);
                      font-family: Arial;
                      font-size: 15px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-transform: capitalize;
                    }
                  }

                  .quarter {
                    width: 100%;
                    // margin-top: 16px;

                    .quarter-header {
                      width: 100%;
                      padding-left: 2px;
                      padding-right: 2px;
                      display: flex;
                      align-items: center;
                      margin-top: 16px;

                      height: 53px;
                      border-radius: 10px 10px 0px 0px;
                      background: var(
                        --side-bar,
                        linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
                      );

                      .goal1 {
                        width: 33.3%;

                        span {
                          color: #fff;
                          font-family: Arial;
                          font-size: 18px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }

                      .quarter {
                        width: 33.3%;

                        span {
                          color: #fff;
                          font-family: Arial;
                          font-size: 18px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }

                      .removegoal {
                        width: 33.3%;
                        text-align: start;
                        padding-left: 11px;

                        button {
                          padding: 0px 7px;
                          border-radius: 43px;
                          background: #ef5350;
                          color: #fff;
                          font-family: "Poppins";
                          font-size: 10px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }

                      .removegoal2 {
                        width: 33.3%;
                        text-align: start;
                        padding-left: 10px;

                        button {
                          border-radius: 43px;
                          background: #01a0d4;
                          padding: 0px 7px;
                          color: #fff;
                          font-family: "Poppins";
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }
                    }

                    .quarter-bottom {
                      width: 100%;
                      //   display: flex;
                      padding-left: 3px;
                      padding-right: 3px;
                      //   display: flex;
                      //   justify-content: space-between;
                      //   align-items: center;
                      border-radius: 0px 0px 10px 10px;
                      background: #f8f8f8;
                      height: fit-content !important;
                      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

                      // filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

                      .currentProcess {
                        width: 100%;

                        span {
                          color: #000;
                          font-family: Arial;
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          text-transform: capitalize;

                          .addstep-section {
                            width: 100%;
                            display: flex;
                            align-items: center;

                            // gap: 10px;
                            // padding: 5px 0;
                            .left-sectionprompt {
                              width: 80%;

                              input {
                                display: flex;
                                width: 100%;
                                height: 35px;
                                padding: 10px 16px;
                                align-items: center;
                                gap: 8px;
                                border-radius: 12px;
                                background: #e6e6e6;
                                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
                                border: none;

                                &::placeholder {
                                  opacity: 0.6;
                                  color: #999;
                                  font-family: Arial;
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: normal;
                                }
                              }
                            }

                            .right-sectionprompt {
                              width: 20%;
                              display: flex;
                              justify-content: center;

                              button {
                                display: inline-flex;
                                height: 38px;
                                padding: 0px 10px;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                border-radius: 8px;
                                background: #01a0d4;
                                color: #fff;
                                font-family: "Poppins";
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: capitalize;
                              }
                            }
                          }
                        }
                      }

                      .selectQuarter {
                        width: 33.3%;

                        .first-quarter {
                          display: flex;
                          width: 100%;
                          background-color: #e6e6e6;
                          // justify-content: end;

                          .inputbox {
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                            gap: 2px;
                            width: 100%;
                            // gap: 0px;
                            // width: 90px;
                            // height: 22px;
                            padding: 0px 2px 0px 2px;
                            // padding: 2px 2px 2px 6px;
                            border-radius: 4px;
                            background: #e6e6e6;
                            cursor: pointer;
                            position: relative;

                            .leftside {
                              color: #000;
                              font-family: Arial;
                              font-size: 13px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: normal;
                              text-transform: capitalize;
                              //   padding: 5px;
                              margin-top: 2px;
                              margin-left: 2px;
                              margin-bottom: 2px;
                              // width: 52px;
                            }

                            .rightside {
                              width: 20px;
                              // width: 18px;
                              // height: 18px;
                              margin-right: 5px;

                              svg {
                                width: 14px;
                                height: 14px;
                              }
                            }

                            .dropdown {
                              position: absolute;
                              top: 25px;
                              width: 100%;
                              z-index: 9;

                              .firstdropdown {
                                width: 100%;
                                border-bottom: 0.2px solid #8d8d8d;
                                background: #fff;
                                height: 22.241px;
                                padding: 2px 2px 2px 6px;
                                display: flex;
                                align-items: center;

                                span {
                                  color: #000;
                                  font-family: Arial;
                                  font-size: 14px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: normal;
                                }
                              }

                              .lastdropdown {
                                background: #fff;
                                height: 22.241px;
                                padding: 2px 2px 2px 6px;
                                display: flex;
                                align-items: center;

                                span {
                                  color: #000;
                                  font-family: Arial;
                                  font-size: 14px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: normal;
                                }
                              }
                            }
                          }

                          // .inputboxGreen {
                          //     background: #F0FFDF;
                          //     color: #9CCC65;

                          // }

                          // .addinClr {

                          //     background: linear-gradient(90deg, #00A2D6 0%, rgba(0, 162, 214, 0.58) 100%);
                          // }

                          // .inputtextgreen {
                          //     color: #9CCC65;
                          // }
                        }
                      }

                      .currentProcess {
                        width: 100%;
                        text-align: start;
                        padding-left: 3px;

                        button {
                          border-radius: 43px;
                          background: #ef5350;
                          padding: 0px 10px;
                          color: #fff;
                          font-family: "Poppins";
                          font-size: 10px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }
                    }

                    .newquarter-sectin {
                      background-color: white;
                      width: 100%;
                      // height: 100px;
                      margin-top: 16px;
                      margin-bottom: 16px;

                      .header-quarter {
                        padding-left: 11px;
                        padding-right: 11px;
                        width: 100%;
                        display: flex;
                        // justify-content: space-between;
                        align-items: center;
                        height: 53px;
                        border-radius: 10px 10px 0px 0px;
                        background: var(
                          --side-bar,
                          linear-gradient(90deg, #00a2d6 0%, #0d4d80 100%)
                        );

                        .leftHeaders {
                          width: 40.3%;

                          span {
                            color: #fff;
                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: capitalize;
                          }
                        }

                        .middleHeaders {
                          width: 40.3%;

                          span {
                            color: #fff;
                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: capitalize;
                          }
                        }

                        .rightHeaders {
                          width: 33.3%;

                          span {
                            button {
                              padding: 0px 10px;
                              border-radius: 43px;
                              background: #01a0d4;
                              color: #fff;
                              font-family: "Poppins";
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 500;
                              line-height: normal;
                              text-transform: capitalize;
                            }
                          }
                        }
                      }

                      .middle-quarter {
                        padding-left: 1px;
                        padding-right: 1px;
                        width: 100%;
                        display: flex;
                        // justify-content: space-between;
                        align-items: center;
                        height: 55px;
                        // border-radius: 10px 10px 0px 0px;
                        background: #f8f8f8;
                        margin-top: 10px;
                        margin-bottom: 10px;

                        .leftHeaders {
                          width: 40.3%;
                          padding-right: 0px;

                          input {
                            width: 100%;
                            background-color: #f8f8f8;
                            height: 100%;
                            padding: 17px 11px 17px 11px;
                            border: none;

                            &::placeholder {
                              color: #999;
                              font-family: Arial;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: normal;
                              text-transform: capitalize;
                            }
                          }
                        }

                        .middleHeaders {
                          width: 40.3%;
                          padding-right: 4px;

                          .tactical-dropdown {
                            width: 100%;
                            height: 100%;

                            .tactical-dropdowntoggle {
                              width: 100%;
                              height: 100%;
                              background: #e6e6e6;
                              border: none;
                              color: black;
                              color: #000;
                              font-family: Arial;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: normal;
                              text-transform: capitalize;
                            }

                            .dropdown-menu.show {
                              width: 100%;
                            }
                          }
                        }

                        .rightHeaders {
                          width: 33.3%;

                          span {
                            button {
                              height: 20px;
                              padding: 0px 10px;
                              border-radius: 43px;
                              background: #ef5350;
                              color: #fff;
                              font-family: "Poppins";
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 500;
                              line-height: normal;
                              text-transform: capitalize;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &:active {
            }
          }
        }
      }

      .submitButton {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 38px;
        padding-bottom: 38px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .tacticSubmit {
          display: inline-flex;
          padding: 4px 10px;
          border-radius: 4px;
          background: #9ccc65;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          color: white;
          margin-right: 10px;
        }

        .tacticsave {
          border-radius: 4px;
          background: #01a0d4;
          padding: 4px 10px;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: white;
        }
      }
    }
  }

  .chatbot-section {
    width: 100%;
    position: relative;

    .chatbot-content {
      width: 50px;
      height: 50px;

      .divsss {
        width: 100%;
        // background-color: #00a2d6;
        position: fixed;
        // left: 0px;
        // right: 150px;
        left: 5px;
        // right: 10px;
        bottom: 50px;
        z-index: 999;
        cursor: pointer;
      }
    }
  }
}
