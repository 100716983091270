body {
  /* margin: 0;
  font: 1em/1.5 sans-serif;
  padding: 2em; */
}

:root {
  box-sizing: border-box;
}



h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
}

/* input[type="text"],
input[type="email"],
input[type="password"],
button {
    padding: 0.5em;
    font: inherit;
    line-height: 1;
} */

button {
  cursor: pointer;
}

p {
  margin: 0;
}

/* p:not(:last-child) {
    margin-bottom: 1.5em;
} */

.dialog {
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog::backdrop {
  background: hsl(0 0% 0% / 50%);
}

.dialog-close-btn {
  font-size: 0.75em;
  position: absolute;
  top: 0.25em;
  right: 0.25em;
}