.goal {
  // margin-top: 110px;
  padding-top: 60px;
  width: 100%;
  background-image: url("../../assets/images/layerimg.png");

  .goalContent {
    padding: 0px 73px 0px 104px;

    .titleheading {
      padding: 88px 0px 0px 0px;
      // padding: 88px 0px 0px 30px;
      width: 100%;

      .goaltitle {
        width: 100%;

        padding: 19.232px 30px 18.27px 30px;
        // border-bottom: 0.962px solid #EAECF0;
        // background: #FFF;
        background-color: var(--white);
        border-bottom: 0.962px solid #eaecf0;
        background: #fff;
        box-shadow: 6px 6px 16px 0px rgba(0, 0, 0, 0.1),
          -2px -2px 16px 0px rgba(187, 210, 255, 0.5);

        h5 {
          color: #0d4d80;
          font-family: Arial;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 4px;
        }

        span {
          color: #0d4d80;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }

    .performance-card {
      padding-top: 26px;
      padding-bottom: 100px;

      .performance-content {
        display: flex;
        gap: 33px;

        .left-side {
          padding-top: 32px;

          .content-div {
            width: 205px;
            text-align: center;
            border-radius: 8px;
            // background: linear-gradient(90deg, #00D2FF 0%, #3A7BD5 100%);
            background: #00a2d6;
            padding: 10px 10px 10px 10px;

            h6 {
              color: #fff;

              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 167.886%;
              /* 26.862px */
            }

            p {
              color: #fff;
              font-family: Arial;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 167.886%;
            }
          }

          .revenge-clr {
            background: linear-gradient(90deg, #c84e89 0%, #f15f79 100%);
          }

          .revenge-clr {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            // background: rgba(13, 77, 128, 0.9);
            background: #00a2d6;

            // background: linear-gradient(90deg, #C84E89 0%, #F15F79 100%);
          }

          .Profit-clr {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            border-radius: 8px;
            // background: rgba(13, 77, 128, 0.85);
            background: #00a2d6;
            // background: linear-gradient(90deg, #BF5AE0 0%, #A811DA 100%);
          }

          .teamsize-clr {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            border-radius: 8px;
            // background: rgba(13, 77, 128, 0.8);
            background: #00a2d6;
            // background: linear-gradient(90deg, #FFE259 0%, #FFA751 100%);
          }

          .Reach-clr {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            border-radius: 8px;
            // background: rgba(13, 77, 128, 0.75);
            background: #00a2d6;
            // background: linear-gradient(90deg, #2193B0 0%, #6DD5ED 100%);
          }
        }

        .left-sides {
          padding-top: 50px;

          .content-div {
            width: 205px;
            text-align: center;
            border-radius: 8px;
            background: #00a2d6;

            // background: linear-gradient(90deg, #00D2FF 0%, #3A7BD5 100%);
            padding: 10px 10px 10px 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h6 {
              color: #fff;

              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 167.886%;
              /* 26.862px */
            }

            p {
              color: #fff;
              font-family: Arial;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 167.886%;
            }
          }

          .revenge-clr {
            background: linear-gradient(90deg, #c84e89 0%, #f15f79 100%);
          }

          .revenge-clr {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background: linear-gradient(90deg, #c84e89 0%, #f15f79 100%);
          }

          .Profit-clr {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            border-radius: 8px;
            background: linear-gradient(90deg, #bf5ae0 0%, #a811da 100%);
          }

          .teamsize-clr {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            border-radius: 8px;
            background: linear-gradient(90deg, #ffe259 0%, #ffa751 100%);
          }

          .Reach-clr {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            border-radius: 8px;
            background: linear-gradient(90deg, #2193b0 0%, #6dd5ed 100%);
          }
        }

        .Culture-clr {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // text-align: center;

          border-radius: 8px;
          background: rgba(13, 77, 128, 0.7);
          // background: linear-gradient(90deg, #02AAB0 0%, #00CDAC 100%);
        }

        .reachlast {
          height: 100%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background: rgba(13, 77, 128, 0.7);
          // background: linear-gradient(90deg, #614385 0%, #516395 100%);
        }

        .employ {
          height: 100%;
          text-align: center;

          border-radius: 8px;
          background: rgba(13, 77, 128, 0.7);
          // background: linear-gradient(90deg, #E43A15 0%, #E65245 100%);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .right-side {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 25px;
          width: 100%;

          .right-l {
            width: 100%;

            .title {
              width: 100%;
              padding-bottom: 34px;

              h6 {
                color: #000;
                text-align: center;
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }

            .content {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .containers {
                display: flex;
                align-items: center;
                height: 55px;
                border-radius: 4px;
                background: #fff;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
              }

              select,
              input {
                height: 100%;
                margin: 0;
                box-sizing: border-box;
                border: none;
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              select {
                display: flex;
                width: 86px;
                cursor: pointer;
                text-align: center !important;

                background: #fff;
                outline: none;
                color: #0d4d80;
                text-align: center;
                font-family: Arial;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-right: 1px solid rgba(0, 0, 0, 0.2);

                /* Remove the default focus outline */
                option {
                  background: white;
                  box-shadow: none;
                  text-align: center !important;
                  outline: none;
                  border: none;
                  // border: 1px solid red !important;
                }
              }

              select:focus,
              option {
                background: white;
                box-shadow: none;
                text-align: center;
                // border: 1px solid red !important;
              }

              option:checked {
                // color: #0D4D80;
                // text-align: center;
                // font-family: Arial;
                // font-size: 14px;
                // font-style: normal;
                // font-weight: 400;
                // line-height: normal;
                background: white;
                box-shadow: none;
                text-align: center;
                // border: 1px solid red !important;
              }

              input {
                flex: 1;
                padding: 0 10px;
                cursor: pointer;
                border-radius: 4px;
                background: #fff;
                outline: none;

                &::placeholder {
                  color: #000;
                  opacity: 0.2;
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                /* Remove the default focus outline */
              }
            }
          }

          .right-r {
            width: 100%;

            .title {
              width: 100%;
              padding-bottom: 34px;

              h6 {
                color: #000;
                text-align: center;
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }

            .content {
              display: flex;
              flex-direction: column;
              gap: 15px;

              .containers {
                display: flex;
                align-items: center;
                height: 55px;
                border-radius: 4px;
                background: #fff;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
              }

              select,
              input {
                height: 100%;
                margin: 0;
                box-sizing: border-box;
                border: none;
                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              select {
                display: flex;
                width: 86px;
                cursor: pointer;
                text-align: center !important;

                background: #fff;
                outline: none;
                color: #0d4d80;
                text-align: center;
                font-family: Arial;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-right: 1px solid rgba(0, 0, 0, 0.2);

                /* Remove the default focus outline */
                option {
                  background: white;
                  box-shadow: none;
                  text-align: center !important;
                  outline: none;
                  border: none;
                  // border: 1px solid red !important;
                }
              }

              select:focus,
              option {
                background: white;
                box-shadow: none;
                text-align: center;
                // border: 1px solid red !important;
              }

              option:checked {
                // color: #0D4D80;
                // text-align: center;
                // font-family: Arial;
                // font-size: 14px;
                // font-style: normal;
                // font-weight: 400;
                // line-height: normal;
                background: white;
                box-shadow: none;
                text-align: center;
                // border: 1px solid red !important;
              }

              input {
                flex: 1;
                padding: 0 10px;
                cursor: pointer;
                border-radius: 4px;
                background: #fff;
                outline: none;

                &::placeholder {
                  color: #000;
                  opacity: 0.2;
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                /* Remove the default focus outline */
              }
            }
          }
        }
      }

      .addnew {
        display: flex;
        justify-content: end;
        padding-top: 17px;

        .addSection {
          display: flex;
          align-items: center;
          cursor: pointer;

          span {
            color: #5ad2ee;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }

    .rankgoal {
      padding: 19px 0px 100px 11px;
      border-top: 0.962px solid #eaecf0;
      background: #fff;
      width: 100%;

      .rankgoal-content {
        .rank-title {
          width: 100%;
          padding-bottom: 37px;

          h6 {
            color: #0d4d80;

            font-family: Arial;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }

        .goals-card {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 20px;

          .first-goal {
            display: flex;
            // justify-content: space-evenly;
            gap: 56px;

            .container-first {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 55px;
              border-radius: 4px;
              background: #fff;
              box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
              position: relative;
              overflow: hidden;
            }

            select {
              width: 100%;
              cursor: pointer;
              border-radius: 4px;
              background: #fff;
              outline: none;
              border: none;
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
              height: 100%;
            }

            .selected-option {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              font-family: Arial;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: #0d4d80;
              cursor: pointer;

              .svg {
                margin-left: 10px;
                width: 16px;
                height: 16px;
              }
            }

            .save-btn {
              display: flex;
              justify-content: end;
              margin-top: 8px;
              margin-bottom: 4px;

              .saveBtn {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 4px;
                background: var(--green, #20ac27);
                padding: 10px;
              }

              button {
                // border-radius: 4px;
                // background: linear-gradient(90deg, #02A3D6 0%, rgba(2, 163, 214, 0.00) 131.65%);
                // color: #FFF;
                // font-family: Arial;
                // font-size: 18px;
                // font-style: normal;
                // font-weight: 400;
                // line-height: normal;
                // padding: 10px;
                color: #fff;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding: 10px;
                border-radius: 4px;
                background: linear-gradient(
                  90deg,
                  #02a3d6 0%,
                  rgba(2, 163, 214, 0) 131.65%
                );
              }
            }
          }

          .first-goals {
            display: flex;
            // justify-content: space-evenly;
            justify-content: start;
            // justify-content: space-evenly;
            gap: 56px;

            .container-first {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 55px;
              border-radius: 4px;
              background: #fff;
              box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
              position: relative;
              overflow: hidden;
            }

            .save-btn {
              display: flex;
              justify-content: end;
              margin-top: 8px;
              margin-bottom: 4px;

              .saveBtn {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 4px;
                background: var(--green, #20ac27);
                padding: 10px;
              }

              button {
                // border-radius: 4px;
                // background: linear-gradient(90deg, #02A3D6 0%, rgba(2, 163, 214, 0.00) 131.65%);
                // color: #FFF;
                // font-family: Arial;
                // font-size: 18px;
                // font-style: normal;
                // font-weight: 400;
                // line-height: normal;
                // padding: 10px;
                color: #fff;
                font-family: Arial;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding: 10px;
                border-radius: 4px;
                background: linear-gradient(
                  90deg,
                  #02a3d6 0%,
                  rgba(2, 163, 214, 0) 131.65%
                );
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .goal {
    .goalContent {
      .rankgoal {
        .rankgoal-content {
          .goals-card {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .goal {
    // margin-top: 110px;
    padding-top: 60px;
    width: 100%;
    background-image: url("../../assets/images/layerimg.png");

    .goalContent {
      padding: 0px 15px 0px 15px;

      .titleheading {
        padding: 20px 0px 20px 0px;
        // padding: 88px 0px 0px 30px;
        width: 100%;

        .goaltitle {
          width: 100%;

          padding: 19.232px 10px 18.27px 10px;
          // border-bottom: 0.962px solid #EAECF0;
          // background: #FFF;
          background-color: var(--white);
          border-bottom: 0.962px solid #eaecf0;
          background: #fff;
          box-shadow: 6px 6px 16px 0px rgba(0, 0, 0, 0.1),
            -2px -2px 16px 0px rgba(187, 210, 255, 0.5);

          h5 {
            color: #0d4d80;
            font-family: Arial;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 4px;
          }

          span {
            color: #0d4d80;
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }

      .performance-card {
        padding-top: 26px;
        padding-bottom: 50px;

        .performance-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 20px;

          .left-side {
            padding-top: 32px;
            width: 100%;

            .content-div {
              width: 100%;
              // width: 205px;
              height: 125px;
              text-align: center;
              border-radius: 8px;
              //   background: rgba(13, 77, 128, 0.75);
              background: #00a2d6;
              // background: linear-gradient(90deg, #00D2FF 0%, #3A7BD5 100%);
              padding: 10px 10px 10px 10px;

              h6 {
                color: #fff;

                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 167.886%;
                /* 26.862px */
              }

              p {
                color: #fff;
                font-family: Arial;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 167.886%;
              }
            }

            .revenge-clr {
              background: linear-gradient(90deg, #c84e89 0%, #f15f79 100%);
            }

            .revenge-clr {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 125px;
              background: rgba(13, 77, 128, 0.9);
              // background: linear-gradient(90deg, #C84E89 0%, #F15F79 100%);
            }

            .Profit-clr {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 125px;
              text-align: center;
              border-radius: 8px;
              background: rgba(13, 77, 128, 0.85);
              // background: linear-gradient(90deg, #BF5AE0 0%, #A811DA 100%);
            }

            .teamsize-clr {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 125px;
              text-align: center;
              border-radius: 8px;
              background: rgba(13, 77, 128, 0.85);
              // background: linear-gradient(90deg, #FFE259 0%, #FFA751 100%);
            }

            .Reach-clr {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 125px;
              text-align: center;
              border-radius: 8px;
              background: rgba(13, 77, 128, 0.75);
              // background: linear-gradient(90deg, #2193B0 0%, #6DD5ED 100%);
            }
          }

          .left-sides {
            padding-top: 0px;

            .content-div {
              width: 100%;
              // width: 205px;
              text-align: center;
              border-radius: 8px;
              //   background: var(--primary-dark, #0d4d80);
              background: #00a2d6;
              // background: linear-gradient(90deg, #00D2FF 0%, #3A7BD5 100%);
              padding: 10px 10px 10px 10px;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              h6 {
                color: #fff;

                font-family: Arial;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 167.886%;
                /* 26.862px */
              }

              p {
                color: #fff;
                font-family: Arial;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 167.886%;
              }
            }

            .revenge-clr {
              background: linear-gradient(90deg, #c84e89 0%, #f15f79 100%);
            }

            .revenge-clr {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 125px;
              background: linear-gradient(90deg, #c84e89 0%, #f15f79 100%);
            }

            .Profit-clr {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              text-align: center;
              border-radius: 8px;
              background: linear-gradient(90deg, #bf5ae0 0%, #a811da 100%);
            }

            .teamsize-clr {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              text-align: center;
              border-radius: 8px;
              background: linear-gradient(90deg, #ffe259 0%, #ffa751 100%);
            }

            .Reach-clr {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;
              text-align: center;
              border-radius: 8px;
              background: linear-gradient(90deg, #2193b0 0%, #6dd5ed 100%);
            }
          }

          .Culture-clr {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // text-align: center;
            border-radius: 8px;
            background: linear-gradient(90deg, #02aab0 0%, #00cdac 100%);
          }

          .reachlast {
            height: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: linear-gradient(90deg, #614385 0%, #516395 100%);
          }

          .employ {
            height: 100%;
            text-align: center;

            border-radius: 8px;
            background: linear-gradient(90deg, #e43a15 0%, #e65245 100%);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .right-side {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 0px;
            width: 100%;

            .right-l {
              width: 100%;

              .title {
                width: 100%;
                padding-bottom: 20px;

                h6 {
                  color: #000;
                  text-align: center;
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                }
              }

              .content {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .containers {
                  display: flex;
                  align-items: center;
                  height: 55px;
                  border-radius: 4px;
                  background: #fff;
                  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
                }

                select,
                input {
                  height: 100%;
                  margin: 0;
                  box-sizing: border-box;
                  border: none;
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                select {
                  display: flex;
                  width: 86px;
                  cursor: pointer;
                  text-align: center !important;

                  background: #fff;
                  outline: none;
                  color: #0d4d80;
                  text-align: center;
                  font-family: Arial;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  border-right: 1px solid rgba(0, 0, 0, 0.2);

                  /* Remove the default focus outline */
                  option {
                    background: white;
                    box-shadow: none;
                    text-align: center !important;
                    outline: none;
                    border: none;
                    // border: 1px solid red !important;
                  }
                }

                select:focus,
                option {
                  background: white;
                  box-shadow: none;
                  text-align: center;
                  // border: 1px solid red !important;
                }

                option:checked {
                  // color: #0D4D80;
                  // text-align: center;
                  // font-family: Arial;
                  // font-size: 14px;
                  // font-style: normal;
                  // font-weight: 400;
                  // line-height: normal;
                  background: white;
                  box-shadow: none;
                  text-align: center;
                  // border: 1px solid red !important;
                }

                input {
                  flex: 1;
                  padding: 0 10px;
                  cursor: pointer;
                  border-radius: 4px;
                  background: #fff;
                  outline: none;

                  &::placeholder {
                    color: #000;
                    opacity: 0.2;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }

                  /* Remove the default focus outline */
                }
              }
            }

            .right-r {
              width: 100%;

              .title {
                width: 100%;
                padding-bottom: 10px;
                // padding-top: 20px;

                h6 {
                  color: #000;
                  text-align: center;
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  margin-top: 20px;
                }
              }

              .content {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .containers {
                  display: flex;
                  align-items: center;
                  height: 55px;
                  border-radius: 4px;
                  background: #fff;
                  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
                }

                select,
                input {
                  height: 100%;
                  margin: 0;
                  box-sizing: border-box;
                  border: none;
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                select {
                  display: flex;
                  width: 86px;
                  cursor: pointer;
                  text-align: center !important;

                  background: #fff;
                  outline: none;
                  color: #0d4d80;
                  text-align: center;
                  font-family: Arial;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  border-right: 1px solid rgba(0, 0, 0, 0.2);

                  /* Remove the default focus outline */
                  option {
                    background: white;
                    box-shadow: none;
                    text-align: center !important;
                    outline: none;
                    border: none;
                    // border: 1px solid red !important;
                  }
                }

                select:focus,
                option {
                  background: white;
                  box-shadow: none;
                  text-align: center;
                  // border: 1px solid red !important;
                }

                option:checked {
                  // color: #0D4D80;
                  // text-align: center;
                  // font-family: Arial;
                  // font-size: 14px;
                  // font-style: normal;
                  // font-weight: 400;
                  // line-height: normal;
                  background: white;
                  box-shadow: none;
                  text-align: center;
                  // border: 1px solid red !important;
                }

                input {
                  flex: 1;
                  padding: 0 10px;
                  cursor: pointer;
                  border-radius: 4px;
                  background: #fff;
                  outline: none;

                  &::placeholder {
                    color: #000;
                    opacity: 0.2;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }

                  /* Remove the default focus outline */
                }
              }
            }
          }
        }

        .addnew {
          display: flex;
          justify-content: end;
          padding-top: 17px;

          .addSection {
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
              color: #5ad2ee;
              font-family: Arial;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }

      .rankgoal {
        padding: 19px 0px 100px 11px;
        border-top: 0.962px solid #eaecf0;
        background: #fff;
        width: 100%;

        .rankgoal-content {
          .rank-title {
            width: 100%;
            padding-bottom: 37px;

            h6 {
              color: #0d4d80;

              font-family: Arial;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }

          .goals-card {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;

            .first-goal {
              display: flex;
              // justify-content: space-evenly;
              gap: 56px;

              .container-first {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 55px;
                border-radius: 4px;
                background: #fff;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
                position: relative;
                overflow: hidden;
              }

              select {
                width: 100%;
                cursor: pointer;
                border-radius: 4px;
                background: #fff;
                outline: none;
                border: none;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                height: 100%;
              }

              .selected-option {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                font-family: Arial;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #0d4d80;
                cursor: pointer;

                .svg {
                  margin-left: 10px;
                  width: 16px;
                  height: 16px;
                }
              }

              .save-btn {
                display: flex;
                justify-content: end;
                margin-top: 8px;
                margin-bottom: 4px;

                .saveBtn {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #fff;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  border-radius: 4px;
                  background: var(--green, #20ac27);
                  padding: 10px;
                }

                button {
                  // border-radius: 4px;
                  // background: linear-gradient(90deg, #02A3D6 0%, rgba(2, 163, 214, 0.00) 131.65%);
                  // color: #FFF;
                  // font-family: Arial;
                  // font-size: 18px;
                  // font-style: normal;
                  // font-weight: 400;
                  // line-height: normal;
                  // padding: 10px;
                  color: #fff;
                  font-family: Arial;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  padding: 10px;
                  border-radius: 4px;
                  background: linear-gradient(
                    90deg,
                    #02a3d6 0%,
                    rgba(2, 163, 214, 0) 131.65%
                  );
                }
              }
            }

            .first-goals {
              display: flex;
              justify-content: center;
              // justify-content: space-evenly;
              // justify-content: space-evenly;
              gap: 0px;

              .container-first {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 55px;
                border-radius: 4px;
                background: #fff;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
                position: relative;
                overflow: hidden;
              }

              .save-btn {
                display: flex;
                justify-content: end;
                margin-top: 8px;
                margin-bottom: 4px;

                .saveBtn {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #fff;
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  border-radius: 4px;
                  background: var(--green, #20ac27);
                  padding: 10px;
                }

                button {
                  // border-radius: 4px;
                  // background: linear-gradient(90deg, #02A3D6 0%, rgba(2, 163, 214, 0.00) 131.65%);
                  // color: #FFF;
                  // font-family: Arial;
                  // font-size: 18px;
                  // font-style: normal;
                  // font-weight: 400;
                  // line-height: normal;
                  // padding: 10px;
                  color: #fff;
                  font-family: Arial;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  padding: 10px;
                  border-radius: 4px;
                  background: linear-gradient(
                    90deg,
                    #02a3d6 0%,
                    rgba(2, 163, 214, 0) 131.65%
                  );
                }
              }
            }
          }
        }
      }
    }
  }
}
