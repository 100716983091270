.updatemedical {
    width: 100%;
    padding-top: 60px;
    background-image: url("../../assets/images/layerimg.png");

    .medical-content {
        width: 100%;
        padding: 47px 73px 50px 104px;

        .medical-header {
            width: 100%;
            padding-block: 18px;
            text-align: center;
            border-bottom: 0.962px solid #EAECF0;
            background: #FFF;
            box-shadow: 6px 6px 16px 0px rgba(0, 0, 0, 0.10), -2px -2px 16px 0px rgba(187, 210, 255, 0.50);

            h6 {
                color: #0D4D80;
                font-family: Arial;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .innerContent {
            width: 100%;
            padding-top: 30px;

            .firstline-theme {
                width: 100%;

                .themeTitle {
                    width: 100%;

                    h6 {
                        color: var(--primary-dark, #0D4D80);
                        font-family: Arial;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }


                }

                .themeCatagery {
                    display: flex;
                    gap: 28px;
                    padding: 11px 0px 27px 0px;

                    .firstcatagery {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        h6 {
                            color: #000;

                            font-family: Arial;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;


                        }
                    }
                }



            }

            .medical-cards {
                width: 100%;

                .medicalTop {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    // gap: 35px;
                    gap: 23px;
                    padding-top: 10px;

                    .firstCard {
                        width: 24%;
                        // height: 466px;
                        border-radius: 4px;
                        background: #FFF;
                        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

                        .titleCard {
                            display: flex;
                            justify-content: space-between;
                            padding: 12px 22px 13px 22px;

                            .titleLeft {
                                span {
                                    color: #000;
                                    font-family: Arial;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            .titleright {

                                display: flex;
                                gap: 10px;

                                button {
                                    padding: 4px;
                                    border-radius: 4px;
                                    border: 0.5px solid #9CCC65;
                                    color: #9CCC65;
                                    font-family: Arial;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    background: white;
                                }

                                .deletebtn {
                                    border: 0.5px solid #cc6565;
                                    color: #cc6565;
                                    background: white;

                                }
                            }
                        }

                        .cardBottom {
                            width: 100%;
                            // height: 50%;
                            padding: 10px 18px;
                            border-top: 1px dashed #D9D9D9;

                            span {
                                color: #D9D9D9;
                                font-family: Arial;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }

                            .systemButtons {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                gap: 6px;

                                .addItem {
                                    color: #9CCC65;
                                    font-family: Arial;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    border-radius: 4px;
                                    border: 0.5px solid #9CCC65;
                                    padding: 4px;
                                    background: white;
                                }

                                .selectTheme {
                                    padding: 4px;
                                    border-radius: 4px;
                                    border: 0.5px solid var(--primary-light, #00A2D6);
                                    color: var(--primary-light, #00A2D6);
                                    font-family: Arial;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    background: white;
                                }

                                .clearTheme {
                                    padding: 4px;
                                    border-radius: 4px;
                                    border: 0.5px solid #EF5350;
                                    color: #EF5350;
                                    font-family: Arial;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    background: white;
                                }
                            }

                            .cardText {
                                width: 100%;
                                height: fit-content;
                                min-height: 108px;
                                border-radius: 4px;
                                background: #BFD6E8;
                                margin-block: 12px;
                                padding: 18px;
                                // padding: 12px 0px 12px 6px;
                                // margin: 12px 0px 12px 0px;
                                // padding: 12px 4px 12px 4px;

                                p {
                                    color: #000;
                                    font-family: Arial;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            .cardText2 {
                                width: 100%;
                                background: #F6D6D5;
                                border-radius: 4px;

                                margin-block: 12px;
                                padding: 18px;
                                // padding: 12px 0px 12px 6px;
                                // margin: 12px 0px 12px 0px;
                                // padding: 12px 4px 12px 4px;

                                p {
                                    color: #000;
                                    font-family: Arial;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            .cardText3 {
                                width: 100%;
                                background: #FAEEC9;
                                border-radius: 4px;

                                margin-block: 12px;
                                padding: 18px;
                                // padding: 12px 0px 12px 6px;
                                // margin: 12px 0px 12px 0px;
                                // padding: 12px 4px 12px 4px;

                                p {
                                    color: #000;
                                    font-family: Arial;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            .bottomButton {
                                padding-top: 12px;
                                display: flex;
                                justify-content: end;
                                gap: 10px;

                                .moveTheme {
                                    padding: 4px;
                                    border-radius: 4px;
                                    border: 0.5px solid var(--primary-light, #00A2D6);
                                    color: var(--primary-light, #00A2D6);
                                    font-family: Arial;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    background: white;
                                }

                                .removeItem {
                                    border-radius: 4px;
                                    border: 0.5px solid #EF5350;
                                    padding: 4px;
                                    color: #EF5350;
                                    font-family: Arial;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    background: white;
                                }
                            }

                            .bottomButtons {
                                padding-top: 12px;
                                display: flex;
                                justify-content: start;
                                gap: 10px;

                                .moveTheme {
                                    padding: 4px;
                                    border-radius: 4px;
                                    border: 0.5px solid var(--primary-light, #00A2D6);
                                    color: var(--primary-light, #00A2D6);
                                    font-family: Arial;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    background: white;
                                }

                                .removeItem {
                                    border-radius: 4px;
                                    border: 0.5px solid #EF5350;
                                    padding: 4px;
                                    color: #EF5350;
                                    font-family: Arial;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    background: white;
                                }
                            }
                        }
                    }
                }

                .medicalBottom {
                    width: 100%;
                    display: flex;
                    gap: 41px;
                    padding: 24px 0px 24px 0px;

                    .bottomLeft {
                        width: 40%;
                        // height: 572px;
                        border-radius: 4px;
                        background: #FFF;
                        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

                        .bottomLeft-content {
                            padding: 17px 10px 17px 12px;

                            .title {
                                display: flex;
                                flex-direction: column;
                                gap: 5px;

                                h6 {
                                    color: var(--primary-dark, #0D4D80);
                                    font-family: Arial;
                                    font-size: 24px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: normal;
                                }

                                span {
                                    color: var(--primary-dark, #0D4D80);
                                    font-family: Arial;
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;

                                }

                                .select-boxes {
                                    padding-top: 0px;
                                    display: flex;
                                    flex-direction: column;
                                    gap: 30px;

                                    .custom-select {
                                        width: 100%;
                                        height: 48px;
                                        // padding: 10px 24px;
                                        /* Adjust height as needed */
                                        position: relative;
                                        display: inline-block;
                                        border-radius: 6px;
                                        border: 1px solid var(--primary-dark, #0D4D80);
                                        background: #FFF;
                                        overflow: hidden;



                                        select {
                                            width: 100%;
                                            height: 100%;
                                            padding: 10px;
                                            border: none;
                                            background: transparent;
                                            outline: none;
                                            -webkit-appearance: none;
                                            -moz-appearance: none;
                                            appearance: none;
                                            cursor: pointer;
                                            // color: #C3C3C3;
                                            // font-family: Arial;
                                            // font-size: 24px;
                                            // font-style: normal;
                                            // font-weight: 400;
                                            // line-height: normal;
                                            // white-space: break-spaces;
                                            padding-right: 59px;
                                            // color: #C3C3C3;
                                            font-family: Arial;
                                            font-size: 18px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;



                                            // padding-right: 90px;

                                            &::placeholder {
                                                color: #C3C3C3;
                                                font-family: Arial;
                                                font-size: 24px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;
                                            }

                                            .options {

                                                // background: #ca0a0a;
                                                padding: 10px 24px;
                                                // color: var(--side-text, #C3C3C3);
                                                color: var(--side-text, #000);
                                                font-family: Arial;
                                                font-size: 18px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;
                                                border: 1px solid green !important;



                                            }
                                        }

                                        &::before {
                                            content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M8 12.586l-5.707-5.707 1.414-1.414L8 9.758l4.293-4.293 1.414 1.414z"/></svg>');
                                            /* Unicode character for down arrow */
                                            position: absolute;
                                            top: 50%;
                                            right: 10px;
                                            transform: translateY(-50%);
                                            font-size: 16px;
                                            color: var(--primary-dark, #0D4D80);
                                        }

                                        /* Styling placeholder option */
                                        option[value=""] {
                                            border-radius: 6px;
                                            // border: 1px solid var(--primary-dark, #0D4D80);
                                            background: #FFF;
                                            color: #C3C3C3;
                                            font-family: Arial;
                                            font-size: 24px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;

                                        }

                                        .options {
                                            color: #C3C3C3;
                                            font-family: Arial;
                                            font-size: 24px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;
                                            margin-left: 50px !important;
                                            // border: 1px solid var(--primary-dark, #0D4D80);


                                        }
                                    }
                                }

                                .priorities-card {
                                    width: 100%;

                                    .title {
                                        width: 100%;
                                        padding: 32px 0px 15px 0px;

                                        h6 {
                                            color: #0D4D80;
                                            font-family: Arial;
                                            font-size: 24px;
                                            font-style: normal;
                                            font-weight: 700;
                                            line-height: normal;
                                        }
                                    }

                                    .inputField-section {
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        gap: 8px;

                                        input {
                                            width: 100%;
                                            border-radius: 4px;
                                            border: 0.5px solid #0D4D80;
                                            padding: 10px;

                                            &::placeholder {
                                                color: black;
                                                // color: #D9D9D9;
                                                font-family: Arial;
                                                font-size: 18px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;
                                            }



                                        }


                                    }
                                }
                            }
                        }
                    }

                    .bottomRight {
                        width: 60%;
                        // height: 572px;
                        border-radius: 4px;
                        background: #FFF;
                        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

                        .bottomright-header {
                            display: flex;
                            justify-content: space-between;
                            padding: 13px 5px 9px 5px;

                            .headerLeft {
                                span {
                                    color: #000;
                                    font-family: Arial;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            .headerRight {
                                button {
                                    padding: 4px;
                                    border-radius: 4px;
                                    border: 0.5px solid #EF5350;
                                    color: #EF5350;
                                    font-family: Arial;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    background: white;
                                }
                            }
                        }

                        .bootomright-bottom {
                            padding: 0px 10px 0px 10px;

                            .firstBottom {
                                width: 100%;
                                padding: 10px 18px;
                                border-radius: 4px;
                                background: #BFD6E8;

                                p {
                                    color: #000;
                                    font-family: Arial;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }

                            }

                            .firstBottom1 {
                                width: 100%;
                                padding: 10px 18px;
                                border-radius: 4px;
                                background: #F6D6D5;


                                p {
                                    color: #000;
                                    font-family: Arial;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }

                            }

                            .firstBottom2 {
                                width: 100%;
                                padding: 10px 18px;
                                border-radius: 4px;
                                background: #FAEEC9;

                                p {
                                    color: #000;
                                    font-family: Arial;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }

                            }

                            .firstbottom-btn {
                                width: 100%;
                                display: flex;
                                justify-content: end;
                                padding-block: 16px;

                                button {
                                    padding: 4px;
                                    border-radius: 4px;
                                    border: 0.5px solid #EF5350;
                                    color: #EF5350;
                                    font-family: Arial;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    background: white;
                                }
                            }
                        }
                    }
                }

                // button css here
                .save-btn {
                    width: 100%;
                    display: flex;
                    justify-content: end;
                    // padding-top: 28px;

                    button {
                        color: #FFF;
                        font-family: Arial;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        padding: 10px;
                        border-radius: 4px;
                        background: linear-gradient(90deg, #02A3D6 0%, rgba(2, 163, 214, 0.00) 131.65%);
                    }
                }
            }


        }
    }

    .finalthemeCard {
        // background: red;
        width: 100%;

        .cardBottom {

            .firstCard2 {

                .titleCard {
                    padding-block: 5px;
                }

                .bottomButton {
                    display: flex;
                    margin-block: 10px;

                    justify-content: start;
                    gap: 10px;
                    margin-bottom: 20px;


                    .moveTheme {
                        border-radius: 4px;
                        border: 0.5px solid var(--primary-light, #00A2D6);
                        padding: 4px;
                        color: var(--primary-light, #00A2D6);
                        font-family: Arial;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        background: white;
                    }
                }
            }
        }
    }
}



@media only screen and (max-width: 1600px) {
    .updatemedical {
        width: 100%;
        padding-top: 60px;
        background-image: url("../../assets/images/layerimg.png");

        .medical-content {
            width: 100%;
            padding: 47px 50px 50px 50px;


            .innerContent {

                .medical-cards {
                    width: 100%;

                    .medicalTop {
                        width: 100%;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 20px;

                        .firstCard {
                            width: 100%;





                        }
                    }

                    .medicalBottom {
                        width: 100%;
                        display: flex;
                        gap: 20px;
                        padding: 24px 0px 24px 0px;

                        .bottomLeft {
                            width: 50%;




                        }

                        .bottomRight {
                            width: 50%;




                        }
                    }



                    // button css here

                }




            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .updatemedical {
        width: 100%;
        padding-top: 60px;
        background-image: url("../../assets/images/layerimg.png");

        .medical-content {
            width: 100%;
            padding: 10px 10px 50px 10px;


            .innerContent {
                .firstline-theme {
                    width: 100%;

                    .themeTitle {
                        width: 100%;

                        h6 {

                            font-size: 18px;

                        }


                    }

                    .themeCatagery {
                        display: flex;
                        gap: 12px;
                        padding: 11px 0px 27px 0px;

                        .firstcatagery {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            h6 {

                                font-size: 13px;



                            }

                            svg {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }



                }

                .medical-cards {
                    width: 100%;

                    .medicalTop {
                        width: 100%;
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        gap: 20px;

                        .firstCard {
                            width: 100%;

                            .cardBottom {
                                .cardText {
                                    width: 100%;
                                    height: 100%;
                                    padding: 10px;

                                    p {
                                        font-size: 16px;
                                        text-align: start;
                                    }

                                }

                                .cardText2 {
                                    width: 100%;
                                    height: 100%;
                                    padding: 10px;

                                    p {
                                        font-size: 16px;
                                        text-align: start;
                                    }
                                }

                                .cardText3 {
                                    width: 100%;
                                    height: 100%;
                                    padding: 10px;

                                    p {
                                        font-size: 16px;
                                        text-align: start;
                                    }
                                }
                            }



                        }
                    }

                    .medicalBottom {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        padding: 24px 0px 24px 0px;

                        .bottomLeft {
                            width: 100%;

                            .bottomLeft-content {
                                padding: 17px 10px 20px 12px;

                                .title {
                                    h6 {

                                        font-size: 20px;

                                    }

                                    span {
                                        font-size: 18px;
                                    }

                                    .select-boxes {
                                        padding-top: 0px;
                                        display: flex;
                                        flex-direction: column;
                                        gap: 10px;

                                        .custom-select {


                                            select {

                                                font-size: 18px;
                                                // white-space: break-spaces;
                                                // padding-right: 80px;

                                                color: #C3C3C3;
                                                font-family: Arial;
                                                font-size: 18px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;


                                                &::placeholder {

                                                    font-size: 18px;

                                                }

                                                .options {

                                                    color: #000;
                                                    font-size: 16px;



                                                }
                                            }

                                            &::before {
                                                content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M8 12.586l-5.707-5.707 1.414-1.414L8 9.758l4.293-4.293 1.414 1.414z"/></svg>');

                                                font-size: 16px;

                                            }

                                            /* Styling placeholder option */
                                            option[value=""] {

                                                font-size: 18px;


                                            }

                                            .options {

                                                font-size: 18px;



                                            }
                                        }
                                    }

                                    .priorities-card {
                                        width: 100%;

                                        .title {
                                            width: 100%;
                                            padding: 5px 0px 15px 0px;

                                            h6 {

                                                font-size: 20px;

                                            }
                                        }

                                        .inputField-section {
                                            width: 100%;
                                            display: flex;
                                            flex-direction: column;
                                            gap: 8px;

                                            input {
                                                width: 100%;
                                                border-radius: 4px;
                                                border: 0.5px solid #0D4D80;
                                                padding: 10px;

                                                &::placeholder {

                                                    color: #D9D9D9;
                                                    font-family: Arial;
                                                    font-size: 18px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height: normal;
                                                }



                                            }


                                        }
                                    }
                                }


                            }


                        }

                        .bottomRight {
                            width: 100%;
                            // height: 572px;
                            border-radius: 4px;
                            background: #FFF;
                            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
                            padding-bottom: 20px;

                            .bottomright-header {
                                display: flex;
                                justify-content: space-between;
                                padding: 13px 10px 9px 34px;

                                .headerLeft {
                                    span {
                                        color: #000;
                                        font-family: Arial;
                                        font-size: 18px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                    }
                                }

                                .headerRight {
                                    button {
                                        padding: 4px;
                                        border-radius: 4px;
                                        border: 0.5px solid #EF5350;
                                        color: #EF5350;
                                        font-family: Arial;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        background: white;
                                    }
                                }
                            }

                            .bootomright-bottom {
                                padding: 0px 10px 0px 10px;

                                .firstBottom {
                                    width: 100%;
                                    padding: 10px 18px;
                                    border-radius: 4px;
                                    background: #BFD6E8;

                                    p {
                                        // text-align: center;
                                        font-size: 16px;

                                    }

                                }

                                .firstBottom1 {
                                    width: 100%;
                                    padding: 10px 18px;
                                    border-radius: 4px;
                                    background: #F6D6D5;

                                    p {
                                        // text-align: center;
                                        font-size: 16px;

                                    }

                                }

                                .firstBottom2 {
                                    width: 100%;
                                    padding: 10px 18px;
                                    border-radius: 4px;
                                    background: #FAEEC9;

                                    p {
                                        // text-align: center;
                                        font-size: 16px;

                                    }

                                }

                                .firstbottom-btn {
                                    width: 100%;
                                    display: flex;
                                    justify-content: end;
                                    padding-block: 16px;

                                    button {
                                        padding: 4px;
                                        border-radius: 4px;
                                        border: 0.5px solid #EF5350;
                                        color: #EF5350;
                                        font-family: Arial;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        background: white;
                                    }
                                }
                            }
                        }
                    }

                    .save-btn {
                        width: 100%;
                        display: flex;
                        justify-content: end;
                        // padding-top: 28px;

                        button {

                            font-size: 14px;


                        }
                    }



                    // button css here

                }




            }
        }
    }


}

.addButton {
    padding: 4px;
    border-radius: 4px;
    border: 0.5px solid #9CCC65;
    color: #9CCC65;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: white;
}

.btn1 {
    background: red;
}